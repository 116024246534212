import React, { useEffect, useRef, useState } from "react";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customBox: {
      borderRadius: "5.134px",
      background: "transparent",
      width: "186.103px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      gap: "2px",
      position: "relative",
    },
    line: {
      borderRight: "2px solid #000", 
      height: "100%",
      position: "relative",
    },
    subBox: {
      width: "176.143px",
      height: "36.5px",
      background: "#EFEFEF",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    },
    selectedBox: {
      backgroundColor: "#0179FF !important",
    },
    selectionBox: {
      position: "absolute",
      border: "2px solid #0179FF",
      zIndex: 1000,
      pointerEvents: "none",
      cursor: "pointer",
      backgroundColor: "rgba(1, 121, 255, 0.50)",
    },
    whiteBox: {
      backgroundColor: "#FFFFFF",
      borderRadius: "4px",
      height: "28.5px",
      margin: "2px 2px",
      userSelect: "none",
      width: "10px",
    },
  })
);

interface GridDemoProps {
  onSelectedBoxesChange: (value: any[][][]) => void;
  selectedBoxes2: any;
  col: number;
  row: number;
  rowDistance: number;
  colDistance: number;
  widths: number[];
  selectedBoxes: any[][][];
  hiddencells: never[]
}

const GridDemo: React.FC<GridDemoProps> = ({
  onSelectedBoxesChange, selectedBoxes2, col, row, rowDistance, colDistance, widths, selectedBoxes,hiddencells
}) => {
  const initializeSelectedBoxes = () => {
    if (selectedBoxes && selectedBoxes.length > 0) {
      if (selectedBoxes.length < col) {
        const diffrence = col - selectedBoxes.length;
        const diffrenceColumnWidths = widths.slice(-diffrence);
        const newArray = diffrenceColumnWidths.map(columnWidth => {
          const boxesInColumn = Math.floor(columnWidth / 30.5);
          return new Array(row).fill(null).map(() => new Array(boxesInColumn).fill(false));
        });
        return [...selectedBoxes, ...newArray];
      } else if (selectedBoxes.length > col) {
        const diffrence = selectedBoxes.length - col;
        return selectedBoxes.slice(0, -diffrence);
      } else {
        return widths.map((columnWidth, colIndex) => {
          const boxesInColumn = Math.floor(columnWidth / 30.5);
          return new Array(row).fill(null).map((_, rowIndex) => {
            const oldRow = selectedBoxes[colIndex] && selectedBoxes[colIndex][rowIndex];
            return new Array(boxesInColumn).fill(null).map((_, boxIndex) => {
              return oldRow && oldRow[boxIndex] !== undefined ? oldRow[boxIndex] : false;
            });
          });
        });
      }
    } else {
      return widths.map(columnWidth => {
        const boxesInColumn = Math.floor(columnWidth / 30.5);
        return new Array(row).fill(null).map(() => new Array(boxesInColumn).fill(false));
      });
    }
  };

  const [currentSelectedBoxes, setCurrentSelectedBoxes] = useState(initializeSelectedBoxes);

  useEffect(() => {
    onSelectedBoxesChange(currentSelectedBoxes);
  }, [currentSelectedBoxes]);

  useEffect(() => {
    setCurrentSelectedBoxes(initializeSelectedBoxes());
  }, [selectedBoxes2, widths, row]);

  const [selectionBox, setSelectionBox] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [isSelecting, setIsSelecting] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const handleMouseDown = (event: React.MouseEvent) => {
    const containerBounds = containerRef.current?.getBoundingClientRect();
    if (!containerBounds) return;

    const x = event.clientX - containerBounds.left;
    const y = event.clientY - containerBounds.top;
    setStartPoint({ x, y });
    setSelectionBox({ x, y, width: 0, height: 0 });
    setIsSelecting(true);
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (!isSelecting || !containerRef.current) return;
    const containerBounds = containerRef.current.getBoundingClientRect();

    const x = Math.min(event.clientX - containerBounds.left, startPoint.x);
    const y = Math.min(event.clientY - containerBounds.top, startPoint.y);
    const width = Math.abs(event.clientX - containerBounds.left - startPoint.x);
    const height = Math.abs(event.clientY - containerBounds.top - startPoint.y);
    setSelectionBox({ x, y, width, height });
  };

  const handleMouseUp = () => {
    if (!isSelecting || !containerRef.current) return;
    setIsSelecting(false);

    const boxWidth = 30.5;
    const boxHeight = 36.5;

    const containerBounds = containerRef.current.getBoundingClientRect();
    const selectionStartX = selectionBox.x;
    const selectionEndX = selectionStartX + selectionBox.width;
    const selectionStartY = selectionBox.y;
    const selectionEndY = selectionStartY + selectionBox.height;

    let columnOffsets = widths.reduce((acc: any, curr, idx) => {
      if (idx === 0) {
        acc.push(0);
      } else {
        acc.push(acc[idx - 1] + widths[idx - 1]);
      }
      return acc;
    }, []);

    let intersectingBoxCount = 0;
    let wasAlreadySelected = false;
    let indexRow: number = -1;
    let indexCol: number = -1;
    let IndexBox: number = -1;

    let updatedSelectedBoxes = currentSelectedBoxes.map((customBox, customBoxIndex) =>
      customBox.map((set, setIndex) =>
        set.map((isSelected, boxIndex) => {
          const columnOffset = columnOffsets[customBoxIndex];
          const dynamicBoxCount = Math.floor(widths[customBoxIndex] / boxWidth);

          const boxStartX = columnOffset + (boxIndex * (widths[customBoxIndex] / dynamicBoxCount)) + (rowDistance * customBoxIndex * 5);
          const boxEndX = boxStartX + (widths[customBoxIndex] / dynamicBoxCount);
          const boxTopY = setIndex * (boxHeight + (colDistance * 5));
          const boxBottomY = boxTopY + boxHeight;

          const intersectsSelection = !(
            boxEndX < selectionStartX ||
            boxStartX > selectionEndX ||
            boxBottomY < selectionStartY ||
            boxTopY > selectionEndY
          );
          if (intersectsSelection) {
            if (indexRow !== customBoxIndex && indexCol !== setIndex && IndexBox !== boxIndex) {
              intersectingBoxCount++;
            }
            if (isSelected) wasAlreadySelected = true;
            indexRow = customBoxIndex;
            indexCol = setIndex;
            IndexBox = boxIndex;
          }
          if(!hiddencells[setIndex][customBoxIndex]) {
            return false
          }
          return isSelected || intersectsSelection;
        })
      )
    );

    if (intersectingBoxCount === 1 && wasAlreadySelected) {
      updatedSelectedBoxes[indexRow][indexCol][IndexBox] = false;

    
     
      setCurrentSelectedBoxes(updatedSelectedBoxes);
    } else {
     
      setCurrentSelectedBoxes(updatedSelectedBoxes);
    }
  };

  const renderBoxes = (customBoxIndex: number, setIndex: number, totalWidth: number, selectedBoxes: any) => {
    const boxWidth = 30.5;
    const dynamicBoxCount = Math.floor(totalWidth / boxWidth);
    return Array.from({ length: dynamicBoxCount }, (_, boxIndex) => (
      <div
        key={`${customBoxIndex}-${setIndex}-${boxIndex}`}
        className={`${classes.whiteBox} ${(selectedBoxes[customBoxIndex]?.[setIndex]?.[boxIndex]) ? classes.selectedBox : ""}`}
        style={{ width: `${boxWidth}px` }}
      />
    ));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F5F9FF",
      }}
    >
      <div
        style={{
          backgroundColor: "#F5F9FF",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px"
        }}
      >
        <div
          style={{
            backgroundColor: "#F5F9FF",

            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            ref={containerRef}
            style={{ display: "flex", flexDirection: "row", position: 'relative', gap: `${rowDistance * 5}px`, cursor: isSelecting ? 'pointer' : 'default', }} 
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          >
            {isSelecting && (
              <div
                className={classes.selectionBox}
                style={{
                  left: selectionBox.x,
                  top: selectionBox.y,
                  width: selectionBox.width,
                  height: selectionBox.height,
                }}
              />
            )}
            {Array.from({ length: col }).map((_, customBoxIndex) => (
              <div key={customBoxIndex} style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "center", gap: `${colDistance * 5}px` }}>
                <div className={classes.customBox} style={{ gap: `${colDistance * 5}px`, width: `${widths[customBoxIndex]}px` }}>
                  {Array.from({ length: row }).map((_, setIndex) => (
                    <div
                      key={`${customBoxIndex}-${setIndex}`}
                      className={classes.subBox}
                      style={{ width: `${widths[customBoxIndex]}px` , visibility:hiddencells[setIndex]&& hiddencells[setIndex][customBoxIndex] ? 'unset':'hidden',
                   

                    }}

                    >
                      {renderBoxes(customBoxIndex, setIndex, widths[customBoxIndex], currentSelectedBoxes)}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridDemo;
