import React, { useState } from "react";
import { TextField, Grid, Paper, IconButton, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Typography from "@mui/material/Typography";

import { Box, Button } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { Remove as RemoveIcon } from "@mui/icons-material";
import { wrap } from "module";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      // marginTop: theme.spacing(2),
      margin: "-5px", // Adjust this value as needed
      width: "auto",
      "& > .MuiGrid-item": {
        padding: "5px", // Adjust this value as needed
      },
    },
    flexContainer: {
      display: "flex",
      margin: `2px`,
    },
    flexItem: {
      padding: `2px 3px`,
    },
    selectedSquare: {
      border: "2px solid #0179FF"
// A gold color for selection, but choose what fits your design
    },
    square: {
      width: 137,
      height: 84,
      backgroundColor: "#B5D8FF",
      boxShadow: "none",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize:"cover", // Ensure the image covers the square
      backgroundPosition: 'center',
      "&:hover":{
        cursor:"pointer"
      }
    },
    controlBar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(2),
      backgroundColor: "#D9E7F8",
      width: "100%",
      borderRadius: "3px",

      // alignSelf: "start",
    },
    verticalControlBar: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "0 34px",
      backgroundColor: "#D9E7F8",

      borderRadius: "3px",
      alignItems: "center",
      minWidth: "82px",
      marginLeft: theme.spacing(2),
      height: "100%", // Make the vertical bar take the height of the squares
    },
    iconButton: {
      width: 28,
      height: 28,
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      color: "#0179FF",
    },
    iconButton2: {
      padding: 6, // Set padding to 0 to remove the default padding
    },
  })
);

interface GridDemoProps {
  row: number;
  col: number;
  rowDistance: number;
  setRowDistance: React.Dispatch<React.SetStateAction<number>>;
  colDistance: number;
  setColDistance: React.Dispatch<React.SetStateAction<number>>;
  setRow: React.Dispatch<React.SetStateAction<number>>;
  setCol: React.Dispatch<React.SetStateAction<number>>;
  selectedSquare:{ col: number; row: number } | null;
  squaresWithImages:Array<Array<string>>;
  setSelectedSquare: React.Dispatch<React.SetStateAction<{
    col: number;
    row: number;
} | null>>;

}

const GridDemo: React.FC<GridDemoProps> = ({
  row,
  col,
  squaresWithImages,
  setSelectedSquare,
  selectedSquare
}) => {
  const classes = useStyles();

  const handleSquareClick = (colIndex: number, rowIndex: number) => {
    setSelectedSquare({ col: colIndex, row: rowIndex });
  };

  const renderSquares = () => {
    return Array.from({ length: row }, (_, rowIndex) => (
      <div key={rowIndex} className={classes.flexContainer}>
        {Array.from({ length: col }, (_, colIndex) => {
          const imageSrc = squaresWithImages[rowIndex]?.[colIndex];
          const isSelected = selectedSquare && selectedSquare.col === colIndex && selectedSquare.row === rowIndex;

          return (
            <div
              key={`${rowIndex}-${colIndex}`}
              onClick={() => handleSquareClick(colIndex, rowIndex)}
              style={{ padding: `4px 4px` }}
            >
              <Paper
                className={`${classes.square} ${isSelected ? classes.selectedSquare : ''}`}
                style={{
                  backgroundImage: imageSrc ? `url(${imageSrc})` : '',
                }}
              ></Paper>
            </div>
          );
        })}
      </div>
    ));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems:"center",
        flexDirection: "column",
        backgroundColor: "#F5F9FF",
      }}
    >

      <div
        style={{
          backgroundColor: "#F5F9FF",
          height: "100vh",
          display: "flex",
          width: "fit-content",
          padding: "0 10px",
          overflow: "auto",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
                <div style={{alignSelf:"center",fontSize:"32px",color:"#9CA0AB",fontWeight:800,marginBottom:"20px"}}>{col} x {row} Packs</div>

        <div style={{ display: "flex" }}>
          <div>{renderSquares()}</div>
        </div>
      </div>
    </div>
  );
};

export default GridDemo;
