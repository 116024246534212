import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, CircularProgress, Snackbar, Alert } from "@mui/material";
import TowLines from "../../../assets/icons/TowLinesVertical.svg";
import {
  reorderIncreaseOrder,
  getSkusOrderedByIncreaseOrder,
  fetchSkusByBrandAndIncreaseOrder,
} from "../../../services/skuService"; // Import the function
import { getSkusWithPriority, reorderSkusInPriority } from "../../../services/skuPriorityService";

interface FillOrderProps {
  height: number;
  boxPerRow: number;
  itemsList: { imageSrc: string; id: number; text: string }[];
  orderType: "increase_order" | "decrease_order";
  brandName: string;
  category?: string;
  setMarlboroIncrease: (items: {}) => void;
  keyAccountId:any;
  onChangeHappen :(value?:string) => void
  // Add prop to update the marlboroIncrease state
}
type ItemProps = {
  id: number;
  pack_image: string;
  variantName: string;
  brandName: string;
  min_facings: number;
  increase_order: number;
};
const FillOrder: React.FC<FillOrderProps> = ({
  height,
  boxPerRow,
  itemsList,
  orderType,
  brandName,
  category,
  setMarlboroIncrease,
  keyAccountId,
  onChangeHappen
}) => {
  const [items, setItems] = useState(itemsList);
  const [loadingItem, setLoadingItem] = useState<number | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  console.log('keyAccountId',keyAccountId)
  useEffect(() => {
    setItems(itemsList);
  }, [itemsList]);
  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setItems(newItems);

    const skuIds = newItems.map((item) => item.id);
    setLoadingItem(result.draggableId);
    try {
      await reorderSkusInPriority(keyAccountId, skuIds, 'increase_order');

      setLoadingItem(null);
      setSuccessMessage("Order updated successfully");
      onChangeHappen('RRP')
      const data = await getSkusWithPriority(keyAccountId, 'RRP');
      const dataInc = data.sort((a: any, b: any) => a.increase_order - b.increase_order);
    
      // Initialize brand arrays
      let heetsIncrease: ItemProps[] = [];
      let tereaIncrease: ItemProps[] = [];
      let fiitIncrease: ItemProps[] = [];
      let deliaIncrease: ItemProps[] = [];
      let veevOneIncrease: ItemProps[] = [];
      let veevNowIncrease: ItemProps[] = [];
      let leviaIncrease: ItemProps[] = [];
    
      // Split dataInc based on brandName and assign to respective variables
      dataInc.forEach((item: ItemProps) => {
        switch (item.brandName.toLowerCase()) {
          case 'heets':
            heetsIncrease.push(item);
            break;
          case 'terea':
            tereaIncrease.push(item);
            break;
          case 'fiit':
            fiitIncrease.push(item);
            break;
          case 'delia':
            deliaIncrease.push(item);
            break;
          case 'veev one':
            veevOneIncrease.push(item);
            break;
          case 'veev now':
            veevNowIncrease.push(item);
            break;
          case 'levia':
            leviaIncrease.push(item);
            break;
          default:
            break;
        }
      });
    
      // Set the updated state for MarlboroIncrease
      setMarlboroIncrease({
        HEETS: heetsIncrease,
        FIIT: fiitIncrease,
        DELIA: deliaIncrease,
        TEREA: tereaIncrease,
        VEEV_ONE: veevOneIncrease,
        VEEV_NOW: veevNowIncrease,
        LEVIA: leviaIncrease,
      });
    
    } catch (error) {
      setLoadingItem(null);
      setErrorMessage(`Error updating order by ${orderType}`);
      console.error(`Error reordering SKUs by ${orderType}:`, error);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage(null);
    setErrorMessage(null);
  };

  console.log('rrp items',items)
  return (
    <Box
      style={{
        height: "620px",
        overflowY: "scroll",
        overflowX: "hidden",
        padding: "8px",
      }}
    >
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item:any, index) => (
                <Draggable
                  key={item.id}
                  draggableId={String(item.id)}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        marginBottom: "2px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: item.minFacings === 0 ? '#f0f0f0' : 'white',
                        opacity:item.minFacings === 0 ? 0.5 : 1
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          margin: "0.5px",
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <div
                          style={{
                            background: "#E1E3E6",
                            marginBottom: "2px",
                            width: "38px",
                            height: "56px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "12px",
                            fontWeight: "700",
                          }}
                        >
                          {index}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            height: "56px",
                            borderRadius: "4px",
                            boxSizing: "border-box",
                            border: "2px solid #E1E3E6",
                            padding: "2px",
                            paddingLeft: "8px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              gap: "12px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <img
                                src={item.imageSrc}
                                width={27}
                                height={42}
                                draggable="false"
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  color: "#383A49",
                                  userSelect: "none",
                                }}
                              >
                                {item.text}
                              </span>
                              {loadingItem &&
                                loadingItem.toString() ===
                                  item.id.toString() && (
                                  <CircularProgress size={20} />
                                )}
                            </div>
                          </div>
                          <div
                            style={{
                              background: "#E4E7EC",
                              borderRadius: "2px",
                              height: "100%",
                              width: "14px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img src={TowLines} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default FillOrder;
