import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Snackbar, Alert } from '@mui/material';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd';
import TowLines from '../../../assets/icons/TowLines.svg';
import { getSkusOrderedByBrand, reorderSkusByBrand } from '../../../services/skuService';
import TabsComponent from '../../SKUPriority/TabComponent/Tab';
import { getBrandByName } from '../../../services/brandService';

interface ICar {
  id: number;
  variantName: string;
  brandName: string;
  pack_image: string;
  min_facings: number;
}

interface CCOrderProps {
  height: number;
  boxPerRow: number;
  minFacings: any[];
  onChangeHappen: (value?:string) => void
}

const CCOrder: React.FC<CCOrderProps> = ({ height, boxPerRow,minFacings,onChangeHappen }) => {
  const [items, setItems] = useState<ICar[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [loadingItem, setLoadingItem] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [selectedBrand, setSelectedBrand] = useState<string>('Marlboro');


  const tabs2 = [
    { title: 'Marlboro', content: <div /> },
    { title: 'L&M', content: <div /> },

    { title: 'PETRA', content: <div /> },
    { title: 'RGD', content: <div /> },

  ];

  useEffect(() => {
    const fetchOrderedSkus = async (brand: string) => {
      setLoading(true);
      try {
        const data = await getSkusOrderedByBrand(brand);

        const mergedData = data.map((item:any) => {
          const minFacingItem = minFacings.find(mf => mf.id === item.id);
          return {
            ...item,
            min_facings: minFacingItem ? minFacingItem.min_facings : item.min_facings,
          };
        });
        setItems(mergedData);
      } catch (error) {
        setError('Error fetching ordered SKUs');
        console.error('Error fetching ordered SKUs:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrderedSkus(selectedBrand);
  }, [selectedBrand,minFacings]);

  const handleReorder = async (skuIds: number[]) => {
    try {
      const id = await getBrandByName(selectedBrand)
      console.log('the selected brand',id.id)
      await reorderSkusByBrand(skuIds, id.id);
      setSuccessMessage("Order updated successfully");
      onChangeHappen('CC')
    } catch (error) {
      setError('Error updating SKU order');
      console.error('Error updating SKU order:', error);
    } finally {
      setLoadingItem(null);
    }
  };

  const onChange = (
    sourceId: string,
    sourceIndex: number,
    targetIndex: number
  ) => {
    const nextState = swap(items, sourceIndex, targetIndex);
    setItems(nextState);
    const skuIds = nextState.map((item) => item.id);
    setLoadingItem(targetIndex.toString());
    handleReorder(skuIds);
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage(null);
    setError(null);
  };

  const handleTabChange = (newTabValue: string) => {
    setSelectedBrand(newTabValue);
  };

  return (
    <Box className="App">
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <TabsComponent tabs={tabs2} onChange={handleTabChange} />
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </div>
      ) : (
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id="items"
            boxesPerRow={boxPerRow}
            rowHeight={height}
            style={{ height: height * Math.ceil(items.length / boxPerRow) }}
          >
            {items.map((item: any, index: number) => (
              <GridItem key={item.id}>
                <div style={{height: '100%',
                    opacity: item.min_facings === 0 ? 0.5 : 1, // Gray out if min_facings === 0
                    pointerEvents: 'auto', }}>
                  <div
                    style={{
                      background: '#E1E3E6',
                      marginBottom: '2px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '12px',
                      fontWeight: '700',
                    }}
                  >
                    {index}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      backgroundColor: item.min_facings === 0 ? '#f0f0f0' : 'transparent',

                      height: '79%',
                      borderRadius: '4px',
                      boxSizing: 'border-box',
                      border: '2px solid #E1E3E6',
                      padding: '2px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <img
                          src={item.pack_image}
                          width={27}
                          height={42}
                          draggable="false"
                          alt=""
                        ></img>
                        <div
                          style={{
                            borderRadius: '2px',
                            padding: '4px',
                            background: 'rgba(228, 231, 236, 0.40)',
                            fontSize: '12px',
                            fontWeight: '700',
                            color: '#383A49',
                          }}
                        >
                          x {item.min_facings}
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: '10px',
                          fontWeight: '400',
                          color: '#383A49',
                          userSelect: 'none',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px'
                        }}
                      >
                        {item.brandName} {item.variantName}
                        {loadingItem && loadingItem?.toString() === String(index) && (
                          <CircularProgress size={12} />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        background: '#E4E7EC',
                        borderRadius: '2px',
                        height: '14px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img src={TowLines} alt="" />
                    </div>
                  </div>
                </div>
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
      )}
    </Box>
  );
};

export default CCOrder;
