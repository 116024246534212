import React, { useEffect, useState } from "react";
import { Box, Typography, Button, IconButton, CircularProgress } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface MarketingMaterial {
  title?: string;
  width: number;
  height: number;
  x: number;
  y: number;
  isAdded: number;
}

interface MarketingMaterialWithCount extends MarketingMaterial {
  addedCount: number;
}

interface MarketingMaterialCardProps {
  material: MarketingMaterialWithCount;
  onAddToShelf: (material: MarketingMaterial) => void;
  color: string;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setMaterialsToEdit: React.Dispatch<React.SetStateAction<any[]>>;
  remove:(id:number,type:string)=> void

}

interface MarketingMaterialListProps {
  materials: MarketingMaterial[];
  handleAddToShelf: (material: MarketingMaterial) => void;
  color: string;
  loading: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
  setMaterialsToEdit: React.Dispatch<React.SetStateAction<any[]>>
  remove:(id:number,type:string)=> void

}

const MarketingMaterialCard: React.FC<MarketingMaterialCardProps> = ({
  material,
  onAddToShelf,
  color,
  setIsEditing,
  setMaterialsToEdit,
  remove
}) => {
  const handleAddClick = () => {
    onAddToShelf(material);
  };
  const handleEditClick = () => {
    // Add your edit logic here
    setIsEditing(true)
    setMaterialsToEdit(material as any)
    console.log('Edit clicked', material);

  };

  const handleRemoveClick = () => {
    // Add your remove logic here
    if(color=='#FFE455') {
      remove((material as any)?.id,'M')

    }else {
      remove((material as any)?.id,'L')

    }
    console.log('Remove clicked', material);
  };
  
  return (
    <div style={{ display: 'flex', marginBottom: 2, background: 'white', borderRadius: '8.5px', boxShadow: 'none' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '12px', gap: '8px', borderRadius: '8.5px' }}>
          <div style={{ width: '64px', height: '64px', padding:'4px',background:   color, alignSelf: 'flex-start', borderRadius: '5px' }}>
    {   color==="#FFE455" &&  <img 
    src={(material as any)?.image} // Replace imageUrl with your actual image source variable
    alt="Material"
    style={{ 
      width: '100%', 
      height: '100%', 
      objectFit: 'contain', // Ensures the image fits within the div
    }} 
  />}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Typography variant="subtitle1" style={{ fontSize: '12px', fontWeight: '600', maxWidth: '120px', lineHeight: 'normal',textTransform:'capitalize' }}>
              {material.title || 'No Title'}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddClick}
                disabled={material.isAdded > 0}
                sx={{
                  maxHeight: '23px',
                  fontSize: '12px',
                  fontWeight: '700',
                  padding: '2px',
                  width: material.isAdded > 0 ? '150px' : '170px',
                  marginTop: '0',
                  marginRight: '8px',
                  background: '#0179FF',
                  color: 'white',
                  opacity: material.isAdded > 0 ? 0.5 : 1,
                  '&:hover': {
                    color: 'black',
                  },
                  '&:disabled': {
                    background: '#0179FF',
                    color: 'white',
                  },
                }}
              >
                {material.isAdded > 0 ? `Added: ${material.isAdded}` : 'Add to shelf'}
              </Button>
              {material.isAdded > 0 && (
                <IconButton
                  size="small"
                  aria-label="add more"
                  onClick={handleAddClick}
                  sx={{
                    maxHeight: '23px',
                    fontSize: '12px',
                    fontWeight: '700',
                    padding: '2px',
                    marginTop: '0',
                    width: "35px",
                    borderRadius: "56px",
                    background: '#0179FF',
                    color: 'white',
                    '&:hover': {
                      color: 'black',
                    },
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </div>
          </div>
         
           <IconButton
              aria-label="edit"
              onClick={handleEditClick}
              sx={{
                alignSelf: 'flex-start',
                padding: 0,
                margin: material.isAdded > 0 ?  '-4px -32px' :  '-4px -16px',
                background: 'transparent',
                '&:hover': {
                  background: '#e0e0e0',
                },
              }}
            >
              <EditIcon  sx={{ fontSize: '18px',color:'#9CA0AB' }}/>
            </IconButton>
            {/* <IconButton
              aria-label="remove"
              onClick={handleRemoveClick}
              sx={{
                alignSelf: 'flex-start',
                padding: 0,
                margin: '-4px -16px',
                background: 'transparent',
                '&:hover': {
                  background: '#e0e0e0',
                },
              }}
            >
              <DeleteIcon    sx={{ fontSize: '18px',color:'#9CA0AB' }} />
            </IconButton> */}
      
        </div>
      </div>
    </div>
  );
};

const MarketingMaterialList: React.FC<MarketingMaterialListProps> = ({ materials,remove, handleAddToShelf, color, loading,setIsEditing,setMaterialsToEdit }) => {
  const preprocessedMaterials = preprocessMaterials(materials);
console.log('materials checkl',materials)
  const mergeMaterials = () => {
    const mergedMaterialsMap = new Map();
    preprocessedMaterials.forEach(material => {
      mergedMaterialsMap.set(material.id || 'No Title', material);
    });
    mockMaterials.forEach(material => {
      const title = (material as any).id || 'No Title';
      if (!mergedMaterialsMap.has(title)) {
        mergedMaterialsMap.set(title, { ...material });
      }
    });
    return Array.from(mergedMaterialsMap.values());
  };

  const [materialState, setMaterialState] = useState<MarketingMaterialWithCount[]>(mergeMaterials());

  useEffect(() => {
    setMaterialState(mergeMaterials());
  }, [materials]);

  const onAddToShelfInternal = (material: MarketingMaterial) => {
    const updatedMaterials = materialState.map(m => {
      if ((m as any).id === (material as any).id) {
        return { ...m, addedCount: m.addedCount, x: 0, y: 0 };
      }
      return { ...m, x: 0, y: 0 };
    });

    setMaterialState(updatedMaterials);
    handleAddToShelf(material);
  };

  return (
    <>
     
        <Box sx={{ width: 300, margin: 'auto', height: '330px', background: '#F2F8FF', padding: '16px', borderRadius: '10px', gap: '8px', overflow: 'auto' }}>
     
     <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
    
       {materialState.map((material, index) => (
         <MarketingMaterialCard  remove={remove} setMaterialsToEdit={setMaterialsToEdit} key={index} material={material} onAddToShelf={onAddToShelfInternal} color={color} setIsEditing={setIsEditing} />
       ))}
     </div>
   
 </Box>
    </>
   
  );
};

export default MarketingMaterialList;

const preprocessMaterials = (materials: any) => {
  const countMap = new Map();

  materials.forEach((material: any) => {
    const title = (material as any).id || 'No Title';
    if (!countMap.has(title)) {
      countMap.set(title, { ...material, addedCount: 0 });
    } else {
      const existingMaterial = countMap.get(title);
      countMap.set(title, { ...existingMaterial, addedCount: existingMaterial.addedCount + 1 });
    }
  });

  return Array.from(countMap.values());
};

const mockMaterials: MarketingMaterial[] = [];
