import React, { useEffect, useState } from 'react';
import { Button, Typography, CircularProgress } from '@mui/material';
import { ReactComponent as Upload } from "../../../assets/icons/Upload.svg";

interface ImageUploadProps {
    onFileSelect: (file: File | null) => void;
    selectedImage: File | null;
    existingImageUrl?: string; 
    id?:string;
    // Add this prop to handle existing image URL
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onFileSelect, selectedImage, existingImageUrl,id }) => {
    const [internalSelectedImage, setInternalSelectedImage] = useState<File | null>(selectedImage);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string | undefined>(existingImageUrl);

    useEffect(() => {
        if (selectedImage) {
            setInternalSelectedImage(selectedImage);
            setImageUrl(URL.createObjectURL(selectedImage));
        } else if (existingImageUrl) {
            setImageUrl(existingImageUrl);
        } else {
            setImageUrl(undefined);
        }
    }, [selectedImage, existingImageUrl]);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            console.log('Selected file in ImageUpload:', file); // Debug log
            setIsLoading(true);
            setTimeout(() => {
                setInternalSelectedImage(file);
                onFileSelect(file);
                setImageUrl(URL.createObjectURL(file));
                setIsLoading(false);
            }, 500); // Reduced delay for responsiveness
        } else {
            setInternalSelectedImage(null);
            onFileSelect(null);
            setImageUrl(existingImageUrl);
        }
    };

   console.log(existingImageUrl)
   console.log(imageUrl)
   console.log(isLoading)
  
   
    return (
        <div style={{ height: "132px",  display: "flex", justifyContent: "center", flexDirection: "column", alignItems: 'center', borderRadius: "10px", backgroundColor: "#F2F8FF", marginBottom: "32px" }}>
            <input
                accept="image/*"
                style={{ display: 'none' }}
                id= {id ? id :"raised-button-file"}
                type="file"
                onChange={handleImageChange}
            />
            <label htmlFor={id ? id :"raised-button-file"} style={{ textAlign: "center", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                {!isLoading && !imageUrl && (
                    <Button variant="contained" component="span" sx={{
                        width: "210px",
                        borderRadius: "10px",
                        marginTop: "0",
                        marginBottom: "8px",
                        backgroundColor: "white",
                        "&:hover": {
                            backgroundColor: "white !important",
                            opacity: 0.8,
                        }
                    }} startIcon={<Upload />}>
                        Upload
                    </Button>
                )}
                {isLoading && <CircularProgress />}
                {imageUrl && !isLoading && (
                    <div>
                        <img src={imageUrl} alt="Uploaded" style={{ maxWidth: "100%", maxHeight: "120px" }} />
                    </div>
                )}
                {!isLoading && !imageUrl && (
                    <Typography variant="h5" sx={{
                        fontSize: "12px",
                        color: "#9CA0AB",
                        maxWidth: "220px",
                        textAlign: "center"
                    }}>
                        {"Available formats PNG, JPG, JPEG click to browse (3MB max)"}
                    </Typography>
                )}
            </label>
        </div>
    );
};

export default ImageUpload;
