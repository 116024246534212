import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Input from "../Input/Input";
import { getFromJson } from "../../../helpers/localData";
import { update } from "lodash";

interface FillOrderProps {
  height: number;
  boxPerRow: number;
  items: { id: number; text: string; color: string; value: number ,width:number}[];
  setItems: React.Dispatch<any>;
  handleDuplexTypeChange: (typeIndex: number, value: number) => void;
  getDuplexTypeValue: (typeIndex: number) => number;
  selectedButton: string;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  widths:number[]; // New prop for continue action
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  overLayResult:boolean[][][]  | undefined;

  displayItems: { text: string; id: number; color: string; value: number; width: number; }[];
  setDisplayItems: React.Dispatch<React.SetStateAction<{ text: string; id: number; color: string; value: number; width: number; }[]>>;
}

const duplexInputs = [
  { text: "VEEV ONE Standing DP", id: 0, color: '#9747FF', value: 0, width: 28.5 },
  { text: "VEEV NOW Laying  DP", id: 1, color: '#FF9F47', value: 0, width: 19.5},
  { text: "VEEV ONE Standing & Laying DP", id: 2, color: '#9747FF', value: 0, width: 28.5 },
  { text: " CC Laying 2 DP", id: 3, color: 'blue', value: 0, width: 27.5 },
  { text: "VEEV Now Standing DP", id: 4, color: '#FF9F47', value: 0, width: 19.5 },
  { text: "HNB DP", id: 5, color: 'blue', value: 0, width: 38.75 },
  { text: "VEEV ONE Laying DP", id: 6, color: '#9747FF', value: 0, width: 28.5 },
  { text: "HEETS", id: 7, color: '#0179FF', value: 0, width: 38.75 },
  { text: "TEREA", id: 8, color: '#F95252', value: 0, width: 38.75 },
  { text: "Fiit", id: 9, color: '#FFE455', value: 0, width: 38.75 },
  { text: "Delia", id: 10, color: '#A5ABAA', value: 0, width: 38.75 },
  { text: "VEEV ONE", id: 11, color: '#9747FF', value: 0, width: 28.5 },
  { text: "VEEV NOW", id: 12, color: '#FF9F47', value: 0, width: 19.5 },
  { text: "LEVIA", id: 13, color: '#5DD671', value: 0, width: 36.5},
];

const FillOrder: React.FC<FillOrderProps> = ({
  height,
  boxPerRow,
  items,
  setItems,
  handleDuplexTypeChange,
  getDuplexTypeValue,
  selectedButton,
  setSnackbarMessage,
  setSnackbarOpen,
  widths,
  displayItems,
  setDisplayItems,
  overLayResult
}) => {
  // useEffect(() => {
  //   setItems(duplexInputs);
  // }, [selectedButton, setItems]);

  const shouldHideItem = (itemId: number) => {
    switch (selectedButton) {
      case 'HNB':
        return ![7, 8, 9, 10].includes(itemId);
      case 'Veev One':
        return itemId !== 11;
      case 'Veev Now':
        return itemId !== 12;
      case 'Levia':
        return itemId !== 13;
      default:
        return false;
    }
  };
const boolArray =overLayResult?.[0]?.[boxPerRow]
function removeDuplicateElements(elements: Element[]): { updatedArray: Element[]; isRemoved: boolean } {
  const seen = new Set<string | number>();
  let isRemoved = false;

  const updatedArray = elements.filter((element) => {
    if (seen.has(element.id)) {
      isRemoved = true;
      return false; // Exclude duplicates
    }
    seen.add(element.id);
    return true; // Include unique elements
  });

  return { updatedArray, isRemoved };
}

const handleInputChange = async (index: number, value: number, items: {
  id: number;
  text: string;
  color: string;
  value: number;
  width: number;
}[]) => {
  // Update the items array with the new value
  const currentTotalWidth = items.reduce((sum, item, index2) => {
    if (index === index2) {
      return sum + item?.width * value + (2*value);
    }
    return sum + item?.width * item.value + (item.value !=0 ? 2 : 0);
  }, 0);

  const maxWidth = ( widths[0]) * 5;
  const trueValusCount =overLayResult ?overLayResult?.[0]?.[boxPerRow].filter((val:any)=>val).length :0
  if (currentTotalWidth > (maxWidth-(28.5*trueValusCount)) ){
    setSnackbarMessage("You don't have enough space to add a pusher 22");
    setSnackbarOpen(true);
    return;
  }
  const updatedItems = items.map((item, i) => {
    if (i === index) {
      return { ...item, value };
    }
    return item;
  });
  const initialValue = items[index].value;

  if (initialValue === 0) {
     const [movedItem] = updatedItems.splice(index, 1);
   updatedItems.push(movedItem);
  }

 

     // Now build the displayItems array
     const displayItemsList = [];
     const activeItems = updatedItems
     .filter(item => item.value > 0)
     .map(item => ({ ...item }));
     let activeItemIndex = 0; // Index in activeItems
     let boolIndex = 0; // Index in boolArray
     let uniqueId = Date.now(); // Unique ID generator
    if(boolArray) {
     while (boolIndex < boolArray.length) {
       if (boolArray[boolIndex]) {
         // Fixed space
         displayItemsList.push({
           id: `fixed-${boolIndex}`,
           text: "Fixed Space",
           color: "gray",
           value: 1,
           width: 28.5,
           duplexTypes: []
         });
         boolIndex++;
       } else {
         // Available space
         // Find consecutive false positions
         let start = boolIndex;
         let widthAvailable = 0;
         while (boolIndex < boolArray.length && !boolArray[boolIndex]) {
           widthAvailable += 30.5;
           boolIndex++;
         }
   
         // Try to fit the active item(s) into the available space
         while (activeItemIndex < activeItems.length) {
           const item = activeItems[activeItemIndex];
           const itemWidth = item.width;
           const itemValue = item.value;
   
           // Total width needed for the item considering its value
           const totalItemWidth = itemWidth * itemValue;
           
 
 
           if (totalItemWidth <= widthAvailable ) {
         
             displayItemsList.push({
                 ...item,
                 id: item.id // Ensure unique ID
               });
               
              
             widthAvailable -= totalItemWidth;
             activeItemIndex++;
            
           } else if (itemWidth <= widthAvailable) {
          
              
                displayItemsList.push({
                  ...item,
                  value:item.value-1,
                  id: item.id  // Ensure unique ID
                });
            
             
             
              const maxUnits = Math.floor(widthAvailable / itemWidth);

             
             // Reduce the item's value by the number of units placed
             activeItems[activeItemIndex].value -= maxUnits;
             widthAvailable -= itemWidth * maxUnits;
            
             // Move to next available space
             
           } else {
             // Item cannot fit in this space
             break; // Move to next available space
           }
         }
   
         // Fill any remaining space with empty placeholders
         const emptySlots = Math.floor(widthAvailable / 28.5);
         for (let i = 0; i < emptySlots; i++) {
           displayItemsList.push({
             id: `empty-${start + i}`,
             text: "Empty Space",
             color: "white",
             value: 1,
             width: 30.5,
             duplexTypes: []
           });
         }
       }
     }
   
     // Check if there are remaining items that could not be placed
     if (activeItemIndex < activeItems.length) {
       setSnackbarMessage(`Not enough space to add ${activeItems[activeItemIndex].text}`);
       setSnackbarOpen(true);
       return;
     }
   
   }
   const result = removeDuplicateElements(displayItemsList as any);
   console.log('thjeresult',result)
    // Check if there are remaining items that could not be placed
    if (result.isRemoved) {
      setSnackbarMessage(`Not enough space to add Pusher`);
      setSnackbarOpen(true);
    }
  if(!result.isRemoved ) {
    setItems(updatedItems);

    setDisplayItems(displayItemsList as any)
  }

   





 // setItems(displayItems);
};

useEffect(()=>{
  
   // Update the state for display items
},[items])









  return (
    <Box
      style={{
        height: "100%",
        padding: "8px",
        overflow: "auto",
      }}
    >
      <div style={{ padding: "4px", borderRadius: "4px" }}>
        {duplexInputs.map((duplexItem, duplexIndex) => {
          const itemIndex = items.findIndex(item => item.id === duplexItem.id);
          const currentItem = items[itemIndex] || duplexItem;
          return (
            <div
              key={currentItem.id}
              style={{
                marginBottom: "4px",
                display: shouldHideItem(currentItem.id) ? "none" : "flex",
                alignItems: "center",
                backgroundColor: "#F5F9FF",
                borderRadius: '8px',
                height: 40,
                padding: '0 4px'
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  margin: "0.5px",
                  display: "flex",
                  gap: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                    height: "40px",
                    borderRadius: "4px",
                    boxSizing: "border-box",
                    padding: "2px",
                    paddingRight: "6px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "2px",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: '5px'
                    }}
                  >
                    <div style={{
                      backgroundColor: currentItem.color, width: '22px', height: '22px',
                      borderRadius: '50%',
                    }}></div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Input
                      icon={undefined}
                      text={currentItem.text}
                      onChange={(value) => handleInputChange(itemIndex, value,items)}
                      value={currentItem.value}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Box>
  );
};

export default FillOrder;
