import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface TabItem {
  title: string;
  content: React.ReactNode;
}

interface TabsComponentProps {
  tabs: TabItem[];
  onChange?: (newValue: string) => void; // Optional onChange prop
}

const TabsComponentV2: React.FC<TabsComponentProps> = ({ tabs, onChange }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (onChange) {
      onChange(tabs[newValue].title);
    }
  };

  return (
    <Box sx={{ width: '100%', bgcolor: '', marginTop: '12px' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          background: 'white',
          padding: '8px',
          borderRadius: '50px',
          height: '40px',
        }}
      >
        <Tabs
          value={value}
          variant="fullWidth"
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            '.MuiTabs-indicator': {
              display: 'none',
              backgroundColor: '#3f51b5',
              height: '4px',
            },
            '.MuiTab-root': {
              textTransform: 'none',
              fontWeight: 'bold',
              borderRadius: '40px',
              height: '40px',
              minHeight: '40px',
              '&.Mui-selected': {
                color: '#0179FF',
                background: '#F5F9FF',
              },
              '&:hover': {
                color: '#3f51b5',
                opacity: 1,
              },
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              label={tab.title}
              id={`simple-tab-${index}`}
              aria-controls={`simple-tabpanel-${index}`}
              key={index}
              sx={{
                // Add any specific styles for each tab here
              }}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel value={value} index={index} key={index}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabsComponentV2;
