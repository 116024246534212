import React, { useEffect, useState } from 'react';
import { Typography, Snackbar, Alert, CircularProgress } from '@mui/material';
import MinFacings from '../MinFacings/MinFacings';
import CCOrder from '../CCOrder/CCOrder';
import FillPolicy from '../FillPolicy/FillPolicy';
import {
  getSkusWithPriority,
  updateSkuPriority,
} from '../../../services/skuPriorityService';
import { getConfiguration, updateConfiguration, Configuration } from '../../../services/configurationService';
import BrandOrder from '../BrandOrder/BrandOrder';

interface CCTabProps {
  keyAccount: any;
  onChangeHappen: (value?:string )=>void
}

const cardStyle: React.CSSProperties = {
  boxShadow: '0px 2px 2px #eee',
  backgroundColor: 'white',
  padding: '22px 32px',
};

const CCTab: React.FC<CCTabProps> = ({ keyAccount ,onChangeHappen}) => {
  const [config, setConfig] = useState<Configuration | null>(null);
  const [items, setItems] = useState<any[]>([]);
  const [loadingItemId, setLoadingItemId] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const [marlboroIncrease, setMarlboroIncrease] = useState<any[]>([]);
  const [loadingMarlboroIncrease, setLoadingMarlboroIncrease] = useState<boolean>(false);
  const [errorMarlboroIncrease, setErrorMarlboroIncrease] = useState<string | null>(null);

  const [value, setValue] = useState<number | null>(null);
console.log('the display',keyAccount)
  useEffect(() => {
    const fetchConfiguration = async () => {
      try {
        const configData = await getConfiguration();
        setValue(configData.Marlboro);
        setConfig(configData);
      } catch (error: any) {
        setError('Error fetching configuration');
        console.error('Error fetching configuration:', error);
      }
    };
    fetchConfiguration();
  }, []);

  useEffect(() => {
    const fetchSkus = async () => {
      if (!keyAccount || !keyAccount.id) {
        setError('Please select a key account 2');
        return;
      }
      console.log('test')
      try {
        const data = await getSkusWithPriority(keyAccount.id, 'CC');
        console.log('the data is',data)
        setItems([...data]);

        // Filter and sort Marlboro SKUs by increase_order
        const marlboroInc = data
          .sort((a: any, b: any) => a.increase_order - b.increase_order);
        setMarlboroIncrease(marlboroInc);
        setLoadingMarlboroIncrease(false);
      } catch (error) {
        setError('Error fetching SKUs');
        console.error('Error fetching SKUs:', error);
      }
    };
    fetchSkus();
  }, [keyAccount]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const handleMinFacingsChange = async (id: number, minFacings: number,KeyAccount:number) => {

    // if (!keyAccount || !keyAccount.id) {
    //   setError('Please select a key account 3');
    //   return;
    // }
    setLoadingItemId(id);
    try {
      console.log('KeyAccount',KeyAccount)
      console.log('the id',id)
      console.log('the min facings',minFacings)

      await updateSkuPriority(KeyAccount, id, { minFacings });
      // const data = await getSkusWithPriority(keyAccount.id, 'CC');
      // console.log('the data is',data)
      // setItems([...data]);
      
    //  setItems(updatedItems);
    const data = await getSkusWithPriority(keyAccount.id, 'CC');
    setItems([...data]);
    const marlboroInc = data
          .sort((a: any, b: any) => a.increase_order - b.increase_order);
        setMarlboroIncrease(marlboroInc);
      setSuccess('SKU min facings updated successfully');
      onChangeHappen('CC')
    } catch (error) {
      setError('Error updating min facings');
      console.error('Error updating min facings:', error);
    } finally {
      setLoadingItemId(null);
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(null);
    setSuccess(null);
    setErrorMarlboroIncrease(null);
  };
console.log('keyAccount',keyAccount)
  return (
    <div style={{ marginTop: '14px' }}>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={!!success} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          {success}
        </Alert>
      </Snackbar>
      <div
        style={{
          ...cardStyle,
          borderTopRightRadius: '15px',
          borderTopLeftRadius: '15px',
          borderBottom: '1px solid #E1E3E6',
        }}
      >
        <BrandOrder height={150} boxPerRow={4 } onChangeHappen={onChangeHappen}></BrandOrder>
        <div style={{ border: '1px solid #E1E3E6', marginBottom: '24px' }}></div>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            color: '#383A49',
            marginBottom: '6px',
          }}
          color="text.primary"
        >
          CC MINIMUM Number of Facings: <strong style={{color:'#5779FF', textTransform:'capitalize'}}>{keyAccount.title}</strong> 
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 500,
            color: '#383A49',
            opacity: 0.5,
            marginBottom: '12px',
          }}
          color="text.primary"
        >
          Type MINIMUM Number Of Facings for Each Pack
        </Typography>
{     keyAccount &&  <MinFacings items={items} onMinFacingsChange={handleMinFacingsChange} loadingItemId={loadingItemId} keyAccount={keyAccount}  />
}        <div style={{ border: '1px solid #E1E3E6', marginBottom: '24px', marginTop: '26px' }}></div>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            color: '#383A49',
            marginBottom: '6px',
          }}
          color="text.primary"
        >
          CC Order: <strong style={{color:'#5779FF', textTransform:'capitalize'}}>{keyAccount.title}</strong> 
        </Typography>
        <CCOrder  height={150} boxPerRow={10} minFacings={items} onChangeHappen={onChangeHappen} />
        <div style={{ border: '1px solid #E1E3E6', marginBottom: '24px', marginTop: '20px' }}></div>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            color: '#383A49',
            marginBottom: '6px',
          }}
          color="text.primary"
        >
          SKU Priority
        </Typography>
        {marlboroIncrease.length > 0 && (
          <FillPolicy
            onChangeHappen={onChangeHappen}
            increase={marlboroIncrease}
            decrease={marlboroIncrease} // Adjust as needed
            brandName="Marlboro"
            loadingIncrease={loadingMarlboroIncrease}
            errorIncrease={errorMarlboroIncrease}
            setMarlboroIncrease={setMarlboroIncrease}
            keyAccountId={keyAccount?.id} 
            loadingDecrease={loadingMarlboroIncrease} errorDecrease={errorMarlboroIncrease}          />
        )}
      </div>
    </div>
  );
};

export default CCTab;
