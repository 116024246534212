import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { blue } from '@mui/material/colors';
import Typography from "@mui/material/Typography";

interface YesNoButtonProps {
  label: string;
  onChange: (value: string)=>void
  yes:boolean
}

const YesNoQuestionComponent: React.FC<YesNoButtonProps> = ({ label,onChange,yes }) => {
  const [selected, setSelected] = useState<string >(yes ? 'yes': 'no');

  useEffect(()=>(
    setSelected(yes ? 'yes': 'no')
  ),[yes])

  const handleClick = (value: string) => {
    setSelected(value);
    onChange(value)
  };
  const isSelected = (value: string) => {
    return selected === value ? 'info' : 'primary';
  };

  return (
    <Box flexDirection={"column"}>
      <Box component="span" sx={{ marginRight: 2, }}>
      
        
        <Typography variant="h6"  >
        {label}
          </Typography>
      </Box>
      <div>
      <Button
         variant="outlined"
         color={isSelected('yes')}
        onClick={() => handleClick('yes')}
        sx={{
          marginRight: 1,
          '&.MuiButton-contained': {
            color: 'white',
          },
        }}
      >
        Yes
      </Button>
      
      <Button
         variant="outlined"
         color={isSelected('no')}
         onClick={() => handleClick('no')}
        sx={{
          '&.MuiButton-contained': {
            color: 'white',
          },
        }}
      >
        No
      </Button>
      </div>
    </Box>
  );
};

export default YesNoQuestionComponent;