import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Snackbar, Alert, CircularProgress } from "@mui/material";

import { ReactComponent as Open } from "../../../assets/icons/Open.svg";
import { ReactComponent as Close } from "../../../assets/icons/Close.svg";
import { ReactComponent as Width } from "../../../assets/icons/SizeWidth.svg";
import { ReactComponent as Height } from "../../../assets/icons/SizeHeight.svg";
import { ReactComponent as Depth } from "../../../assets/icons/SizeDepth.svg";

import { useStyles } from "./SideBarStyles";

import Typography from "@mui/material/Typography";
import Dropdown from "../Dropdown/Dropdown";
import ImageUpload from "../ImageUpload/ImageUpload";
import Input from "../Input/Input";
import SizeInput from "../SizeInput/SizeInput";
import { OptionsOrGroups } from "react-select";
import { Add as AddIcon } from "@mui/icons-material";

import { updateSkuWithImage, getSkuById, Sku } from "../../../services/skuService";
import { getAllBrands } from "../../../services/brandService";
import { getAllPackTypes } from "../../../services/packTypeService";
import { getAllSizes } from "../../../services/sizeService";
import { getAllVariants } from "../../../services/variantService";
import DropdownComponent from "../DropDownMulti/DropDown";
import { getAllChannels, getAllKeyAccounts, getPosUniversesWithNotNullKeyAccount } from "../../../services/posuniversService";
import { getKeyAccountsWithMinFacingsForSku } from "../../../services/skuPriorityService";

interface EditSidebarProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  skuId: number | null;
  refetchSKUs?: () => void; // Add refetchSKUs prop
}

const initialSkuData: Sku = {
  name: '',
  description: '',
  category: '',
  subcategory: '',
  consumablesDevice: '',
  packBrandForm: '',
  productDescription: '',
  eanCode: '',
  width: 0,
  height: 0,
  depth: 0,
  brandId: 0,
  variantId: 0,
  packTypeId: 0,
  sizeId: 0,
  packImage: '',
  packImageLaying: '', // Add packImageLaying
};

interface OptionType {
  label: string;
  value: string;
}

const EditSideBarRight: React.FC<EditSidebarProps> = ({ isOpen, setIsOpen, skuId, refetchSKUs }) => {
  const classes = useStyles();
  const [skuData, setSkuData] = useState<Sku>(initialSkuData);
  const [file, setFile] = useState<File | null>(null);
  const [fileLaying, setFileLaying] = useState<File | null>(null); // New state for laying image

  const [brands, setBrands] = useState<OptionType[]>([]);
  const [packTypes, setPackTypes] = useState<OptionType[]>([]);
  const [sizes, setSizes] = useState<OptionType[]>([]);
  const [variants, setVariants] = useState<OptionType[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarContent, setSnackbarContent] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [brandData, packTypeData, sizeData, variantData] = await Promise.all([
          getAllBrands(),
          getAllPackTypes(),
          getAllSizes(),
          getAllVariants()
        ]);

        setBrands(brandData.map((brand: any) => ({ label: brand.name, value: brand.id?.toString() })));
        setPackTypes(packTypeData.map((packType: any) => ({ label: packType.name, value: packType.id?.toString() })));
        setSizes(sizeData.map((size: any) => ({ label: size.name, value: size.id?.toString() })));
        setVariants(variantData.map((variant: any) => ({ label: variant.name, value: variant.id?.toString() })));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const snakeToCamel = (str: string) => {
    return str.replace(/(_\w)/g, matches => matches[1].toUpperCase());
  };

  const convertKeysToCamelCase = (obj: any): any => {
    if (obj === null || obj === undefined) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(item => convertKeysToCamelCase(item));
    }

    if (typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const camelKey = snakeToCamel(key);
        acc[camelKey] = convertKeysToCamelCase(obj[key]);
        return acc;
      }, {} as any);
    }

    return obj;
  };

  useEffect(() => {
    if (isOpen && skuId) {
      const fetchSku = async () => {
        try {
          const data = await getSkuById(skuId);
          const camelCaseData = convertKeysToCamelCase(data);
          console.log('camelCaseData', camelCaseData); // Debugging information
          setSkuData(camelCaseData);
        } catch (error) {
          console.error("Error fetching SKU:", error);
        }
      };

      fetchSku();
      setFile(null); // Reset file state when opening the sidebar
      setFileLaying(null); // Reset fileLaying state when opening the sidebar
      setErrors([]);
    }
  }, [isOpen, skuId]);

  const handleChange = (name: string, value: string) => {
    setSkuData({ ...skuData, [name]: value });
  };

  const handleDropdownChange = (name: string, value: string) => {
    setSkuData({ ...skuData, [name]: value });
  };

  const handleFileChange = (file: File | null) => {
    console.log('Selected file in EditSideBarRight:', file); // Debug log
    setFile(file);
  };

  const handleFileLayingChange = (file: File | null) => {
    setFileLaying(file);
  };

  const handleSubmit = async () => {
    const newErrors: string[] = [];
    if (!skuData.name) newErrors.push("Name is required.");
    if (!skuData.description) newErrors.push("Description is required.");
    if (!skuData.category) newErrors.push("Category is required.");
    if (!skuData.subcategory) newErrors.push("Subcategory is required.");
    if (!skuData.consumablesDevice) newErrors.push("Consumables/Device is required.");
    if (!skuData.packBrandForm) newErrors.push("Pack Brand Form is required.");
    if (!skuData.productDescription) newErrors.push("Product Description is required.");
    if (!skuData.eanCode) newErrors.push("EAN Code is required.");
    if (!skuData.width) newErrors.push("Width is required.");
    if (!skuData.height) newErrors.push("Height is required.");
    if (!skuData.depth) newErrors.push("Depth is required.");
    if (!skuData.brandId) newErrors.push("Brand is required.");
    if (!skuData.variantId) newErrors.push("Variant is required.");
    if (!skuData.packTypeId) newErrors.push("Pack Type is required.");
    if (!skuData.sizeId) newErrors.push("Size is required.");

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true); // Set loading state to true
    try {
      await updateSkuWithImage(skuId!, {...skuData, 'keyAccountsWithMinFacings': selectedKeyAccount ?JSON.stringify(selectedKeyAccount) :JSON.stringify([])}, (file || undefined), (fileLaying || undefined)); // Include fileLaying
      setSnackbarContent("SKU updated successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setIsOpen(false);
      refetchSKUs && refetchSKUs(); // Refetch the SKUs after successfully updating a SKU
    } catch (error) {
      setSnackbarContent("Error updating SKU");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error('Error updating SKU:', error);
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const CategoryOptions: OptionsOrGroups<OptionType, any> = [
    { value: "cc", label: "CC" },
    { value: "rrp", label: "RRP" },
  ];
  const subCategoryOptions: OptionsOrGroups<OptionType, any> = [
    { value: "hnb", label: "HNB" },
  ];
  const type: OptionsOrGroups<OptionType, any> = [
    { value: "consumables", label: "Consumables" },
    { value: "devices", label: "Devices" },
  ];
  const [keyAccounts, setKeyAccounts] = useState<any[]>([]);
  const [channels, setChannels] = useState<any[]>([]);
  const [accountsStores, setAccountsStores] = useState<any[]>([]);
  const [channelsData, setChannelsData] = useState<any[]>([]);
  const [selectedKeyAccount, setSelectedKeyAccount] = useState<any>(null);
  const handleKeyAccountSelect = (selectedChain: any) => {
    console.log("Selected Key Account:", selectedChain);
    setSelectedKeyAccount(selectedChain); // Update the selected key account
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const keyAccountsData = await getAllKeyAccounts();
        setKeyAccounts(keyAccountsData);

        const channelsData = await getAllChannels();
        setChannels(channelsData);

        const accountsStoresData = await getPosUniversesWithNotNullKeyAccount(); // Ensure this function exists
        setAccountsStores(accountsStoresData);

        const itemsChaine = keyAccountsData.map((account: any) => ({
          title: account.name,
          description: "Key account description",
          id: account.id,
        }));

        console.log("itemsChaine", itemsChaine);

        const groupedChains = channelsData.map((channel: any) => ({
          channelId: channel.id,
          channelName: channel.name,
          chains: itemsChaine
            .filter((chain: any) =>
              accountsStoresData.some(
                (accountStore: any) =>
                  chain.id === accountStore.key_account_id &&
                  accountStore.channel_id === channel.id
              )
            )
            .map((chain: any) => ({
              ...chain,
            })),
        }));

        console.log("groupedChains", groupedChains);
        setChannelsData(groupedChains);

        // Set the first channel's first key account as default selected
        // if (groupedChains.length > 0 && groupedChains[0].chains.length > 0) {
        //   setSelectedKeyAccount(groupedChains[0].chains[0]);
        // }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const [initialKeyAccounts,setInitialKeyAccounts] = useState <[]>([])
  useEffect(() => {
    const fetchData = async () => {
      if(skuId && channelsData.length > 0) {
        try {
          const keyAccountsValue = await getKeyAccountsWithMinFacingsForSku(skuId);
          console.log('Fetched Key Accounts:', channels);
            if(keyAccountsValue) {
              const filteredKeyAccounts =  channelsData
              .map((channel) => {
                // Filter the chains within each channel
                const filteredChains = channel.chains?.filter((chain:any) =>
                  keyAccountsValue.includes(chain.id)
                );
            
                // Return a new channel object with the filtered chains
                return {
                  ...channel,
                  chains: filteredChains,
                };
              })
              console.log('filteredKeyAccounts',filteredKeyAccounts)
              setInitialKeyAccounts(keyAccountsValue)

            }
         
        } catch (error) {
          console.error('Error fetching key accounts:', error);
        }
      }
     
    };

    fetchData();
  }, [skuId,channelsData,isOpen]);
  const handleOptionAdded = (option: { label: string; value: string }, dropdownType: string) => {
    switch (dropdownType) {
      case "brandId":
        setBrands((prev) => [...prev, option]);
        break;
      case "variantId":
        setVariants((prev) => [...prev, option]);
        break;
      case "packTypeId":
        setPackTypes((prev) => [...prev, option]);
        break;
      case "sizeId":
        setSizes((prev) => [...prev, option]);
        break;
    }
  };


  return <>
    {isOpen && <div className={classes.overlay} onClick={toggleSidebar}></div>}
    {file?.name}
    <Box
      className={`${classes.container} ${
        isOpen ? classes.sidebarOpen : classes.sidebarClosed
      }`}
    >
      <IconButton className={classes.toggleButton} onClick={toggleSidebar} size="large">
        <Box component="span" className={classes.iconButton}>
          {isOpen ? <Close /> : <Open />}
        </Box>
      </IconButton>
      <div
        className={classes.buttonContainer}
        style={{ justifyContent: "flex-start" }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="h4"
            sx={{ marginBottom: "12px", fontSize: "20px !important" }}
          >
            Edit SKU
          </Typography>
          <Typography
            variant="h6"
            sx={{ marginBottom: "50px", maxWidth: "244px" }}
          >
            Edit shelf product
          </Typography>
         
          <div style={{ display:'flex',gap:'10px',width:'100%',justifyContent:'space-between',      fontSize: "14px",color:'#0179FF'
}}>
           <div style={{width: '50%'}}>
           <div style={{marginBottom:'5px'}}>
            <Typography variant='h2' style={{ marginBottom: "8px",textTransform:'capitalize' }}>
            Standing Pack

      </Typography>
            </div>
            <ImageUpload onFileSelect={handleFileChange} selectedImage={file} existingImageUrl={skuData.packImage} id="image-1" />

           </div>
           <div style={{width: '50%'}}>
           <div style={{marginBottom:'5px'}}>
           <Typography variant='h2' style={{ marginBottom: "8px",textTransform:'capitalize' }}>
            Laying Pack

      </Typography>                </div>
      <ImageUpload onFileSelect={handleFileLayingChange} selectedImage={fileLaying} existingImageUrl={skuData.packImageLaying} id="image-2" />

           </div>
          </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "24px" }}>
            <Dropdown
              label="Category"
              options={CategoryOptions}
              notEditable
              selectedOption={CategoryOptions.find(option => option.value === skuData.category) || null}
              onChange={(option) => handleDropdownChange('category', option.value)}
              onOptionAdded={(option) => handleOptionAdded(option, "category")}
            />
            <Dropdown
              label="Subcategory"
              options={subCategoryOptions}
              notEditable
              selectedOption={subCategoryOptions.find(option => option.value === skuData.subcategory) || null}
              onChange={(option) => handleDropdownChange('subcategory', option.value)}
              onOptionAdded={(option) => handleOptionAdded(option, "subcategory")}
            />
          </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "24px" }}>
            <Dropdown
              label="Consumables/Device"
              options={type}
              notEditable
              selectedOption={type.find(option => option.value === skuData.consumablesDevice) || null}
              onChange={(option) => handleDropdownChange('consumablesDevice', option.value)}
              onOptionAdded={(option) => handleOptionAdded(option, "consumablesDevice")}
            />
          </div>
          <div style={{ display: "flex", flexDirection:'column', gap: "10px", marginBottom: "24px" }}>
          <label htmlFor="reactmulti-select-dropdown">
        <Typography variant='h2' style={{ marginBottom: "0px",textTransform:'capitalize' }}>
        Visibility  {selectedKeyAccount && selectedKeyAccount.length > 0 ?<span
  style={{
    borderRadius: '20px',
    background: 'rgba(46, 210, 31, 0.10)',
    color: '#2ED21F',
    fontFamily: 'Mulish',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: 'normal',
    padding: '4px 8px',
    display: 'inline-flex',
    alignItems: 'center',
  }}
>
  <span
    style={{
      width: '6px',
      height: '6px',
      backgroundColor: '#2ED21F',
      borderRadius: '50%',
      marginRight: '4px',
    }}
  ></span>
  on
</span>
 : <span
 style={{
   borderRadius: '20px',
   background: 'rgba(255, 0, 0, 0.10)',
   color: '#FF0000',
   fontFamily: 'Mulish',
   fontSize: '11px',
   fontStyle: 'normal',
   fontWeight: 800,
   lineHeight: 'normal',
   padding: '4px 8px',
   display: 'inline-flex',
   alignItems: 'center',
 }}
>
 <span
   style={{
     width: '6px',
     height: '6px',
     backgroundColor: '#FF0000',
     borderRadius: '50%',
     marginRight: '4px',
   }}
 ></span>
 off
</span>
}
        </Typography>
      </label>
          <DropdownComponent
          channels={channelsData}
          onKeyAccountSelect={handleKeyAccountSelect}
         initialValue={initialKeyAccounts} // Pass the selected key account
        />
</div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "24px" }}>
            <Dropdown
              label="Brand"
              options={brands}
              selectedOption={brands.find(option => option.value === skuData.brandId?.toString()) || null}
              onChange={(option) => handleDropdownChange('brandId', option.value)}
              onOptionAdded={(option) => handleOptionAdded(option, "brandId")}
            />
          </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "24px" }}>
            <Dropdown
              label="Variant"
              options={variants}
              selectedOption={variants.find(option => option.value === skuData.variantId?.toString()) || null}
              onChange={(option) => handleDropdownChange('variantId', option.value)}
              onOptionAdded={(option) => handleOptionAdded(option, "variantId")}
            />
          </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "24px" }}>
            <Dropdown
              label="Size"
              options={sizes}
              selectedOption={sizes.find(option => option.value === skuData.sizeId?.toString()) || null}
              onChange={(option) => handleDropdownChange('sizeId', option.value)}
              onOptionAdded={(option) => handleOptionAdded(option, "sizeId")}
            />
          </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "24px" }}>
            <Dropdown
              label="Pack Type"
              options={packTypes}
              selectedOption={packTypes.find(option => option.value === skuData.packTypeId?.toString()) || null}
              onChange={(option) => handleDropdownChange('packTypeId', option.value)}
              onOptionAdded={(option) => handleOptionAdded(option, "packTypeId")}
            />
          </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "24px" }}>
            <Input
              label="Pack brand form"
              onChange={(value) => handleChange('packBrandForm', value)}
              placeholder="Enter Pack brand form"
              value={skuData.packBrandForm}
            />
          </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "24px" }}>
            <Input
              isTextArea
              label="Product Description"
              onChange={(value) => handleChange('productDescription', value)}
              placeholder="Type product description here..."
              value={skuData.productDescription}
            />
          </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "24px" }}>
            <Input
              label="EAN Code"
              onChange={(value) => handleChange('eanCode', value)}
              placeholder="Enter EAN Code"
              value={skuData.eanCode}
            />
          </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "24px" }}>
            <div
              style={{
                width: "100%",
                background: "#F5F9FF",
                borderRadius: "10px",
                gap: "12px",
                padding: "12px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "12px",
                  padding: "12px",
                }}
              >
                <SizeInput
                  text="Width"
                  onChange={(value) => setSkuData({ ...skuData, width: value })}
                  value={skuData.width}
                  icon={<Width />}
                />
                <SizeInput
                  text="Height"
                  onChange={(value) => setSkuData({ ...skuData, height: value })}
                  value={skuData.height}
                  icon={<Height />}
                />
                <SizeInput
                  text="Depth"
                  onChange={(value) => setSkuData({ ...skuData, depth: value })}
                  value={skuData.depth}
                  icon={<Depth />}
                />
              </div>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#9CA0AB",
                  marginLeft: "12px",
                }}
              >
                These dimensions are set by default standard. Editing them will
                impact ONLY THIS SKU.
              </Typography>
            </div>
          </div>
          {errors.length > 0 && (
            <div style={{ color: 'red', marginBottom: '24px', display: 'flex', justifyContent: 'center' }}>
              <div>{errors[0]}</div>
            </div>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            sx={{
              marginBottom: "10px !important",
              backgroundColor: "#0179FF",
              color: "white",
              width: "260px",
              justifyContent: "center",
              "&:hover": {
                color: "black",
              },
            }}
            startIcon={
              loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <Box component="span" className={classes.iconButton}>
                  <AddIcon />
                </Box>
              )
            }
            disabled={loading}
          >
            {loading ? "Updating..." : "Update"}
          </Button>
        </div>
      </div>
    </Box>

    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={handleSnackbarClose} variant="filled" severity={snackbarSeverity} sx={{ width: '100%' }}>
        {snackbarContent}
      </Alert>
    </Snackbar>
  </>;
};

export default EditSideBarRight;