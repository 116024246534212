import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';

type MeasurementInputProps = {
  value: number;
  unit: string;
  onValueChange: (value: number) => void;
  onUnitChange: (unit: string) => void;
  width?: number;
  max?:number;
  min?:number;
};

const MeasurementInput: React.FC<MeasurementInputProps> = ({
  value,
  unit,
  onValueChange,
  onUnitChange,
  width=186,min=0,max=900
}) => {
  return (
    <div style={{ }}>

<Box sx={{ display: 'flex', alignItems: 'center', gap: 0 ,width,height:"36px", justifySelf:"flex-start",            marginRight:"8px",
}}>
      <TextField
        type="number"
        value={value?.toString()} // Set the value prop
        onChange={(e) => onValueChange(parseInt(e.target.value, 10) || 0)}
        sx={{
          textAlign: "center !important", // Center the text

          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '1px solid #D9E7F8 !important',

            },
            '&:hover fieldset': {
              border: '1px solid #D9E7F8 !important',
            },
            '&.Mui-focused fieldset': {
              border: '1px solid #D9E7F8 !important',
            },
            '& input[type="number"]': {
              '-moz-appearance': 'textfield',
              height:"100% !important",

              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,

              },
            },
          },
        }}
        InputProps={{
          inputProps: { min, max },

          endAdornment: <InputAdornment position="end" sx={{ fontSize: '14px', '& .MuiTypography-root': { fontSize: '14px !important' , color:"#383A49"} }} >{unit}</InputAdornment>,
          style: { height: '36px' ,
      

          backgroundColor:"white",

            border: "1px solid #D9E7F8",
            fontSize: "16px",
            fontWeight: 600,
            color:"#383A49",
            borderRadius:"3px",



        
        }, 
        }}
      />
    
    </Box>
    </div>
   
  );
};

export default MeasurementInput;
