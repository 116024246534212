import React, { useState } from "react";
import { Box, Button, IconButton, Snackbar, CircularProgress } from "@mui/material";
import Logo from "../../../assets/images/Logo1.png";
import { ReactComponent as Back } from "../../../assets/icons/Back.svg";
import { useStyles } from "./SideBarStyles";
import Input from "../Input/Input";
import { ReactComponent as Row } from "../../../assets/icons/Row.svg";
import { useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { updateShelfConfig } from "../../../services/shelfConfigurationService";
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface SidebarProps {
  id?: string;
  shelfConfig: {
    col_position: number;
    row_position: number;
    rowDistance: number;
    colDistance: number;
    widths: number[];
    height: number;
  };
  setShelfConfig: React.Dispatch<React.SetStateAction<{
    col_position: number;
    row_position: number;
    rowDistance: number;
    colDistance: number;
    widths: number[];
    height: number;
  }>>;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Sidebar: React.FC<SidebarProps> = ({
  id, shelfConfig, setShelfConfig
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

  const handleSave = async () => {
    if (id) {
      console.log(shelfConfig);
      setLoadingNext(true);
      try {
        await updateShelfConfig(id, {
          col_position: shelfConfig.col_position,
          row_position: shelfConfig.row_position,
          rowDistance: shelfConfig.rowDistance,
          colDistance: shelfConfig.colDistance,
          widths: shelfConfig.widths,
          height: shelfConfig.height || 10
        });
        setSnackbarMessage("Shelf configuration updated successfully!");
        setSnackbarSeverity("success");
        navigate(`/step3/${id}`);
      } catch (error) {
        console.error('Error updating shelf configuration', error);
        setSnackbarMessage("Error updating shelf configuration");
        setSnackbarSeverity("error");
      } finally {
        setLoadingNext(false);
        setSnackbarOpen(true);
      }
    } else {
      console.error('No ID provided for update');
      setSnackbarMessage("No ID provided for update");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSaveSecond = async () => {
    if (id) {
      console.log(shelfConfig);
      setLoadingBack(true);
      try {
        await updateShelfConfig(id, {
          col_position: shelfConfig.col_position,
          row_position: shelfConfig.row_position,
          rowDistance: shelfConfig.rowDistance,
          colDistance: shelfConfig.colDistance,
          widths: shelfConfig.widths,
          height: shelfConfig.height || 10
        });
        setSnackbarMessage("Shelf configuration updated successfully!");
        setSnackbarSeverity("success");
        navigate(`/step1/${id}`);
      } catch (error) {
        console.error('Error updating shelf configuration', error);
        setSnackbarMessage("Error updating shelf configuration");
        setSnackbarSeverity("error");
      } finally {
        setLoadingBack(false);
        setSnackbarOpen(true);
      }
    } else {
      console.error('No ID provided for update');
      setSnackbarMessage("No ID provided for update");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box className={classes.container}>
      <img src={Logo} alt="Logo" className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
            STEP 2/9
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "12px" }}>
            Set Drawer Dimension
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "50px" }}>
            Edit your drawer dimensions
          </Typography>
          <Input
            onChange={(value) => setShelfConfig(prev => ({ ...prev, row_position: value }))}
            value={shelfConfig.row_position}
            icon={<Row />}
            text={"Drawers"}
            max={12}
            min={1}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={handleSaveSecond} disabled={loadingBack}>
            <Box component="span" className={classes.iconButton}>
              {loadingBack ? <CircularProgress size={24} /> : <Back />}
            </Box>
          </IconButton>
          <Button
            variant="contained"
            color="info"
            onClick={handleSave}
            sx={{ marginTop: "0px", position: "relative" }}
            disabled={loadingNext}
          >
            {loadingNext ? <CircularProgress size={24} sx={{ position: "absolute" }} /> : "Next"}
          </Button>
        </div>
      </div>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Sidebar;
