import React from "react";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomNumberInput from "../InputV2/InputV2";
import BoxList from "../BoxList/BoxList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customBox: {
      borderRadius: "5.134px",
      background: "#B5D8FF",
      width: "186.103px",
      height: "390.994px",
    },
    secondBox: {
      width: "186.103px",
      height: "82.142px",
      flexShrink: 0,
      borderRadius: "3.85px",
      background: "#98C9FF",
      // marginTop: "10px",
    },
    lineContainer: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: "20px", // Adjust the margin as needed
    },
    line: {
      borderRight: "2px solid #000", // Change the color as needed
      height: "100%", // Height set dynamically to match the combined height
      position: "relative",
    },
    arrowIcon: {
      color: "#000", // Change the color as needed
      fontSize: "24px", // Adjust the size as needed
      position: "absolute",
      top: 0,
      left: -11,
      transform: "translateY(-50%) rotate(180deg)",
    },
    arrowIconDown: {
      color: "#000", // Change the color as needed
      fontSize: "24px", // Adjust the size as needed
      position: "absolute",
      bottom: 0,
      left: -12,
      transform: "translateY(-50%) rotate(360deg)",
    },
  })
);

interface GridDemoProps {}

const GridDemo: React.FC<GridDemoProps> = () => {
  const classes = useStyles();

  // Calculate the combined height of the two boxes
  const combinedHeight = 473; // Adjust the margin as needed

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F5F9FF",
      }}
    >

<div style={{ flexGrow: 1 }}></div>

      <div
        style={{
          backgroundColor: "#F5F9FF",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomNumberInput
          value={"10"}
          unit="cm"
          onValueChange={() => {}}
          onUnitChange={() => {}}
        />

        <div
          style={{
            backgroundColor: "#F5F9FF",

            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="188"
              height="10"
              viewBox="0 0 188 10"
              fill="none"
            >
              <path
                d="M1 5L186.5 4.99999M1 5L5 9M1 5L5 1M186.5 4.99999L182.5 1M186.5 4.99999L182.5 8.99997"
                stroke="#0179FF"
              />
            </svg>
            <div className={classes.customBox}></div>

            {/* Adding the second custom box */}
            <div className={classes.secondBox}></div>
          </div>

          {/* Adding the line with dynamically calculated height and arrow icons */}
          <div
            className={classes.lineContainer}
            style={{ height: `${combinedHeight}px` }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height={combinedHeight}
              viewBox="0 0 10 478"
              fill="none"
            >
              <path
                d="M5 477L5 1M5 477L9 473M5 477L1 473M5 1L1.00002 4.99997M5 1L8.99998 4.99997"
                stroke="#0179FF"
              />
            </svg>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#F5F9FF",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >

        <CustomNumberInput
          value={"164"}
          unit="cm"
          width={110}
          onValueChange={() => {}}
          onUnitChange={() => {}}
        />
      </div>
      <div style={{ flexGrow: 1 }}></div>

      <div
      style={{
        backgroundColor: "#F5F9FF",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // Add additional styling as needed for BoxList
      }}
    >

      <BoxList></BoxList>
    </div>   
    </div>
  );
};

export default GridDemo;
