import { saveToJson } from "../../../helpers/localData";

type Array3D = boolean[][][];



export const updatePmiPositions = (shelfDuplex: Array3D, pmiPositions: Array3D): Array3D => {


    // Create a deep copy of pmiPositions to avoid mutating the original array
    const updatedPmiPositions = pmiPositions.map(layer => layer.map(row => [...row]));

    shelfDuplex.forEach((layer, layerIndex) => {
      layer.forEach((row, rowIndex) => {
        row.forEach((hasDuplexPusher, colIndex) => {
          if (hasDuplexPusher) {
            // If the next position is within bounds and not already true, insert true
            if (colIndex + 1 < row.length) {
            
                updatedPmiPositions[layerIndex][rowIndex].splice(colIndex + 1, 0, true);
              
            } else {
              // If the next position is out of bounds, push true to the end
              updatedPmiPositions[layerIndex][rowIndex].push(true);
            }
          }
        });
      });
    });
  return updatedPmiPositions;
};
export const updatePmiPositions2 = (shelfDuplex: Array3D, pmiPositions: Array3D,selectedBoxesPMIOverLay:any): Array3D => {
  // Create a deep copy of pmiPositions to avoid mutating the original array
  const updatedPmiPositions = pmiPositions.map(layer => layer.map(row => [...row]));

  shelfDuplex.forEach((layer, layerIndex) => {
    layer.forEach((row, rowIndex) => {
      row.forEach((hasDuplexPusher, colIndex) => {
        if (hasDuplexPusher ) {
          // If the next position is within bounds and not already true, insert true
          if (colIndex + 1 < row.length && !selectedBoxesPMIOverLay[layerIndex]?.[rowIndex]?.[colIndex] ) {
          
              updatedPmiPositions[layerIndex][rowIndex].splice(colIndex + 1, 0, false);
            
          } else if( !selectedBoxesPMIOverLay[layerIndex]?.[rowIndex]?.[colIndex] ) {
            // If the next position is out of bounds, push true to the end
            updatedPmiPositions[layerIndex][rowIndex].push(false);
          }else {
            updatedPmiPositions[layerIndex][rowIndex].push(true);

          }
        }
      });
    });
  });
return updatedPmiPositions;
}; 

export const combineArrays = (arrays: Array3D[]): Array3D => {
    if (arrays.length === 0) {
        // Handle the case where no arrays are provided
        return [];
    }

    // Find the maximum depth and rows across all arrays
    const maxDepth = arrays.reduce((acc, curr) => Math.max(acc, curr.length), 0);
    const maxRows = arrays.reduce((acc, curr) => Math.max(acc, curr.reduce((accRow, currRow) => Math.max(accRow, currRow.length), 0)), 0);

    // Initialize the result array with appropriate depth and rows but no columns yet
    const result: Array3D = Array.from({ length: maxDepth }, () =>
        Array.from({ length: maxRows }, () => [])
    );

    // Determine the maximum columns for each specific depth and row and fill with `false`
    for (let d = 0; d < maxDepth; d++) {
        for (let r = 0; r < maxRows; r++) {
            let maxCols = 0;
            // Find the maximum column size for the current depth and row across all arrays
            arrays.forEach(array => {
                if (array.length > d && array[d].length > r) {
                    maxCols = Math.max(maxCols, array[d][r].length);
                }
            });
            // Initialize columns for the current depth and row
            result[d][r] = Array.from({ length: maxCols }, () => false);
        }
    }

    // Iterate over every array and every element to apply the logical OR operation
    arrays.forEach(array => {
        for (let d = 0; d < array.length; d++) {
            for (let r = 0; r < array[d].length; r++) {
                for (let c = 0; c < array[d][r].length; c++) {
                    result[d][r][c] = result[d][r][c] || array[d][r][c];
                }
            }
        }
    });

    return result;
};

export function createFacingArray(n:any, minimumFacing:any, packOrder:any, increaseOrder:any, decreaseOrder:any) {
    // Initialize the array without null values initially
    let resultArray = [];

    // Create a map from minimumFacing for quick lookup
    const facingMap = new Map(minimumFacing.map((item:any) => [item.title.trim(), item.minFacing]));

    // Fill the array based on the order and minFacing values
    for (let pack of packOrder) {
        pack = pack.trim(); // Clean any whitespace
        if (facingMap.has(pack)) {
            let count = facingMap.get(pack);
            for (let i = 0; i < (count as any); i++) {
                resultArray.push(pack);
            }
        }
    }

    // Handle the case where the initial array is less than n
    let currentIndex = resultArray.length;
    while (currentIndex < n) {
        for (let pack of increaseOrder) {
            if (currentIndex >= n) break;
            pack = pack.trim();
            resultArray.push(pack);
            currentIndex++;
        }
    }

    // If the total exceeds n, start decreasing according to the decreaseOrder
    currentIndex = resultArray.length;
    if (currentIndex > n) {
        let decreaseIndex = 0;
        while (currentIndex > n) {
            let packToRemove = decreaseOrder[decreaseIndex % decreaseOrder.length].trim();
            let removalIndex = resultArray.lastIndexOf(packToRemove);
            if (removalIndex !== -1) {
                resultArray.splice(removalIndex, 1);
                currentIndex--;
            }
            decreaseIndex++;
        }
    }

    // Fill any remaining 'null' slots if needed (safety check)
    resultArray = resultArray.concat(new Array(n - resultArray.length).fill(null));

    return resultArray;
}

export function combineBooleanArrays(array1: boolean[][][], array2: boolean[][][]): boolean[][][] {
    // Check if both arrays have the same dimensions
  
    if (array1.length !== array2.length) {
      throw new Error('Arrays do not have the same dimensions');
    }
  
    const resultArray: boolean[][][] = [];
  
    // Iterate over the first dimension
    for (let i = 0; i < array1.length; i++) {
      if (array1[i].length !== array2[i].length) {
  
        throw new Error('Arrays do not have the same dimensions');
      }
  
      const layer: boolean[][] = [];
  
      // Iterate over the second dimension
      for (let j = 0; j < array1[i].length; j++) {
        if (array1[i][j].length !== array2[i][j].length) {
         
          throw new Error('Arrays do not have the same dimensions');
        }
  
        const row: boolean[] = [];
  
        // Iterate over the third dimension and apply the OR operation
        for (let k = 0; k < array1[i][j].length; k++) {
          row.push(array1[i][j][k] || !array2[i][j][k]);
        }
  
        layer.push(row);
      }
  
      resultArray.push(layer);
    }
  
    return resultArray;
  }

  export function replaceWithEmptyString(arr: any[]): any[] {
    return arr.map(item => {
      // If the item is an array, recurse into it
      if (Array.isArray(item)) {
        return replaceWithEmptyString(item);
      }
      // Otherwise, replace the item with an empty string
      return "";
    });
  }

  export function orderByCustomOrder(stringsArray: string[], orderArray: string[]): string[] {
    return stringsArray.sort((a, b) => orderArray.indexOf(a) - orderArray.indexOf(b));
  }
  

  export const initializeSelectedBoxes = (
    col: number,
    row: number,
    columnWidths: number[]
  ): boolean[][][] => {
    const savedBoxes = ([] as any);
  
    if (savedBoxes.length > 0) {
      if (savedBoxes.length < (col || 2)) {
        let difference = (col || 2) - savedBoxes.length;
        let differenceColumnWidths = columnWidths.slice(-difference);
        let newArray = differenceColumnWidths.map(columnWidth => {
          const boxesInColumn = Math.floor(columnWidth / 30.5);
          return new Array(row || 2)
            .fill(null)
            .map(() => new Array(boxesInColumn).fill(false));
        });
        saveToJson('selectedBoxesDuplex', [...savedBoxes, ...newArray]);
        return [...savedBoxes, ...newArray];
        
      } else if (savedBoxes.length > (col || 2)) {
        let difference = savedBoxes.length - (col || 2);
        saveToJson('selectedBoxesDuplex', [...savedBoxes.slice(0, -difference)]);
        return [...savedBoxes.slice(0, -difference)];
        
      } else {
        const rowNumber = row || 2;
        return columnWidths.map((columnWidth, columnIndex) => {
          const boxesInColumn = Math.floor(columnWidth / 30.5);
          return new Array(rowNumber)
            .fill(null)
            .map((_, rowIndex) => {
              const oldRow = savedBoxes[columnIndex] && savedBoxes[columnIndex][rowIndex];
              return new Array(boxesInColumn)
                .fill(null)
                .map((_, boxIndex) => oldRow && oldRow[boxIndex] !== undefined ? oldRow[boxIndex] : false);
            });
        });
      }
    } else {
      return columnWidths.map(columnWidth => {
        const boxesInColumn = Math.floor(columnWidth / 30.5);
        return new Array(row || 2)
          .fill(null)
          .map(() => new Array(boxesInColumn).fill(false));
      });
    }
  };

  export const initializeSelectedBoxesPMI = (
    selectedBoxesPmi: boolean[][][],
    col: number,
    row: number,
    columnWidths: number[]
  ): boolean[][][] => {
    const savedBoxes = selectedBoxesPmi || [] ;


    if (savedBoxes.length > 0) {
      if(savedBoxes.length < (col || 2)){
           let diffrence= (col || 2)-savedBoxes.length 
           let diffrenceColumnWidths = columnWidths.slice(-diffrence);
           let newArray = diffrenceColumnWidths.map(columnWidth => {
            const boxesInColumn = Math.floor(columnWidth / 30.5);
            return new Array((row || 2))
              .fill(null)
              .map(() => new Array(boxesInColumn).fill(false));
          });
          saveToJson('selectedBoxes', [...savedBoxes,...newArray]);

          return [...savedBoxes,...newArray]
      }else if (savedBoxes.length > (col || 2)){
        let diffrence= savedBoxes.length - (col || 2)
    


        saveToJson('selectedBoxes', [...savedBoxes.slice(0, -diffrence)]);

       return [...savedBoxes.slice(0, -diffrence)]
      }else {
        const rowNumber = row || 2; // Default to 2 if not specified
        return columnWidths.map((columnWidth, columnIndex) => {
          const boxesInColumn = Math.floor(columnWidth / 30.5);
          return new Array(rowNumber)
            .fill(null)
            .map((_, rowIndex) => {
              // Check if the oldSavedBoxes has a row at this index and column
              const oldRow = savedBoxes[columnIndex] && savedBoxes[columnIndex][rowIndex];
              return new Array(boxesInColumn)
                .fill(null)
                .map((_, boxIndex) => {
                  // Preserve the state of existing boxes, initialize new ones as false
                  return oldRow && oldRow[boxIndex] !== undefined ? oldRow[boxIndex] : false;
                });
            });
        });
      }
      return savedBoxes;
    } else {
      return columnWidths.map(columnWidth => {
        const boxesInColumn = Math.floor(columnWidth / 30.5);
        return new Array(row|| 2)
          .fill(null)
          .map(() => new Array(boxesInColumn).fill(false));
      });
    }
  };
 

  export const handleMouseUp = (
    width: number,
    height: number,
    x: number,
    y: number,
    columnWidths: number[],
    selectedBoxesPMIOverLay: boolean[][][],
    rowDistance: number,
    colDistance: number,
    
  ): boolean[][][] => {
        // Constants
        const boxWidth = 30.5; // Fixed width of each box
        const boxHeight = 51.5;
    
        const selectionStartX = x;
        const selectionEndX = selectionStartX + (width)  ;
    
        const selectionStartY = y;
        const selectionEndY = selectionStartY + height;
      
        // Calculate column offsets dynamically based on actual column widths
        let columnOffsets = columnWidths.reduce((acc:any, curr, idx) => {
          if (idx === 0) {
            acc.push(0);
          } else {
            acc.push(acc[idx - 1] + columnWidths[idx - 1]);
          }
          return acc;
        }, []);
        let intersectingBoxCount = 0;
      let wasAlreadySelected = false;
      let indexRow:number = -1 
      let indexCol: number= -1
      let IndexBox :number= -1
    
        let updatedSelectedBoxes = selectedBoxesPMIOverLay.map((customBox, customBoxIndex) => 
          customBox.map((set, setIndex) => 
            set.map((isSelected, boxIndex) => {
              const columnOffset = columnOffsets[customBoxIndex];
              const dynamicBoxCount = Math.floor(columnWidths[customBoxIndex] / boxWidth); 
              
              // Calculate boxes per column dynamically
              //if (boxIndex >= dynamicBoxCount) return false;
               // Ignore boxes that exceed the dynamic count
      
              const boxStartX = columnOffset + (boxIndex * (columnWidths[customBoxIndex]/dynamicBoxCount  )) +(((rowDistance as number) * 5 || 2)* customBoxIndex) ;
              const boxEndX = boxStartX + (columnWidths[customBoxIndex]/dynamicBoxCount  );
              const boxTopY = (setIndex) * (boxHeight +((colDistance as number) * 5 )) ; // Adjust if your layout differs
              const boxBottomY = boxTopY + (boxHeight  );
      
              // Check if the box intersects with the selection area
              const intersectsSelection = !(
                boxEndX <= selectionStartX ||
                boxStartX >= selectionEndX ||
                boxBottomY <= selectionStartY ||
                boxTopY >= selectionEndY
              );
              if (intersectsSelection ) {
                if(indexRow!=customBoxIndex && indexCol!=setIndex && IndexBox!=boxIndex)
                {
                  intersectingBoxCount++;
    
                }
                if (isSelected) wasAlreadySelected = true;
                indexRow=customBoxIndex
                indexCol=setIndex
                IndexBox=boxIndex
             
    
              }
    
      
              return  intersectsSelection;
            })
          )
        );
       
        return updatedSelectedBoxes
    
      
      }


      export const processSelectedBoxesOverlay = (
        selectedBoxesPMIOverLay: boolean[][][],
        combinedArray: boolean[][][],
        squaresWithImages: string[][][],
        facedArray: string[],
        facedArrayLaying: string[],
        facedArrayNaming: string[],
        facedArrayEan: string[],
        squaresWithNames: string[][][],
        isAuto: boolean,
        setImages:any,
        setImagesLaying:any,
        setNaming:any,
        setEan:any,
        setPackHeights:any,
        setPackWidths:any,
        squaresWithWithds:any,
        squaresWithHeights:any,
        facedArrayWidths: string[],
        facedArrayHeights: string[],

      ) => {
        let cloneSquaresWithImages =replaceWithEmptyString(combinedArray)
        let cloneSquaresWithNaming =replaceWithEmptyString(selectedBoxesPMIOverLay)
        let cloneSquaresWithEan =replaceWithEmptyString(selectedBoxesPMIOverLay)
        let cloneSquaresWithImagesLaying =replaceWithEmptyString(selectedBoxesPMIOverLay)
        let cloneSquaresWithWidths =replaceWithEmptyString(selectedBoxesPMIOverLay)
        let cloneSquaresWithHeights =replaceWithEmptyString(selectedBoxesPMIOverLay)
        let ind = 0
        if(selectedBoxesPMIOverLay.length > 0) {
          const numRows = selectedBoxesPMIOverLay.length;
          const numCols = selectedBoxesPMIOverLay[0].length;
          for (let columnIndex = 0; columnIndex < numCols; columnIndex++) {
            const newRow = [];
            for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
              combinedArray[rowIndex][columnIndex].forEach((_item, box) => {
                if(!combinedArray[rowIndex][columnIndex][box] && !squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
                  cloneSquaresWithImages[rowIndex][columnIndex][box]= !isAuto ? facedArray[ind] : '-'
                  ind ++;
                } else if(squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
                  cloneSquaresWithImages[rowIndex][columnIndex][box]=squaresWithImages[rowIndex]?.[columnIndex]?.[box]
                  ind ++;
                }else {
                 // console.log("pk")
                }
              })
           
           
            }
          }
        }
        ind = 0
        if(selectedBoxesPMIOverLay.length > 0) {
          const numRows = selectedBoxesPMIOverLay.length;
          const numCols = selectedBoxesPMIOverLay[0].length;
          for (let columnIndex = 0; columnIndex < numCols; columnIndex++) {
            const newRow = [];
            for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
              combinedArray[rowIndex][columnIndex].forEach((_item, box) => {
                if(!combinedArray[rowIndex][columnIndex][box] && !squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
                  cloneSquaresWithImagesLaying[rowIndex][columnIndex][box]= !isAuto ?  facedArrayLaying[ind] : ''
                  ind ++;
                } else if(squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
                  cloneSquaresWithImagesLaying[rowIndex][columnIndex][box]=squaresWithImages[rowIndex]?.[columnIndex]?.[box]
                  ind ++;
                }else {
                  console.log("pk")
                }
              })
           
           
            }
          }
        }
        ind = 0
        if(selectedBoxesPMIOverLay.length > 0) {
          const numRows = selectedBoxesPMIOverLay.length;
          const numCols = selectedBoxesPMIOverLay[0].length;
          for (let columnIndex = 0; columnIndex < numCols; columnIndex++) {
            const newRow = [];
            for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
              combinedArray[rowIndex][columnIndex].forEach((_item, box) => {
                if(!combinedArray[rowIndex][columnIndex][box] && !squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
                  cloneSquaresWithNaming[rowIndex][columnIndex][box]= !isAuto ?  facedArrayNaming[ind] : ''
                  ind ++;
                } else if(squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
                  cloneSquaresWithNaming[rowIndex][columnIndex][box]=squaresWithNames[rowIndex]?.[columnIndex]?.[box]
                  ind ++;
                }else {
                  console.log("pk")
                }
              })
           
           
            }
          }
        }
      
        ind = 0
        if(selectedBoxesPMIOverLay.length > 0) {
          const numRows = selectedBoxesPMIOverLay.length;
          const numCols = selectedBoxesPMIOverLay[0].length;
          for (let columnIndex = 0; columnIndex < numCols; columnIndex++) {
            const newRow = [];
            for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
              combinedArray[rowIndex][columnIndex].forEach((_item, box) => {
                if(!combinedArray[rowIndex][columnIndex][box] && !squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
                  cloneSquaresWithEan[rowIndex][columnIndex][box]= !isAuto ?  facedArrayEan[ind] : ''
                  ind ++;
                } else if(squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
                  cloneSquaresWithEan[rowIndex][columnIndex][box]=squaresWithNames[rowIndex]?.[columnIndex]?.[box]
                  ind ++;
                }else {
                  console.log("pk")
                }
              })
           
           
            }
          }
        }
        ind = 0
        if(selectedBoxesPMIOverLay.length > 0) {
          const numRows = selectedBoxesPMIOverLay.length;
          const numCols = selectedBoxesPMIOverLay[0].length;
          console.log("numRows",selectedBoxesPMIOverLay)
          for (let columnIndex = 0; columnIndex < numCols; columnIndex++) {
            const newRow = [];
            for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
              combinedArray[rowIndex][columnIndex].forEach((_item, box) => {
                if(!combinedArray[rowIndex][columnIndex][box] && !squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
                  cloneSquaresWithWidths[rowIndex][columnIndex][box]= !isAuto ?  facedArrayWidths[ind] : ''
                  ind ++;
                } else if(squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
                  cloneSquaresWithWidths[rowIndex][columnIndex][box]=squaresWithWithds[rowIndex]?.[columnIndex]?.[box]
                  ind ++;
                }else {
                  console.log("pk")
                }
              })
           
           
            }
          }
        }
        ind = 0
        if(selectedBoxesPMIOverLay.length > 0) {
          const numRows = selectedBoxesPMIOverLay.length;
          const numCols = selectedBoxesPMIOverLay[0].length;
          console.log("numRows",selectedBoxesPMIOverLay)
          for (let columnIndex = 0; columnIndex < numCols; columnIndex++) {
            const newRow = [];
            for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
              combinedArray[rowIndex][columnIndex].forEach((_item, box) => {
                if(!combinedArray[rowIndex][columnIndex][box] && !squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
                  cloneSquaresWithHeights[rowIndex][columnIndex][box]= !isAuto ?  facedArrayHeights[ind] : ''
                  ind ++;
                } else if(squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
                  cloneSquaresWithHeights[rowIndex][columnIndex][box]=squaresWithHeights[rowIndex]?.[columnIndex]?.[box]
                  ind ++;
                }else {
                  console.log("pk")
                }
              })
           
           
            }
          }
        }
        selectedBoxesPMIOverLay.forEach((row, rowIndex) => {
          row.forEach((boxs, columnIndex) => {
           
        
           
            // Or do whatever you need with each item in the array
          });
        });
        
        setImages(cloneSquaresWithImages)
        setImagesLaying(cloneSquaresWithImagesLaying)
        setNaming(cloneSquaresWithNaming)
        setEan(cloneSquaresWithEan)
        setPackWidths(cloneSquaresWithWidths)
        setPackHeights(cloneSquaresWithHeights)
      
      }