import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import MeasurementInput from "../InputV2/InputV2";
import ImageUpload from "../ImageUpload/ImageUpload";

interface EditMaterialMarketingProps {
  onCancel: () => void;
  onContinue: (data: { id:number,width: string; height: string; title: string; image: File | null }) => void;
  color: string;
  type?: number;
  initialValues: {
    id:number;
    width: string;
    height: string;
    title: string;
    imageUrl: string; // URL of the initial image
  };
}

const EditMaterialMarketing: React.FC<EditMaterialMarketingProps> = ({
  onCancel,
  onContinue,
  color,
  type = 1,
  initialValues,
}) => {
  const [height, setHeight] = useState(initialValues.height);
  const [width, setWidth] = useState(initialValues.width);
  const [title, setTitle] = useState(initialValues.title);
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>(initialValues.imageUrl); // Image URL state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  console.log('initialValues',initialValues)
  const handleImageUpload = (file: File) => {
    setImage(file);
    setImageUrl(URL.createObjectURL(file)); // Update image preview with the uploaded file
  };

  const handleContinueClick = () => {
    setError("");
    setLoading(true);

    // Placeholder for continue logic
    onContinue({id:initialValues.id,width,height,title,image});


    setLoading(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "301px",
          height: "100%",
          borderRadius: "15px",
          background: "#F5F9FF",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "50%",
            background: "white",
            border: "2px solid #F5F9FF",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
            gap: "0px",
          }}
        >
          <div style={{ marginLeft: "-26px", textAlign: "center" }}>
            <Typography
              style={{
                fontSize: "10px",
                fontWeight: "600",
                color: "#383A49",
                lineHeight: "0",
              }}
            >
              W
            </Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="66"
              height="8"
              viewBox="0 0 66 8"
              fill="none"
            >
              <path
                d="M1.5 4.00001L64.5 4M1.5 4.00001L4.4973 7.00001M1.5 4.00001L4.4973 1M64.5 4L61.5027 1M64.5 4L61.5027 6.99999"
                stroke="#0179FF"
              />
            </svg>
          </div>

          <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
            <div
              style={{
                width: "64px",
                height: "64px",
                background: color,
                borderRadius: "8px",
              }}
            ></div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="65"
              viewBox="0 0 8 65"
              fill="none"
            >
              <path
                d="M3.99999 1L3.99999 63.5001M3.99999 1L0.999988 3.9973M3.99999 1L7 3.99731M3.99999 63.5001L6.99999 60.5028M3.99999 63.5001L0.999994 60.5028"
                stroke="#0179FF"
              />
            </svg>
            <Typography
              style={{ fontSize: "10px", fontWeight: "600", color: "#383A49" }}
            >
              H
            </Typography>
          </div>
        </div>
        
        <div style={{ padding: "8px" }}>
          {type === 2 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px 10px",
                background: "white",
              }}
            >
              <ImageUpload onUpload={handleImageUpload} />
              {imageUrl && (
                <img
                  src={imageUrl}
                  alt="Preview"
                  style={{ maxWidth: "100%", maxHeight:'100px', marginTop: "10px", borderRadius: "8px" }}
                />
              )}
            </div>
          )}
          <div style={{ marginTop: "10px" }}>
            <MeasurementInput
              label={"TYPE OF LED SCREEN"}
              type={"text"}
              value={title}
              unit={""}
              onValueChange={(value) => {
                setTitle(value);
              }}
              onUnitChange={() => {}}
              width={286}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              marginTop: "12px",
            }}
          >
            <MeasurementInput
              label={"Height"}
              value={height}
              unit={"cm"}
              onValueChange={(value) => {
                setHeight(value);
              }}
              onUnitChange={() => {}}
            />
            <MeasurementInput
              label={"Width"}
              value={width}
              unit={"cm"}
              onValueChange={(value) => {
                setWidth(value);
              }}
              onUnitChange={() => {}}
            />
          </div>
        </div>
      </div>
      {error && (
        <Typography color="error" style={{ marginTop: "8px", fontSize: "12px" }}>
          {error}
        </Typography>
      )}
      <div style={{ marginTop: "8px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onCancel()}
          sx={{
            maxHeight: "34px",
            fontSize: "12px",
            fontWeight: "700",
            padding: "2px",
            width: "147px",
            marginTop: "0",
            background: "#ECF3FB",
            marginRight: "7px",
            color: "#383A49",
            "&:hover": {
              color: "#383A49",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            maxHeight: "34px",
            fontSize: "12px",
            fontWeight: "700",
            padding: "2px",
            width: "147px",
            marginTop: "0",
            background: "#0179FF",
            color: "white",
            "&:hover": {
              color: "black",
              background: "#0179FF",
            },
          }}
          onClick={handleContinueClick}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Continue'}
        </Button>
      </div>
    </div>
  );
};

export default EditMaterialMarketing;
