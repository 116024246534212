import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';

type MeasurementInputProps = {
  value: string;
  unit: string;
  onValueChange: (value: string) => void;
  onUnitChange: (unit: string) => void;
  width?: number;
};

const MeasurementInput: React.FC<MeasurementInputProps> = ({
  value,
  unit,
  onValueChange,
  onUnitChange,
  width=186
}) => {
  return (
    <div style={{ alignSelf:"flex-start"}}>

<Box sx={{ display: 'flex', alignItems: 'center', gap: 0 ,width,height:"36px", justifySelf:"flex-start"}}>
      <TextField
        type="number"
        value={value}
        onChange={(e) => onValueChange(e.target.value)}
        InputProps={{
          endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
          style: { height: '36px' ,
      
       
        
            border: "1px solid #D9E7F8",
            borderRadius: "10px",
            fontSize: "16px",
            fontWeight: 600,
            color:"#383A49"


        
        }, 
        }}
      />
    
    </Box>
    </div>
   
  );
};

export default MeasurementInput;
