import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { CircularProgress } from '@mui/material';

type ItemProps = {
  id: number;
  pack_image: string;
  variantName: string;
  brandName: string;
  min_facings: number;
};

type MinFacingsProps = {
  items: ItemProps[];
  onMinFacingsChange: (id: number, minFacings: number, keyAccount: number) => void;
  loadingItemId: number | null; // Add loadingItemId prop
  keyAccount: any;
};

const MinFacings: React.FC<MinFacingsProps> = ({ items, onMinFacingsChange, loadingItemId, keyAccount }) => {
  const [currentKeyAccount, setCurrentKeyAccount] = useState(keyAccount);

  useEffect(() => {
    setCurrentKeyAccount(keyAccount);
  }, [keyAccount]);

  const debouncedChange = useCallback(
    debounce((id: number, minFacings: number, currentKeyAccount: any) => {
      onMinFacingsChange(id, minFacings, currentKeyAccount.id);
    }, 500),
    [keyAccount]
  );

  const [inputValues, setInputValues] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    const initialValues = items.reduce((acc, item) => {
      acc[item.id] = item.min_facings.toString();
      return acc;
    }, {} as { [key: number]: string });
    setInputValues(initialValues);
  }, [items]);

  const handleChange = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    if (parseInt(value, 10) < 0) {
      value = '0';
    }
    // Update local input value
    setInputValues((prev) => ({
      ...prev,
      [id]: value,
    }));

    const minFacings = parseInt(value, 10);
    if (!isNaN(minFacings)) {
      debouncedChange(id, minFacings, currentKeyAccount);
    }
  };

  // Sort items so that those with min_facings of 0 appear last
  const sortedItems = [...items].sort((a, b) => {
    return a.min_facings === 0 ? 1 : b.min_facings === 0 ? -1 : 0;
  });

  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0px', border: '1px solid #E1E3E6', background: '#E1E3E6', boxSizing: 'border-box' }}>
      {sortedItems.map((item) => (
        <div
          key={item.id}
          style={{
            border: '1.5px solid #E1E3E6',
            borderRadius: '5px',
            padding: '10px',
            display: 'flex',
            background: parseInt(inputValues[item.id]) > 0 ? 'white' : '#F3F4F5',
            gap: '10px',
            boxSizing: 'border-box',
            justifyContent: 'space-between',
            alignItems: 'center',
            opacity: parseInt(inputValues[item.id]) > 0 ? 1 : 0.8,
          }}
        >
          <img src={item.pack_image} width={25} height={38} alt="" style={{ height: 'auto',maxHeight:'35px',objectFit:'contain' }} />
          <div style={{ fontSize: '12px', fontWeight: '400', color: '#383A49', display: 'flex', alignItems: 'center' }}>
            {item.brandName} {item.variantName}
            {loadingItemId === item.id && <CircularProgress size={14} style={{ marginLeft: '8px' }} />}
          </div>
          <input
            type="number"
            value={inputValues[item.id] || ''}
            onChange={(event) => handleChange(item.id, event)}
            style={{
              width: '31px',
              height: '40px',
              borderRadius: '4px',
              border: '1px solid var(--Stroke, #D9E7F8)',
              background: '#FFF',
              MozAppearance: 'textfield',
              WebkitAppearance: 'none',
              textAlign: 'center',
              fontSize: '14px',
              color: '#383A49',
              fontWeight: 700,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default MinFacings;
