import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { ReactComponent as Search } from "../../../../assets/icons/Search.svg";

type MeasurementInputProps = {
  value: string;
  unit: string;
  onValueChange: (value: string) => void;
  onUnitChange: (unit: string) => void;
  width?: number;
  label:string;
  type?:string

};

const SearchInput: React.FC<MeasurementInputProps> = ({
  value,
  unit,
  onValueChange,
  onUnitChange,
  width=138,
  label,
  type="text"
}) => {
  return (
    <div style={{ alignSelf:"center", marginBottom:"12px",marginTop:"20px",width:'100%'}}>
  <Typography style={{fontSize:"14px",fontWeight:"400",color:"#9CA0AB", marginLeft:"2px"}}> {label}</Typography>

<Box sx={{ display: 'flex', alignItems: 'center', gap: 0 ,width:'100%',height:"36px", justifySelf:"flex-start"}}>
    <TextField
        type={type}
        value={value}
        placeholder='Search for product here'
        onChange={(e) => onValueChange(e.target.value)}
        sx={{ 
          width: '100%',
          '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: "1px solid D9E7F8",
          },
          '&:hover fieldset': {
              borderColor: 'newColorHover', // Change newColorHover to your desired color for hover state
          },
          '&.Mui-focused fieldset': {
              borderColor: 'newColorFocused', // Change newColorFocused to your desired color for focused state
          },
          '& .MuiInputBase-input': {
            '&::placeholder': {
              color: '#9CA0AB', // Style for placeholder
              fontSize: "14px",
              fontWeight: 300,
              fontStyle:"italic"

      
            },
          },
      },}}
        InputProps={{
          endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
          startAdornment: (
            <InputAdornment position="start">
              <Search /> {/* This is where you include the icon */}
            </InputAdornment>
          ),
          style: { height: '36px' ,

            borderRadius: "8px",
            fontSize: "16px",
            fontWeight: 600,
            color:"#383A49",
            backgroundColor: "white",
            width: "100%",
            


        
        }, 
        }}
      />
    
    </Box>
    </div>
   
  );
};

export default SearchInput;
