import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./ContentStyles";
import Viewer from "../../RrpPreview/Viewer";

type Square = {
  title?: string;
  width: number;
  height: number;
  x: number;
  y: number;
};

interface GridDemoProps {
  squaresYellow: Square[];
  yellowSquares: Square[];
  setYellowSquares: React.Dispatch<React.SetStateAction<Square[]>>;
  col: number;
  row: number;
  rowDistance: number;
  colDistance: number;
  widths: number[];
  selectedBoxes: boolean[][][];
  hiddencells: never[]
}

const GridDemo: React.FC<GridDemoProps> = ({
  squaresYellow,
  yellowSquares,
  setYellowSquares,
  col,
  row,
  rowDistance,
  colDistance,
  widths,
  selectedBoxes,
  hiddencells
}) => {
  const classes = useStyles();
  const initializeSelectedBoxesPMI = () => {
    if (selectedBoxes && selectedBoxes.length > 0) {
      if (selectedBoxes.length < col) {
        const diffrence = col - selectedBoxes.length;
        const diffrenceColumnWidths = widths.slice(-diffrence);
        const newArray = diffrenceColumnWidths.map((columnWidth) => {
          const boxesInColumn = Math.floor(columnWidth / 30.5);
          return new Array(row).fill(null).map(() => new Array(boxesInColumn).fill(false));
        });
        return [...selectedBoxes, ...newArray];
      } else if (selectedBoxes.length > col) {
        const diffrence = selectedBoxes.length - col;
        return selectedBoxes.slice(0, -diffrence);
      } else {
        return widths.map((columnWidth, colIndex) => {
          const boxesInColumn = Math.floor(columnWidth / 30.5);
          return new Array(row).fill(null).map((_, rowIndex) => {
            const oldRow = selectedBoxes[colIndex] && selectedBoxes[colIndex][rowIndex];
            return new Array(boxesInColumn).fill(null).map((_, boxIndex) => {
              return oldRow && oldRow[boxIndex] !== undefined ? oldRow[boxIndex] : false;
            });
          });
        });
      }
    } else {
      return widths.map((columnWidth) => {
        const boxesInColumn = Math.floor(columnWidth / 30.5);
        return new Array(row).fill(null).map(() => new Array(boxesInColumn).fill(false));
      });
    }
  };

  const [currentSelectedBoxes, setCurrentSelectedBoxes] = useState(initializeSelectedBoxesPMI);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentSelectedBoxes(initializeSelectedBoxesPMI());
  }, [selectedBoxes, widths, row]);

  useEffect(() => {
    setYellowSquares((prevYellowSquares) => {
      return squaresYellow.map((square, index) => {
        const existingYellowSquare = prevYellowSquares[index];
        return {
          ...square,
          x: existingYellowSquare ? existingYellowSquare.x : 0,
          y: existingYellowSquare ? existingYellowSquare.y : 0,
        };
      });
    });
  }, [ setYellowSquares]);
  const handleYellowSquareMouseDown = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    event.stopPropagation();
    event.preventDefault();

    const yellowSquare = yellowSquares[index];
    const containerBounds = containerRef.current?.getBoundingClientRect();
    if (!containerBounds) return;

    const offsetX = event.clientX - yellowSquare.x - containerBounds.left;
    const offsetY = event.clientY - yellowSquare.y - containerBounds.top;

    const handleMouseMove = (moveEvent: MouseEvent) => {
      if (!containerRef.current) return;

      const containerBounds = containerRef.current.getBoundingClientRect();
      let newX = moveEvent.clientX - offsetX - containerBounds.left + window.scrollX;
      let newY = moveEvent.clientY - offsetY - containerBounds.top + window.scrollY;

      // newX = Math.max(0, Math.min(newX, containerBounds.width - yellowSquare.width));
      // newY = Math.max(0, Math.min(newY, containerBounds.height - yellowSquare.height));

      setYellowSquares((prev) =>
        prev.map((square, idx) =>
          idx === index
            ? {
                ...square,
                x: newX,
                y: newY,
              }
            : square
        )
      );
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const removeYellowSquare = (index: number) => {
    setYellowSquares((prev) => prev.filter((_, i) => i !== index));
  };

  const renderBoxes = (customBoxIndex: number, setIndex: number, totalWidth: number) => {
    const boxWidth = 30.5;
    const dynamicBoxCount = Math.floor(totalWidth / boxWidth);
    return Array.from({ length: dynamicBoxCount }, (_, boxIndex) => (
      <div
        key={`${customBoxIndex}-${setIndex}-${boxIndex}`}
        className={`${classes.whiteBox} ${currentSelectedBoxes[customBoxIndex]?.[setIndex]?.[boxIndex] ? classes.selectedBox : ""} `}
        style={{ width: `${boxWidth}px` }}
      />
    ));
  };
  return (
    <div style={{ display: "flex", justifyContent: "center", backgroundColor: "#F5F9FF" }}>
      <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div
          ref={containerRef}
          style={{
            display: "flex",
            gap: `${rowDistance * 5}px`,
            width:'100%',
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {yellowSquares.map((square, index) => (
            <div
              key={index}
              className={classes.yellowSquare}
              style={{
                width: square.width,
                height: square.height,
                left: square.x,
                top: square.y,
                position: "absolute",
              }}
              onMouseDown={(e) => handleYellowSquareMouseDown(e, index)}
            >
              <div>
              <Viewer id={(square as any).id} rowSpace={colDistance * 5 }></Viewer>
              <CloseIcon className={classes.closeIcon} onClick={() => removeYellowSquare(index)} />
              </div>
             
            </div>
          ))}

          {Array.from({ length: col }).map((_, customBoxIndex) => (
            <div
              key={customBoxIndex}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: `${rowDistance * 5}px`,
              }}
            >
              <div
                className={classes.customBox}
                style={{
                  gap: `${colDistance * 5}px`,
                  width: `${widths[customBoxIndex]}px`,
                }}
              >
                {Array.from({ length: row }).map((_, setIndex) => (
                  <div
                    key={`${customBoxIndex}-${setIndex}`}
                    className={classes.subBox}
                    style={{ width: `${widths[customBoxIndex]}px`,visibility:hiddencells[setIndex]&& hiddencells[setIndex][customBoxIndex] ? 'unset':'hidden' }}
                  >
                    {renderBoxes(customBoxIndex, setIndex, widths[customBoxIndex])}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GridDemo;
