import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Input from "../Input/Input";
import { getFromJson } from "../../../helpers/localData";

interface FillOrderProps {
  height: number;
  boxPerRow: number;
  items: { id: number; text: string; color: string; value: number ,width:number}[];
  setItems: React.Dispatch<any>;
  handleDuplexTypeChange: (typeIndex: number, value: number) => void;
  getDuplexTypeValue: (typeIndex: number) => number;
  selectedButton: string;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>
}


const duplexInputs = [
  { text: "Standing DP", id: 0, color: '#5DD671', value: 0, width: 28.5 },
  { text: "VEEV NOW Laying  DP", id: 1, color: '#FF9F47', value: 0, width: 19.5},
  { text: "Standing & Laying DP", id: 2, color: '#FFE455', value: 0, width: 28.5 },
  { text: " CC Laying 2 DP", id: 3, color: 'blue', value: 0, width: 27.5 },
  { text: "VEEV Now Standing DP", id: 4, color: '#FF9F47', value: 0, width: 19.5 },
  { text: "HNB DP", id: 5, color: 'blue', value: 0, width: 35 },
  { text: "Laying DP", id: 6, color: '#F95252', value: 0, width: 28.5 },
  { text: "HEETS", id: 7, color: '#0179FF', value: 0, width: 38.75 },
  { text: "TEREA", id: 8, color: '#F95252', value: 0, width: 38.75 },
  { text: "Fiit", id: 9, color: '#FFE455', value: 0, width: 38.75 },
  { text: "Delia", id: 10, color: '#A5ABAA', value: 0, width: 38.75 },
  { text: "VEEV ONE", id: 11, color: '#9747FF', value: 0, width: 28.5 },
  { text: "VEEV NOW", id: 12, color: '#FF9F47', value: 0, width: 19.5 },
  { text: "LEVIA", id: 13, color: '#5DD671', value: 0, width: 36.5 },
];


const FillOrder: React.FC<FillOrderProps> = ({
  height,
  boxPerRow,
  items,
  setItems,
  handleDuplexTypeChange,
  getDuplexTypeValue,
  selectedButton,
  setSnackbarMessage,
  setSnackbarOpen
}) => {
  // useEffect(() => {
  //   setItems(duplexInputs);
  // }, [selectedButton, setItems]);

  const shouldHideItem = (itemId: number) => {
    switch (selectedButton) {
      case 'HNB':
        return ![7, 8, 9, 10].includes(itemId);
      case 'VEEV ONE':
        return ![0, 2,6].includes(itemId);
      case 'Veev Now':
        return ![0, 2].includes(itemId);
      case 'Levia':
        return itemId !== 13;
      default:
        return false;
    }
  };

  const handleInputChange = (index: number, value: number) => {
    const currentTotalWidth = items.reduce((sum, item, index2) => {
      if (index === index2) {
        return sum + item?.width * value + 2;
      }
      return sum + item?.width * item.value + 2;
    }, 0);
  
    const maxWidth = (getFromJson<number[]>('RRPcolumnWidths')?.[0] || 50) * 5;
  
    // if (currentTotalWidth > maxWidth) {
    //   setSnackbarMessage("You don't have enough space to add a pusher");
    //   setSnackbarOpen(true);
    //   return;
    // }
  
    const updatedItems = items.map((item, i) => {
      if (i === index) {
        return { ...item, value };
      }
      return item;
    });
  
    const initialValue = items[index].value;
     if(items.find(item => item.id === 11)?.value === 0 && items[index].value <value ) {
      setSnackbarMessage("There is no available  Veev Now pushers");
      setSnackbarOpen(true);
      return ;
     }
    // Check if the index corresponds to items with IDs 1 or 4
    if (items[index].id === 0 || items[index].id === 2 || items[index].id === 6) {
      const veevNowIndex = items.findIndex(item => item.id === 11  );
      if (veevNowIndex !== -1) {
        const veevNowItem = { ...updatedItems[veevNowIndex] };
  
        if (value > initialValue) {
          // Increment case
          veevNowItem.value -= 1;
        } else {
          // Decrement case
          veevNowItem.value += 1;
        }
  
        updatedItems[veevNowIndex] = veevNowItem;
  
        // Remove the updated item from its current position
        const [movedItem] = updatedItems.splice(index, 1);
  
        if (initialValue === 0) {
          // Insert the updated item just before the VEEV NOW item (id 12)
          const newVeevNowIndex = updatedItems.findIndex(item => item.id === 11);
          updatedItems.splice(newVeevNowIndex, 0, movedItem);
        } else {
          // Insert the updated item back in its original position
          updatedItems.splice(index, 0, movedItem);
        }
      }
    }
  
    setItems(updatedItems);
  };

  return (
    <Box
      style={{
        height: "100%",
        padding: "8px",
        overflow: "auto",
      }}
    >
      <div style={{ padding: "4px", borderRadius: "4px" }}>
        {duplexInputs.map((duplexItem, duplexIndex) => {
          const itemIndex = items.findIndex(item => item.id === duplexItem.id);
          const currentItem = items[itemIndex] || duplexItem;
          return (
            <div
              key={currentItem.id}
              style={{
                marginBottom: "4px",
                display: shouldHideItem(currentItem.id) ? "none" : "flex",
                alignItems: "center",
                backgroundColor: "#F5F9FF",
                borderRadius: '8px',
                height: 40,
                padding: '0 4px'
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  margin: "0.5px",
                  display: "flex",
                  gap: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                    height: "40px",
                    borderRadius: "4px",
                    boxSizing: "border-box",
                    padding: "2px",
                    paddingRight: "6px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "2px",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: '5px'
                    }}
                  >
                    <div style={{
                      backgroundColor: currentItem.color, width: '22px', height: '22px',
                      borderRadius: '50%',
                    }}></div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Input
                      icon={undefined}
                      text={duplexItem.text}
                      onChange={(value) => handleInputChange(itemIndex, value)}
                      value={currentItem.value}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Box>
  );
};

export default FillOrder;
