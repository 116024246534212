import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Sidebar from '../components/Step11-mini/sideBar/SideBar';
import DemoComponent from '../components/Step11-mini/Content/Content';
import { getFromJson, saveToJson } from '../components/helpers/localData';
// Import the utility functions

const AboutPage: React.FC = () => {
  // Attempt to retrieve initial values from localStorage, falling back to default values if not found
  const initialCol = getFromJson<number>('col-mini') || 2;
  const initialRow = getFromJson<number>('row-mini') || 2;
  const initialRowDistance = getFromJson<number>('rowDistance') || 2;
  const initialColDistance = getFromJson<number>('colDistance') || 2;

  // State initialization with potentially retrieved values
  const [col, setCol] = useState(initialCol);
  const [row, setRow] = useState(initialRow);
  const [rowDistance, setRowDistance] = useState(initialRowDistance);
  const [colDistance, setColDistance] = useState(initialColDistance);

  // Save state to localStorage whenever it changes
  useEffect(() => {
    saveToJson('col-mini', col);
  }, [col]);

  useEffect(() => {
    saveToJson('row-mini', row);
  }, [row]);

  useEffect(() => {
    saveToJson('rowDistance', rowDistance);
  }, [rowDistance]);

  useEffect(() => {
    saveToJson('colDistance', colDistance);
  }, [colDistance]);

  return (
    <div>
      <Grid container>
        <Grid item xs={2.8}>
          <Sidebar 
            col={col} row={row} 
            colDistance={colDistance} onColDistanceChange={setColDistance} 
            rowDistance={rowDistance} onRowDistanceChange={setRowDistance}  
            onColChange={setCol} onRowChange={setRow}/>
       
        </Grid>
        <Grid item xs={9.2}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#e0e0e0",
            }}
          >
           <DemoComponent 
             col={col} row={row} 
             setCol={setCol} setRow={setRow} 
             rowDistance={rowDistance} setRowDistance={setRowDistance}  
             colDistance={colDistance} setColDistance={setColDistance}/>
     
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AboutPage;
