import React, { useEffect, useState } from "react";
import { Box, Typography, Button, IconButton, styled } from "@mui/material";
import { Paper, Theme } from "@mui/material";


import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';


interface HNBProduct {
  title?: string;
  imgSrc:string;
}


interface HNBProductCardProps {
  material: HNBProduct;
  setSelectedProductCardImage:React.Dispatch<React.SetStateAction<string>>;

}

interface HNBProductListProps {
  materials: HNBProduct[];
  setSelectedProductCardImage:React.Dispatch<React.SetStateAction<string>>;

}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // Other styles remain unchanged
   image:{
    width: "50px",
    height: "33px",
    "&:hover":{
      cursor: "pointer"
    }
   }
    // Other styles remain unchanged
  })
);
const HNBProductCard: React.FC<HNBProductCardProps> = ({
  material,
  setSelectedProductCardImage
}) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        marginBottom: 2,
        background: "white",
        borderRadius: "8.5px",
        boxShadow: "none",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "12px",
            gap: "8px",
            borderRadius: "8.5px",
          }}
        >
          <div
            style={{
              width: "64px",
              height: "64px",
              background: "#F2F8FF",
              alignSelf: "flex-start",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={material.imgSrc}
              onClick={()=>{setSelectedProductCardImage(material.imgSrc)}}
              className={classes.image}
            ></img>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontSize: "12px",
                fontWeight: "600",
                maxWidth: "165px",
                lineHeight: "normal",
              }}
            >
              {material.title || "No Title"}
            </Typography>

            <div style={{ display: "flex", alignItems: "center" }}>
              <a
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#0179FF",
                  textDecoration: "underline",
                }}
              >
                {" "}
                Edit
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HNBProductList: React.FC<HNBProductListProps> = ({
  materials,
  setSelectedProductCardImage
}) => {
  const [materialState, setMaterialState] =
    useState<HNBProduct[]>(materials);

  return (
    <Box
      sx={{
        width: 300,
        margin: "auto",

        height: "280px",
        background: "#F2F8FF",
        padding: "16px",
        borderRadius: "10px",
        gap: "8px",
        overflow: "auto",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {materialState.map((material, index) => (
          <HNBProductCard
            key={index}
            material={material}
            setSelectedProductCardImage={setSelectedProductCardImage}
            />
        ))}
      </div>
    </Box>
  );
};

export default HNBProductList;
