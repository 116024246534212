import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import NoShelfCard from "../noShelfCard/NoshelfCard";
import { useStyles } from "./ContentStyles";

import { useNavigate } from "react-router-dom";

import ListComponent from "../List/List";

import TabsComponent from "../TabComponent/Tab";
import TabsComponentV2 from "../TabComponentV2/Tab";

import MeasurementInput from "../InputV2/InputV2";
import SideBarRight from "../AddSkuModal/SideBarRight";
import StoreCardsList from "../StoreCardList/StoreCardList";
import {
  getAllKeyAccounts,
  getAllPosUniverses,
  getPosUniversesWithNullKeyAccount,
  searchKeyAccountByName,
  searchPosUnivers,
  getAllChannels,
  getPosUniversesWithNotNullKeyAccount,
  getGroupedChains,
  getFilteredItemsChaine,
} from "../../../services/posuniversService";
import {
  Grid,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
} from "@mui/material";
import { set } from "lodash";
interface ContentComponentProps {
  title: string;
  subtitle: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
type StoreType = {
  name: string;
  posId: string;
  address: string;
  region: string;
  visibility: boolean;
};

const ContentComponent: React.FC<ContentComponentProps> = ({
  title,
  subtitle,
  isOpen,
  setIsOpen,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [keyAccounts, setKeyAccounts] = useState<any[]>([]);
  const [channels, setChannels] = useState<{ id: number; name: string }[]>([]);

  const [stores, setStores] = useState<StoreType[]>([]);

  const [accountsStores, setAccountsStores] = useState<any[]>([]);

  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");
  const [activeChannelId, setActiveChannelId] = useState<number>(0);
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const fetchData = async () => {
    try {
      setLoading(true);
      const [keyAccounts, chnnels, posUnivers] =
        await Promise.all([
          getAllKeyAccounts(),
          getAllChannels(),
          getPosUniversesWithNullKeyAccount(),
        ]);
      setKeyAccounts(keyAccounts);
      setChannels(chnnels);
      setStores(posUnivers);
    } catch (error) {
      console.error("Error fetching data", error);
      setSnackbarContent("Error fetching data");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const fetchKeyAccounts = async () => {
      try {
        setLoading(true);
        const keyAccounts = await getAllKeyAccounts();
        setKeyAccounts(keyAccounts);
      } catch (error) {
        console.error("Error fetching key accounts", error);
        setSnackbarContent("Error fetching key accounts");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };
    const fetchChannels = async () => {
      try {
        setLoading(true);
        const keyAccounts = await getAllChannels();
        setChannels(keyAccounts);
      } catch (error) {
        console.error("Error fetching channels", error);
        setSnackbarContent("Error fetching channels");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };
    const fetchKeyPosUnivers = async () => {
      try {
        setLoading(true);
        const posUnivers = await getPosUniversesWithNotNullKeyAccount();
        setAccountsStores(posUnivers);
      } catch (error) {
        console.error("Error fetching POS Univers with key accounts", error);
        setSnackbarContent("Error fetching POS Univers with key accounts");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };
    const fetchPosUnivers = async () => {
      try {
        setLoading(true);
        const posUnivers = await getPosUniversesWithNullKeyAccount();
        setStores(posUnivers);
      } catch (error) {
        console.error("Error fetching POS Univers", error);
        setSnackbarContent("Error fetching POS Univers");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };
    fetchChannels();
  //  fetchKeyPosUnivers();
    fetchKeyAccounts();
    fetchPosUnivers();
  }, []);

  const handleOpenSidebar = (id: number | null = null) => {
    console.log("id", id);
    setSelectedId(id);
    setIsOpen(true);
  };

  const handleSearchChange = async (value: string) => {
    setSearchTerm(value);
    setLoading(true);
    try {
      if (value) {
        const [searchPosResults, searchKeyAccountResults] =
          await Promise.allSettled([
            searchPosUnivers(value),
            searchKeyAccountByName(value),
          ]);

        if (searchPosResults.status === "fulfilled") {
          setStores(searchPosResults.value);
        } else {
          console.error("Error searching POS Univers", searchPosResults.reason);
        }

        if (searchKeyAccountResults.status === "fulfilled") {
          setKeyAccounts(searchKeyAccountResults.value);
        } else {
          console.error(
            "Error searching Key Accounts",
            searchKeyAccountResults.reason
          );
        }
      } else {
        const [posUnivers, keyAccounts] = await Promise.all([
          getAllPosUniverses(),
          getAllKeyAccounts(),
        ]);
        setStores(posUnivers);
        setKeyAccounts(keyAccounts);
      }
    } catch (error) {
      console.error("Error searching", error);
      setSnackbarContent("Error searching");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };





  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  useEffect(() => {
    const fetchFilteredItems = async () => {
      setLoading(true);
      try {
        const data = await getFilteredItemsChaine(channels[activeChannelId].id);
        setFilteredItems(data);
      } catch (err: any) {
        console.log('somthing when wrong ')
      } finally {
        setLoading(false);
      }
    };

      fetchFilteredItems();
    
  }, [activeChannelId,channels]); 
  const tabs = [
    {
      title: "Key Accounts",
      content: (
        <ListComponent
          items={filteredItems}
        />
      ),
    },
    {
      title: "Independent Stores",
      content: (
        <StoreCardsList
          stores={
            stores.filter(
              (x: any) => x.channel_id === channels[activeChannelId]?.id
            ) as any
          }
          onEdit={handleOpenSidebar}
        />
      ),
    },
  ];

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
     {  isOpen && <SideBarRight
          isOpen={isOpen}
          keyaccounts={keyAccounts}
          setIsOpen={setIsOpen}
          channels={channels}
          id={selectedId}
          onActionComplete={fetchData}
        ></SideBarRight>}
        <div>
          <Typography> My POS Universe</Typography>
          <Typography variant="h2"> Manage your stores</Typography>
        </div>
        {loading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={"26px"}
          >
            <CircularProgress size={"30px"} />
          </Box>
        )}
        <MeasurementInput
          width={280}
          unit=""
          value={searchTerm}
          onValueChange={handleSearchChange}
          onUnitChange={() => {}}
          label=""
          type="text"
        />
      </div>
      <div style={{ width: "100%" }}>
        <TabsComponentV2
          onChange={(index: number) => setActiveChannelId(index)}
          tabs={channels.map(
            (x: { id: number; name: string }, index: number) => ({
              title: x.name,
              id: x.id,
              content: <TabsComponent tabs={tabs}></TabsComponent>,
            })
          )}
        ></TabsComponentV2>

        {/* <DndProvider backend={HTML5Backend}> */}

        {/* <Shelf></Shelf> */}
        {/* </DndProvider> */}
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenSidebar(null)}
      >
        Add New POS
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          variant="filled"
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarContent}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContentComponent;
