import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { ReactComponent as Search } from "../../../assets/icons/Search.svg";

type SearchInputProps = {
  value: string;
  onValueChange: (value: string) => void;
  width?: number;
  label: string;
  placeholder: string;
};

const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onValueChange,
  width = 138,
  label,
  placeholder
}) => {
  return (
    <div style={{ alignSelf: "center", marginBottom: "12px", marginTop: "32px" }}>
      <Typography style={{ fontSize: "14px", fontWeight: "400", color: "#9CA0AB", marginLeft: "2px" }}>
        {label}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0, width, height: "36px", justifySelf: "flex-start" }}>
        <TextField
          value={value}
          placeholder={placeholder}
          onChange={(e) => onValueChange(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: "0px solid transparent !important",
              },
              '&:hover fieldset': {
                borderColor: 'transparent',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'transparent',
              },
              '& .MuiInputBase-input': {
                '&::placeholder': {
                  color: '#9CA0AB',
                  fontSize: "14px",
                  fontWeight: 300,
                  fontStyle: "italic"
                },
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            style: {
              height: '47px',
              borderRadius: "83px",
              fontSize: "16px",
              fontWeight: 600,
              color: "#383A49",
              backgroundColor: "white",
              width: width,
            },
          }}
        />
      </Box>
    </div>
  );
};

export default SearchInput;
