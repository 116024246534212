import React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import { useStyles } from './NoShelfCardStyles';
import { ReactComponent as AddIcon } from '../../../assets/icons/AddIcon.svg';

interface NoShelfCardProps {
  title: string;
  buttonText: string;
  onClick: () => void;
}

const NoShelfCard: React.FC<NoShelfCardProps> = ({ title, buttonText, onClick }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box className={classes.contentContainer}>
          <AddIcon className={classes.icon} />
          <Typography variant="h6" color="text.primary" className={classes.title}>
            {title}
          </Typography>
          <Button variant="contained" color="info"   className={classes.button} onClick={onClick}>
            {buttonText}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default NoShelfCard;
