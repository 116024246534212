import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    card: {
      background: "#F5F9FF",
      maxWidth: "305px",
      height:"100%",
      minHeight: "200px",
      border: "1px dashed #0179FF",
      borderRadius: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    icon: {
      fontSize: 48,
      marginBottom: theme.spacing(1),
      backgroundColor: "white",
      color: "#0179FF",
    },
    carContent:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    textStyle: {
        maxWidth: "150px", 
        textAlign: "center" 
    }
  }));
  