import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add'; // Importing the plus icon
import SearchInput from '../SearchInput/SearchInput';
import { ReactComponent as Search } from "../../../assets/icons/Search.svg";

interface MarketingMaterial {
  title?: string;
  width: number;
  height: number;
  x: number;
  y: number;
}

interface MarketingMaterialWithCount extends MarketingMaterial {
  addedCount: number;
}

interface MarketingMaterialCardProps {
  material: MarketingMaterialWithCount;
  onAddToShelf: (material: MarketingMaterial) => void;
  color: string;
}

interface MarketingMaterialListProps {
  materials: MarketingMaterial[];
  handleAddToShelf: (material: MarketingMaterial) => void;
  color: string;
}

const MarketingMaterialCard: React.FC<MarketingMaterialCardProps> = ({
  material,
  onAddToShelf,
  color,
}) => {
  const handleAddClick = () => {
    onAddToShelf(material);
  };

  return (
    <div
      style={{
        display: 'flex',
        marginBottom: 2,
        background: 'white',
        borderRadius: '8.5px',
        boxShadow: 'none',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '12px',
            gap: '8px',
            borderRadius: '8.5px',
          }}
        >
          <div
            style={{
              width: '64px',
              height: '64px',
              background: color,
              alignSelf: 'flex-start',
              borderRadius: '5px',
            }}
          ></div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontSize: '12px',
                fontWeight: '600',
                maxWidth: '120px',
                lineHeight: 'normal',
              }}
            >
              {material.title || 'No Title'}
            </Typography>

            <div style={{ display: 'flex', alignItems: 'center' }}>
             
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddClick}
                disabled={material.addedCount > 0}
                sx={{
                  maxHeight: '23px',
                  fontSize: '12px',
                  fontWeight: '700',
                  padding: '2px',
                  width: material.addedCount > 0 ? '150px' : '170px',
                  marginTop: '0',
                  marginRight:"8px",
                  background: '#0179FF',
                  color: 'white',
                  opacity: material.addedCount > 0 ? 0.5 : 1,
                  '&:hover': {
                    color: 'black',
                  },
                  '&:disabled': {
                    background: '#0179FF',
                    color: 'white',
                  },
                }}
              >
                {material.addedCount > 0 ?  `Added: ${material.addedCount}` : 'Add to shelf'}
              </Button>
            {  material.addedCount > 0 &&   <IconButton
                size="small"
                aria-label="add more"
                onClick={handleAddClick} // Use the same handler to add more
                sx={{
                  maxHeight: '23px',
                  fontSize: '12px',
                  fontWeight: '700',
                  padding: '2px',
                  marginTop: '0',
                  width: "35px",
                  borderRadius:"56px",
                  background: '#0179FF',
                  color: 'white',
                  '&:hover': {
                    color: 'black',
                  },
                }}
              >
                <AddIcon />
              </IconButton>}
            </div>
          </div>

          <IconButton
            aria-label="settings"
            sx={{
              alignSelf: 'flex-start',
              padding: 0,
              margin:'-4px -16px',
              background: 'transparent',
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

const MarketingMaterialList: React.FC<MarketingMaterialListProps> = ({
  materials,
  handleAddToShelf,
  color,
}) => {
  console.log("materials",materials)

  useEffect(()=>{
    setMaterialState(mergeMaterials())
  },[materials])
  const preprocessedMaterials = preprocessMaterials(materials);
  
  // Step 2: Merge mockMaterials with preprocessedMaterials
  const mergeMaterials = () => {
    const mergedMaterialsMap = new Map();

    // Add all preprocessed materials to the map
    preprocessedMaterials.forEach(material => {
      mergedMaterialsMap.set(material.title || 'No Title', material);
    });

    // Add materials from mockMaterials to the map if they are not already present
    mockMaterials.forEach(material => {
      const title = material.title || 'No Title';
      if (!mergedMaterialsMap.has(title)) {
        mergedMaterialsMap.set(title, { ...material, addedCount: 0 });
      }
    });

    return Array.from(mergedMaterialsMap.values());
  };

  const [materialState, setMaterialState] = useState<MarketingMaterialWithCount[]>(mergeMaterials());

  const onAddToShelfInternal = (material: MarketingMaterial) => {
    const updatedMaterials = materialState.map(m => {
      if (m.title === material.title) {
        return { ...m, addedCount: m.addedCount + 1 };
      }
      return m;
    });

    setMaterialState(updatedMaterials);
    handleAddToShelf(material);
  };

  return (
    <Box
      sx={{
        width: 300,
        margin: 'auto',
        height: '100%',
        background: '#F2F8FF',
        padding: '16px',
        borderRadius: '10px',
        gap: '8px',
        overflow: 'auto',
      }}
    >
     
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {materialState.map((material, index) => (
          <MarketingMaterialCard
            key={index}
            material={material}
            onAddToShelf={onAddToShelfInternal}
            color={color}
          />
        ))}
      </div>
    </Box>
  );
};

export default MarketingMaterialList;



const preprocessMaterials = (materials:any) => {
  const countMap = new Map();

  materials.forEach((material:any) => {
    const title = material.title || 'No Title'; // Handle materials without a title
    if (!countMap.has(title)) {
      countMap.set(title, { ...material, addedCount: 1 }); // Initialize with addedCount 1
    } else {
      const existingMaterial = countMap.get(title);
      countMap.set(title, { ...existingMaterial, addedCount: existingMaterial.addedCount + 1 });
    }
  });

  return Array.from(countMap.values()); // Convert map values back to array
};

const mockMaterials: MarketingMaterial[] = [
  {
    title: "Marketing Material Example #3",
    width: 50,
    height: 10,
    x: 50,
    y: 60
  },
  {
    title: "Marketing Material Example #5", // Duplicate title for testing
    width: 50,
    height: 20,
    x: 50,
    y: 60
  },
  {
    title: "Unique Marketing Material",
    width: 30,
    height: 10,
    x: 70,
    y: 80
  },
 
];