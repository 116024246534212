import React, { useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Logo from "../../../assets/images/Logo1.png";
import { ReactComponent as SKUS } from "../../../assets/icons/SKUS.svg";
import { ReactComponent as Stores } from "../../../assets/icons/Stores.svg";
import { ReactComponent as Account } from "../../../assets/icons/Account.svg";
import { ReactComponent as Back } from "../../../assets/icons/Back.svg";
import { ReactComponent as Open } from "../../../assets/icons/Open.svg";
import { ReactComponent as Close } from "../../../assets/icons/Close.svg";

import { useStyles } from "./SideBarStyles";
import Input from "../Input/Input";
import { ReactComponent as Col } from "../../../assets/icons/Col.svg";
import { ReactComponent as Row } from "../../../assets/icons/Row.svg";
import { ReactComponent as Width } from "../../../assets/icons/Width.svg";
import { ReactComponent as Height } from "../../../assets/icons/Height.svg";
import { ReactComponent as BlueIcon } from "../../../assets/icons/Blue.svg";

import Typography from "@mui/material/Typography";
import Dropdown from "../Dropdown/Dropdown";
import ColoredMenuItem from "../ColorMenuItem/ColoredMenuItem ";
import MarketingMaterialList from "../MarketingList/MarketingMaterialList";
import AddMaterialMarketing from "../AddMaterialMarketing/AddMaterialMarketing";
import SearchInput from "../SearchInput/SearchInput";
interface SidebarProps {
  onChange: (value: number) => void;
  onAddShelf: (width: string,height:string,title:string) => void;
  onAddMaterial: (material: MarketingMaterial) => void;
  squaresYellow:any;

}
interface MarketingMaterial {
  title?: string;
  width: number;
  height: number;
  x: number;
  y: number;
}

const SideBarRight: React.FC<SidebarProps> = ({ onChange ,onAddShelf,onAddMaterial,squaresYellow }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const materials: MarketingMaterial[] = [
    ...squaresYellow,
  
   
   
  ];
  // Function to toggle the sidebar's state
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Box
      className={`${classes.container} ${
        isOpen ? classes.sidebarOpen : classes.sidebarClosed
      }`}
    >
      <IconButton className={classes.toggleButton} onClick={toggleSidebar}>
        <Box component="span" className={classes.iconButton}>
          {isOpen ? <Close></Close> : <Open></Open>}
        </Box>
      </IconButton>
      <div
        className={classes.buttonContainer}
        style={{ justifyContent: isAdding ? "flex-start" : "space-between" }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="h4"
            sx={{ marginBottom: "12px", fontSize: "20px !important" }}
          >
            Marketing Materials
          </Typography>
          <Typography
            variant="h6"
            sx={{ marginBottom: "50px", maxWidth: "244px" }}
          >
            Lorem ipsum dolor sit amet consectetur. In arcu tellus.
          </Typography>
          {!isAdding && (
            <>
            <Button
              color="primary"
              variant="contained"
              sx={{
                marginBottom: "10px !important",
                minWidth: "301px",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                setIsAdding(true);
              }}
              startIcon={
                <Box component="span" className={classes.iconButton}>
                  <AddIcon />
                </Box>
              }
            >
              Add a material
            </Button>
             <SearchInput
             label={"TYPE OF LED SCREEN"}
             type={"text"}
             value={""}
             unit={""}
             onValueChange={(value) => {}}
             onUnitChange={() => {}}
             width={286}
           />
           </>
          )}
        </div>
        {isAdding ? (
          <AddMaterialMarketing
            onCancel={() => {
              setIsAdding(!isAdding);
            }}
            addToShelf={(width, height,title) => {
              onAddShelf(width, height,title);
             
            }}
            type={2}
            color={"#FFE455"}
          />
        ) : (
          <MarketingMaterialList materials={materials} handleAddToShelf={(material)=>{onAddMaterial(material)}} color={"#FFE455"} />
        )}

        <div></div>
      </div>
    </Box>
  );
};

export default SideBarRight;
