import React, { useEffect, useState } from "react";
import { Paper, Button, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Tooltip from "@mui/material/Tooltip";
import { getRRPNewConfigById } from '../../../../services/rrpNewConfigurationService';
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexContainer: {
      display: "flex",
      margin: `5px`,
    },
    square: {
      width: 400,
      height: 60,
      backgroundColor: "#B5D8FF",
      boxShadow: "none",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "end",
      backgroundSize: "cover",
      backgroundPosition: "center",
      boxSizing: "border-box",
      border: "0.5px solid transparent",
      position: "relative",
      zindex: -1,
      "&:hover": {
        cursor: "pointer",
      },
    },
    selectedSquare: {
      border: "0.5px solid #0179FF",
      boxSizing: "border-box",
    },
    smallDiv: {
      width: 28.5,
      height: 55,
      backgroundColor: "#ffffff",
      margin: "1.25px",
      borderRadius: "4px",
    },
    smallDivContainer: {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      zIndex: 0,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      justifyContent: "flex-start",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "20px",
      paddingTop: "20px",
    },
    button: {
      display: "flex",
      width: 140,
      height: 56,
      fontFamily: "Mulish",
      flexDirection: "column",
      alignItems: "center",
      margin: "0 2px",
      padding: "10px 20px",
      borderRadius: "0",
      border: "1px solid transparent",
      backgroundColor: "#fff",
      fontSize: "16px",
      fontWeight: 400,
      color: "#383A49",
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
      "&.selected": {
        color: "#0179FF  !important ",
        fontSize: "16px !important",
        fontWeight: 700,
      },
      "&.disabled": {
        opacity: 0.95,
        cursor: "not-allowed",
      },
    },
    pinkSquare: {
      position: "absolute",
      backgroundColor: "#F95252",
      cursor: "grab",
      zIndex: 500,
      "&:active": {
        cursor: "grabbing",
      },
    },
    yellowSquare: {
      position: "absolute",
      backgroundColor: "#FFD700",
      cursor: "grab",
      zIndex: 400,
      "&:active": {
        cursor: "grabbing",
      },
    },
    buttonLeft: {
      borderTopLeftRadius: "30px",
      borderBottomLeftRadius: "30px",
    },
    buttonRight: {
      borderTopRightRadius: "30px",
      borderBottomRightRadius: "30px",
    },
    buttonText: {
      marginBottom: "5px",
    },
    buttonDot: {
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: "#0179FF",
      marginTop: "5px",
    },
    nonMatchingTooltip: {
      opacity: 0.3,
    },
  })
);
type Array3D = boolean[][][];

interface GridDemoProps {
  row: number;
  col: number;
  rowDistance: number;
  setRowDistance: React.Dispatch<React.SetStateAction<number>>;
  colDistance: number;
  setColDistance: React.Dispatch<React.SetStateAction<number>>;
  setRow: React.Dispatch<React.SetStateAction<number>>;
  setCol: React.Dispatch<React.SetStateAction<number>>;
  selectedSquare: { col: number; row: number } | null;
  squaresWithImages: Array<Array<string>>;
  setSelectedSquare: React.Dispatch<
    React.SetStateAction<{
      col: number;
      row: number;
    } | null>
  >;
  items: {
    text: string;
    id: number;
    color: string;
    value: number;
    width: number;
  }[];
  setItems: React.Dispatch<
    React.SetStateAction<
      {
        text: string;
        id: number;
        color: string;
        value: number;
        width: number;
      }[]
    >
  >;
  handleDuplexTypeChange: (typeIndex: number, value: number) => void;
  getDuplexTypeValue: (typeIndex: number) => number;
  selectedButton: string;
  setSelectedButton: React.Dispatch<React.SetStateAction<string>>;
  squaresData: SquareData[];
  setSquaresData: React.Dispatch<React.SetStateAction<SquareData[]>>;
  pinkSquares: {
    width: number;
    height: number;
    x: number;
    y: number;
    title?: string | undefined;
    positions?: any;
  }[];
  yellowSquares: {
    width: number;
    height: number;
    x: number;
    y: number;
    title?: string | undefined;
    positions?: any;
  }[];
}

interface SquareData {
  row: number;
  col: number;
  duplexTypes: {
    id: number;
    value: number;
    width: number;
    color: string;
    text: string;
  }[];
}

const NewDemoComponent: React.FC<GridDemoProps> = ({
  row,
  col,
  squaresWithImages,
  setSelectedSquare,
  selectedSquare,
  items,
  setItems,
  handleDuplexTypeChange,
  getDuplexTypeValue,
  selectedButton,
  setSelectedButton,
  squaresData,
  setSquaresData,
  yellowSquares,
  pinkSquares
}) => {
  const classes = useStyles();
  const [columnWidths, setColumnWidths] = useState<number[]>([]);
  const [ccSpace, setCcSpace] = useState<number>(8);
  const { id } = useParams<{ id: string }>();
  const [overLayResult, setOverLayResult] = useState<boolean[][][]>();
  const [squaresDataWithCC, setSquaresDataWithCC] = useState<[][]>([[]]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = id && await getRRPNewConfigById(id);
        setColumnWidths(config.widths);
        setCcSpace(config.ccSpace);
        setSquaresData(config.squaresData);
        setItems(config.items);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };

    fetchConfig();
  }, [setItems]);

  const handleSquareClick = (colIndex: number, rowIndex: number) => {
    setSelectedSquare({ col: colIndex, row: rowIndex });
    const squareExists = squaresData.find(
      (data) => data.row === rowIndex && data.col === colIndex
    );
    console.log('squareExists', squareExists);
    console.log('squaresData', squaresData);

    if (!squareExists) {
      setSquaresData((prev) => [
        ...prev,
        {
          row: rowIndex,
          col: colIndex,
          duplexTypes: items.map((item) => ({
            ...item,
            id: item.id,
            value: 0,
          })),
        },
      ]);
      setItems(
        items.map((item) => {
          return { ...item, id: item.id, value: 0 };
        })
      );
    } else {
      setItems(
        squareExists.duplexTypes.map((input) => {
          const squareData = squareExists.duplexTypes.find(
            (d) => d.id === input.id
          );
          return {
            ...input,
            value: squareData ? squareData.value : 0,
            color: items.find((item2) => { return input.id === item2.id })?.color || '',
            duplexTypes: [],
          };
        })
      );
    }
  };

  const updateSelectedSquareData = () => {
    if (selectedSquare) {
      setSquaresData((prev) =>
        prev.map((square) => {
          if (
            square.row === selectedSquare.row &&
            square.col === selectedSquare.col
          ) {
            return {
              ...square,
              duplexTypes: items.map((item) => ({
                ...item,
                id: item.id,
                value: item.value,
              })),
            };
          }
          return square;
        })
      );
    }
  };

  useEffect(() => {
    updateSelectedSquareData();
  }, [items]);

  const renderSmallDivs = (numDivs: number,rowIndex:number) => {
    const smallDivs = [];
    const currentRowOverLay = overLayResult ? overLayResult?.[0]?.[rowIndex] : null
    for (let i = 0; i < numDivs; i++) {
      smallDivs.push(<div key={i} className={classes.smallDiv} style={{ left: `${i * 32}px`, background: currentRowOverLay?.[i] ? 'red': 'white',opacity:currentRowOverLay?.[i] ?0.4 :1   }} />); // Position each small div
    }
    return smallDivs;
  };
  const getTheOverlayedDuplex = (boolArray:[],currentItems:{
    text: string;
    id: number;
    color: string;
    value: number;
    width: number;
  }[]) => {
    const displayItemsList = [];
      const activeItems = currentItems
      .filter(item => item.value > 0)
      .map(item => ({ ...item }));
      let activeItemIndex = 0; // Index in activeItems
      let boolIndex = 0; // Index in boolArray
      let uniqueId = Date.now(); // Unique ID generator
    
      while (boolIndex < boolArray?.length) {
        if (boolArray[boolIndex]) {
          // Fixed space
          displayItemsList.push({
            id: `fixed-${boolIndex}`,
            text: "Fixed Space",
            color: "gray",
            value: 1,
            width: 28.5,
            duplexTypes: []
          });
          boolIndex++;
        } else {
          // Available space
          // Find consecutive false positions
          let start = boolIndex;
          let widthAvailable = 0;
          while (boolIndex < boolArray.length && !boolArray[boolIndex]) {
            widthAvailable += 30.5;
            boolIndex++;
          }
    
          // Try to fit the active item(s) into the available space
          while (activeItemIndex < activeItems.length) {
            const item = activeItems[activeItemIndex];
            const itemWidth = item.width;
            const itemValue = item.value;
    
            // Total width needed for the item considering its value
            const totalItemWidth = itemWidth * itemValue;
            
  
  
            if (totalItemWidth <= widthAvailable ) {
              
              console.log('enterone',activeItemIndex)
             
              displayItemsList.push({
                  ...item,
                  id: item.id // Ensure unique ID
                });
             
              widthAvailable -= totalItemWidth;
              activeItemIndex++;
            } else if (itemWidth <= widthAvailable) {
              console.log('enter2',activeItemIndex)
  
              
              const maxUnits = Math.floor(widthAvailable / itemWidth);
                displayItemsList.push({
                  ...item,
                  value:item.value-1,
                  id: item.id  // Ensure unique ID
                });
              
              // Reduce the item's value by the number of units placed
              activeItems[activeItemIndex].value -= maxUnits;
              widthAvailable -= itemWidth * maxUnits;
             
              break; // Move to next available space
              
            } else {
              // Item cannot fit in this space
              break; // Move to next available space
            }
          }
    
          // Fill any remaining space with empty placeholders
          const emptySlots = Math.floor(widthAvailable / 30.5);
          for (let i = 0; i < emptySlots; i++) {
            displayItemsList.push({
              id: `empty-${start + i}`,
              text: "Empty Space",
              color: "white",
              value: 1,
              width: 30.5,
              duplexTypes: []
            });
          }
        }
      }
      return displayItemsList
  }
  const handleMouseUp = (width: number,height: number,x:number,y:number) => {
    // Constants
    const boxWidth = 30.5; // Fixed width of each box
    const boxHeight = 51.5;
  
  
  
    const selectionStartX = x;
    const selectionEndX = selectionStartX + (width-6.5)  ;
    console.log('selectionStartX',selectionStartX)
  
    const selectionStartY = y;
    console.log(selectionEndX)
    const selectionEndY = selectionStartY + height;
    const spacing = parseInt(ccSpace.toString())
    // Calculate column offsets dynamically based on actual column widths
    let columnOffsets = columnWidths.reduce((acc:any, curr, idx) => {
      if (idx === 0) {
        acc.push(0);
      } else {
        acc.push(acc[idx - 1] + columnWidths[idx - 1]);
      }
      return acc;
    }, []);
    let intersectingBoxCount = 0;
  let wasAlreadySelected = false;
  let indexRow:number = -1 
  let indexCol: number= -1
  let IndexBox :number= -1
  
    let updatedSelectedBoxes =   squaresDataWithCC.map((set, setIndex) => 
        set.map((isSelected:any, boxIndex:any) => {
          const columnOffset =  columnOffsets[0];
          const dynamicBoxCount =spacing; 
          
          // Calculate boxes per column dynamically
          //if (boxIndex >= dynamicBoxCount) return false;
           // Ignore boxes that exceed the dynamic count
  
          const boxStartX = columnOffset + (boxIndex * (columnWidths[0]*5/dynamicBoxCount  )) +(((1.25 as number) * 5 || 2)* 0) ;
          const boxEndX = boxStartX + (columnWidths[0]*5/dynamicBoxCount  );
          const boxTopY = (setIndex) * (boxHeight +((2 as number) * 5 )) ; // Adjust if your layout differs
          const boxBottomY = boxTopY + (boxHeight  );
  
          // Check if the box intersects with the selection area
          const intersectsSelection = !(
            boxEndX <= selectionStartX ||
            boxStartX >= selectionEndX ||
            boxBottomY <= selectionStartY ||
            boxTopY >= selectionEndY
          );
          if (intersectsSelection ) {
            if(indexRow!=0 && indexCol!=setIndex && IndexBox!=boxIndex)
            {
              intersectingBoxCount++;
  
            }
            if (isSelected) wasAlreadySelected = true;
            indexRow=0
            indexCol=setIndex
            IndexBox=boxIndex
         
  
          }
  
  
          return  intersectsSelection;
        })
      )
    
   
    return updatedSelectedBoxes
  
  
  };
 
  const combineArrays = (arrays: Array3D[]): Array3D => {
    if (arrays.length === 0) {
        // Handle the case where no arrays are provided
        return [];
    }
  
    // Find the maximum depth and rows across all arrays
    const maxDepth = arrays.reduce((acc, curr) => Math.max(acc, curr.length), 0);
    const maxRows = arrays.reduce((acc, curr) => Math.max(acc, curr.reduce((accRow, currRow) => Math.max(accRow, currRow.length), 0)), 0);
  
    // Initialize the result array with appropriate depth and rows but no columns yet
    const result: Array3D = Array.from({ length: maxDepth }, () =>
        Array.from({ length: maxRows }, () => [])
    );
  
    // Determine the maximum columns for each specific depth and row and fill with `false`
    for (let d = 0; d < maxDepth; d++) {
        for (let r = 0; r < maxRows; r++) {
            let maxCols = 0;
            // Find the maximum column size for the current depth and row across all arrays
            arrays.forEach(array => {
                if (array.length > d && array[d].length > r) {
                    maxCols = Math.max(maxCols, array[d][r].length);
                }
            });
            // Initialize columns for the current depth and row
            result[d][r] = Array.from({ length: maxCols }, () => false);
        }
    }
  
    // Iterate over every array and every element to apply the logical OR operation
    arrays.forEach(array => {
        for (let d = 0; d < array.length; d++) {
            for (let r = 0; r < array[d].length; r++) {
                for (let c = 0; c < array[d][r].length; c++) {
                    result[d][r][c] = result[d][r][c] || array[d][r][c];
                }
            }
        }
    });
  
    return result;
  };
  const createRowsWithSingleTrue = (rows: number, ccspace: any) => {
    const spacing = parseInt(ccspace)
  return Array.from({ length: rows }, (_, rowIndex) => {
    const row = Array(spacing).fill(true); // Start with all false
    return row;
  });
};
  useEffect(() => {
    console.log('roccSpacew',ccSpace)
    const clonesquaresData = createRowsWithSingleTrue(row, ccSpace);
    setSquaresDataWithCC(clonesquaresData as any);
  
  
  }, [row, ccSpace]);
  useEffect(()=>{
    let pink = pinkSquares.map(({width,height,x,y})=>{
      return [handleMouseUp((width*5),(height*5),x,y)]
   })
  
   let yellow =  yellowSquares.map(({width,height,x,y})=>{
    return [handleMouseUp((width*5),(height*5),x,y)]

   })

   console.log('pink',pink.length)
   if(yellowSquares.length > 0 ||pinkSquares.length > 0  ){
    setOverLayResult([...combineArrays([...pink,...yellow])])
  } else if (squaresDataWithCC.length > 0) {
    setOverLayResult([squaresDataWithCC.map(val=>val.map(val2=>false))])
  }
  },[squaresDataWithCC,columnWidths,squaresData,yellowSquares,pinkSquares])
  const renderSquares = () => {
    return Array.from({ length: row }, (_, rowIndex) => (
      <div key={rowIndex} className={classes.flexContainer}>
        {Array.from({ length: col }, (_, colIndex) => {
          const imageSrc = squaresWithImages[rowIndex]?.[colIndex];
          const squareData = squaresData.find(
            (data) => data.row === rowIndex && data.col === colIndex
          );
          const OverLayedresult = squareData &&(overLayResult && overLayResult?.length >0 ) && getTheOverlayedDuplex(overLayResult[0]?.[rowIndex] as any,squareData.duplexTypes)
          
          const isSelected =
            selectedSquare &&
            selectedSquare.col === colIndex &&
            selectedSquare.row === rowIndex;

          return (
            <div
              key={`${rowIndex}-${colIndex}`}
              onClick={() => handleSquareClick(colIndex, rowIndex)}
              style={{ padding: `4px 4px` }}
            >
              <Paper
                className={`${classes.square} ${
                  isSelected ? classes.selectedSquare : ""
                }`}
                style={{
                  backgroundImage: imageSrc ? `url(${imageSrc})` : "",
                  width:
                    (columnWidths[0] || 50) * 5,
                  padding: "2px",
                  justifyContent: "start",
                  gap: "2px",
                }}
              >
                <div className={classes.smallDivContainer}>
                {renderSmallDivs(ccSpace,rowIndex)}
                </div>
                {squareData && OverLayedresult &&
             
             OverLayedresult.map((item, index) => {
                    const duplex = OverLayedresult.find(
                      (d) => d.id === item.id
                    );
                    const isNonMatching =
                      duplex?.id !== 12 && duplex?.id !== 1 && duplex?.id !== 4 && duplex?.id.toString().split('-')[0] != ('fixed') && duplex?.id.toString().split('-')[0] != ('empty');
                    return duplex
                      ? [...Array(duplex.value)].map((_, boxIdx) => (
                          <Tooltip
                            key={`type-${index}-${boxIdx}`}
                            title={` ${item.text}`}
                            arrow
                            placement="top"
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, 10],
                                  },
                                },
                              ],
                            }}
                          >
                            <div
                              key={`type-${index}-${boxIdx}`}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "85%",
                                zIndex: 15,
                              }}
                            >
                              {item.id === 4 || item.id === 12 ? (
                                <div
                                  style={{
                                    width: `${item.width}px`,
                                    height: "100%",
                                    backgroundColor: isNonMatching
                                      ? hexToHexWithOpacity(items.find((item2) => { return item.id === item2.id })?.color || '', 0.7)
                                      : items.find((item2) => { return item.id === item2.id })?.color,
                                    borderRadius: "3px",
                                  }}
                                />
                              ) : item.id === 1 ? (
                                <div
                                  style={{
                                    width: `${item.width}px`,
                                    height: "100%",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    boxSizing: 'border-box',
                                    padding: '2px',
                                    gap: '1px',
                                    borderRadius: "3px",
                                    border: "1px solid",
                                    borderColor: isNonMatching
                                      ? hexToHexWithOpacity(items.find((item2) => { return item.id === item2.id })?.color || '', 0.7)
                                      : items.find((item2) => { return item.id === item2.id })?.color,
                                  }}
                                >
                                  <div style={{
                                    width: `${item.width - 4}px `,
                                    height: "24%",
                                    backgroundColor: isNonMatching
                                      ? hexToHexWithOpacity(items.find((item2) => { return item.id === item2.id })?.color|| '', 0.7)
                                      : items.find((item2) => { return item.id === item2.id })?.color,
                                    borderRadius: "2px",
                                  }}>
                                  </div>
                                  <div style={{
                                    width: `${item.width - 4}px `,
                                    height: "24%",
                                    backgroundColor: isNonMatching
                                      ? hexToHexWithOpacity(items.find((item2) => { return item.id === item2.id })?.color|| '', 0.7)
                                      : items.find((item2) => { return item.id === item2.id })?.color,
                                    borderRadius: "2px",
                                  }}>
                                  </div>
                                  <div style={{
                                    width: `${item.width - 4}px `,
                                    height: "24%",
                                    backgroundColor: isNonMatching
                                      ? hexToHexWithOpacity(items.find((item2) => { return item.id === item2.id })?.color|| '', 0.7)
                                      : items.find((item2) => { return item.id === item2.id })?.color,
                                    borderRadius: "2px",
                                  }}>
                                  </div>
                                  <div style={{
                                    width: `${item.width - 4}px `,
                                    height: "24%",
                                    backgroundColor: isNonMatching
                                      ? hexToHexWithOpacity(items.find((item2) => { return item.id === item2.id })?.color|| '', 0.7)
                                      : items.find((item2) => { return item.id === item2.id })?.color,
                                    borderRadius: "2px",
                                  }}>
                                  </div>


                                </div>
                              ) :   (
                                <>
                                  <div
                                    style={{
                                      width: `${item.width}px`,
                                      height: "50%",
                                      backgroundColor: isNonMatching
                                        ? hexToHexWithOpacity(items.find((item2) => { return item.id === item2.id })?.color|| '', 0.7)
                                        : items.find((item2) => { return item.id === item2.id })?.color,
                                      borderRadius: "3px",
                                      marginBottom: "2px",
                                    }}
                                  />
                                  <div
                                    style={{
                                      width: `${item.width}px`,
                                      height: "50%",
                                      backgroundColor: isNonMatching
                                        ? hexToHexWithOpacity(items.find((item2) => { return item.id === item2.id })?.color|| '', 0.7)
                                        : items.find((item2) => { return item.id === item2.id })?.color,
                                      borderRadius: "3px",
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </Tooltip>
                        ))
                      : null;
                  })}
              </Paper>
            </div>
          );
        })}
      </div>
    ));
  };

  const buttons = [
    {
      text: "HNB",
      className: `${classes.buttonLeft} disabled`,
      color: "",
      disabled: true,
    },
    {
      text: "Veev One",
      className: "disabled",
      color: "#9747FF",
      disabled: true,
    },
    { text: "Veev Now", className: "", color: "#FF9F47", disabled: false },
    {
      text: "Levia",
      className: `${classes.buttonRight} disabled`,
      color: "#5DD671",
      disabled: true,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#F5F9FF",
        height: "100vh",
      }}
    >
      <div className={classes.buttonContainer}>
        {buttons.map((button) => (
          <Button
            key={button.text}
            className={`${classes.button} ${button.className} ${
              selectedButton === button.text ? "selected" : ""
            }`}
            onClick={() => !button.disabled && setSelectedButton(button.text)}
            disabled={button.disabled}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className={classes.buttonText}>{button.text}</div>
              {button.text == "HNB" ? (
                <div style={{ display: "flex", gap: "2px" }}>
                  <div
                    className={classes.buttonDot}
                    style={{ backgroundColor: "#0179FF" }}
                  ></div>
                  <div
                    className={classes.buttonDot}
                    style={{ backgroundColor: "#F95252" }}
                  ></div>
                  <div
                    className={classes.buttonDot}
                    style={{ backgroundColor: "#FFE455" }}
                  ></div>
                  <div
                    className={classes.buttonDot}
                    style={{ backgroundColor: "#A5ABAA" }}
                  ></div>
                </div>
              ) : (
                <div
                  className={classes.buttonDot}
                  style={{ backgroundColor: button.color }}
                ></div>
              )}
            </div>
          </Button>
        ))}
      </div>
      <div
        style={{
          backgroundColor: "#F5F9FF",
          height: "100vh",
          display: "flex",
          width: (columnWidths[0] || 50) * 5,
          padding: "0 10px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        <div
          style={{
            alignSelf: "center",
            fontSize: "32px",
            color: "#9CA0AB",
            fontWeight: 800,
            marginBottom: "20px",
          }}
        ></div>
        <div style={{ display: "flex" }}>
          <div style={{position:'relative'}} >
            {renderSquares()}
              {/* {pinkSquares.map((square, index) => (
              <div
                key={index}
                className={classes.pinkSquare}
                style={{
                  width: square.width * 5,
                  height: square.height * 5,
                  left: square.x,
                  top: square.y,
                  position: "absolute",
                }}
              />
            ))} */}
            {/* {yellowSquares.map((square, index) => (
              <div
                key={index}
                className={classes.yellowSquare}
                style={{
                  width: square.width * 5,
                  height: square.height * 5,
                  left: square.x,
                  top: square.y,
                  position: "absolute",
                }}
              />
            ))} */}
            
            
            </div>
        </div>
      </div>
    </div>
  );
};

export default NewDemoComponent;

function hexToHexWithOpacity(hex: string, opacity: number) {
  hex = hex.replace(/^#/, "");

  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  r = Math.round(r + (255 - r) * opacity);
  g = Math.round(g + (255 - g) * opacity);
  b = Math.round(b + (255 - b) * opacity);

  const blendedHex = `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;

  return blendedHex;
}
