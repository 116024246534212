import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Sidebar from '../../components/RRP/Step2/sideBar/SideBar';
import DemoComponent from '../../components/RRP/Step2/Content/Content';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from 'react-router-dom';
import { getRRPNewConfigById, updateRRPNewConfig } from '../../services/rrpNewConfigurationService';

const AboutPage: React.FC = () => {
  const { id, index } = useParams<{ id: string,index?:string }>();
  const navigate = useNavigate();

  const [col, setCol] = useState<number>(2);
  const [row, setRow] = useState<number>(2);
  const [rowDistance, setRowDistance] = useState<number>(2);
  const [colDistance, setColDistance] = useState<number>(2);
  const [columnWidths, setColumnWidths] = useState<number[]>([]);
  const [ccSpace, setCcSpace] = useState<number>(8);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        const config = await getRRPNewConfigById(id);
        setCol(config.col_position);
        setRow(config.row_position);
        setColumnWidths(config.widths || []);
        setCcSpace(config.ccSpace);
      };
      fetchData();
    }
  }, [id]);

  const handleNext = async () => {
    setLoading(true);
    try {
      const data = {
        col_position: col,
        row_position: row,
        widths: columnWidths,
        ccSpace: ccSpace
      };
      await updateRRPNewConfig(id!, data);
      navigate("/rrp/step4/" + id + '/'+(index || ''));
    } catch (error) {
      console.error('Error updating RRP new configuration:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          <Sidebar
            row={row}
            col={col}
            rowDistance={rowDistance}
            colDistance={colDistance}
            onRowChange={(value) => {
              if (value > 12) {
                setRow(12);
              } else if (value < 2) {
                setRow(2);
              } else {
                setRow(value);
              }
            }}
            onColChange={setCol}
            onRowDistanceChange={setRowDistance}
            onColDistanceChange={setColDistance}
            onNext={handleNext}
            loading={loading}
          />
        </Grid>
        <Grid item xs={9}>
          <div style={{ width: "100%", backgroundColor: "#e0e0e0" }}>
            <DemoComponent
              col={col}
              row={row}
              setCol={setCol}
              setRow={setRow}
              rowDistance={rowDistance}
              setRowDistance={setRowDistance}
              colDistance={colDistance}
              setColDistance={setColDistance}
              columnWidths={columnWidths}
              setColumnWidths={setColumnWidths}
              ccSpace={ccSpace}
              setCcSpace={setCcSpace}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AboutPage;
