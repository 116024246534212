import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useStyles } from "./ContentStyles";
import SKUCardList from "../SKUCardList/SKUCardList";
import TabsComponent from "../TabComponent/Tab";
import SearchInput from "../InputV2/InputV2";
import SideBarRight from "../AddSkuModal/SideBarRight";
import { getSkusByCategory, searchSkusByBrandVariantAndCategory } from "../../../services/skuService";
import EditSideBarRight from "../EditSideBarRight/EditSideBarRight";
import CircularProgress from '@mui/material/CircularProgress'; // Add this import for the loader

interface ContentComponentProps {
  title: string;
  subtitle: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export interface Sku {
  id:number;
  name: string;
  description: string;
  category: string;
  subcategory: string;
  consumables_device: string;
  pack_brand_form: string;
  product_description: string;
  ean_code: string;
  width: number;
  height: number;
  depth: number;
  brand_id: number;
  variant_id: number;
  pack_type_id: number;
  size_id: number;
  pack_image: string;
  packImage?: string;
  brandName: string;
  variantName: string;
  packTypeName?: string;
  sizeName?: string;
}

const ContentComponent: React.FC<ContentComponentProps> = ({
  title,
  subtitle,
  isOpen,
  setIsOpen
}) => {
  const classes = useStyles();
  const [skusCC, setSkusCC] = useState<Sku[]>([]);
  const [skusHNB, setSkusHNB] = useState<Sku[]>([]);
  const [search, setSearch] = useState<string>("");
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [skuId, setSkuId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state

  const fetchSKUs = async () => {
    try {
      setLoading(true); // Set loading to true before fetching data
      const ccSkus = await getSkusByCategory('CC');
      setSkusCC(ccSkus);
      const hnbSkus = await getSkusByCategory('RRP');
      setSkusHNB(hnbSkus);
    } catch (error) {
      console.error('Error fetching SKUs by category:', error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const fetchSearchResults = async (searchTerm: string) => {
    try {
      setLoading(true); // Set loading to true before fetching data
      const ccSkus = await searchSkusByBrandVariantAndCategory('CC', searchTerm);
      setSkusCC(ccSkus);
      const hnbSkus = await searchSkusByBrandVariantAndCategory('RRP', searchTerm);
      setSkusHNB(hnbSkus);
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    if (search) {
      fetchSearchResults(search);
    } else {
      fetchSKUs();
    }
  }, [search]);

  const handleEditClick = (skuId: number) => {
    setSkuId(skuId);
    setIsEditOpen(true);
  };

  const tabs = [
    { title: 'CC', content: <SKUCardList items={skusCC.map(sku => ({ title: sku.brandName + ' ' + sku.variantName, imageUrl: sku['pack_image'] ,id:sku.id}))} onEditClick={handleEditClick} loading={loading} /> },
    { title: 'HNB', content: <SKUCardList items={skusHNB.map(sku => ({ title: sku.brandName + ' ' + sku.variantName, imageUrl: sku['pack_image'] ,id:sku.id}))} onEditClick={handleEditClick} loading={loading} /> },
  ];

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: '40px' }}>
        {!isEditOpen && <SideBarRight isOpen={isOpen} setIsOpen={setIsOpen} refetchSKUs={fetchSKUs} />}
        <EditSideBarRight isOpen={isEditOpen} setIsOpen={setIsEditOpen} skuId={skuId} refetchSKUs={fetchSKUs} />
        <Typography> My SKUs</Typography>
        <SearchInput width={280} placeholder="Search for products" value={search} onValueChange={setSearch} label="" />
      </div>
      <div style={{ width: "100%", height: '100%' }}>
        <TabsComponent tabs={tabs}></TabsComponent>
      </div>
    </div>
  );
};

export default ContentComponent;
