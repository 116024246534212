import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import Input from "../InputV3/Input";

interface BrandModalProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (name: string) => void;
  label: string;
  usedName: string | null;

}

const BrandModal: React.FC<BrandModalProps> = ({ open, handleClose, handleSubmit, label,usedName }) => {
  const [configurationName, setConfigurationName] = useState(!usedName ? '':usedName);
  const handleAddConfiguration = () => {
    configurationName &&  handleSubmit(configurationName);
    handleClose();
  };
   useEffect(()=>{
    setConfigurationName(!usedName ?'':usedName)

   },[usedName])
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "15px !important",
          paddingBottom: "0",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4">{ usedName==='draft' ?'Add New':'Update' } {label}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography
            variant="h6"
            sx={{ marginBottom: "12px", color: "#9CA0AB" }}
          >
            { usedName==='draft' ?' To add a new':' To update a '  }  {label}, please enter the name of the Configuration you want to
            { usedName==='draft' ?' add.':' update. '  } {" "}
          </Typography>
        </DialogContentText>
        <Input
          label="Configuration Name"
          onChange={(e) => setConfigurationName(e)}
          placeholder="Enter Configuration Name"
          value={configurationName || ''}
          marginB={0}
        />
      </DialogContent>
      <DialogActions>
        <div style={{ display: "flex", width: "100%", gap: "8px" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClose}
            sx={{
              marginBottom: "10px !important",
              background: "rgba(217, 231, 248, 0.50)",
              justifyContent: "center",
              color: "#383A49",
              flexBasis: "33%",
              "&:hover": {
                color: "black",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleAddConfiguration}
            sx={{
              marginBottom: "10px !important",
              "background-color": "#0179FF",
              color: "white",
              flexGrow: 1,
              justifyContent: "center",
              "&:hover": {
                color: "white",
                backgroundColor: "#0179FF !important",
                opacity: 0.8,
              },
            }}
          >
              { usedName==='draft' ?' Add ':' Update '  } {label} 
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default BrandModal;
