import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  SwitchProps,
  Typography,
  styled
} from "@mui/material";
import Input from "../InputV3/Input";

interface BrandModalProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (name: string) => void;
  label: string;
  usedName: string | null;
  exportOptions: { compositionName: boolean; eanCode: boolean; validityDate: boolean };
  setExportOptions: React.Dispatch<React.SetStateAction<{ compositionName: boolean; eanCode: boolean; validityDate: boolean }>>;
}

const BrandModal: React.FC<BrandModalProps> = ({
  open,
  handleClose,
  handleSubmit,
  label,
  usedName,
  exportOptions,
  setExportOptions,
}) => {
  const [configurationName, setConfigurationName] = useState(usedName || "");

  const handleAddConfiguration = () => {
   // configurationName && handleSubmit(configurationName);
    handleClose();
    handleSubmit('')
  };

  useEffect(() => {
    setConfigurationName(usedName || "");
  }, [usedName]);

  const handleSwitchChange = (option: keyof typeof exportOptions) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setExportOptions((prev) => ({
      ...prev,
      [option]: event.target.checked,
    }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "15px !important",
          paddingBottom: "0",
          width:'600px'
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4" sx={{paddingBottom:'10px'}}>Export Planogram</Typography>
        <Typography
            variant="h6"
            sx={{ marginBottom: "12px", color: "#9CA0AB" }}
          >
            Choose how you would like to export your composition.
          </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
        
        </DialogContentText>
        <div style={{ display: "flex", flexDirection: "column", gap: "0px",background:'' }}>
          <div style={{display:'flex', justifyContent:'space-between',borderTopRightRadius:'3px',borderTopLeftRadius:'3px',border:'1px solid #D9E7F8',alignItems:'center',padding:'8px 12px'}}>
          <Typography sx={{ fontSize:'16px',fontWeight:'400',color:'#383A49'}}>SKU Name</Typography>

<IOSSwitch
  checked={exportOptions.compositionName}
  onChange={handleSwitchChange("compositionName")}
  sx={{ m: 1 }}
/>
          </div>
          <div style={{display:'flex', justifyContent:'space-between',border:'1px solid #D9E7F8', alignItems:'center',padding:'8px 12px'}}>
          <Typography sx={{ fontSize:'16px',fontWeight:'400',color:'#383A49'}}>EAN Code</Typography>
          <IOSSwitch
            checked={exportOptions.eanCode}
            onChange={handleSwitchChange("eanCode")}
            sx={{ m: 1 }}
          />
</div>
<div style={{display:'flex', justifyContent:'space-between',borderBottomRightRadius:'3px',borderBottomLeftRadius:'3px',border:'1px solid #D9E7F8',alignItems:'center',padding:'8px 12px'}}>
<Typography sx={{ fontSize:'16px',fontWeight:'400',color:'#383A49'}}>Validity Date</Typography>

<IOSSwitch
            checked={exportOptions.validityDate}
            onChange={handleSwitchChange("validityDate")}
            sx={{ m: 1 }}
          />
</div>
        
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ display: "flex", width: "100%", gap: "8px" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClose}
            sx={{
              marginBottom: "10px !important",
              background: "rgba(217, 231, 248, 0.50)",
              justifyContent: "center",
              color: "#383A49",
              flexBasis: "33%",
              "&:hover": {
                color: "black",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleAddConfiguration}
            sx={{
              marginBottom: "10px !important",
              "background-color": "#0179FF",
              color: "white",
              flexGrow: 1,
              justifyContent: "center",
              "&:hover": {
                color: "white",
                backgroundColor: "#0179FF !important",
                opacity: 0.8,
              },
            }}
          >
            save & export
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default BrandModal;


const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));