import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, CircularProgress } from "@mui/material";
import Logo from "../../../assets/images/Logo1.png";
import { ReactComponent as Back } from "../../../assets/icons/Back.svg";
import { useStyles } from "./SideBarStyles";
import Input from "../Input/Input";
import { ReactComponent as BlueIcon } from "../../../assets/icons/Blue.svg";
import { useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";

interface SidebarProps {
  id?: string;
  pmiPositions: any;
  clearClick: () => void;
  onSave: () => void;
  onBack: () => void;
  loadingNext: boolean;
  loadingBack: boolean;
  hiddencells: never[]
}

const Sidebar: React.FC<SidebarProps> = ({
  id, pmiPositions, clearClick, onSave, onBack, loadingNext, loadingBack,hiddencells
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [falsePercentage, setFalsePercentage] = useState(0);
  const [falseCount, setFalseCount] = useState(0);
 
  useEffect(() => {
    let hiddenNumber=0
    const flatArray = pmiPositions.map((value1:any,index1:number)=> {
      return value1.map((value2:any,index2:number)=>{
          return  value2.map((value3:any,index3:number)=>{
              if(hiddencells[index1] && !hiddencells[index2][index1]){
                hiddenNumber = hiddenNumber +1
                return value3
              }else {
                return value3
              }
            } )
          
        })
    }).flat(2)  || [];
    console.log('flatArray',hiddenNumber)
    const totalElements = flatArray.length -hiddenNumber ;

    const falseCountValue = flatArray.filter((value: boolean) => value === true).length;
    const percentage = (falseCountValue / totalElements) * 100;
    setFalsePercentage(percentage);
    setFalseCount(falseCountValue);
  }, [pmiPositions,hiddencells]);

  return (
    <Box className={classes.container}>
      <img src={Logo} alt="Logo" className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
            STEP 4/9
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "12px" }}>
            Set the position of PMI
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "50px" }}>
            Set the position of PMI
          </Typography>
          <Input
            onChange={() => {}}
            value={falsePercentage}
            icon={<BlueIcon />}
            text={"PMI percentage"}
          />
          <div style={{
            background: "#F5F9FF",
            borderRadius: "2px 5px 5px 2px",
            padding: "5px",
            display: "flex",
            justifyContent: "space-between"
          }}>
            <Typography variant="h1">
              Used: <strong>{falseCount}</strong>
            </Typography>
            <a href="#" onClick={clearClick}>Clear</a>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <IconButton onClick={onBack} disabled={loadingBack}>
              <Box component="span" className={classes.iconButton}>
                {loadingBack ? <CircularProgress size={24} /> : <Back />}
              </Box>
            </IconButton>
            <Button
              variant="contained"
              color="info"
              onClick={onSave}
              sx={{ marginTop: "0px", position: "relative" }}
              disabled={loadingNext}
            >
              {loadingNext ? <CircularProgress size={24} sx={{ position: "absolute" }} /> : "Next"}
            </Button>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Sidebar;
