import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Sidebar from '../components/Step111 copy/sideBar/SideBar';
import DemoComponent from '../components/Step111 copy/Content/Content';
import { useParams } from 'react-router-dom';
import { getShelfConfigById } from '../services/shelfConfigurationService';

const Step11: React.FC = () => {
  const { id } = useParams<{ id?: string }>(); // Read the ID from the URL, may be undefined

  const [shelfConfig, setShelfConfig] = useState({
    col_position: 2,
    row_position: 2,
    rowDistance: 2,
    colDistance: 2,
    widths: [] as number[],
    height: 10,

  });
 const [hiddencells,setHiddencells] = useState([])
  useEffect(() => {
    const fetchShelfConfig = async () => {
      if (id) {
        try {
          const response = await getShelfConfigById(id);
          if(response.widths) {
            setShelfConfig(response);

          }else {
            setShelfConfig({...response,widths:[]});

          }
          setHiddencells(response.hiddencells || [])
         
        } catch (error) {
          console.error('Error fetching shelf configuration', error);
          // Handle error (e.g., show an error message)
        }
      } else {
        console.error('No ID provided in URL');
        // Handle the case where ID is not provided (e.g., redirect or show an error)
      }
    };

    fetchShelfConfig();
  }, [id]);

  useEffect(() => {
    if (shelfConfig.widths.length === 0) {
      const initialWidths = Array(shelfConfig.col_position).fill(50);
      setShelfConfig(prevState => ({ ...prevState, widths: initialWidths }));
    }
  }, [shelfConfig.col_position, shelfConfig.widths.length]);

  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          <Sidebar
            id={id}
            shelfConfig={shelfConfig}
            setShelfConfig={setShelfConfig}
          />
        </Grid>
        <Grid item xs={9}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#e0e0e0",
            }}
          >
            <DemoComponent
              col={shelfConfig.col_position}
              row={shelfConfig.row_position}
              setCol={(value) => setShelfConfig((prevState:any) => ({ ...prevState, col_position: value }))}
              setRow={(value) => setShelfConfig((prevState:any) => ({ ...prevState, row_position: value }))}
              rowDistance={shelfConfig.rowDistance}
              setRowDistance={(value) => setShelfConfig((prevState:any) => ({ ...prevState, rowDistance: value }))}
              colDistance={shelfConfig.colDistance}
              setColDistance={(value) => setShelfConfig((prevState:any) => ({ ...prevState, colDistance: value }))}
              widths={shelfConfig.widths}
              setWidths={(widths) => setShelfConfig((prevState:any) => ({ ...prevState, widths }))}
              hiddencells={hiddencells}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Step11;
