import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useParams } from 'react-router-dom';
import Sidebar from '../components/Step11/sideBar/SideBar';
import DemoComponent from '../components/Step11/Content/Content';
import { getShelfConfigById } from '../services/shelfConfigurationService';

const Step1: React.FC = () => {
  const { id } = useParams<{ id?: string }>(); // Read the ID from the URL, may be undefined
  const [col, setCol] = useState(2);
  const [row, setRow] = useState(2);
  const [rowDistance, setRowDistance] = useState(2);
  const [colDistance, setColDistance] = useState(2);
  const [height, setHeight] = useState(10);
  const [hiddencells, setHiddencells] = useState<boolean[][]>([]);

  useEffect(() => {
    const fetchShelfConfig = async () => {
      if (id) {
        try {
          const response = await getShelfConfigById(id);
          setCol(response.col_position);
          setRow(response.row_position);
          setRowDistance(response.rowDistance);
          setColDistance(response.colDistance);
          response.height && setHeight(response.height);
          if (response.hiddencells) {
            setHiddencells(response.hiddencells);
          } else {
            // Initialize hiddencells if not present
            const initialHiddencells = Array(response.row_position)
              .fill(null)
              .map(() => Array(response.col_position).fill(true));
            setHiddencells(initialHiddencells);
          }
        } catch (error) {
          console.error('Error fetching shelf configuration', error);
          // Handle error (e.g., show an error message)
        }
      } else {
        // Initialize hiddencells if no ID provided
        const initialHiddencells = Array(row)
          .fill(null)
          .map(() => Array(col).fill(true));
        setHiddencells(initialHiddencells);
      }
    };

    fetchShelfConfig();
  }, [id]);
  useEffect(() => {
    // Adjust hiddencells when row or col change
    setHiddencells((prevGrid) => {
      const newGrid = [];
      for (let i = 0; i < row; i++) {
        const newRow = [];
        for (let j = 0; j < col; j++) {
          if (prevGrid && prevGrid[i] && prevGrid[i][j] !== undefined) {
            newRow.push(prevGrid[i][j]);
          } else {
            newRow.push(true); // Initial value is true
          }
        }
        newGrid.push(newRow);
      }
      return newGrid;
    });
  }, [row, col]);

  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          <Sidebar 
            id={id}
            col={col}
            row={row}
            colDistance={colDistance}
            hiddencells={hiddencells}
            onColDistanceChange={(val)=>{
              console.log(val)
              setColDistance(val)} }
            rowDistance={rowDistance}
            onRowDistanceChange={setRowDistance}
            height={height}
            onColChange={(value) => {
              if (value > 4) setCol(4);
              else if (value < 1) setCol(1);
              else setCol(value);
            }}
            onRowChange={(value) => {
              if (value > 12) setRow(12);
              else if (value < 2) setRow(2);
              else setRow(value);
            }}
          />
        </Grid>
        <Grid item xs={9}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#e0e0e0",
            }}
          >
            <DemoComponent
              col={col}
              row={row}
              setCol={setCol}
              setRow={setRow}
              rowDistance={rowDistance}
              setRowDistance={setRowDistance}
              colDistance={colDistance}
              setColDistance={setColDistance}
              hiddencells={hiddencells}
              setHiddencells={setHiddencells}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Step1;
