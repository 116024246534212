import React from "react";
import Logo from "../../../assets/icons/KeyAccount.svg";
import Typography from "@mui/material/Typography";

// Define the type for our list item
interface ListItem {
  title: string;
  description: string;
  id: number;
}

// Props for the ListDisplay component
interface ListDisplayProps {
  items: ListItem[];
}

// The ListDisplay component
const ListDisplay: React.FC<ListDisplayProps> = ({ items }) => {
  return (
    <div style={{ marginTop: "12px" }}>
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "66px",
            width: "100%",
            padding: " 14px 20px",
            boxSizing: "border-box",
            background: "white",
            borderTopRightRadius: index === 0 ? "20px" : "0px",
            borderTopLeftRadius: index === 0 ? "20px" : "0px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
            <img src={Logo} alt="" style={{ marginRight: "10px" }} />
            <div>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {" "}
                {item.title}
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontWeight: 400, color: "#9CA0AB", fontSize: "12px" }}
              >
                {" "}
                {item.description}
              </Typography>
            </div>
          </div>
          <div style={{ alignSelf: "flex-start" }}>
            <a
              href={"/chain/" + item.id}
              style={{
                fontSize: "14px",
                fontWeight: "400",
                fontStyle: "normal",
                color: "#0179FF",
              }}
            >
              {" "}
              View stores
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ListDisplay;
