import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Sidebar from "../../components/RRP/Step4/sideBar/SideBar";
import DemoComponent from "../../components/RRP/Step4/Content/Content";
import { getFromJson, saveToJson } from "../../components/helpers/localData";
import SideBarRight from "../../components/RRP/Step4/sideBarRight/SideBarRight";
import SideBarRightYellow from "../../components/RRP/Step4/sideBarRightYellow/SideBarRight";
import { getRRPNewConfigById, updateLedScreensRRP, updateMarketingMaterialsRRP } from "../../services/rrpNewConfigurationService";
import { getMarketingMaterialById } from "../../services/marketingMaterialsService";
import { getLedScreenById } from "../../services/ledScreensService";
import { useParams } from "react-router-dom";

type Square = {
  width: number;
  height: number;
  x: number;
  y: number;
  title?: string;
  positions?: any;
  id?: number;
  isAdded?: number;
};

type MarketingMaterial = {
  id?: number;
  title?: string;
  width: number;
  height: number;
  x: number;
  y: number;
  positions?: any;
};

interface SquareData {
  row: number;
  col: number;
  duplexTypes: { id: number; value: number }[];
}
const Step4: React.FC = () => {
  const initialCol =  1;
  const initialRow = 2;
  
  const initialRowDistance =  2;
  const initialColDistance =1;
  const [selectedPanel, setSelectedPanel] = useState(4);
  const [squares, setSquares] = useState<Square[]>(
     []
  );

  const [squaresYellow, setSquaresYellow] = useState<Square[]>(
   []
  );

  const [pinkSquares, setPinkSquares] = useState(
  squares
  );
  const [yellowSquares, setYellowSquares] = useState(
    squaresYellow
  );
  const [col, setCol] = useState(initialCol);
  const [row, setRow] = useState(initialRow);
  const [rowDistance, setRowDistance] = useState(initialRowDistance);
  const [colDistance, setColDistance] = useState(initialColDistance);
const [widths,setWidths]=useState([])


 


  const [squaresWithImages, setSquaresWithImages] = useState<
    Array<Array<string>>
  >([]);

  const [selectedProductCardImage, setSelectedProductCardImage] =
    useState<string>("");
  useEffect(() => {
    console.log(selectedProductCardImage);
  }, [selectedProductCardImage]);

  useEffect(() => {
    setSquaresWithImages(new Array(row).fill(new Array(col).fill("")));
  }, [col, row]);

  const duplexInputs = [
    { text: "VEEV ONE Standing DP", id: 0, color: '#9747FF', value: 0, width: 28.5 },
    { text: "VEEV NOW Laying  DP", id: 1, color: '#FF9F47', value: 0, width: 19.5},
    { text: "VEEV ONE Standing & Laying DP", id: 2, color: '#9747FF', value: 0, width: 28.5},
    { text: " CC Laying 2 DP", id: 3, color: 'blue', value: 0, width: 27.5 },
    { text: "VEEV Now Standing DP", id: 4, color: '#FF9F47', value: 0, width: 19.5 },
    { text: "HNB DP", id: 5, color: 'blue', value: 0, width: 35 },
    { text: "VEEV ONE Laying DP", id: 6, color: '#9747FF', value: 0, width: 28.5 },
    { text: "HEETS", id: 7, color: '#0179FF', value: 0, width:  38.75  },
    { text: "TEREA", id: 8, color: '#F95252', value: 0, width:   38.75  },
    { text: "Fiit", id: 9, color: '#FFE455', value: 0, width:  38.75 },
    { text: "Delia", id: 10, color: '#A5ABAA', value: 0, width:   38.75  },
    { text: "VEEV ONE ", id: 11, color: '#9747FF', value: 0, width: 28.5 },
    { text: "VEEV NOW", id: 12, color: '#FF9F47', value: 0, width: 19.5 },
    { text: "LEVIA", id: 13, color: '#5DD671', value: 0, width: 36.5 },
  ];

  const [items, setItems] = useState(duplexInputs);
  const [loadingYellow, setLoadingYellow] = useState(true);
  const [loadingPink, setLoadingPink] = useState(true);
  const { id, index } = useParams<{ id: string,index?:string }>();

  const fetchShelfConfig = async (id: string) => {
    try {
      const shelfConfig = await getRRPNewConfigById(id);
      const marketingMaterialIds = shelfConfig.marketingMaterials || [];
      
      const materialMap = new Map<number, MarketingMaterial & { isAdded: number, instances: { x: number, y: number }[] }>();

      // Store materials with their respective x and y values
      for (const item of marketingMaterialIds) {
        const material = await getMarketingMaterialById(item.id);
        if (materialMap.has(material.id)) {
          const existingMaterial = materialMap.get(material.id)!;
          existingMaterial.isAdded! += 1;
          existingMaterial.instances.push({ x: item.x, y: item.y });
        } else {
          materialMap.set(material.id, { ...material, isAdded: 1, instances: [{ x: item.x, y: item.y }] });
        }
      }


      // Expand materials with their respective x and y values
      const expandedMaterials = Array.from(materialMap.values()).flatMap(material => 
        material.instances.map(instance => ({
          ...material,
          x: instance.x,
          y: instance.y,
          isAdded: 1 // Set isAdded to 1 for each instance in the expanded list
        }))
      );
   
      
      setCol(shelfConfig.col_position);
      setRow(shelfConfig.row_position);
      setRowDistance(shelfConfig.rowDistance);
      setColDistance(shelfConfig.colDistance);
      setWidths(shelfConfig.widths.map((width: any) => width));
  
      setYellowSquares(expandedMaterials);
      setSquaresYellow(expandedMaterials);


    } catch (error) {
      console.error('Failed to fetch shelf configuration and marketing materials:', error);
    }
  };

  const fetchShelfConfigLed = async (id: string) => {
    try {
      const shelfConfig = await getRRPNewConfigById(id);
      const ledScreenIds = shelfConfig.ledScreens;
  
      const ledScreenMap = new Map<number, MarketingMaterial & { isAdded: number, instances: { x: number, y: number }[] }>();
  
      // Store LED screens with their respective x and y values
      for (const item of ledScreenIds) {
        const ledScreen = await getLedScreenById(item.id);
        if (ledScreenMap.has(ledScreen.id)) {
          const existingLedScreen = ledScreenMap.get(ledScreen.id)!;
          existingLedScreen.isAdded! += 1;
          existingLedScreen.instances.push({ x: item.x, y: item.y });
        } else {
          ledScreenMap.set(ledScreen.id, { ...ledScreen, isAdded: 1, instances: [{ x: item.x, y: item.y }] });
        }
      }
  
      // Expand LED screens with their respective x and y values
      const expandedLedScreens = Array.from(ledScreenMap.values()).flatMap(ledScreen => 
        ledScreen.instances.map(instance => ({
          ...ledScreen,
          x: instance.x,
          y: instance.y,
          isAdded: 1 // Set isAdded to 1 for each instance in the expanded list
        }))
      );
  
      setPinkSquares(expandedLedScreens);
    } catch (error) {
      console.error('Failed to fetch shelf configuration and LED screens:', error);
    }
  };
  useEffect(() => {

    const fetchData = async () => {
    if (id) {
      await fetchShelfConfig(id); // Fetch shelf configuration first
      await fetchShelfConfigLed(id); // Fetch LED screens after the shelf configuration
    }
  };

  fetchData().catch(error => {
    console.error('Error fetching shelf configuration and LED screens:', error);
  });// Replace with the actual shelf config ID

  }, [id]);
  const handleAddMaterial = async (material: MarketingMaterial) => {
    try {
      const existingMaterial = yellowSquares.find(m => m.id === material.id);
      let newYellowSquares;
      newYellowSquares = [...yellowSquares, { ...material, isAdded: 1, x: 0, y: 0 }];

  
      const marketingMaterialIds = newYellowSquares.map((m, index) => ({
        id: m.id?.toString() || '',
        x: m.x,
        y: m.y
      }));
  
      id &&   await updateMarketingMaterialsRRP((id), marketingMaterialIds as  any); 
      setYellowSquares(newYellowSquares);
      console.log('material tp add', material)

  // Replace with actual shelf config ID
    } catch (error) {
      console.error('Failed to update marketing materials:', error);
    }
  };

  useEffect(() => {
    const updatePositions = async () => {
      if(id && widths.length > 0){
        try {
          setLoadingYellow(true)
          const marketingMaterialIds = yellowSquares.map((m, index) => ({
            id: m.id?.toString() || '',
            x: m.x,
            y: m.y
          }));
          await updateMarketingMaterialsRRP((id ), marketingMaterialIds as any); 
          setLoadingYellow(false)
  
        } catch (error) {
          console.error('Failed to update marketing materials:', error);
          setLoadingYellow(false)
  
        }
      }
 
    };

    updatePositions();
  }, [yellowSquares,setYellowSquares,id]);
  useEffect(() => {
    const updatePositions = async () => {
      if(id && widths.length > 0){
      try {
        setLoadingPink(true);
        const ledScreenIds = pinkSquares.map((m, index) => ({
          id: m.id?.toString() || '',
          x: m.x,
          y: m.y
        }));
        id &&  await updateLedScreensRRP((id ), ledScreenIds as any); 
        setLoadingPink(false);
  
      } catch (error) {
        console.error('Failed to update LED screens:', error);
        setLoadingPink(false);
      }}
    };
  
    updatePositions();
  }, [pinkSquares, setPinkSquares,id]);
  const handleAddLedScreen = async (ledScreen: MarketingMaterial) => {
    try {
      const existingLedScreen = pinkSquares.find(m => m.id === ledScreen.id);
      let newPinkSquares;
      newPinkSquares = [...pinkSquares, { ...ledScreen, isAdded: 1, x: 0, y: 0 }];
  
      const ledScreenIds = newPinkSquares.map((m, index) => ({
        id: m.id?.toString() || '',
        x: m.x,
        y: m.y
      }));
  
    id &&   await updateLedScreensRRP((id ), ledScreenIds as any); 
      setPinkSquares(newPinkSquares);
      console.log('LED screen to add', ledScreen);
  
      // Replace with actual shelf config ID
    } catch (error) {
      console.error('Failed to update LED screens:', error);
    }
  };
  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          <Sidebar onChange={(value) => setSelectedPanel(value)} />
        </Grid>
        <Grid item xs={9}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#e0e0e0",
            }}
          >
            <DemoComponent
              col={col}
              row={row}
              setCol={setCol}
              setRow={setRow}
              items={items}
              squares={squares}
              pinkSquares={pinkSquares}
              setPinkSquares={setPinkSquares}
              yellowSquares={yellowSquares}
              setYellowSquares={setYellowSquares}
              squaresYellow={squaresYellow}
            />
            {selectedPanel === 3 && (
              <SideBarRight
                onChange={(value) => {
                  setSelectedPanel(value);
                }}
                loading={loadingPink}
                setLoading={setLoadingPink}
                squaresYellow={pinkSquares as any}
                onAddShelf={(w, h, t:any) =>
                  setPinkSquares([
                    ...pinkSquares,
                    {
                      width: parseInt(w),
                      height: parseInt(h),
                      title: t,
                      x: 90,
                      y: 90,
                    },
                  ])
                }
                onAddMaterial={handleAddLedScreen}
              ></SideBarRight>
            )}
            {selectedPanel === 4 && (
              <SideBarRightYellow
                onChange={(value) => {
                  setSelectedPanel(value);
                }}
                squaresYellow={yellowSquares}
                loading={loadingYellow}
                setLoading={setLoadingYellow}
                onAddShelf={(w, h, t) =>
                  setYellowSquares([
                    ...yellowSquares,
                    {
                      width: parseInt(w),
                      height: parseInt(h),
                      title: t,
                      x: 90,
                      y: 90,
                    },
                  ])
                }
                onAddMaterial={handleAddMaterial}
              ></SideBarRightYellow>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Step4;
