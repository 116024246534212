/**
 * Saves a JavaScript object as JSON string to localStorage.
 * @param key The key under which to store the data.
 * @param value The object to be saved.
 */
export const saveToJson = (key: string, value: any): void => {
    try {
      const jsonValue = JSON.stringify(value);
      localStorage.setItem(key, jsonValue);
    } catch (error) {
      console.error("Error saving to localStorage", error);
    }
  };
  
  /**
   * Retrieves a JavaScript object from a JSON string stored in localStorage.
   * @param key The key under which the data is stored.
   * @returns The parsed JavaScript object or null if not found or on error.
   */
  export const getFromJson = <T>(key: string): T | null => {
    try {
      const jsonValue = localStorage.getItem(key);
      return jsonValue ? JSON.parse(jsonValue) : null;
    } catch (error) {
      console.error("Error retrieving from localStorage", error);
      return null;
    }
  };


  