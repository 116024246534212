import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface TabItem {
  title: string;
  content: React.ReactNode;
}

interface TabsComponentProps {
  tabs: TabItem[];
  defaultTab?: number;
}

const TabsComponent: React.FC<TabsComponentProps> = ({ tabs, defaultTab = 0 }) => {
  const [value, setValue] = React.useState(defaultTab);

  useEffect(() => {
    setValue(defaultTab);
  }, [defaultTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', bgcolor: '' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          background: "white",
          padding: "8px",
          borderRadius: "50px",
          height: "40px"
        }}
      >
        <Tabs
          value={value}
          variant="fullWidth"
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            '.MuiTabs-indicator': {
              display: "none",
              backgroundColor: '#3f51b5',
              height: '4px'
            },
            '.MuiTab-root': {
              textTransform: 'none',
              fontWeight: 'bold',
              borderRadius: "40px",
              height: "40px",
              minHeight: "40px",
              '&.Mui-selected': {
                color: '#0179FF',
                background: "#F5F9FF"
              },
              '&:hover': {
                color: '#3f51b5',
                opacity: 1,
              },
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              label={tab.title}
              id={`simple-tab-${index}`}
              aria-controls={`simple-tabpanel-${index}`}
              key={index}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel value={value} index={index} key={index}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabsComponent;
