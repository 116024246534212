import React from "react";
import { Box, Button, IconButton } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Logo from "../../../assets/images/Logo1.png";
import { ReactComponent as SKUS } from "../../../assets/icons/SKUS.svg";
import { ReactComponent as Stores } from "../../../assets/icons/Stores.svg";
import { ReactComponent as Account } from "../../../assets/icons/Account.svg";
import { ReactComponent as Back } from "../../../assets/icons/Back.svg";

import { useStyles } from "./SideBarStyles";
import Input from "../Input/Input";
import { ReactComponent as Col } from "../../../assets/icons/Col.svg";
import { ReactComponent as Row } from "../../../assets/icons/Row.svg";
import { ReactComponent as DrawerDistance } from "../../../assets/icons/DrawerDistance.svg";
import { ReactComponent as ColDistance } from "../../../assets/icons/ColDistance.svg";
import { useNavigate } from 'react-router-dom';

import Typography from "@mui/material/Typography";



interface SidebarProps {
  onColChange:(value:number) => void;
  onRowChange:(value:number) => void;
  onRowDistanceChange:(value:number) => void;
  onColDistanceChange:(value:number) => void;
  colDistance:number;

  rowDistance:number;
  row:number;
  col:number;

}

const Sidebar: React.FC<SidebarProps> = ({onColChange,onRowChange,row,col,onRowDistanceChange,rowDistance,colDistance,onColDistanceChange}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.container}>
      <img src={Logo} alt="Logo" className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
            STEP 1/10
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "12px" }}>
            New Shelf Composition
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "50px" }}>
           </Typography>
          <Input
            onChange={onColChange}
            value={col}
            icon={<Col />}
            max={20}
            min={2}
            text={"Horizontal Rows"}
          ></Input>
          <div style={{background:"#F5F9FF",padding:"12px",borderRadius:"15px"}}>
          <Input
            onChange={onRowChange}
            value={row}
            icon={<Row />}
            background="white"
            max={12}
            min={2}
            text={"Vertical Rows:"}
          ></Input>
           <Typography variant="h4" sx={{ fontSize:"12px",fontWeight:"400",color:"#9CA0AB"}}>
           These dimensions are set by default standard       </Typography>
           <a style={{fontSize:"12px",fontWeight:"700",color:"#0179FF"}}>Edit Now</a>
          </div>
         
        
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            // color="primary"
            // variant="contained"
            // sx={{marginBottom:"10px !important", padding:"0 !important", margin:"0 !important",background:"transparent", '&:hover':{ background:"transparent !important",boxShadow:"none"}}}
            onClick={() => {navigate('/sku-priority')}}
            size="large">
            <Box component="span" className={classes.iconButton}>
              <Back />
            </Box>
          </IconButton>
          <Button variant="contained" color="info" onClick={() => {navigate('/step2-mini')}} sx={{marginTop:"0px"}}>
            Get Started
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default Sidebar;
