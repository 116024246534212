import makeStyles from '@mui/styles/makeStyles';


export const useStyles = makeStyles((theme) => ({
    container: {
      width: "365px",
      height: "100vh !important",
      backgroundColor: "white !important",
      boxShadow: theme.shadows[2],
      display: "flex !important",
      flexDirection: "column",
      alignItems: "center !important",
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      transition: 'all 0.3s ease',
      position: 'fixed', // or 'absolute', depending on your layout
    top: 0,
    right: 0, 
    zIndex: 900,// Align to the right
    },
    logo: {
      maxWidth: "100%",
      maxHeight: "44px",
      marginBottom: theme.spacing(2),
      marginTop: "40px",
    },
    buttonContainer:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        marginBottom: "10px",
    },
   
    iconButton: {
      width: 32,
      height: 32,
      //marginRight: theme.spacing(1),
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgba(217, 231, 248, 0.50)",
      color: "#0179FF",
    },
  
    sidebarOpen: {
      right: 0, // Stick to the right edge when open
    },
    sidebarClosed: {
      right: '-330px', // Adjust as needed based on the width of your sidebar
    },
    toggleButton: {
      
      position: 'absolute',
      top: '50%', // Adjust as needed
      right: `calc(100% - 25px)`, // Position it outside the sidebar
      transform: 'translateY(-50%)', 
      zIndex: 1010, // Above the sidebar to ensure visibility
      // Center it vertically
      // Add more styling for the button here
    },
  }),{ name: 'SideBarStyles' });