import React, { useState, useEffect } from "react";
import StoreIcon from "../../../assets/icons/KeyAccount.svg";
import ID from "../../../assets/icons/POSID.svg";
import Location from "../../../assets/icons/Location.svg";
import Region from "../../../assets/icons/Region.svg";
import Visibility from "../../../assets/icons/Visb.svg";
import { Switch, SwitchProps, styled, Snackbar, Alert } from "@mui/material";
import { updatePosUnivers } from "../../../services/posuniversService";
import { useNavigate } from "react-router-dom";
import IqosPartnerCheckBox from "../../POSUniverse/IqosPartnerCheckBox/IqosPartnerCheckBox";

interface StoreCardProps {
  id: number;
  name: string;
  posId: string;
  address: string;
  region: string;
  visibility: boolean;
  iqosPartner: boolean;
  onEdit: (id: number) => void;
  keyAccountId: number;
  type: "individual" | "key account";
}

const StoreCard: React.FC<StoreCardProps> = ({
  id,
  name,
  posId,
  address,
  region,
  visibility,
  iqosPartner,
  keyAccountId,
  type,
  onEdit,
}) => {
  const [isVisible, setIsVisible] = useState(visibility);
  const [isPartner, setIsPartner] = useState(iqosPartner);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setIsVisible(visibility);
    setIsPartner(iqosPartner);
  }, [visibility, iqosPartner]);

  const handleVisibilityChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newVisibility = event.target.checked;
    setIsVisible(newVisibility);
    try {
      await updatePosUnivers(id, {
        name,
        posId: posId as any,
        address,
        region,
        visibility: newVisibility,
        iqosPartner: isPartner,
        type,
        keyAccountId,
      });
      setSnackbarMessage("Visibility updated successfully");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Failed to update visibility");
      setSnackbarOpen(true);
    }
  };

  const handlePartnerChange = async (newPartnerStatus: boolean) => {
    setIsPartner(!newPartnerStatus);
    try {
      await updatePosUnivers(id, {
        name,
        posId: posId as any,
        address,
        region,
        visibility: isVisible,
        iqosPartner: !newPartnerStatus,
        type,
        keyAccountId,
      });
      setSnackbarMessage("Partner status updated successfully");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Failed to update partner status");
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const cardStyle = {
    padding: "12px",
    width: "240px",
    background: "white",
    borderRadius: "5px",
    display: "flex",
  };

  const title = {
    fontSize: "10px",
    fontWeight: 400,
    color: "#383A49",
    opacity: 0.5,
  };

  const rowStyle = {
    marginBottom: "5px",
  };

  return (
    <div style={{ ...cardStyle, flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "14px",
          marginBottom: "12px",
        }}
      >
        <img src={StoreIcon} width={50} height={50} alt="Store Icon" />
        <div style={rowStyle}>{name}</div>
      </div>
      <div
        style={{
          borderTop: "1px solid rgba(156, 160, 171, 0.30)",
          marginBottom: "16px",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "14px",
          marginBottom: "12px",
        }}
      >
        <img src={ID} width={24} height={24} alt="POS ID Icon" />
        <div>
          <div style={{ ...title }}>POS ID</div>
          <div style={{ fontSize: "14px" }}>{posId}</div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "14px",
          marginBottom: "12px",
        }}
      >
        <img src={Location} width={24} height={24} alt="Address Icon" />
        <div>
          <div style={{ ...title }}>ADDRESS</div>
          <div style={{ fontSize: "14px" }}>{address}</div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "14px",
          marginBottom: "12px",
        }}
      >
        <img src={Region} width={24} height={24} alt="Region Icon" />
        <div>
          <div style={{ ...title }}>Region</div>
          <div style={{ fontSize: "14px" }}>{region}</div>
        </div>
      </div>
      <div
        style={{
          borderTop: "1px solid rgba(156, 160, 171, 0.30)",
          marginBottom: "10px",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "14px",
          marginBottom: "12px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "14px",
            marginBottom: "1px",
          }}
        >
          <img src={Visibility} width={24} height={24} alt="Visibility Icon" />
          <div>
            <div style={{ ...title }}>Visibility</div>
            <div style={{ fontSize: "14px" }}>{isVisible ? "On" : "Off"}</div>
          </div>
        </div>
        <IOSSwitch
          checked={isVisible}
          onChange={handleVisibilityChange}
          sx={{ m: 1 }}
        />
      </div>
      <div
        style={{
          borderTop: "1px solid rgba(156, 160, 171, 0.30)",
          borderBottom: "1px solid rgba(156, 160, 171, 0.30)",
          padding: "16px 0",
          marginBottom: "12px",
        }}
      >
        <IqosPartnerCheckBox
          option1Text={""}
          option2Text={""}
          onChange={handlePartnerChange}
          isPartner={!isPartner}
        />
      </div>
      <div
        style={{
          ...rowStyle,
          alignSelf: "center",
          marginBottom: "12px",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <a
          href="#"
          onClick={() => onEdit(id)}
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#0179FF",
            display: "block",
          }}
        >
          Edit
        </a>
        <a
          href="#"
          onClick={() => navigate("/pos-edit/" + id)}
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#0179FF",
            display: "block",
          }}
        >
          Settings
        </a>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default StoreCard;

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
