import React from "react";
import { Box, Button, IconButton } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Logo from "../../../assets/images/Logo1.png";
import { ReactComponent as SKUS } from "../../../assets/icons/SKUS.svg";
import { ReactComponent as Stores } from "../../../assets/icons/Stores.svg";
import { ReactComponent as Account } from "../../../assets/icons/Account.svg";
import { ReactComponent as Back } from "../../../assets/icons/Back.svg";

import { useStyles } from "./SideBarStyles";
import Input from "../Input/Input";
import { ReactComponent as Col } from "../../../assets/icons/Col.svg";
import { ReactComponent as Row } from "../../../assets/icons/Row.svg";
import { ReactComponent as Width } from "../../../assets/icons/Width.svg";
import { ReactComponent as Height } from "../../../assets/icons/Height.svg";

import Typography from "@mui/material/Typography";

const Sidebar: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <img src={Logo} alt="Logo" className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
          STEP 2/10
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "12px" }}>
          Set Drawer Dimension
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "50px" }}>
          Edit your drawer dimensions
          </Typography>
          <Input
            onChange={() => {}}
            value={10}
            icon={<Width />}
            text={"Width"}
          />
          <Input
            onChange={() => {}}
            value={10}
            icon={<Height />}
            text={"Height"}
          />
        </div>
        <div>
        <Input
            onChange={() => {}}
            value={10}
            icon={<Col />}
            text={"Drawers"}
          />
          <div style={{ display: "flex", alignItems: "center",justifyContent:"center"  }}>
       
       <IconButton
         // color="primary"
         // variant="contained"
         // sx={{marginBottom:"10px !important", padding:"0 !important", margin:"0 !important",background:"transparent", '&:hover':{ background:"transparent !important",boxShadow:"none"}}}
         onClick={() => {}}
         size="large">
         <Box component="span" className={classes.iconButton}>
           <Back />
         </Box>
       </IconButton>
       <Button variant="contained" color="info" onClick={() => {}} sx={{marginTop:"0px"}}>
         Get Started
       </Button>
     </div>
        </div>
        
      </div>
    </Box>
  );
};

export default Sidebar;
