import React from "react";
import StoreCard from "../StoreCard/StoreCard";

interface Store {
  id: number;
  name: string;
  posId: string;
  address: string;
  region: string;
  visibility: boolean;
  iqosPartner: boolean;
}

interface StoreCardsListProps {
  stores: Store[];
  onEdit: (id: number) => void; // Add onEdit prop
}

const StoreCardsList: React.FC<StoreCardsListProps> = ({ stores, onEdit }) => {
  const gridStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
    gap: "20px",
    padding: "20px 0",
  };

  return (
    <div style={gridStyle}>
      {stores.map((store) => (
        <StoreCard
          key={store.id}
          id={store.id}
          name={store.name}
          posId={(store as any).pos_id}
          address={store.address}
          region={store.region}
          visibility={store.visibility}
          iqosPartner={(store as any).iqos_partner}
          keyAccountId={(store as any).key_account_id}
          type={(store as any).type}
          onEdit={onEdit} // Pass onEdit handler
        />
      ))}
    </div>
  );
};

export default StoreCardsList;
