// NoShelfCardStyles.ts
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  card: {
    width: 386,
    height: 387,
    borderRadius:"10px !important",
    boxShadow:"none !important",
    backgroundColor: "#F5F9FF !important"
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap:10,
    backgroundColor: "#F5F9FF"

  },
  icon: {
    width: 170,
    height: 180,
    color: theme.palette.primary.main,
  },
  title: {
    marginTop: theme.spacing(2),
   


  },
  button: {
    marginTop: theme.spacing(2),
  },
}));
