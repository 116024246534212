import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Sidebar from "../../components/RRP/Step5/sideBar/SideBar";
import DemoComponent from "../../components/RRP/Step5/Content/Content";
import { getFromJson } from "../../components/helpers/localData";
import SideBarRight from "../../components/RRP/Step5/sideBarRight/SideBarRight";
import SideBarRightYellow from "../../components/RRP/Step5/sideBarRightYellow/SideBarRight";
import { useParams, useNavigate } from "react-router-dom";
import { getRRPNewConfigById, updateRRPNewConfig } from "../../services/rrpNewConfigurationService";
import { getMarketingMaterialById } from "../../services/marketingMaterialsService";
import { getLedScreenById } from "../../services/ledScreensService";

type Square = {
  width: number;
  height: number;
  x: number;
  y: number;
  title?: string;
  positions?: any;
};

interface DuplexType {
  id: number;
  value: number;
  images: (string | null)[];
}

interface SquareData {
  row: number;
  col: number;
  duplexTypes: { id: number; value: number ,color:string,text:string,width:number,images:string[]}[];
}
type MarketingMaterial = {
  id?: number;
  title?: string;
  width: number;
  height: number;
  x: number;
  y: number;
  positions?: any;
};

const Step5Page: React.FC = () => {
  const duplexInputs = [
    { text: "VEEV ONE Standing DP", id: 0, color: '#9747FF', value: 0, width: 28.5 },
    { text: "VEEV NOW Laying  DP", id: 1, color: '#FF9F47', value: 0, width: 19.5},
    { text: "VEEV ONE Standing & Laying DP", id: 2, color: '#9747FF', value: 0, width: 28.5 },
    { text: " CC Laying 2 DP", id: 3, color: 'blue', value: 0, width: 27.5 },
    { text: "VEEV Now Standing DP", id: 4, color: '#FF9F47', value: 0, width: 19.5 },
    { text: "HNB DP", id: 5, color: 'blue', value: 0, width: 35 },
    { text: "VEEV ONE Laying DP", id: 6, color: '#9747FF', value: 0, width: 28.5 },
    { text: "HEETS", id: 7, color: '#0179FF', value: 0, width:  38.75  },
    { text: "TEREA", id: 8, color: '#F95252', value: 0, width:  38.75 },
    { text: "Fiit", id: 9, color: '#FFE455', value: 0, width:  38.75  },
    { text: "Delia", id: 10, color: '#A5ABAA', value: 0, width:   38.75  },
    { text: "VEEV ONE", id: 11, color: '#9747FF', value: 0, width: 28.5 },
    { text: "VEEV NOW", id: 12, color: '#FF9F47', value: 0, width: 19.5 },
    { text: "LEVIA", id: 13, color: '#5DD671', value: 0, width: 36.5 },
  ];

  const [selectedSquare, setSelectedSquare] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  
  const { id, index } = useParams<{ id: string, index?:string }>();
  const [col, setCol] = useState<number>(1);
  const [row, setRow] = useState<number>(2);
  const [name, setName] = useState<string>('');

  const [squaresData, setSquaresData] = useState<SquareData[]>([]);
  const [widths, setWidths] = useState<number[]>([]);
  const [squares, setSquares] = useState<Square[]>(
    []
 );
const [keyAccount,setKeyAccount]= useState<number>(0)
 const [squaresYellow, setSquaresYellow] = useState<Square[]>(
  []
 );

 const [pinkSquares, setPinkSquares] = useState(
 squares
 );
 const [yellowSquares, setYellowSquares] = useState(
   squaresYellow
 );
 const [ccSpace, setCcSpace] = useState<number>(8);

    const fetchShelfConfig = async (id: string) => {
      try {
        const shelfConfig = await getRRPNewConfigById(id);
        const marketingMaterialIds = shelfConfig.marketingMaterials || [];
        
        const materialMap = new Map<number, MarketingMaterial & { isAdded: number, instances: { x: number, y: number }[] }>();
  
        for (const item of marketingMaterialIds) {
          const material = await getMarketingMaterialById(item.id);
          if (materialMap.has(material.id)) {
            const existingMaterial = materialMap.get(material.id)!;
            existingMaterial.isAdded! += 1;
            existingMaterial.instances.push({ x: item.x, y: item.y });
          } else {
            materialMap.set(material.id, { ...material, isAdded: 1, instances: [{ x: item.x, y: item.y }] });
          }
        }
  
        const expandedMaterials = Array.from(materialMap.values()).flatMap(material => 
          material.instances.map(instance => ({
            ...material,
            x: instance.x,
            y: instance.y,
            isAdded: 1 // Set isAdded to 1 for each instance in the expanded list
          }))
        );
     
        setCol(shelfConfig.col_position);
        setRow(shelfConfig.row_position);
        setSquaresData(shelfConfig.squaresData)
        setWidths(shelfConfig.widths.map((width: any) => width ));
        setYellowSquares(expandedMaterials);
        setCcSpace(shelfConfig.ccSpace)
        setKeyAccount(shelfConfig.key_account_id)
        setName(shelfConfig.name)

      } catch (error) {
        console.error('Failed to fetch shelf configuration and marketing materials:', error);
      }
    };
  
    const fetchShelfConfigLed = async (id: string) => {
      try {
        const shelfConfig = await getRRPNewConfigById(id);
        const ledScreenIds = shelfConfig.ledScreens;
    
        const ledScreenMap = new Map<number, MarketingMaterial & { isAdded: number, instances: { x: number, y: number }[] }>();
    
        for (const item of ledScreenIds) {
          const ledScreen = await getLedScreenById(item.id);
          if (ledScreenMap.has(ledScreen.id)) {
            const existingLedScreen = ledScreenMap.get(ledScreen.id)!;
            existingLedScreen.isAdded! += 1;
            existingLedScreen.instances.push({ x: item.x, y: item.y });
          } else {
            ledScreenMap.set(ledScreen.id, { ...ledScreen, isAdded: 1, instances: [{ x: item.x, y: item.y }] });
          }
        }
    
        const expandedLedScreens = Array.from(ledScreenMap.values()).flatMap(ledScreen => 
          ledScreen.instances.map(instance => ({
            ...ledScreen,
            x: instance.x,
            y: instance.y,
            isAdded: 1 // Set isAdded to 1 for each instance in the expanded list
          }))
        );
    
        setPinkSquares(expandedLedScreens);
      } catch (error) {
        console.error('Failed to fetch shelf configuration and LED screens:', error);
      }
    };

    const navigate = useNavigate();

    const handleContinue = async (name:string) => {
      if (!id) {
        console.error('Config ID is not set');
        return;
      }
  
      const config = {
        col_position: col,
        row_position: row,
        squaresData: squaresData,
        widths: widths,
        name,
      };
  
      try {
        const response = await updateRRPNewConfig(id, config);
        if(index) {
          navigate('/step17-new/'+index+'?index=1');

        }else {
          navigate('/sku-priority');

        }
        console.log('Config updated:', response);
      } catch (error) {
        console.error('Error updating config:', error);
      }
    };

    useEffect(() => {
      if (id) {
        fetchShelfConfig(id);
        fetchShelfConfigLed(id);
      }
    }, [id]);

  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          <Sidebar setSelectedImage={setSelectedImage} selectedSquare={selectedSquare} onContinue={handleContinue} name={name} />
        </Grid>
        <Grid item xs={9}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#e0e0e0",
            }}
          >
            <DemoComponent
              col={col}
              row={row}
              items={duplexInputs}
              pinkSquares={pinkSquares || []}
              yellowSquares={yellowSquares || []}
              selectedSquare={selectedSquare}
              setSelectedSquare={setSelectedSquare}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              squaresData={squaresData}
              setSquaresData={setSquaresData}
              ccSpace={ccSpace}
              widths={widths}
              keyAccount={keyAccount}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Step5Page;
