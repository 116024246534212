import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error('API URL not defined in environment variables');
}

export const createVariant = async (name: string) => {
  try {
    const response = await axios.post(`${API_URL}/variants`, { name });
    return response.data;
  } catch (error:any) {
    throw error.response.data;
  }
};

export const getAllVariants = async () => {
  try {
    const response = await axios.get(`${API_URL}/variants`);
    return response.data;
  } catch (error:any) {
    throw error.response.data;
  }
};
