import React from "react";
import { adaptV4Theme } from "@mui/material/styles";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  createTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./pages/Home";
import SKUPriority from "./pages/SKUPriority";

import SKUList from "./pages/SKUlist";
import POSUniverse from "./pages/POSUniverse";

import AboutPage from "./pages/About";
import AboutPage1 from "./pages/About1 copy";

import Step2 from "./pages/Step2";

import Step3 from "./pages/Step3 copy";
import Step31 from "./pages/Step31";

import Step4 from "./pages/Step4";
import Step5 from "./pages/Step5";
import Step6 from "./pages/Step6";
import Step7 from "./pages/Step7";
import Step8 from "./pages/Step8 copy";
import Step9 from "./pages/Step9 copy";
import Step9Export from "./pages/Step9 copy 2";

import Chain from "./pages/Chain";
import Step1Mini from "./pages/Step1Mini";
import Step2Mini from "./pages/Step2Mini";
import Step7New from "./pages/Step7New copy";
import Step2MiniStruct from "./pages/Step2MiniStruct";
import Step1 from "./pages/RRP/Step1";
import Step2RRP from "./pages/RRP/step2";
import Step3RRP from "./pages/RRP/step3";
import Step4RRP from "./pages/RRP/step4";
import Step5RRP from "./pages/RRP/step5";
import Step3RRPNew from "./pages/RRP/Step3New";
import Step4RRPNew from "./pages/RRP/Step4New";
import PosEditPage from "./pages/PosEdit";
import LoginPage from "./pages/Login/Login";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import NoAccess from "./pages/NoAccess"; // Import NoAccess component

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  typography: {
    fontFamily: "Mulish, sans-serif",
    h4: {
      fontSize: "24px",
      fontWeight: "800",
      color: "#383A49",
    },

    h2: {
      fontSize: "14px",
      fontWeight: "400",
      color: "#383A49",
    },
    h1: {
      fontSize: "14px",
      fontWeight: "500",
      color: "#383A49",
    },
    h5: {
      fontSize: "14px",
      fontWeight: "600",
      color: "#383A49",
    },
    h6: {
      fontSize: "16px",
      fontWeight: 400,
      color: "#383A49",
    },
    h3: {
      fontSize: "16px",
      fontWeight: "600",
      color: "#0179FF",
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: "400",
      color: "#383A49",
    },
    subtitle2: {
      fontSize: "16px",
      fontWeight: "600",
      color: "#0179FF",
    },
    body1: {
      fontSize: "20px",
      fontWeight: "600",
      color: "#383A49",
    },
    body2: {
      fontSize: "14px",
      fontWeight: "400",
      color: "#0179FF",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          borderRadius: "83px !important",
          background: "white ",
          height: 47,
          alignSelf: "start !important",
          padding: "12px 20px 12px 12px",
          marginTop: "0px !important ",
          marginLeft: 0,
          textTransform: "capitalize",
          color: "black !important",
          fontSize: "14px !important",

          boxShadow: "none !important",
          "&:hover": {
            background: "transparent",
            boxShadow: "none",
            opacity: 0.8,
          },
        },
        containedPrimary: {
          background: "#F5F9FF",
          height: 47,
          marginLeft: 0,
          borderRadius: 83,
          marginTop: "20px",
          padding: "12px 20px 12px 12px",
          textTransform: "capitalize",
          color: "black",
          fontSize: "14px",
          fontWeight: "700",
          boxShadow: "none",
          alignSelf: "center",
          "&:hover": {
            background: "#F5F9FF !important",
            opacity: 0.8,
          },
        },
        containedInfo: {
          borderRadius: "47px",
          background: "#0179FF",
          height: 47,
          marginLeft: 0,
          marginTop: "20px",
          padding: "18px 44px 18px 44px",
          textTransform: "capitalize",
          color: "white",
          fontSize: "16px",
          fontWeight: "600",
          boxShadow: "none",
          alignSelf: "center",
          "&:hover": {
            background: "#0179FF !important",
            opacity: 0.8,
          },
        },
        outlinedInfo: {
          borderRadius: "33px",
          background: "#0179FF",
          height: 36,
          marginLeft: 0,
          padding: "8px",
          textTransform: "capitalize",
          color: "white",
          fontSize: "14px",
          fontWeight: "700",
          boxShadow: "none",
          alignSelf: "center",
          "&:hover": {
            background: "#0179FF !important",
            opacity: 0.8,
          },
        },
        outlinedPrimary: {
          borderRadius: "33px",
          background: "white",
          border: "1px solid #D9E7F8",
          height: 36,
          marginLeft: 0,
          padding: "8px",
          textTransform: "capitalize",
          color: "#383A49",
          fontSize: "14px",
          fontWeight: "300",
          boxShadow: "none",
          alignSelf: "center",
          "&:hover": {
            background: "#0179FF !important",
            opacity: 0.8,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "green", // Change the outline color
            },
            "&:hover fieldset": {
              borderColor: "red", // Change the outline color on hover
            },
          },
        },
      },
    },
  },
});

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  
  // Extract roles from the account object
  const userRoles = accounts[0]?.idTokenClaims?.roles || [];
  
  // Helper function to check if the user has a specific role
  const hasRole = (role: string) => userRoles.includes(role);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>

    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
          {
                  !isAuthenticated  && <>
                    <Route path="/" element={<Navigate to='/login' />} />
                    <Route path="/login" element={<LoginPage />} />
                  </>
              }
          
               {isAuthenticated && (
          <>
            {/* Common routes for all authenticated users */}
            <Route path="/" element={<HomePage />} />
            <Route path="/skulist" element={<SKUList />} />
            <Route path="/step1/:id?" element={<AboutPage />} />
            <Route path="/step1-mini/" element={<Step1Mini />} />
            <Route path="/step2-mini/" element={<Step2Mini />} />
            <Route
              path="/step2-mini-structure/"
              element={<Step2MiniStruct />}
            />
            <Route path="/login" element={<Navigate to='/' />} />

            <Route path="/rrp/step1/:id?/:index?" element={<Step1 />} />
            <Route path="/rrp/step2/:id?/:index?" element={<Step2RRP />} />
            <Route path="/rrp/step3/:id?/:index?" element={<Step3RRP />} />
            <Route path="/rrp/step3new/:id?/:index?" element={<Step3RRPNew />} />
            <Route path="/rrp/step4new/:id?/:index?" element={<Step4RRPNew />} />
            <Route path="/rrp/step4/:id?/:index?" element={<Step4RRP />} />
            <Route path="/rrp/step5/:id?/:index?" element={<Step5RRP />} />

            <Route path="/step11/:id?" element={<AboutPage1 />} />
            <Route path="/step2" element={<Step2 />} />
            <Route path="/step3/:id?" element={<Step3 />} />
            <Route path="/step3-1" element={<Step31 />} />
            <Route path="/step4" element={<Step4 />} />
            <Route path="/step5/:id" element={<Step5 />} />
            <Route path="/step6/:id" element={<Step6 />} />
            <Route path="/step7" element={<Step7 />} />
            <Route path="/step17-new/:id?/:index?" element={<Step7New />} />

            <Route path="/step8/:id?" element={<Step8 />} />
            <Route path="/step9/:id?" element={<Step9Export />} />
            <Route path="/step9-export/:id?" element={<Step9Export />} />

            
            {/* Routes restricted for Admin but allowed for Super Admin */}
            {(hasRole("PMICLDSGShelfcreationtoolSKAdminDEVIGA") ||  hasRole("PMICLDSGShelfcreationtoolSKManagerDEVIGA")) ? (
              <>
                {/* SuperAdmin has access to all the following routes */}
                <Route path="/sku-priority" element={<SKUPriority />} />
                <Route path="/pos" element={<POSUniverse />} />
                <Route path="/pos-edit/:id" element={<PosEditPage />} />
                <Route path="/chain/:keyAccountId" element={<Chain />} />
              </>
            ) : !(hasRole("PMICLDSGShelfcreationtoolSKAdminDEVIGA") ||  hasRole("PMICLDSGShelfcreationtoolSKManagerDEVIGA")) ? (
              <>
                {/* If Admin tries to access these restricted routes, redirect to NoAccess */}
                <Route path="/sku-priority" element={<NoAccess />} />
                <Route path="/pos" element={<NoAccess />} />
                <Route path="/pos-edit/:id" element={<NoAccess />} />
                <Route path="/chain/:keyAccountId" element={<NoAccess />} />
              </>
            ) : null}

          
            
            {/* Redirect logged-in users without access to a default page */}
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
           
          </Routes>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
    </LocalizationProvider>

  );
}

export default App;
