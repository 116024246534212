import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import { getFromJson, saveToJson } from "../../helpers/localData";
import { LeakRemoveTwoTone } from '@mui/icons-material';
import { updateLedScreens, updateMarketingMaterials } from '../../../services/shelfConfigurationService';
import { useParams } from 'react-router-dom';
import Viewer from '../../RrpPreview/Viewer';
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customBox: {
      borderRadius: '5.134px',
      background: 'transparent',
      width: '186.103px',
      //height: '390.994px',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '2px',
      position: 'relative',
    },
    line: {
      borderRight: '2px solid #000',
      height: '100%',
      position: 'relative',
    },
    subBox: {
      width: '176.143px',
      height: '51.5px',
      background: '#EFEFEF',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    secondBox: {
      width: '177.103px',
      height: '82.142px',
      flexShrink: 0,
      borderRadius: '3.85px',
      background: '#EFEFEF',
    },
    selectedBox: {
      backgroundColor: '#ADD5FF !important',
    },
    selectionBox: {
      position: 'absolute',
      border: '2px solid #0179FF',
      zIndex: 1000,
      pointerEvents: 'none',
      cursor: 'pointer',
      backgroundColor: 'rgba(1, 121, 255, 0.50)',
      '&:active': {
        cursor: 'pointer',
      },
    },
    whiteBox: {
      backgroundColor: '#FFFFFF',
      borderRadius: '4px',
      height: '41.5px',
      margin: '2px 2px',
      userSelect: 'none',
      //width: 'calc(75% / 10)',
      position: 'relative',
    },
    pinkSquare: {
      position: 'absolute',
      backgroundColor: '#F95252',
      cursor: 'grab',
      zIndex: 500,
      '&:active': {
        cursor: 'grabbing',
      },
    },
    whiteBoxSelected: {
      backgroundColor: 'transparent !important',

      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: '4px',
        right: 0,
        height: 'calc(50% - 1.5px)',
        backgroundColor: '#63D259',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: '4px',
        height: 'calc(50% - 1.5px)',
        backgroundColor: '#63D259',
      },
      
    },
    closeIcon: {
      position: 'absolute',
      width: "16px !important",
      height:"16px !important",
      top: 0,
      right: 0,
      cursor: 'pointer',
      zIndex: 600, // Ensure it's above the squares
      color: 'black', // Adjust color as needed
      '&:hover': {
        opacity: 0.4, // Change color on hover
      },
    },
    yellowSquare: {
      position: 'absolute',
      backgroundColor: '#FFD700', // Yellow color
      cursor: 'grab',
      zIndex: 500, // Ensure it's below the pink square if they overlap
      '&:active': {
        cursor: 'grabbing',
      },
    },
    blueSquare: {
      position: 'absolute',
      backgroundColor: 'transparent', // Yellow color
      cursor: 'grab',
      zIndex: 400, // Ensure it's below the pink square if they overlap
      '&:active': {
        cursor: 'grabbing',
      },
    },
    mergedBox: {
      position: 'absolute',
      backgroundColor: '#FF6C01',
      zIndex: 900,
    },
  })
);
type Square = {
  title?:string;
  width: number;
  height: number;
  x:number;
  y:number;
};
interface GridDemoProps {
  selectionMode?: number;
    squares:Square[]
    squaresYellow:Square[]
    squaresBlue:Square[]

    pinkSquares: {
      width: number;
      height: number;
      x: number;
      y: number;
      title?: string | undefined;
      positions?: any;
  }[]
  setPinkSquares:React.Dispatch<React.SetStateAction<{
    title?: string | undefined;
    width: number;
    height: number;
    x: number;
    y: number;
}[]>>
yellowSquares:{
  width: number;
  height: number;
  x: number;
  y: number;
  title?: string | undefined;
  positions?: any;
}[]
setYellowSquares:React.Dispatch<React.SetStateAction<{
  title?: string | undefined;
  width: number;
  height: number;
  x: number;
  y: number;
}[]>>
blueSquares:{
  width: number;
  height: number;
  x: number;
  y: number;
  title?: string | undefined;
  positions?: any;
}[]
setBlueSquares:React.Dispatch<React.SetStateAction<{
  title?: string | undefined;
  width: number;
  height: number;
  x: number;
  y: number;
}[]>>
col: number;
row: number;
shelfDuplex: boolean[][][];
setShelfDuplex: React.Dispatch<React.SetStateAction<boolean[][][]>>;
rowDistance: number;
colDistance: number;
widths: number[];
selectedBoxesPmi: boolean[][][];
hiddencells: never[]

}

const GridDemo: React.FC<GridDemoProps> = ({ selectionMode = 1 ,squares,squaresYellow,pinkSquares,setPinkSquares,yellowSquares,setYellowSquares,blueSquares,col,
  row,
  rowDistance,
  colDistance,
  widths,
  selectedBoxesPmi,
  shelfDuplex,
  setShelfDuplex,
  hiddencells
}) => {

  const classes = useStyles();
  const boxCount = 10;
  const setCount = 10;
  const customBoxCount = 5;

  const columnWidths = widths
  const { id } = useParams<{ id: string }>();

  const [selectedBoxes, setSelectedBoxes] = useState(selectedBoxesPmi);
  useEffect(() => {
    saveToJson('selectedBoxesDuplex', selectedBoxes);
  }, [selectedBoxes]);
  

  const initializeSelectedBoxesPMI = () => {
    const savedBoxes = selectedBoxesPmi ;

    console.log(savedBoxes)
    if (savedBoxes.length > 0) {
      if(savedBoxes.length < (col)){
           let diffrence= (col)-savedBoxes.length 
           let diffrenceColumnWidths = columnWidths.slice(-diffrence);
           let newArray = diffrenceColumnWidths.map(columnWidth => {
            const boxesInColumn = Math.floor(columnWidth / 10);
            return new Array((row))
              .fill(null)
              .map(() => new Array(boxesInColumn).fill(false));
          });
         // saveToJson('selectedBoxes', [...savedBoxes,...newArray]);

          return [...savedBoxes,...newArray]
      }else if (savedBoxes.length > (col)){
        let diffrence= savedBoxes.length - (col)
        alert("ok")
        console.log("the console ")
        console.log(savedBoxes)


       // saveToJson('selectedBoxes', [...savedBoxes.slice(0, -diffrence)]);

       return [...savedBoxes.slice(0, -diffrence)]
      }else {
        const rowNumber = row; // Default to 2 if not specified
        return columnWidths.map((columnWidth, columnIndex) => {
          const boxesInColumn = Math.floor(columnWidth / 10);
          return new Array(rowNumber)
            .fill(null)
            .map((_, rowIndex) => {
              // Check if the oldSavedBoxes has a row at this index and column
              const oldRow = savedBoxes[columnIndex] && savedBoxes[columnIndex][rowIndex];
              return new Array(boxesInColumn)
                .fill(null)
                .map((_, boxIndex) => {
                  // Preserve the state of existing boxes, initialize new ones as false
                  return oldRow && oldRow[boxIndex] !== undefined ? oldRow[boxIndex] : false;
                });
            });
        });
      }
      return savedBoxes;
    } else {
      return columnWidths.map(columnWidth => {
        const boxesInColumn = Math.floor(columnWidth / 10);
        return new Array(row)
          .fill(null)
          .map(() => new Array(boxesInColumn).fill(false));
      });
    }
  };

  //const [shelfDuplex, setShelfDuplex] = useState<boolean[][][]>(() => initializeShelfDuplex());
  useEffect(() => {
    saveToJson('shelfDuplex', shelfDuplex);
  }, [shelfDuplex]);
  const [selectedBoxesPMI, setSelectedBoxesPMI] = useState(() => initializeSelectedBoxesPMI());

  const [selectionBox, setSelectionBox] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [isSelecting, setIsSelecting] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [mergedBox, setMergedBox] = useState<{ x: number; y: number; width: number; height: number } | null>(null);
  const [mergedBoxes, setMergedBoxes] = useState<
    Array<{ x: number; y: number; width: number; height: number }>
  >([]);
  const handleMouseDown = (event: React.MouseEvent) => {
    const containerBounds = containerRef.current?.getBoundingClientRect();
    if (!containerBounds) return;

    const x = event.clientX - containerBounds.left;
    const y = event.clientY - containerBounds.top;
    setStartPoint({ x, y });
    setSelectionBox({ x, y, width: 0, height: 0 });
    setIsSelecting(true);
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (!isSelecting || !containerRef.current) return;
    const containerBounds = containerRef.current.getBoundingClientRect();

    const x = Math.min(event.clientX - containerBounds.left, startPoint.x);
    const y = Math.min(event.clientY - containerBounds.top, startPoint.y);
    const width = Math.abs(event.clientX - containerBounds.left - startPoint.x);
    const height = Math.abs(event.clientY - containerBounds.top - startPoint.y);
    setSelectionBox({ x, y, width, height });
  };

  const handleMouseUp = () => {
    if (!isSelecting || !containerRef.current) return;
    setIsSelecting(false);

    const containerBounds = containerRef.current.getBoundingClientRect();
    const selectionRight = containerBounds.left + selectionBox.x + selectionBox.width;
    const selectionBottom = containerBounds.top + selectionBox.y + selectionBox.height;

    if (selectionMode === 1) {
      if (!isSelecting || !containerRef.current) return;
      setIsSelecting(false);
    
      // Constants
      const boxWidth = 30.5; // Fixed width of each box
      const boxHeight = 36.5; // Assuming a fixed height for simplicity
    
      const selectionStartX = selectionBox.x;
      const selectionEndX = selectionStartX + selectionBox.width;
      const selectionStartY = selectionBox.y;
      const selectionEndY = selectionStartY + selectionBox.height;
    
      // Calculate column offsets dynamically based on actual column widths
      let columnOffsets = columnWidths.reduce((acc:any, curr, idx) => {
        if (idx === 0) {
          acc.push(0);
        } else {
          acc.push(acc[idx - 1] + columnWidths[idx - 1]);
        }
        return acc;
      }, []);
      let customBoxIndexSelected;
      let setIndexSelected;
    
      let updatedSelectedBoxes = selectedBoxes.map((customBox, customBoxIndex) => 
        customBox.map((set, setIndex) => 
          set.map((isSelected, boxIndex) => {
            const columnOffset = columnOffsets[customBoxIndex];
            const dynamicBoxCount = Math.floor(columnWidths[customBoxIndex] / boxWidth); 
           
             
            // Calculate boxes per column dynamically
            //if (boxIndex >= dynamicBoxCount) return false; // Ignore boxes that exceed the dynamic count
    
            const boxStartX = columnOffset + (boxIndex * (columnWidths[customBoxIndex]/dynamicBoxCount  )) +((rowDistance * 5 || 2)* customBoxIndex) ;
            const boxEndX = boxStartX + (columnWidths[customBoxIndex]/dynamicBoxCount  );
            const boxTopY = (setIndex) * (boxHeight +(colDistance * 5 || 2)) ; // Adjust if your layout differs
            const boxBottomY = boxTopY + (boxHeight  );
    
            // Check if the box intersects with the selection area
            const intersectsSelection = !(
              boxEndX < selectionStartX ||
              boxStartX > selectionEndX ||
              boxBottomY < selectionStartY ||
              boxTopY > selectionEndY
            );
              if(intersectsSelection  ){
              
                customBoxIndexSelected =customBoxIndex
                setIndexSelected =setIndex
             
              }
            return isSelected || intersectsSelection;
          })
        )
      );
      if(customBoxIndexSelected != undefined && setIndexSelected  != undefined ) {
          let targetRow = updatedSelectedBoxes;
          
          for (let i = 0; i < updatedSelectedBoxes[customBoxIndexSelected][setIndexSelected].length; i++) {

            targetRow[customBoxIndexSelected][setIndexSelected][i] = true;
          }

          setSelectedBoxes([...targetRow])
    
    }
   
    
      //setSelectedBoxes(updatedSelectedBoxes);
    } else if (selectionMode === 2) {
      let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

      containerRef.current.querySelectorAll(`.${classes.whiteBox}`).forEach(box => {
        const boxBounds = box.getBoundingClientRect();

        const intersectsSelection = !(boxBounds.right < containerBounds.left + selectionBox.x ||
          boxBounds.left > selectionRight ||
          boxBounds.bottom < containerBounds.top + selectionBox.y ||
          boxBounds.top > selectionBottom);

        if (intersectsSelection) {
          minX = Math.min(minX, boxBounds.left - containerBounds.left);
          maxX = Math.max(maxX, boxBounds.right - containerBounds.left);
          minY = Math.min(minY, boxBounds.top - containerBounds.top);
          maxY = Math.max(maxY, boxBounds.bottom - containerBounds.top);
        }
      });

      if (minX < Infinity && minY < Infinity && maxX > -Infinity && maxY > -Infinity) {
        setMergedBoxes((prevMergedBoxes) => [
          ...prevMergedBoxes,
          {
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY,
          },
        ]);
      } else {
        setMergedBox(null);
      }
    }
  };
 


 
  useEffect(() => {
    setPinkSquares(prevPinkSquares => {
      return squares.map((square, index) => {
        console.log("existingPinkSquare",square)

        // Find the corresponding pink square in the current state
        const existingPinkSquare = prevPinkSquares[index];
        return {
          ...square,
          // Use the current positions if the pink square exists, otherwise use initial positions
          x: existingPinkSquare ? existingPinkSquare.x : 50,
          y: existingPinkSquare ? existingPinkSquare.y : 50,
        };
      });
    })
   // saveToJson("LedScreens",pinkSquares)

  }, [squares,setPinkSquares]);
  useEffect(() => {
    setYellowSquares(prevYellowSquares => {
      return squaresYellow.map((square, index) => {
        // Find the corresponding yellow square in the current state
        const existingYellowSquare = prevYellowSquares[index];
        return {
          ...square,
          x: existingYellowSquare ? existingYellowSquare.x : 100,
          y: existingYellowSquare ? existingYellowSquare.y : 100,
        };
      });
    });
   // saveToJson("MarketingList",squaresYellow)

  }, [squaresYellow,setYellowSquares]);
  const handlePinkSquareMouseDown = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    event.stopPropagation();
    event.preventDefault(); // Prevent default to avoid text selection, etc.
  
    const pinkSquare = pinkSquares[index];
    const containerBounds = containerRef.current?.getBoundingClientRect();
    if (!containerBounds) return;
  
    // Calculate the offset inside the pink square where the mouse was clicked.
    const offsetX = event.clientX - pinkSquare.x - containerBounds.left;
    const offsetY = event.clientY - pinkSquare.y - containerBounds.top;
  
    const handleMouseMove = (moveEvent: MouseEvent) => {
      if (!containerRef.current) return;
  
      const containerBounds = containerRef.current.getBoundingClientRect();
  
      // Adjust the new position based on the mouse movement and initial click offset.
      // Substract container's left/top to convert to relative container coordinates.
      let newX = moveEvent.clientX - offsetX - containerBounds.left + window.scrollX; // Adjust for horizontal scroll
      let newY = moveEvent.clientY - offsetY - containerBounds.top + window.scrollY; // Adjust for vertical scroll
  
      // Check boundaries: Ensure the pink square doesn't go outside the container.
      // newX = Math.max(0, Math.min(newX, containerBounds.width - pinkSquare.width));
      // newY = Math.max(0, Math.min(newY, containerBounds.height - pinkSquare.height));
  
      // Update the specific pink square's position.
      setPinkSquares(prev => prev.map((square, idx) => idx === index ? {
        ...square,
        x: newX,
        y: newY,
      } : square));
    };
    
  
    const handleMouseUp = () => {
      // Remove event listeners when the mouse is released.
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  
    // Add event listeners to the document for mouse move and mouse up.
    // This allows the drag to continue even if the mouse leaves the pink square or container.
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };
  const handleYellowSquareMouseDown  = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    event.stopPropagation();
    event.preventDefault(); // Prevent default to avoid text selection, etc.
  
    const yellowSquare = yellowSquares[index];
    const containerBounds = containerRef.current?.getBoundingClientRect();
    if (!containerBounds) return;
  
    // Calculate the offset inside the pink square where the mouse was clicked.
    const offsetX = event.clientX - yellowSquare.x - containerBounds.left;
    const offsetY = event.clientY - yellowSquare.y - containerBounds.top;
  
    const handleMouseMove = (moveEvent: MouseEvent) => {
      if (!containerRef.current) return;
  
      const containerBounds = containerRef.current.getBoundingClientRect();
  
      // Adjust the new position based on the mouse movement and initial click offset.
      // Substract container's left/top to convert to relative container coordinates.
      let newX = moveEvent.clientX - offsetX - containerBounds.left + window.scrollX; // Adjust for horizontal scroll
      let newY = moveEvent.clientY - offsetY - containerBounds.top + window.scrollY; // Adjust for vertical scroll
  
      // Check boundaries: Ensure the pink square doesn't go outside the container.
      // newX = Math.max(0, Math.min(newX, containerBounds.width - yellowSquare.width));
      // newY = Math.max(0, Math.min(newY, containerBounds.height - yellowSquare.height));
  
      // Update the specific pink square's position.
      
      setYellowSquares(prev => prev.map((square, idx) => idx === index ? {
        ...square,
        x: newX,
        y: newY,
      } : square));
    };
     // Function to handle removing a pink square
 
  
    const handleMouseUp = () => {
      // Remove event listeners when the mouse is released.
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  
    // Add event listeners to the document for mouse move and mouse up.
    // This allows the drag to continue even if the mouse leaves the pink square or container.
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };
  
  const removePinkSquare = async (index: number) => {
    try {
      const marketingMaterialIds = pinkSquares.filter((_, i) => i !== index).map((m, index) => ({
        id: (m as any).id?.toString() || '',
        x: m.x,
        y: m.y
      }));
      id && await updateLedScreens((id ), marketingMaterialIds as any); 
      setPinkSquares(prev => prev.filter((_, i) => i !== index));
    //  saveToJson("MarketingList",yellowSquares)
    } catch (error) {
      console.error('Failed to update marketing materials:', error);
    }
   
  };

  // Function to handle removing a yellow square
  const removeYellowSquare = async (index: number) => {
    try {
      const marketingMaterialIds = yellowSquares.filter((_, i) => i !== index).map((m, index) => ({
        id: (m as any).id?.toString() || '',
        x: m.x,
        y: m.y
      }));
      id &&  await updateMarketingMaterials((id ), marketingMaterialIds as any); 
      setYellowSquares(prev => prev.filter((_, i) => i !== index));
     // saveToJson("MarketingList",yellowSquares)
    } catch (error) {
      console.error('Failed to update marketing materials:', error);
    }
   
    
  };
 
  const renderBoxes = (customBoxIndex:number, setIndex:number, totalWidth:number) => {
    // Fixed box width
    const boxWidth = 30.5; // Fixed width of each box
  
    // Calculate the dynamic number of boxes that can fit in the totalWidth of the column
    const dynamicBoxCount = Math.floor(totalWidth / boxWidth);
  
    return Array.from({ length: dynamicBoxCount }, (_, boxIndex) => (
      <div
        key={`${customBoxIndex}-${setIndex}-${boxIndex}`}
        className={`${classes.whiteBox} ${selectedBoxesPmi[customBoxIndex]?.[setIndex]?.[boxIndex] ? classes.selectedBox : ""} ${
          selectedBoxes[customBoxIndex]?.[setIndex]?.[boxIndex]
            ? classes.whiteBoxSelected
            : ''
        }`}
        style={{ width: `${boxWidth}px` }} 
        onClick={() => {
          if (selectionMode === 5) {
            const updatedShelfDuplex = [...shelfDuplex];
            updatedShelfDuplex[customBoxIndex][setIndex][boxIndex] = !updatedShelfDuplex[customBoxIndex][setIndex][boxIndex];
            console.log('updatedShelfDuplex',updatedShelfDuplex)
            setShelfDuplex(updatedShelfDuplex);
          }
        }}
        // Use fixed width
      >

{shelfDuplex[customBoxIndex]?.[setIndex]?.[boxIndex] && (
          <>
            <div style={{ width: '100%', height: '20%', backgroundColor: '#9747FF',borderRadius: '2px'
}} />
            <div style={{ width: '100%', height: '20%', backgroundColor: '#9747FF',borderRadius: '2px' ,marginBottom:'2px'}} />
            <div style={{ width: '100%', height: '20%', backgroundColor: '#9747FF',borderRadius: '2px' }} />
            <div style={{ width: '100%', height: '20%', backgroundColor: '#9747FF' ,borderRadius: '2px'}} />
          </>
        )}
      </div>
    ));
  };
console.log('yellowSquares display',yellowSquares)
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#F5F9FF',
      }}
    >
      <div style={{height:"100vh", display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',}}>
      <div
        ref={containerRef}
        style={{
          display: 'flex',
          //flexDirection: 'column',
          gap:(rowDistance * 5 || 2),
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          cursor: isSelecting ? 'pointer' : 'default',
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
     {pinkSquares.map((square, index) => (
        <div
          key={index}
          className={classes.pinkSquare}
          style={{
            width: square.width * 5,
            height: square.height * 5 ,
            left: square.x,
            top: square.y,
            position: 'absolute',
          }}
          onMouseDown={(e) => handlePinkSquareMouseDown(e, index)}
        >
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => removePinkSquare(index)}
          />
        </div>
      ))}
{yellowSquares.map((square, index) => (
        <div
          key={index}
          className={classes.yellowSquare}
          style={{
            width: square.width * 5,
            height: square.height * 5,
            left: square.x,
            top: square.y,
            position: 'absolute',
          }}
          onMouseDown={(e) => handleYellowSquareMouseDown(e, index)}
        >
          <img  src={(square as any).image} width={square.width * 5} height={square.height * 5} style={{objectFit:'contain'}}>

          </img>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => removeYellowSquare(index)}
          />
        </div>
      ))}
      {blueSquares.map((square, index) => (
        <div
          key={index}
          className={classes.blueSquare}
          style={{
            width: square.width,
            height: square.height,
            left: square.x,
            top: square.y,
            position: 'absolute',
          }}
        >
        <div>
              <Viewer id={(square as any).id} rowSpace={colDistance * 5 }></Viewer>
              </div>
        </div>
      ))}
        {isSelecting && (
          <div
            className={classes.selectionBox}
            style={{
              left: selectionBox.x,
              top: selectionBox.y,
              width: selectionBox.width,
              height: selectionBox.height,
            }}
          />
        )}
         {mergedBoxes.map((box, index) => (
        <div
          key={index}
          className={classes.mergedBox}
          style={{
            left: box.x,
            top: box.y,
            width: box.width,
            height: box.height,
          }}
        />
      ))}
        {Array.from({ length:  col }).map((_, customBoxIndex) => (
       <div style={{display:"flex",flexDirection:'column', justifyContent:"center",alignItems:"center",gap:(rowDistance * 5 || 2)}}>
       <div key={customBoxIndex} className={classes.customBox} style={{gap: (colDistance * 5 || 2),width: `${columnWidths[customBoxIndex ]}px` }}>
       
       {Array.from({ length: row }).map((_, setIndex) => (
            <div
              key={`${customBoxIndex}-${setIndex}`}
              className={classes.subBox}
              style={{ width: `${columnWidths[customBoxIndex ]}px`,visibility:hiddencells[setIndex]&& hiddencells[setIndex][customBoxIndex] ? 'unset':'hidden' }} 
              // Dynamically set width
            >
             { selectedBoxes[customBoxIndex]?.[setIndex]?.[0] &&
              <CloseIcon
            className={classes.closeIcon}
            onClick={() => { 
              
              if (selectedBoxes.length > customBoxIndex && selectedBoxes[customBoxIndex].length > setIndex) {
                let targetRow = selectedBoxes;
                for (let i = 0; i < selectedBoxes[customBoxIndex][setIndex].length; i++) {
                  targetRow[customBoxIndex][setIndex][i] = false;
                }
                setSelectedBoxes([...targetRow])
            } 
            }}
          /> }
              {renderBoxes(customBoxIndex, setIndex,columnWidths[customBoxIndex ])}
            </div>
          ))}
        </div>
        

       </div> 
      ))}
      </div>
      </div>
      
    </div>
  );
};

export default GridDemo;
