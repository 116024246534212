import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  label: string;
  onValueSelected(values: any[]): void; // Updated to pass an array of selected values
  configurations: any[];
  initialSelectedValues: number[];
  loading:boolean

}

const Modal: React.FC<ModalProps> = ({
  open,
  handleClose,
  label,
  onValueSelected,
  configurations,
  initialSelectedValues,
  loading
}) => {
  const [selectedValues, setSelectedValues] = useState<number[]>([]); // Updated to handle multiple selections

  useEffect(() => {
    setSelectedValues(initialSelectedValues); // Initialize selected values
  }, [initialSelectedValues]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const isChecked = event.target.checked;
    setSelectedValues((prev) =>
      isChecked ? [...prev, id] : prev.filter((value) => value !== id)
    );
  };

  const handleSave = () => {
    const selectedConfigurations = configurations.filter((config) =>
      selectedValues.includes(config.id)
    );
    onValueSelected(selectedConfigurations); // Pass all selected configurations
    handleClose();
  };
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // Select all
      const allIds = configurations.map((config) => config.id);
      setSelectedValues(allIds);
    } else {
      // Deselect all
      setSelectedValues([]);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "570px",
          borderRadius: "15px !important",
          paddingBottom: "0",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4"> Updating {configurations?.length} Planograms</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography
            variant="h6"
            sx={{ marginBottom: "12px", color: "#9CA0AB" }}
          >
         <div style={{marginBottom:'20px'}}> A change to the customer-specific list for this Key Account affects X planograms that were manually modified: </div>  
<span>
  <div> <strong>Select:</strong>  The planograms will be reset to the updated customer specific priority list. </div>
 <strong>Leave Unselected:</strong> Manual updates will be needed later.
</span>


          </Typography>
        </DialogContentText>
        {false ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <div style={{ maxHeight: "600px", overflow: "auto", padding: "8px" }}>
                        <FormControlLabel
              control={
                <Checkbox
                  checked={selectedValues.length === configurations.length && configurations.length > 0} // Check if all are selected
                  indeterminate={
                    selectedValues.length > 0 &&
                    selectedValues.length < configurations.length
                  } // Indeterminate state when some are selected
                  onChange={handleSelectAll}
                />
              }
              label={<div style={{fontSize:'14px',color:'#383A49',fontWeight:'400'}}>
                All Selected
              </div>}
              sx={{
                marginBottom: "10px",
                background: "#F5F9FF",
                width:'100%',
                fontSize:'14px',
                padding:0,
                 paddingRight:0,
                 paddingLeft:0,
                borderRadius: "4px",
              }}
            />

            <FormGroup>
              {configurations.map((option) => {
                return (
                  option.name &&
                  option.name !== "null" && (
                    <FormControlLabel
                      key={option.id}
                      control={
                        <Checkbox
                          checked={selectedValues.includes(option.id)} // Check if the option is selected
                          onChange={(event) => handleChange(event, option.id)}
                        />
                      }
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#383A49",
                              }}
                            >
                              {option.name ? option.name : "Draft " + option.id}{" "}
                              <span style={{ color: "#0179FF" }}>{` `}</span>
                            </Typography>
                           
                          </div>
                          {/* <div>
                            <Link
                              href="#"
                              underline="hover"
                              sx={{
                                marginRight: "8px",
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#0179FF",
                              }}
                            >
                              {option.store_count} stores
                            </Link>
                          </div> */}
                        </div>
                      }
                      sx={{
                        height: "42px",
                        width: "100%",
                        background: "#F5F9FF",
                        marginBottom: "1px",
                        "& .MuiTypography-root": { flex: 1 },
                        "&:hover": { backgroundColor: "#e1e5ea" },
                      }}
                    />
                  )
                );
              })}
            </FormGroup>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <div style={{ display: "flex", width: "100%", gap: "8px" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClose}
            sx={{
              marginBottom: "10px !important",
              background: "rgba(217, 231, 248, 0.50)",
              justifyContent: "center",
              color: "#383A49",
              flexBasis: "33%",
              "&:hover": { color: "black" },
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
            sx={{
              marginBottom: "10px !important",
              "background-color": "#0179FF",
              color: "white",
              flexGrow: 1,
              justifyContent: "center",
              "&:hover": {
                color: "white",
                backgroundColor: "#0179FF !important",
                opacity: 0.8,
              },
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Apply Changes"}
            </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
