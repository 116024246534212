import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Select, { components, InputActionMeta, OptionsOrGroups, StylesConfig } from 'react-select';
import {
  Box,
  Button,
} from "@mui/material";
import {
  Add as AddIcon,
} from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';
import BrandModal from '../ModalComponent/Modal';
import { createBrand } from "../../../services/brandService";
import { createVariant } from "../../../services/variantService";
import { createPackType } from "../../../services/packTypeService";
import { createSize } from "../../../services/sizeService";

interface OptionType {
  label: string;
  value: string;
}

interface DropdownProps {
  label: string;
  options: OptionsOrGroups<OptionType, any>;
  notEditable?: boolean;
  onChange: (option: OptionType) => void;
  selectedOption: OptionType | null;
  onOptionAdded: (option: OptionType) => void;
}

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100% !important",
    backgroundColor: "white !important",
    boxShadow: theme.shadows[2],
    display: "flex !important",
    flexDirection: "column",
    alignItems: "center !important",
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  logo: {
    maxWidth: "100%",
    maxHeight: "44px",
    marginBottom: theme.spacing(2),
    marginTop: "40px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    marginBottom: "10px",
  },
  iconButton: {
    width: 34,
    height: 34,
    marginRight: theme.spacing(1),
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    color: "#0179FF",
  },
}), { name: 'SideBarStyles' });

const Dropdown: React.FC<DropdownProps> = ({ label, options, notEditable = false, onChange, selectedOption, onOptionAdded }) => {
  const [internalSelectedOption, setInternalSelectedOption] = useState<OptionType | null>(selectedOption);
  const classes = useStyles();

  useEffect(() => {
    setInternalSelectedOption(selectedOption);
  }, [selectedOption]);

  const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    console.log('Input Changed', newValue, actionMeta.action);
  };

  const handleChange = (option: OptionType | null) => {
    setInternalSelectedOption(option);
    option && onChange(option);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const getServiceFunction = () => {
    switch (label) {
      case 'Brand':
        return createBrand;
      case 'Variant':
        return createVariant;
      case 'Pack Type':
        return createPackType;
      case 'Size':
        return createSize;
      default:
        return createBrand;
    }
  };

  const MenuList = (props: any) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        {!notEditable && <Button
          color="primary"
          variant="contained"
          onClick={handleOpen}
          sx={{
         
            marginBottom: "10px !important", "background-color": "#F2F8FF", "color": "#0179FF", width: "100%", justifyContent: "center",
            "&:hover": {
              color: "black", // Replace with your desired color
            }
          }}
          startIcon={
            <Box component="span" className={classes.iconButton}>
              <AddIcon />
            </Box>
          }
        >
          Add New
        </Button>}
      </components.MenuList>
    );
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="7" viewBox="0 0 9 7" fill="none">
          <path d="M5.22929 5.72267C4.83426 6.14372 4.16574 6.14373 3.77072 5.72267L0.450949 2.18421C-0.148157 1.54564 0.304619 0.500001 1.18024 0.500001L7.81976 0.5C8.69538 0.5 9.14815 1.54564 8.54905 2.18421L5.22929 5.72267Z" fill="#9CA0AB" />
        </svg>
      </components.DropdownIndicator>
    );
  };

  const CustomOption = (props: any) => {
    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",textTransform:'capitalize',   zIndex:1, }}>
          {props.label}
          {props.isSelected && (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '8px' }}>
              <path d="M5.75 11.5L10.25 16L18.25 8" stroke="#0179FF" strokeWidth="2" />
            </svg>
          )}
        </div>

      </components.Option>
    );
  }

  const customStyles: StylesConfig<OptionType, false> = {
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: (base: any, state) => ({
      ...base,
      height: '47px',
      minHeight: '50px',
      width: '100%',
      borderRadius: "10px",
      textTransform:'capitalize',

    }),
    menuList: (base: any) => ({
      ...base,
      boxShadow: "0px 4px 30px 0px rgba(35, 40, 59, 0.10)",
      backgroundColor: 'white',
      borderRadius: '8px',
      border: '1px solid white',
      padding: "12px"
    }),
    menu: (base: any) => ({
      ...base,
      boxShadow: "0px 4px 30px 0px rgba(35, 40, 59, 0.10)",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: 'white',
      color: state.isSelected ? '#0179FF' : '#222',
      fontSize: "14px",
      textTransform:'capitalize',
      fontWeight: state.isSelected ? 700 : 400,
      fontFamily: "Mulish",
      "&:hover": {
        cursor: "pointer"
      }
    }),
  };

  return (
    <div style={{ width: "100%" }}>
      <BrandModal open={openModal} handleClose={handleClose} label={label} addService={getServiceFunction()} onOptionAdded={onOptionAdded} />

      <label htmlFor="react-select-dropdown">
        <Typography variant='h2' style={{ marginBottom: "8px",textTransform:'capitalize' }}>
          {label}
        </Typography>
      </label>
      <Select
        inputId="react-select-dropdown"
        classNamePrefix="react-select"
        placeholder="Search"
        components={{ MenuList: MenuList, DropdownIndicator, Option: CustomOption }}
        value={internalSelectedOption}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        menuPlacement="auto"
        isSearchable
        styles={customStyles}
      />
    </div>
  );
};

export default Dropdown;
