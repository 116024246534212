import React from "react";
import { Box, Button, IconButton } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Logo from "../../../assets/images/Logo1.png";
import { ReactComponent as SKUS } from "../../../assets/icons/SKUS.svg";
import { ReactComponent as Stores } from "../../../assets/icons/Stores.svg";
import { ReactComponent as Account } from "../../../assets/icons/Account.svg";
import { ReactComponent as Back } from "../../../assets/icons/Back.svg";

import { useStyles } from "./SideBarStyles";
import Input from "../Input/Input";
import { ReactComponent as Col } from "../../../assets/icons/Col.svg";
import { ReactComponent as Row } from "../../../assets/icons/Row.svg";
import { ReactComponent as Width } from "../../../assets/icons/Width.svg";
import { ReactComponent as Height } from "../../../assets/icons/Height.svg";
import { useNavigate } from 'react-router-dom';

import Typography from "@mui/material/Typography";

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.container}>
      <img src={Logo} alt="Logo" className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
          STEP 5/10
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "12px" }}>
          New Shelf Composition
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "50px" }}>
          Add a new shelf composition
          </Typography>
          <Input
            onChange={() => {}}
            label="POS Name"
            placeholder={"Enter your POS name"}
            value=""
          />
         <Input
            onChange={() => {}}
            label="Retailer Name"
            placeholder={"Enter your retailer name"}
            value=""
          />
        </div>
        <div>
      
          <div style={{ display: "flex", alignItems: "center" }}>
       
       <IconButton
         // color="primary"
         // variant="contained"
         // sx={{marginBottom:"10px !important", padding:"0 !important", margin:"0 !important",background:"transparent", '&:hover':{ background:"transparent !important",boxShadow:"none"}}}
         onClick={() => {navigate('/step3')}}
         size="large">
         <Box component="span" className={classes.iconButton}>
           <Back />
         </Box>
       </IconButton>
       <Button variant="contained" color="info" onClick={() => {navigate('/step5')}} sx={{marginTop:"0px"}}>
         Next
       </Button>
     </div>
        </div>
        
      </div>
    </Box>
  );
};

export default Sidebar;
