import React, { useState } from "react";
import { TextField, Grid, Paper, IconButton, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Typography from "@mui/material/Typography";

import { Box, Button } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { Remove as RemoveIcon } from "@mui/icons-material";
import { wrap } from "module";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      // marginTop: theme.spacing(2),
      margin: "-5px", // Adjust this value as needed
      width: "auto",
      "& > .MuiGrid-item": {
        padding: "5px", // Adjust this value as needed
      },
    },
    flexContainer: {
      display: "flex",
      margin: `2px`,
    },
    flexItem: {
      padding: `2px 3px`,
    },
    square: {
      width: 180,
      height: 30,
      backgroundColor: "#B5D8FF",
      boxShadow: "none",
    },
    controlBar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(2),
      backgroundColor: "#D9E7F8",
      width: "100%",
      borderRadius: "3px",

      // alignSelf: "start",
    },
    verticalControlBar: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "0 34px",
      backgroundColor: "#D9E7F8",

      borderRadius: "3px",
      alignItems: "center",
      minWidth: "82px",
      marginLeft: theme.spacing(2),
      height: "100%", // Make the vertical bar take the height of the squares
    },
    iconButton: {
      width: 28,
      height: 28,
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      color: "#0179FF",
    },
    iconButton2: {
      padding: 6, // Set padding to 0 to remove the default padding
    },
  })
);

interface GridDemoProps {
  row: number;
  col: number;

  setRow: React.Dispatch<React.SetStateAction<number>>;
  setCol: React.Dispatch<React.SetStateAction<number>>;
}

const GridDemo: React.FC<GridDemoProps> = ({
  row,
  col,
  setRow,
  setCol,
}) => {
  const classes = useStyles();

  const handleAddRow = () => {
    setRow(row + 1);

let value = row + 1
    if(value > 12) {
      setRow(12)
    }else if(value < 1) {
      setRow(1)

    }else {
      setRow(value) 
    }
  };

  const handleRemoveRow = () => {
    

    let value = row - 1
    if(value > 12) {
      setRow(12)
    }else if(value < 1) {
      setRow(1)

    }else {
      setRow(value) 
    }

  };

  const handleAddColumn = () => {
    
    if((col + 1) > 4) {
      setCol(4)
    }else if((col + 1) < 1) {
      setCol(1)

    }else {
      setCol(col + 1)
    }
  };

  const handleRemoveColumn = () => {
    if (col > 1) {
      setCol(col - 1);
    }

    if((col - 1) > 4) {
      setCol(4)
    }else if((col - 1) < 1) {
      setCol(1)

    }else {
      setCol(col - 1)
    }
  };

  const renderSquares = () => {
    const rowsArray = [];
    for (let i = 0; i < row; i++) {
      const rowSquares = [];
      for (let j = 0; j < col; j++) {
        rowSquares.push(
          <div
            key={`${i}-${j}`}
            style={{ padding: `2px 2px`  }}
          >
            <Paper className={classes.square}></Paper>
          </div>
        );
      }
      rowsArray.push(
        <div key={i} className={classes.flexContainer}>
          {rowSquares}
        </div>
      );
    }
    return rowsArray;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F5F9FF",
      }}
    >
      <div
        style={{
          backgroundColor: "#F5F9FF",
          height: "100vh",
          display: "flex",
          width: "fit-content",
          padding:"0 10px",
          overflow :"auto",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        <div
          className={classes.controlBar}
          style={{
            width: `calc(180px * ${col} + ${
              2 * ((col - 1) * 2)
            }px  )`,
            marginLeft: `2px`,
          }}
        >
          <IconButton className={classes.iconButton2} onClick={handleAddRow} size="large">
            <Box component="span" className={classes.iconButton}>
              <AddIcon />
            </Box>
          </IconButton>
          <Typography variant="h3">  Rows  {row} </Typography>
          <IconButton onClick={handleRemoveRow} className={classes.iconButton2} size="large">
            <Box component="span" className={classes.iconButton}>
              <RemoveIcon />
            </Box>
          </IconButton>
        </div>
        <div style={{ display: "flex" }}>
          <div>{renderSquares()}</div>
        
        </div>
      </div>
    </div>
  );
};

export default GridDemo;
