import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, IconButton } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Logo from "../../../assets/images/Logo1.png";
import { ReactComponent as Back } from "../../../assets/icons/Back.svg";
import { ReactComponent as Export } from "../../../assets/icons/export.svg";
import { ReactComponent as Done } from "../../../assets/icons/done.svg";
import { ReactComponent as Reset } from "../../../assets/icons/Reset.svg";
import { ReactComponent as Bin } from "../../../assets/icons/Bin.svg";
import { useStyles } from "./SideBarStyles";
import { Switch, SwitchProps, styled, Snackbar, Alert } from "@mui/material";

import { useNavigate, useParams } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import SearchInput from "../SearchInput/SearchInput";
import HNBProductList from "../HNBProductList/HNBProductList";
import SideBarRight from "../../SKUList/AddSkuModal/SideBarRight";
import { getSkusByCategory, searchSkusByBrandVariantAndCategory } from "../../../services/skuService"; // Adjust the import path based on your project structure
import BrandModal from "../ModalComponent/Modal";
import BrandModalOptions from "../ModalComponentOptions/Modal";

import { updateIsAutoConfiguration, updateShelfConfigName } from "../../../services/shelfConfigurationService";

interface SidebarProps {
  setSelectedProductCardImage: React.Dispatch<React.SetStateAction<string>>;
  name: string | null;
  exportToPDF: () => void;
  exportToXSLX: () => void;

  isLoading: boolean;
  question:any[];
  question2:any[]; // Add this prop
  onResetConfiguration?: () => void;
  isAutoConfiguration?:boolean;  // New prop for reset function
  setIsAutoConfiguration: React.Dispatch<React.SetStateAction<boolean>>
  isDrag?:boolean;  // New prop for reset function
  setIsDrag: React.Dispatch<React.SetStateAction<boolean>>
  exportOptions: { compositionName: boolean; eanCode: boolean; validityDate: boolean };
  setExportOptions: React.Dispatch<React.SetStateAction<{ compositionName: boolean; eanCode: boolean; validityDate: boolean }>>;

}

const Sidebar: React.FC<SidebarProps> = ({ exportOptions,setExportOptions,setSelectedProductCardImage,setIsAutoConfiguration,isDrag,setIsDrag, name,exportToPDF , exportToXSLX,onResetConfiguration ,isLoading,question,question2,isAutoConfiguration=false
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModaOptionsl, setOpenModalOptions] = useState(false);

  const [isContinue, setIsContinue] = useState(false);
  const [materials, setMaterials] = useState<{ imgSrc: string, title: string }[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [autoConfig, setAutoConfig] = useState(isAutoConfiguration); 

  // State to track the switch
useEffect(()=>{
  setAutoConfig(isAutoConfiguration)
},[isAutoConfiguration])
  const { id } = useParams<{ id: string }>();

  const fetchSkus = async (search = "") => {
    try {
      const skus = search
        ? await searchSkusByBrandVariantAndCategory("CC", search)
        : await getSkusByCategory("CC");
      const transformedMaterials = skus.map((sku: any) => ({
        imgSrc: sku.pack_image,
        id: sku.id,
        title: `${sku.brandName} ${sku.variantName}`
      }));
      setMaterials(transformedMaterials);
    } catch (error) {
      console.error("Failed to fetch SKUs:", error);
    }
  };

  useEffect(() => {
    fetchSkus();
  }, []);

  const handleModalSubmit = async (name: string) => {
    try {
      id && await updateShelfConfigName(id, name);
      setOpenModal(false);
      navigate('/');
    } catch (error) {
      console.error("Failed to update shelf configuration name:", error);
      // Optionally, handle the error, e.g., show a notification to the user
    }
  };
  const handleModalSubmitOptions = async (name: string) => {
    exportToXSLX()
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    fetchSkus(value);
  };
  const handleAutoConfigChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setAutoConfig(newValue);
    setIsAutoConfiguration(newValue)

    try {
      id && await updateIsAutoConfiguration(id, newValue); // Call the API to update the value
    } catch (error) {
      console.error("Failed to update isAutoConfiguration:", error);
      // Optionally, handle the error, e.g., revert the switch state
      setAutoConfig(!newValue);
      setIsAutoConfiguration(newValue)
    }
  };
  const handleIsDrag = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setIsDrag(newValue);
  
  };
  return (
    <Box className={classes.container}>
      <SideBarRight isOpen={isOpen} setIsOpen={setIsOpen}></SideBarRight>
      <img src={Logo} alt="Logo" className={classes.logo} />
      {isContinue ? (
        <div className={classes.buttonContainer}>
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography variant="body2" sx={{ marginBottom: "12px" }}>
              STEP 9/9
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: "12px" }}>
              You are good to go!
            </Typography>
            <Typography variant="h6" sx={{ marginBottom: "10px" }}>
              Your shelf configuration is ready!
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Done />
          </div>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                color="primary"
                variant="contained"
                sx={{
                  marginBottom: "10px !important",
                  "background-color": "#0179FF",
                  "color": "white",
                  width: "100%",
                  justifyContent: "center",
                  height: "56px",
                  marginTop: "12px !important",
                  fontSize: "16px",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#0179FF !important"
                  }
                }}
                onClick={()=>{exportToPDF()}}

                startIcon={
                  <Box component="span" className={classes.iconButton3}>
                    <Export />
                  </Box>
                }
                disabled={isLoading} 
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Export as PDF"
                )}
              </Button>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                color="primary"
                variant="contained"
                sx={{
                  marginBottom: "10px !important",
                  "background-color": "#0179FF",
                  "color": "white",
                  width: "100%",
                  justifyContent: "center",
                  height: "56px",
                  marginTop: "12px !important",
                  fontSize: "16px",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#0179FF !important"
                  }
                }}
                onClick={()=>{setOpenModalOptions(true)}}

                startIcon={
                  <Box component="span" className={classes.iconButton3}>
                    <Export />
                  </Box>
                }
                disabled={isLoading} 
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Export as XSLX"
                )}
              </Button>
            </div>
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => { setOpenModal(true) }}
                sx={{
                  marginBottom: "10px !important",
                  "background-color": "#ECF3FB",
                  "color": "#383A49",
                  width: "100%",
                  justifyContent: "center",
                  height: "56px",
                  marginTop: "12px !important",
                  fontSize: "16px",
                  "&:hover": {
                    color: "#383A49",
                    backgroundColor: "#ECF3FB !important"
                  }
                }}
              >
                Save & Exit
              </Button>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => { navigate('/') }}
                sx={{
                  marginBottom: "10px !important",
                  "background-color": "#ECF3FB",
                  "color": "#383A49",
                  width: "100%",
                  justifyContent: "center",
                  height: "56px",
                  marginTop: "12px !important",
                  fontSize: "16px",
                  "&:hover": {
                    color: "#383A49",
                    backgroundColor: "#ECF3FB !important"
                  }
                }}
              >
                Save as draft
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.buttonContainer}>
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography variant="body2" sx={{ marginBottom: "12px" }}>
              STEP 8/9
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: "12px" }}>
              Shelf Configuration
            </Typography>
            <Typography variant="h6" sx={{ marginBottom: "10px" }}>
              Choose which pack to add to PMI area
            </Typography>
            <div
              style={{
                backgroundColor: "#F2F8FF",
                maxWidth: "316px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                borderRadius: "10px",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => { onResetConfiguration && onResetConfiguration(); }}  // Call the reset function
                sx={{
                  marginBottom: "10px !important",
                  "background-color": "white",
                  color: "#0179FF",
                  width: "100%",
                  maxWidth: "261px",
                  justifyContent: "center",
                  height: "32px",
                  marginTop: "12px !important",
                  "&:hover": {
                    color: "black", // Replace with your desired color
                  },
                }}
                startIcon={
                  <Box component="span" className={classes.iconButton3}>
                    <Reset />
                  </Box>
                }
              >
                Reset Configuration
              </Button>
              
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                paddingBottom: "10px",

              }}>
              <div style={{
                  backgroundColor: "white",
                  borderRadius:'83px'
,                 height:'32px',
fontSize:'14px',
fontWeight:'700',
                  color: "#0179FF",
                  width: "100%",
                  maxWidth: "261px",
                  justifyContent: "space-between",
                  marginTop: "12px !important",
                  display: 'flex',
                  alignItems: 'center',
                  gap: "10px",
                  padding:' 0 1px'
                }}>
               <div style={{marginLeft:'6px'}}>
               Clear Auto Configuration  

               </div>
               <IOSSwitch
                    checked={autoConfig}
                    onChange={handleAutoConfigChange}
                    sx={{ m: 1 }}
                  />
              </div>
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                paddingBottom: "10px",

              }}>
              <div style={{
                  backgroundColor: "white",
                  borderRadius:'83px'
,                 height:'32px',
fontSize:'14px',
fontWeight:'700',
                  color: "#0179FF",
                  width: "100%",
                  maxWidth: "261px",
                  justifyContent: "space-between",
                  marginTop: "12px !important",
                  display: 'flex',
                  alignItems: 'center',
                  gap: "10px",
                  padding:' 0 1px'
                }}>
               <div style={{marginLeft:'6px'}}>
               Enable Drag and Drop  

               </div>
               <IOSSwitch
                    checked={isDrag}
                    onChange={handleIsDrag}
                    sx={{ m: 1 }}
                  />
              </div>
              </div>
             
            </div>
            <SearchInput value={searchTerm} onValueChange={handleSearchChange} unit={""} onUnitChange={() => { }} label={"PRODUCT LIST"} />
            <HNBProductList
              materials={materials}
              setSelectedProductCardImage={setSelectedProductCardImage}
            />
             
            <Button
              color="primary"
              variant="contained"
              onClick={() => { setIsOpen(!isOpen) }}
              sx={{
                marginBottom: "10px !important",
                "background-color": "#F2F8FF",
                "color": "#0179FF",
                width: "100%",
                justifyContent: "center",
                height: "32px",
                marginTop: "12px !important",
                "&:hover": {
                  color: "black",
                }
              }}
              startIcon={
                <Box component="span" className={classes.iconButton}>
                  <AddIcon />
                </Box>
              }
            >
              Add
            </Button>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <IconButton
              onClick={() => {
                if(question.length === 0 && question2.length === 0 ){
                  navigate(`/step6/${id}`);
          
                  }else if (question.length > 0 && question2.length === 0  ){
                    navigate(`/step17-new/${id}`) 
          
                  }else {
                    navigate(`/step8/${id}`)
                  }
          
              
              
              
              }}
            >
              <Box component="span" className={classes.iconButton2}>
                <Back />
              </Box>
            </IconButton>
            <Button variant="contained" color="info" onClick={() => { 
              
              setIsDrag(false)
              setIsContinue(true) }} sx={{ marginTop: "0px" }}>
              Continue
            </Button>
          </div>
        </div>
      )}
      <BrandModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleSubmit={handleModalSubmit}
        label="Shelf Configuration"
        usedName={name==null ? '' : name}
      />
      <BrandModalOptions
        open={openModaOptionsl}
        setExportOptions={setExportOptions}
        exportOptions={exportOptions}
        handleClose={() => setOpenModalOptions(false)}
        handleSubmit={handleModalSubmitOptions}
        label="Shelf Configuration"
        usedName={name==null ? '' : name}
      />
    </Box>
  );
};

export default Sidebar;

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));