


import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import Logo from "../../../assets/images/Logo1.png";
import ButtonCheckbox from "../ButtonCheckBox/ButtonCheckBox";
import Dropdown from "../DropdownModal/Dropdown";
import { createRRPNewConfig, getRRPNewConfigById, updateRRPNewConfig } from "../../../services/rrpNewConfigurationService";

import {
  getAccountStoreEntry,
  getAllChannels,
  getAllKeyAccounts,
  getGroupedChains,
  getPosUniversesWithNotNullKeyAccount,
} from "../../../services/posuniversService";
import { ReactComponent as InAccount } from "../../../assets/icons/InAccount.svg";
import { ReactComponent as KeyS } from "../../../assets/icons/KeyS.svg";

import {
  createShelfConfig,
  getShelfConfigById,
  updateShelfConfig,
} from "../../../services/shelfConfigurationService";
import { useNavigate } from "react-router-dom";

interface BrandModalProps {
  open: boolean;
  handleClose: () => void;
  label: string;
  id?: number;
  groupedChains:any
  indId:number | undefined
}

interface OptionType {
  label: string;
  value: string;
}

const BrandModal: React.FC<BrandModalProps> = ({
  open,
  handleClose,
  label,
  id,
  groupedChains,
  indId
}) => {
  const [selectedChannel, setSelectedChannel] = useState<number | null>(null);
  const [accountsStoresData, setAccountsStoresData] = useState<any[]>([]);
  const [selectedKeyAccount, setSelectedKeyAccount] =
    useState<OptionType | null>(null);
  const [configData, setConfigData] = useState<any>(null);
  const [loading, setLoading] = useState(false); // Add loading state

  // Snackbar state
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "info" | "warning"
  >("success");
 
  // Mapping from ButtonCheckbox values to actual channel IDs
  const channelIdMap: { [key: number]: number } = {
    1: 3, // Food
    2: 4, // Kiosk
    3: 2, // Petrol
    4: 1, // Tobacco
  };
  const [isKeyAccountSelected, setIsKeyAccountSelected] = useState(false);
  const [isStoreSelected, setIsStoreSelected] = useState(false);

  useEffect(()=>{
    if(!id) {
      setAccountsStoresData([])
      setSelectedKeyAccount(null)
      setIsKeyAccountSelected(false)
      setIsStoreSelected(false)
      setConfigData(null)
    }
   
  },[open])
  const handleSelectChannel = (value: number | null) => {
    if (value !== null) {
      setSelectedChannel(value);
      setSelectedKeyAccount(null);
    } else {
      setSelectedKeyAccount(null);
      setSelectedChannel(null);
    }
  };
  const navigate = useNavigate();
  const handleContinue = async () => {
    console.log('selectedKeyAccount test',isStoreSelected)
    console.log('selectedKeyAccount test',indId)

    if ( isStoreSelected && indId) {
    }else if(!selectedKeyAccount) {
      setSnackbarMessage("You have to pick a key account to proceed");
      setSnackbarSeverity("info");
      setOpenSnackbar(true);
      return;
    }

    if (!id ) {

      try {
        const response = await createRRPNewConfig(   {
          key_account_id:  isStoreSelected ?indId : parseInt((selectedKeyAccount as any).value),
          col_position: 1,
        row_position: 2,
        marketingMaterials: [],
        ledScreens: [],
        squaresData: [],
        items: [],
        ccSpace: 8
        });
     
        const { data } = response;
        // Show success snackbar
        setSnackbarMessage("Shelf configuration created successfully");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);

        navigate(`/rrp/step1/${data.id}`);
        handleClose();
      } catch (err) {
        console.error(err);
        // Show error snackbar
        setSnackbarMessage("An error occurred during creation");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    } else  {
      try {
        await updateRRPNewConfig(id.toString(), {
          key_account_id: parseInt((selectedKeyAccount as any).value),
        });
        // Show success snackbar
        // setSnackbarMessage("Shelf configuration updated successfully");
        // setSnackbarSeverity("success");
        // setOpenSnackbar(true);

        handleClose();
      } catch (err) {
        console.error(err);
        // Show error snackbar
        setSnackbarMessage("An error occurred during update");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    }
  };

 

  // Fetch configuration data if id is provided
  useEffect(() => {
    const fetchConfigData = async () => {
      if (id && groupedChains.length > 0 && indId != undefined ) {
          try {
            setLoading(true)
  
            const response = await getRRPNewConfigById(id.toString());
            const configData = response;
            console.log(response);
            setConfigData(configData);
            console.log(configData);
            const keyAccountId = configData.key_account_id;
            // Find the channel_id associated with the key_account_id
            const accountStoreEntry = await getAccountStoreEntry(keyAccountId)
         
            const channelId = (accountStoreEntry as any)?.channel_id;
            if (channelId && accountStoreEntry.key_account_id != indId) {
              // Map back to selectedChannel value
              const selectedChannelValue = Number(
                Object.keys(channelIdMap).find(
                  (key) => channelIdMap[Number(key)] === channelId
                )
              );
              setSelectedChannel(selectedChannelValue);
            }
            setLoading(false)
  
          } catch (err) {
            setLoading(false)
  
            console.error(err);
          }
        
       
      }
    };

    fetchConfigData();
  }, [id, groupedChains,indId]);

  // Set selectedKeyAccount after selectedChannel is set
  useEffect(() => {
    if (configData && selectedChannel  && groupedChains.length > 0) {
      const channelId = channelIdMap[selectedChannel];
      const chainGroup = groupedChains.find(
        (group:any) => group.channelId === channelId
      );
      const options =
        chainGroup?.chains.map((chain: any) => ({
          value: chain.id,
          label: chain.title,
        })) || [];

      // Find the key account option that matches the key_account_id
      const keyAccountOption = options.find(
        (option: any) => Number(option.value) === configData.key_account_id
      );
      setSelectedKeyAccount(keyAccountOption || null);
      setIsKeyAccountSelected(true)
    }
  }, [configData, selectedChannel, groupedChains]);

  useEffect(() => {
    if (configData?.key_account_id=== indId && indId !=null) {
   
      setSelectedKeyAccount({label:'tet',value:indId?.toString()});
      setIsStoreSelected(true)
    }
  }, [configData,indId]);

  // Compute options for the dropdown based on the selected channel
  const optionsForDropdown = selectedChannel
    ? groupedChains
        ?.find(
          (chainGroup:any) =>
            chainGroup.channelId === channelIdMap[selectedChannel]
        )
        ?.chains.map((chain: any) => ({
          value: chain.id,
          label: chain.title,
        })) || []
    : [];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          minHeight: "700px",
          width: "615px",
          borderRadius: "15px !important",
          paddingBottom: "0",
        },
      }}
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
        <img
          src={Logo}
          alt="Logo"
          style={{ width: "201px", marginBottom: "32px" }}
        />
      </DialogTitle>
      <DialogContent>
      { loading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div> :
        <>
        <Typography
          variant="h6"
          sx={{
            marginBottom: "12px",
            color: "#0179FF",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          START
        </Typography>
        <Typography
          variant="h6"
          sx={{
            marginBottom: "6px",
            color: "#383A49",
            fontSize: "24px",
            fontWeight: 800,
          }}
        >
          Select Key Account
        </Typography>
        <Typography
          sx={{
            color: "#383A49",
            marginBottom: "32px",
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          Please select a key account you are creating the shelf for
        </Typography>
        {  !isKeyAccountSelected &&   <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '20px' }}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => setIsKeyAccountSelected(true)}
            sx={{
              height: "80px",
              borderRadius: "10px",
              borderColor: "#D9E7F8",
              backgroundColor: "#FFF",
              justifyContent: "flex-start",
              padding: "0 16px",
              textAlign: "left",
            }}
          >
           
           <KeyS   style={{ marginRight: "16px" }}  />
            <div>
              <Typography sx={{ color: "#383A49", fontWeight: 700, fontSize: "16px", fontFamily: "Mulish" }}>
                Key account
              </Typography>
              <Typography sx={{ color: "#383A49", fontSize: "14px", fontFamily: "Mulish",fontWeight:500 }}>
              For multi-location store chains or major retail accounts.
              </Typography>
            </div>
          </Button>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {setIsStoreSelected(!isStoreSelected)

             
            }}

             sx={{
    height: "80px",
    borderRadius: "10px",
    borderColor: isStoreSelected ? "#3F51B5" : "#D9E7F8", // Active border color
    backgroundColor: isStoreSelected ? "#E3F2FD" : "#FFF", // Active background color
    justifyContent: "flex-start",
    padding: "0 16px",
    textAlign: "left",
    "&:hover": {
      backgroundColor: isStoreSelected ? "#BBDEFB" : "#f5f5f5", // Active hover effect
    },
  }}
          >
           
          <InAccount      style={{ marginRight: "16px" }} />

            <div>
              <Typography sx={{ color: "#383A49", fontWeight: 700, fontSize: "16px", fontFamily: "Mulish" }}>
              Individual Store
              </Typography>
              <Typography sx={{ color: "#383A49", fontSize: "14px", fontFamily: "Mulish",fontWeight:500 }}>
              For single, independent sellers.
              </Typography>
            </div>
          </Button>
        </div> }
        {/* ButtonCheckbox for selecting the channel */}
        {  isKeyAccountSelected &&   <>
   <ButtonCheckbox
          label="Select a Channel"
          option1Text="Food"
          option2Text="Kiosk"
          option3Text="Petrol"
          option4Text="Tobacco"
          onChange={handleSelectChannel}
          selectedValue={selectedChannel}
        />

        {/* Dropdown component */}
        <div style={{ marginTop: "32px",marginBottom:'10px' }}>
          <Dropdown
            label="SELECT A KEY ACCOUNT"
            options={optionsForDropdown}
            notEditable
            onChange={setSelectedKeyAccount}
            selectedOption={selectedKeyAccount}
            setSelectedOption={setSelectedKeyAccount}
          />
        </div> 
      
      </>  }</>}
      </DialogContent>
      <DialogActions sx={{
        zIndex:0
      }}>
        <div style={{ display: "flex", width: "100%", gap: "8px" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClose}
            sx={{
              marginBottom: "10px !important",
              background: "rgba(217, 231, 248, 0.50)",
              justifyContent: "center",
              color: "#383A49",
              flexBasis: "50%",
              "&:hover": {
                color: "black",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleContinue}
            sx={{
              marginBottom: "10px !important",
              backgroundColor: "#0179FF",
              color: "white",
              flexGrow: 1,
              justifyContent: "center",
              "&:hover": {
                color: "white",
                backgroundColor: "#0179FF !important",
                opacity: 0.8,
              },
            }}
          >
            Continue
          </Button>
        </div>
      </DialogActions>

      {/* Snackbar Component */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default BrandModal;



