import React, { useState, useEffect, CSSProperties } from "react";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

type ButtonCheckboxProps = {
  label: string;
  option1Text: string;
  option2Text: string;
  option3Text?: string;
  onChange: (value: number | null) => void;
  selectedValue?: number | null; // Optional selectedValue prop for initial selection
};

const ButtonCheckbox: React.FC<ButtonCheckboxProps> = ({
  label,
  option1Text,
  option2Text,
  option3Text,
  onChange,
  selectedValue,
}) => {
  const [selected, setSelected] = useState<number | null>(
    selectedValue ?? null
  );

  useEffect(() => {
    setSelected(selectedValue ?? null);
  }, [selectedValue]);

  const handleClick = (option: number) => {
    const newValue = option === selected ? null : option;
    setSelected(newValue);
    onChange(newValue); // Toggle selection
  };

  // Inline styles for selected and non-selected buttons
  const selectedStyle: CSSProperties = {
    width: "100%",
    borderRadius: "10px",
    background: "var(--Zen-Blue, #0179FF)",
    color: "var(--White, #FFF)",
    fontFamily: "Mulish",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    border: "none",
    textTransform: "capitalize",
  };

  const nonSelectedStyle: CSSProperties = {
    width: "100%",
    borderRadius: "10px",
    border: "1px solid var(--Stroke, #D9E7F8)",
    background: "#FFF",
    color: "var(--Zen-Text, #383A49)",
    fontFamily: "Mulish",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    textTransform: "capitalize",
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography
        variant={"h2"}
        style={{
          marginBottom: "6px",
        }}
      >
        {label}
      </Typography>
      <div style={{ display: "flex", gap: "8px", width: "100%" }}>
        <Button
          style={selected === 1 ? selectedStyle : nonSelectedStyle}
          onClick={() => handleClick(1)}
          fullWidth
        >
          {option1Text}
        </Button>
        <Button
          style={selected === 2 ? selectedStyle : nonSelectedStyle}
          onClick={() => handleClick(2)}
          fullWidth
        >
          {option2Text}
        </Button>
        {option3Text && (
          <Button
            style={selected === 3 ? selectedStyle : nonSelectedStyle}
            onClick={() => handleClick(3)}
            fullWidth
          >
            {option3Text}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ButtonCheckbox;
