import React from "react";
import { Box, Button, IconButton } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Logo from "../../../assets/images/Logo1.png";
import { ReactComponent as SKUS } from "../../../assets/icons/SKUS.svg";
import { ReactComponent as Stores } from "../../../assets/icons/Stores.svg";
import { ReactComponent as Account } from "../../../assets/icons/Account.svg";
import { ReactComponent as Back } from "../../../assets/icons/Back.svg";

import { useStyles } from "./SideBarStyles";
import Input from "../Input/Input";
import { ReactComponent as Col } from "../../../assets/icons/Col.svg";
import { ReactComponent as Row } from "../../../assets/icons/Row.svg";
import { ReactComponent as Width } from "../../../assets/icons/Width.svg";
import { ReactComponent as Height } from "../../../assets/icons/Height.svg";
import { ReactComponent as BlueIcon } from "../../../assets/icons/Blue.svg";
import { useNavigate, useParams } from 'react-router-dom';

import Typography from "@mui/material/Typography";
import Dropdown from "../Dropdown/Dropdown";
import ColoredMenuItem from "../ColorMenuItem/ColoredMenuItem ";
type Square = {
  width: number;
  height: number;
};
interface SidebarProps {
  onChange: (value:number)=>void;
  squares:Square[]
  question:any[]
  question2:any[]


}
const Sidebar: React.FC<SidebarProps> = ({onChange,squares,question,question2}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
question2.includes("Marketing Materials")
  return (
    <Box className={classes.container}>
      <img src={Logo} alt="Logo" className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
          STEP 7/9
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "12px",fontSize:"20px !important" }}>
          Set the position of RRP Area
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "50px" ,maxWidth:'350px'}}>
          Choose the Marketing Material and LED Screen. Place it anywhere on the shelf.

</Typography>
          
       <ColoredMenuItem onChange={(value)=>onChange(value)} question2={question2} ></ColoredMenuItem>
        </div>
        <div>
      
          <div style={{ display: "flex", alignItems: "center",justifyContent:"center"  }}>
       
       <IconButton
         // color="primary"
         // variant="contained"
         // sx={{marginBottom:"10px !important", padding:"0 !important", margin:"0 !important",background:"transparent", '&:hover':{ background:"transparent !important",boxShadow:"none"}}}
         onClick={() => {
          
          if(question.length > 0) {
            navigate('/step17-new/'+id)

          }else {
            navigate('/step6/'+id)

          }
        
        }}
       >
         <Box component="span" className={classes.iconButton}>
           <Back />
         </Box>
       </IconButton>
       <Button variant="contained" color="info" onClick={() => {navigate("/step9/"+id)}} sx={{marginTop:"0px"}}>
         Next
       </Button>
     </div>
        </div>
        
      </div>
    </Box>
  );
};

export default Sidebar;
