import React, { ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

interface InputProps {
  label: string; // Label text
  placeholder: string; // Placeholder text
  onChange: (value: string) => void; // Updated to handle string input
  value: string; // Updated to handle string value
}

const Input: React.FC<InputProps> = ({ label, placeholder, onChange, value }) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value); // Directly pass the string value
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column", // Changed to column for vertical alignment
        marginBottom: "20px",
      }}
    >
      <Typography
       variant={"h2"}
       style={{
        marginBottom:"5px"
       }}
      >
        {label}
      </Typography>
      <TextField
        size="small"
        type="text" // Changed to text input
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        InputProps={{
          style: {
            height: "47px",
            border: "1px solid #D9E7F8",
            borderRadius: "10px",
            fontStyle: "italic",
            fontSize: "16px",
            fontWeight: 300,
            color: "#383A49",
          },
        }}
        variant="outlined"
      />
    </div>
  );
};

export default Input;
