import React, { useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Logo from "../../../assets/images/Logo1.png";

import { ReactComponent as Back } from "../../../assets/icons/Back.svg";

import { useStyles } from "./SideBarStyles";

import { useNavigate } from 'react-router-dom';

import Typography from "@mui/material/Typography";
import SearchInput from "../SearchInput/SearchInput";
import HNBProductList from "../HNBProductList/HNBProductList";
import SideBarRight from "../../SKUList/AddSkuModal/SideBarRight";
import BrandModal from "../ModalComponent/Modal";



interface SidebarProps {
  onColChange:(value:number) => void;
  onRowChange:(value:number) => void;
  onRowDistanceChange:(value:number) => void;
  onColDistanceChange:(value:number) => void;
  colDistance:number;

  rowDistance:number;
  row:number;
  col:number;
  selectedProductCardImage:string;
  setSelectedProductCardImage:React.Dispatch<React.SetStateAction<string>>;

}

const Sidebar: React.FC<SidebarProps> = ({onColChange,onRowChange,row,col,onRowDistanceChange,rowDistance,colDistance,onColDistanceChange,setSelectedProductCardImage}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isOpen,setIsOpen]= useState(false)
  const [openModal,SetOpenModal]=useState(false)
  return (
    <Box className={classes.container}>
              <SideBarRight isOpen={isOpen}  setIsOpen={setIsOpen}></SideBarRight>
              <BrandModal open={openModal} handleClose={()=>{SetOpenModal(false)}} label={"Configuration"} />

      <img src={Logo} alt="Logo" className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column" ,width:"100%"}}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
          STEP 2/3
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "12px" }}>
          New HNB Configuration
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          Add a New HNB Configuration
          </Typography>
         
         <SearchInput value={""} onValueChange={() => { } } unit={""} onUnitChange={function (unit: string): void {
            throw new Error("Function not implemented.");
          } } label={"PRODUCT LIST"}></SearchInput>
          <HNBProductList
            materials={[{title:"Terea Ruby Wave Example Name of 2 lines",imgSrc:"/HNB1.png"},
            {title:"Terea Ruby Wave Example",imgSrc:"/HNB2.png"},
            {title:"Terea Ruby Wave Example Name of 2 lines",imgSrc:"/HNB3.png"},
            {title:"Terea Ruby Wave Example Name of 2 lines",imgSrc:"/HNB4.png"},
          ]}
          setSelectedProductCardImage={setSelectedProductCardImage}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={()=>{setIsOpen(!isOpen)}}
            sx={{marginBottom:"10px !important","background-color":"#F2F8FF","color":"#0179FF",width:"100%",justifyContent:"center", height:"32px",marginTop:"12px !important",
            "&:hover": {
              color: "black", // Replace with your desired color
            }
           }}

            startIcon={
              <Box component="span" className={classes.iconButton}>
                <AddIcon />
              </Box>
            }
          >
            Add 
          </Button>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            // color="primary"
            // variant="contained"
            // sx={{marginBottom:"10px !important", padding:"0 !important", margin:"0 !important",background:"transparent", '&:hover':{ background:"transparent !important",boxShadow:"none"}}}
            onClick={() => {navigate('/step1-mini')}}
            size="large">
            <Box component="span" className={classes.iconButton2}>
              <Back />
            </Box>
          </IconButton>
          <Button   variant="contained" color="info" onClick={() => {SetOpenModal(true)}} sx={{marginTop:"0px"}}>
            Continue
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default Sidebar;
