import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%",
    background: "#F5F9FF",
    boxShadow: "none",
    padding:"40px",
    textAlign: "left"

  },
  contentContainer: {
    padding: "30px 40px 0 40px",
    alignSelf: "flex-start",
  },
  centerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    flex: 1,
  },
}));