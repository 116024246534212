// AboutPage.tsx
import React from 'react';
import Grid from "@mui/material/Grid";
import Sidebar from '../components/Step12/sideBar/SideBar';
import DemoComponent from '../components/Step12/Content/Content';


const Step2: React.FC = () => {
  return (
    <div>
      <Grid container>
        <Grid item xs={2.5}>
          <Sidebar></Sidebar>
        </Grid>
        <Grid item xs={9.5}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#e0e0e0",
            }}
          >
           <DemoComponent ></DemoComponent>
          </div>
        </Grid>
      </Grid>
    </div>
    
  );
};

export default Step2;
