import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import DemoComponent, { ChildRef } from "./Content/ContentOriginal";
import { getFromJson, saveToJson } from "../helpers/localData";
import {
  getAllSkus,
  getSkuById
} from "../../services/skuService";
import {
  getShelfConfigById,
  updateFacings
} from "../../services/shelfConfigurationService";
import {
  getMarketingMaterialById
} from "../../services/marketingMaterialsService";
import {
  getLedScreenById
} from "../../services/ledScreensService";
import {
  getRRPConfigurationById
} from "../../services/rrpConfigurationService";
import {
  Snackbar,
  Alert,
  CircularProgress,
  Box
} from "@mui/material";
import { getRRPNewConfigById } from "../../services/rrpNewConfigurationService";

type Square = {
  width: number;
  height: number;
  x: number;
  y: number;
  title?: string;
  positions?: any;
};

type ShelfPreviewProps = {
  id: string;
};
type Array3D = boolean[][][];


const ShelfPreview: React.FC<ShelfPreviewProps> = ({ id }) => {
  const [selectedPanel, setSelectedPanel] = useState(1);
  const [squares, setSquares] = useState<Square[]>(getFromJson<Square[]>("LedScreens") || []);
  const [col, setCol] = useState(2);
  const [name, setName] = useState<string | null>(null);
  const [row, setRow] = useState(2);
  const [rowDistance, setRowDistance] = useState(2);
  const [colDistance, setColDistance] = useState(2);
  const [squaresYellow, setSquaresYellow] = useState<Square[]>(getFromJson<Square[]>("MarketingList") || []);
  const [squaresBlue, setSquaresBlue] = useState<Square[]>(getFromJson<Square[]>("RRPList") || []);
  const [widths, setWidths] = useState<number[]>([]);
  const [pmiPositions, setPmiPositions] = useState<any[][][]>([]);
  const [pinkSquares, setPinkSquares] = useState(getFromJson<Square[]>("LedScreens") || squares);
  const [yellowSquares, setYellowSquares] = useState(getFromJson<Square[]>("MarketingList") || squaresYellow);
  const [blueSquares, setBlueSquares] = useState(getFromJson<Square[]>("RRPList") || squaresBlue);
  const [squaresWithImages, setSquaresWithImages] = useState<any>(getFromJson<[]>("squaresWithImages") || []);
  const [shelfDuplex, setShelfDuplex] = useState<any[][][]>([]);
  const [isAutoConfiguration, setIsAutoConfiguration] = useState(false);
  const [hiddencells,setHiddencells] = useState([])
  const [KeyAccountId, setKeyAccountID] = useState<number|null>(null);

  const [question, setQuestion] = useState<any[]>([]);
  const [question2, setQuestion2] = useState<any[]>([]);
  const [squaresWithNames, setSquaresWithNames] = useState<any>(
    []
  );
  const [squaresWithEan, setSquaresWithEan] = useState<any>(
    []
  );
  const [squaresWithWithds, setSquaresWithWithds] = useState<any>(
    []
  );
  const [squaresWithHeights, setSquaresWithHeight] = useState<any>(
    []
  );
  // New state for the currently selected square
  const [selectedSquare, setSelectedSquare] = useState<{
    col: number;
    row: number;
    z: number;
  } | null>(null);

  const [loading, setLoading] = useState(false); // Loader state
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
  const [snackbarContent, setSnackbarContent] = useState(""); // Snackbar content state
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success"); // Snackbar severity state

  useEffect(() => {
    console.log(selectedSquare);
  }, [selectedSquare]);

  // New state for the selected product card image URL
  const [selectedProductCardImage, setSelectedProductCardImage] = useState<string>("");

  useEffect(() => {
    console.log(selectedProductCardImage);
  }, [selectedProductCardImage]);

  // Initialize squaresWithImages based on col and row state
  function replaceWithEmptyString(arr: any[]): any[] {
    return arr.map((item) => {
      // If the item is an array, recurse into it
      if (Array.isArray(item)) {
        return replaceWithEmptyString(item);
      }
      // Otherwise, replace the item with an empty string
      return "";
    });
  }

  useEffect(() => {}, []);

  useEffect(() => {
    // Ensure both selectedSquare and selectedProductCardImage have values before proceeding
    if (selectedSquare && selectedProductCardImage) {
      alert("ok");

      const skuimage = getSkuById(parseInt(selectedProductCardImage)).then(async (value) => {
        console.log("skuimage", value);
        try {
          // Save to JSON
          saveToJson("squaresWithImages", squaresWithImages);

          // Update facings
          // Default id
          const updatedSquares2 = squaresWithImages.map((row: any) => [...row]);

          updatedSquares2[selectedSquare.col][selectedSquare.row][selectedSquare.z] = value.id;
          const facings = updatedSquares2; // Assuming squaresWithImages contains skuIds
          id && (await updateFacings(id, facings));
          console.log("the second", squaresWithImages);
        } catch (error) {
          console.error("Error updating facings:", error);
        }
        setSquaresWithImages((currentSquares: any) => {
          // Clone the current state to avoid direct mutation
          const updatedSquares = currentSquares.map((row: any) => [...row]);

          updatedSquares[selectedSquare.col][selectedSquare.row][selectedSquare.z] = value.pack_image;

          return updatedSquares;
        });
      });

      saveToJson("squaresWithImages", squaresWithImages);
      setSelectedSquare(null);
      setSelectedProductCardImage("");
    }
  }, [selectedSquare, selectedProductCardImage]);
  const updatePmiPositions = (shelfDuplex: Array3D, pmiPositions: Array3D): Array3D => {
    // Create a deep copy of pmiPositions to avoid mutating the original array
    const updatedPmiPositions = pmiPositions.map(layer => layer.map(row => [...row]));

    shelfDuplex.forEach((layer, layerIndex) => {
      layer.forEach((row, rowIndex) => {
        row.forEach((hasDuplexPusher, colIndex) => {
          if (hasDuplexPusher) {
            // If the next position is within bounds and not already true, insert true
            if (colIndex + 1 < row.length) {
            
                updatedPmiPositions[layerIndex][rowIndex].splice(colIndex + 1, 0, true);
              
            } else {
              // If the next position is out of bounds, push true to the end
              updatedPmiPositions[layerIndex][rowIndex].push(true);
            }
          }
        });
      });
    });
console.log('updatedPmiPositions',updatedPmiPositions)
  return updatedPmiPositions;
};
function addTrueNextToExistingTrue(array:any) {
  // Create a new array to store the modified structure
  const newArray = array.map((subArray2D:any) => 
      subArray2D.map((subArray1D:any) => {
          const newSubArray = [];
          // Loop through the 1D array and add a duplicate true next to each true
          for (let k = 0; k < subArray1D.length; k++) {
              newSubArray.push(subArray1D[k]); // Add the current element
              if (subArray1D[k] === true) {
                  newSubArray.push(true); // Add an extra true if the current element is true
              }
          }
          return newSubArray;
      })
  );
  return newArray;
}
  useEffect(() => {
    const fetchShelfConfig = async () => {  
      if(id) {
        try {
          const shelfConfig = await  getShelfConfigById(id);
          const marketingMaterialIds = shelfConfig.marketingMaterials || [];
          const ledScreensIds = shelfConfig.ledScreens || [];

        const materialMap = new Map<number, any & { isAdded: number, instances: { x: number, y: number }[] }>();
        const ledMap = new Map<number, any & { isAdded: number, instances: { x: number, y: number }[] }>();

        // Store materials with their respective x and y values
        for (const item of marketingMaterialIds) {
          const material = await getMarketingMaterialById(item.id);
          if (materialMap.has(material.id)) {
            const existingMaterial = materialMap.get(material.id)!;
            existingMaterial.isAdded! += 1;
            existingMaterial.instances.push({ x: item.x, y: item.y });
          } else {
            materialMap.set(material.id, { ...material, isAdded: 1, instances: [{ x: item.x, y: item.y }] });
          }
        }
        for (const item of ledScreensIds) {
          const material = await getLedScreenById(item.id);
          if (ledMap.has(material.id)) {
            const existingMaterial = ledMap.get(material.id)!;
            existingMaterial.isAdded! += 1;
            existingMaterial.instances.push({ x: item.x, y: item.y });
          } else {
            ledMap.set(material.id, { ...material, isAdded: 1, instances: [{ x: item.x, y: item.y }] });
          }
        }
  
  
        // Expand materials with their respective x and y values
        const expandedMaterials = Array.from(materialMap.values()).flatMap(material => 
          material.instances.map((instance:any) => ({
            ...material,
            x: instance.x,
            y: instance.y,
            isAdded: 1 // Set isAdded to 1 for each instance in the expanded list
          }))
        );
        const expandedleds = Array.from(ledMap.values()).flatMap(material => 
          material.instances.map((instance:any) => ({
            ...material,
            x: instance.x,
            y: instance.y,
            isAdded: 1 // Set isAdded to 1 for each instance in the expanded list
          }))
        );
        const rrpConfigurations = await Promise.all(
          (shelfConfig.rrp || []).map(async (rrp: { id: number, x: number, y: number }) => {
            const fullRRP = await getRRPNewConfigById(rrp.id.toString());
            return {
              ...fullRRP,
              x: rrp.x,
              y: rrp.y,
            };
          })
        );
        const newSquares = rrpConfigurations.map((value) => ({
          id: value.id,
          title: value?.name,
          width: value.widths[0] *5  || 250 ,
          height: (value.row_position ) * 51.5+ (value.row_position  - 1) * (colDistance *5),
          x: value.x || 0,
          y: value.y || 0,
        }));
        const skus =await getAllSkus()
        type PackItem = {
          id: string;
          pack_image: string;
          pack_image_laying?: string;

        };
      
        const getPackImageById = (id: string, items: PackItem[]): string | undefined => {
          const item = items.find(item => item.id === id);
          return item ? item.pack_image : undefined;
        };
        const getPackImageByIdLaying = (id: string, items: PackItem[]): string | undefined => {
          const item = items.find(item => item.id === id);
          return item ? item.pack_image_laying : undefined;
        };
        const getPackNameById = (id: string, items: PackItem[]): string | undefined => {
          const item = items.find(item => item.id === id);
          return item ? (item as any).brandName + ' ' + (item as any).variantName: undefined;
        };
        const getPackEanById = (id: string, items: PackItem[]): string | undefined => {
          const item = items.find(item => item.id === id);
          return item ? (item as any).ean_code: undefined;
        };
        const getPackWidthById = (id: string, items: PackItem[]): string | undefined => {
          const item = items.find(item => item.id === id);
          return item ? (item as any).width: undefined;
        };
        const getPackHeightById = (id: string, items: PackItem[]): string | undefined => {
          const item = items.find(item => item.id === id);
          return item ? (item as any).height: undefined;
        };
        const replaceIdsWithPackImages = (idArray: string[][][], items: PackItem[]): string[][][] => {
          const duplexUpdated = shelfConfig?.duplex != null  ? addTrueNextToExistingTrue(shelfConfig.duplex) : null
          console.log('duplexUpdated',duplexUpdated)
         return idArray.map((layer,layerIndex) =>
           layer.map((row,rowIndex) =>
            
           {
             let dx = 0
             
             return  row.map((id,idIndex) => {
              
               if( shelfConfig?.duplex != null && (duplexUpdated[layerIndex][rowIndex][idIndex]  ) ) {
                
              
                
                 return getPackImageByIdLaying(id, items) || id
               }else {
                 return getPackImageById(id, items) || id
               }
             
             
             }
           )}
           )
         );
       };
        const replaceIdsWithPackName = (idArray: string[][][], items: PackItem[]): string[][][] => {
          return idArray.map(layer =>
            layer.map(row =>
              row.map(id => getPackNameById(id, items) || id)
            )
          );
        };
        const replaceIdsWithPackEan = (idArray: string[][][], items: PackItem[]): string[][][] => {
          return idArray.map(layer =>
            layer.map(row =>
              row.map(id => getPackEanById(id, items) || id)
            )
          );
        };
        const replaceIdsWithPackWidths= (idArray: string[][][], items: PackItem[]): string[][][] => {
          return idArray.map(layer =>
            layer.map(row =>
              row.map(id => getPackWidthById(id, items) || id)
            )
          );
        };
        const replaceIdsWithPackHeights= (idArray: string[][][], items: PackItem[]): string[][][] => {
          return idArray.map(layer =>
            layer.map(row =>
              row.map(id => getPackHeightById(id, items) || id)
            )
          );
        };
          if (
            shelfConfig &&
            shelfConfig.facings &&
            shelfConfig.facings != null &&
            shelfConfig.facings[0] != null
          ) {
            setSquaresWithImages(replaceIdsWithPackImages(shelfConfig.facings,skus));
            setSquaresWithNames(replaceIdsWithPackName(shelfConfig.facings,skus));
            setSquaresWithEan(replaceIdsWithPackEan(shelfConfig.facings,skus));
            setSquaresWithWithds(replaceIdsWithPackWidths(shelfConfig.facings,skus));
            setSquaresWithHeight(replaceIdsWithPackHeights(shelfConfig.facings,skus));
          } else {

            const savedBoxes = updatePmiPositions(shelfConfig.duplex || [],shelfConfig.pmiPositions|| []);
              console.log('the save boxes',savedBoxes)
            setSquaresWithImages(replaceWithEmptyString(savedBoxes));
            setSquaresWithWithds(replaceWithEmptyString(savedBoxes));
            setSquaresWithHeight(replaceWithEmptyString(savedBoxes));
            setSquaresWithNames(replaceWithEmptyString(savedBoxes));

          }
          setCol(shelfConfig.col_position);
          setRow(shelfConfig.row_position);
          setRowDistance(shelfConfig.rowDistance);
          setColDistance(shelfConfig.colDistance);
          setWidths(shelfConfig.widths.map((width: any) => width * 5));
          setPmiPositions(shelfConfig.pmiPositions || []);
          setBlueSquares(newSquares)
          setYellowSquares(expandedMaterials);
          setSquaresYellow(expandedMaterials);
          setPinkSquares(expandedleds)
          setQuestion(shelfConfig.question1)
          setQuestion2(shelfConfig.question2)
          setKeyAccountID(shelfConfig.key_account_id)

          setHiddencells(shelfConfig.hiddencells)
          setShelfDuplex(shelfConfig.duplex || [])
          setIsAutoConfiguration(shelfConfig.isAutoConfiguration)
          setName(shelfConfig.name === null ? '' :shelfConfig.name );
        } catch (error) {
          console.error("Error fetching shelf configuration:", error);
        }
      }
    
    };

    fetchShelfConfig();
  }, [id,colDistance]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  const childRef = useRef<ChildRef>(null);
  return (
    <div>
      <Grid container>
        <div
          style={{
            width: "100%",
            backgroundColor: "#e0e0e0",
          }}
        >
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <CircularProgress />
            </Box>
          ) : (
            pmiPositions.length > 0 && (
              <DemoComponent
                  ref={childRef}
                  selectionMode={selectedPanel}
                  squares={squares}
                  keyAccountId={KeyAccountId}
                  squaresWithHeights={squaresWithHeights}
                  squaresWithWithds={squaresWithWithds}
                  squaresWithImages={squaresWithImages}
                  squaresWithNames={squaresWithNames}
                  squaresWithEan={squaresWithEan}
                  selectedSquare={selectedSquare}
                  setSelectedSquare={setSelectedSquare}
                  pinkSquares={pinkSquares}
                  setPinkSquares={setPinkSquares}
                  yellowSquares={yellowSquares}
                  setYellowSquares={setYellowSquares}
                  squaresYellow={squaresYellow}
                  blueSquares={blueSquares}
                  setBlueSquares={setBlueSquares}
                  squaresBlue={squaresBlue}
                  col={col}
                  row={row}
                  shelfDuplex={shelfDuplex}
                  setShelfDuplex={setShelfDuplex}
                  isAutoConfiguration={isAutoConfiguration}

                  rowDistance={rowDistance}
                  colDistance={colDistance}
                  widths={widths}
                  selectedBoxesPmi={pmiPositions}
                  setIsLoading={setIsLoading}
                  hiddencells={hiddencells}
                   exportOptions={{
                    compositionName: false,
                    eanCode: false,
                    validityDate: false
                  }} 
                  validFrom={null} validTo={null}
                   name={name}               ></DemoComponent>
            )
          )}
        </div>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          variant="filled"
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarContent}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ShelfPreview;
