import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import {  Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customBox: {
      borderRadius: "5.134px",
      background: "#B5D8FF",
      width: "160.103px",
      height: "320px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      gap:"2px"
    },
    secondBox: {
      width: "160.103px",
      height: "60.142px",
      flexShrink: 0,
      borderRadius: "3.85px",
      background: "#98C9FF",
      // marginTop: "10px",
    },
    customBoxCute: {
      borderRadius: "5.134px",
      background: "#B5D8FF",
      width: "112.103px",
      height: "320px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      gap:"2px"
    },
    secondBoxCute: {
      width: "112.103px",
      height: "60.142px",
      flexShrink: 0,
      borderRadius: "3.85px",
      background: "#98C9FF",
      // marginTop: "10px",
    },
    subBox: {
      width: "156.143px",
      height: "36.5px",
      background:" #73B5FF",
      borderRadius: "4px"

    },
    subBoxCute: {
      width: "100.143px",
      height: "36.5px",
      background:" #73B5FF",
      borderRadius: "4px"

    },
  })
);

const Component: React.FC = () => {
  const classes = useStyles();

  return (
    <div style={{ display: "flex", gap: "5px" , justifySelf:"flex-end" }}>
     
      <div
        style={{
          backgroundColor: "#F5F9FF",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <div className={classes.customBox}>
          <div className={classes.subBox}></div>
              <div className={classes.subBox}></div>
              <div className={classes.subBox}></div>
              <div className={classes.subBox}></div>
              <div className={classes.subBox}></div>
              <div className={classes.subBox}></div>
              <div className={classes.subBox}></div>
              <div className={classes.subBox}></div>
          </div>
          <div className={classes.secondBox}></div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#F5F9FF",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <div className={classes.customBox}>
          <div className={classes.subBox}></div>
              <div className={classes.subBox}></div>
              <div className={classes.subBox}></div>
              <div className={classes.subBox}></div>
              <div className={classes.subBox}></div>
              <div className={classes.subBox}></div>
              <div className={classes.subBox}></div>
              <div className={classes.subBox}></div>
           
          </div>
          <div className={classes.secondBox}></div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#F5F9FF",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <div className={classes.customBoxCute}>
              <div className={classes.subBoxCute}></div>
              <div className={classes.subBoxCute}></div>
              <div className={classes.subBoxCute}></div>
              <div className={classes.subBoxCute}></div>
              <div className={classes.subBoxCute}></div>

              <div className={classes.subBoxCute}></div>
              <div className={classes.subBoxCute}></div>
              <div className={classes.subBoxCute}></div>
          </div>
          <div className={classes.secondBoxCute}></div>
        </div>
      </div>
    </div>
  );
};

export default Component;