import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  card: {
    background: "white",
    width: "100%",
    border: "0px ",
    borderRadius: 10,
    padding: "16px 0 0px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    boxShadow: "none",
    margin: "10px", // Add margin to separate the cards
    [theme.breakpoints.down('sm')]: {
      width: "100%", // Make the card take full width on small screens
      margin: "10px 0", // Adjust margin for small screens
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "10px 10px",
      height:'100%',    
    background: "#F5F9FF",
    borderRadius: "8px 8px 0 0",
    position: "relative", // Ensure the settings button stays in place
  },
  settingsButton: {
    position: "absolute",
    top: "-8px",
    right: "-8px",
  },
  icon: {
    fontSize: 48,
    marginBottom: '2px',
    backgroundColor: "white",
    color: "#0179FF",
  },
  image: {
    width: "100%",
    marginBottom: theme.spacing(1),
    borderRadius: "2px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    textAlign: "left",
    marginTop: "10px",
    width:'100%'
  },
  lastEdit: {
    marginTop: theme.spacing(1),
    color: "#555",
  },
}));
