import React, { useEffect, useRef, useState } from 'react';
import { Theme  } from '@mui/material';
import MeasurementInput from '../InputV2/InputV2';
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import Paper from '@mui/material/Paper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexContainer: {
      display: "flex",
      margin: `2px`,
    },
    square: {
      width: 137,
      height: 30,
      backgroundColor: "#B5D8FF !important",
      boxShadow: "none",
    },
    gridContainer: {
      margin: "-5px",
      width: "auto",
      "& > .MuiGrid-item": {
        padding: "5px",
      },
    },
    controlBar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(2),
      backgroundColor: "#D9E7F8",
      width: "100%",
      borderRadius: "3px",
    },
    verticalControlBar: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "0 34px",
      backgroundColor: "#D9E7F8",
      borderRadius: "3px",
      alignItems: "center",
      marginLeft: theme.spacing(2),
      height: "100%",
    },
    smallDiv: {
      width: 28,
      height: 28,
      backgroundColor: "#ffffff",
      margin: "1.25px",
      borderRadius: '4px'
    },
    iconButton: {
      width: 28,
      height: 28,
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      color: "#0179FF",
    },
    iconButton2: {
      padding: 6,
    },
  })
);

interface GridDemoProps {
  row: number;
  col: number;
  rowDistance: number;
  setRowDistance: React.Dispatch<React.SetStateAction<number>>;
  colDistance: number;
  setColDistance: React.Dispatch<React.SetStateAction<number>>;
  setRow: React.Dispatch<React.SetStateAction<number>>;
  setCol: React.Dispatch<React.SetStateAction<number>>;
  widths: number[];
  setWidths: (widths: number[]) => void;
  hiddencells: never[]
}

const GridDemo: React.FC<GridDemoProps> = ({
  row,
  col,
  rowDistance,
  colDistance,
  widths,
  setWidths,
  hiddencells
}) => {
  const classes = useStyles();
  const [globalWidthEnabled, setGlobalWidthEnabled] = useState(true);
  const [numDivsArray, setNumDivsArray] = useState<number[]>(widths.map(width => Math.floor((width * 5) / 31.5)));

  const updatingFromWidths = useRef(false);
  const updatingFromNumDivs = useRef(false);
  console.log('hiddencells',hiddencells)
  useEffect(() => {
    if (col > widths.length && widths.length > 0) {
      const additionalWidths = Array(col - widths.length).fill(50);
      setWidths(widths.concat(additionalWidths));

      const additionalNumDivs = Array(col - numDivsArray?.length || 0).fill(Math.floor((50 * 5) / 30.5));
      setNumDivsArray(numDivsArray.concat(additionalNumDivs));
    } else if (col < widths.length) {
      setWidths(widths.slice(0, col));
      setNumDivsArray(numDivsArray.slice(0, col));
    }
  }, [col]);

  useEffect(() => {
    if (updatingFromNumDivs.current) {
      updatingFromNumDivs.current = false;
      return;
    }
    updatingFromWidths.current = true;
    const newNumDivsArray = widths.map(width => Math.floor((width * 5) / 30.5));
    setNumDivsArray(newNumDivsArray);
  }, [widths]);

  useEffect(() => {
    if (updatingFromWidths.current) {
      updatingFromWidths.current = false;
      return;
    }
    updatingFromNumDivs.current = true;
    const newWidths = numDivsArray.map(numDivs => (numDivs * 30.5) / 5);
    setWidths(newWidths);
  }, [numDivsArray]);

  const handleWidthChange = (value: number, colIndex: number) => {
    const newWidths = [...widths];
    newWidths[colIndex] = value;
    setWidths(newWidths);
    setGlobalWidthEnabled(false);
  };

  const handleNumDivsChange = (value: number, colIndex: number) => {
    const newNumDivsArray = [...numDivsArray];
    newNumDivsArray[colIndex] = value;
    setNumDivsArray(newNumDivsArray);
    setGlobalWidthEnabled(false);
  };

  const handleValueChange = (value: number, colIndex: number) => {
    if (colIndex === -1) { // Global width change
      const newWidths = Array(col).fill(value);
      setWidths(newWidths);
      setGlobalWidthEnabled(true);
    } else { // Individual column width change
      handleWidthChange(value, colIndex);
    }
  };

  const renderInputRow = () => {
    const inputRow = [];
    for (let i = 0; i < col; i++) {
      inputRow.push(
        <div key={`div-${i}`} style={{ display: 'flex', padding: `${rowDistance * 5 / 2}px`, paddingBottom: "20px", borderBottom: "2px dashed #9CA0AB" }}>
          <MeasurementInput
            key={`input-width-${i}`}
            value={widths[i]}
            unit="cm"
            onValueChange={(value) => handleWidthChange(value, i)}
            onUnitChange={() => { }}
            width={widths[i]/2}
          />
          <MeasurementInput
            key={`input-numDivs-${i}`}
             unit="CC"
            type="number"
            onUnitChange={() => { }}
            min={2}
            width={widths[i]/2}
            value={numDivsArray[i]}
            onValueChange={(value) => handleNumDivsChange(Number(value), i)}
          />
        </div>
      );
    }
    return (
      <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", textAlign: "center" }}>
        <div className={classes.flexContainer}>
          {inputRow}
        </div>
        <div>
          <Typography variant="h6" sx={{ marginBottom: "12px" }}>
            Width
          </Typography>
          <MeasurementInput
            key="inputGlobal"
            value={!globalWidthEnabled ? "Mixed" : widths[0]}
            unit="cm"
            onValueChange={(value) => handleValueChange(value, -1)}
            onUnitChange={() => { }}
            width={200}
            type={!globalWidthEnabled ? "text" : "number"}
          />
        </div>
      </div>
    );
  };

  const renderSmallDivs = (numDivs: number) => {
    const smallDivs = [];
    for (let i = 0; i < numDivs; i++) {
      smallDivs.push(<div key={i} className={classes.smallDiv} />);
    }
    return smallDivs;
  };

  const renderSquares = () => {
    const rowsArray = [];
    for (let i = 0; i < row; i++) {
      const rowSquares = [];
      for (let j = 0; j < col; j++) {
        const squareWidthCm = widths[j]; // Width in cm
        const squareWidthPx = squareWidthCm * 5; // Convert cm to px
        const numDivs = numDivsArray[j];
        const display =hiddencells[i] && hiddencells[i][j]
        rowSquares.push(
          <div
            key={`${i}-${j}`}
            style={{
              padding: `${colDistance * 5 / 2}px ${rowDistance * 5 / 2}px`,
              width: `${squareWidthPx}px`,
            }}
          >
            <Paper className={classes.square} style={{ width: `${squareWidthPx}px` , display: !display ? 'none' : '' }}>
              <div style={{ display: "flex", flexWrap: "wrap", alignItems: 'center' }}>
                {renderSmallDivs(numDivs)}
              </div>
            </Paper>
          </div>
        );
      }
      rowsArray.push(
        <div key={i} className={classes.flexContainer}>
          {rowSquares}
        </div>
      );
    }
    return rowsArray;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F5F9FF",
      }}
    >
      <div
        style={{
          backgroundColor: "#F5F9FF",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto",
          width: "fit-content",
          padding: "0 10px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {renderSquares()}
        </div>
        {renderInputRow()}
      </div>
    </div>
  );
};

export default GridDemo;
