import React, { useState } from "react";
import { Card, CardContent, IconButton } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { useNavigate } from 'react-router-dom';

import { useStyles } from "./CreatShelfCardStyles";
import BrandModal from "../../SKUPriority copy/ModalComponent/Modal";
interface CreateProps {
  groupedChains:any[]
  indId:number | undefined
}
const CreateShelfCard: React.FC<CreateProps> = ({groupedChains,indId}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <BrandModal open={openModal} handleClose={handleCloseModal} groupedChains={groupedChains} indId={indId}  label="Shelf" />
      <Card className={classes.card}>
        <CardContent className={classes.carContent}>
          <IconButton color="primary" className={classes.icon} onClick={handleOpenModal}>
            <AddIcon />
          </IconButton>
          <Typography variant="subtitle2" className={classes.textStyle}>
            Create New
            HNB Configuration
          </Typography>
        </CardContent>
      </Card>
    </>

  );
};

export default CreateShelfCard;
