// AboutPage.tsx
import React, { useEffect, useState } from "react";
import PosEdit from "../components/PosEdit/PosEdit";
import { useParams } from "react-router-dom";



const PosEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <div>
 {id &&  <PosEdit id={parseInt(id)}></PosEdit>}
    </div>
  );
};

export default PosEditPage;
