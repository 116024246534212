import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface TabItem {
  title: string;
  id: number;
  content: React.ReactNode;
}

interface TabsComponentProps {
  tabs: TabItem[];
  onChange: (index: number) => void;
}

const TabsComponent: React.FC<TabsComponentProps> = ({ tabs, onChange }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "", marginBottom: "12px" }}>
      {" "}
      {/* Adjust the background color as needed */}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          background: "white",

          borderRadius: "50px",
          height: "56px", // Adds shadow to the tabs bar, adjust as necessary
        }}
      >
        <Tabs
          value={value}
          variant="fullWidth"
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            ".MuiTabs-indicator": {
              display: "none",
              backgroundColor: "#3f51b5", // Indicator color
              height: "4px", // Indicator height
            },
            ".MuiTab-root": {
              textTransform: "none", // Removes uppercase styling
              fontWeight: "bold",
              height: "56px",
              minHeight: "40px",
              // Sets font weight
              "&.Mui-selected": {
                color: "white",
                background: "#0179FF", // Selected tab text color
              },
              "&:hover": {
                opacity: 1,
              },
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              label={tab.title}
              id={`simple-tab-${index}`}
              onClick={() => onChange(index)}
              aria-controls={`simple-tabpanel-${index}`}
              key={index}
              sx={{
                borderTopLeftRadius: index === 0 ? "40px" : "0px",
                borderBottomLeftRadius: index === 0 ? "40px" : "0px",
                borderTopRightRadius:
                  index === tabs.length - 1 ? "40px" : "0px",
                borderBottomRightRadius:
                  index === tabs.length - 1 ? "40px" : "0px",
              }}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel value={value} index={index} key={index}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabsComponent;
