import makeStyles from '@mui/styles/makeStyles';


export const useStyles = makeStyles((theme) => ({
    container: {
      width: "100%",
      height: "100vh !important",
      overflow:"auto",
      backgroundColor: "white !important",
      boxShadow: theme.shadows[2],
      display: "flex !important",
      flexDirection: "column",
      alignItems: "center !important",
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
    },
    logo: {
      maxWidth: "100%",
      maxHeight: "44px",
      marginBottom: theme.spacing(2),
      marginTop: "40px",
    },
    buttonContainer:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        marginBottom: "10px",
    },
   
    iconButton: {
      width: 12,
      height: 12,
      //marginRight: theme.spacing(1),
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "transparent",
      color: "#0179FF",
    },
    iconButton2: {
      width: 56,
      height: 56,
      //marginRight: theme.spacing(1),
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgba(217, 231, 248, 0.50)",
      color: "#0179FF",
    },
  }),{ name: 'SideBarStyles' });