import React, { useState } from "react";
import { TextField, Grid, Paper, IconButton, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Typography from "@mui/material/Typography";

import { Box, Button } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { Remove as RemoveIcon } from "@mui/icons-material";
import { wrap } from "module";
import Input from "../Input/Input";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      // marginTop: theme.spacing(2),
      margin: "-5px", // Adjust this value as needed
      width: "auto",
      "& > .MuiGrid-item": {
        padding: "5px", // Adjust this value as needed
      },
    },
    flexContainer: {
      display: "flex",
      margin: `2px`,
    },
    flexItem: {
      padding: `2px 3px`,
    },
    selectedSquare: {
      border: "2px solid #0179FF"
// A gold color for selection, but choose what fits your design
    },
    square: {
      width: 400,
      height: 84,
      backgroundColor: "#B5D8FF",
      boxShadow: "none",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize:"cover", // Ensure the image covers the square
      backgroundPosition: 'center',
      "&:hover":{
        cursor:"pointer"
      }
    },
    controlBar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(2),
      backgroundColor: "#D9E7F8",
      width: "100%",
      borderRadius: "3px",

      // alignSelf: "start",
    },
    verticalControlBar: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "0 34px",
      backgroundColor: "#D9E7F8",

      borderRadius: "3px",
      alignItems: "center",
      minWidth: "82px",
      marginLeft: theme.spacing(2),
      height: "100%", // Make the vertical bar take the height of the squares
    },
    iconButton: {
      width: 28,
      height: 28,
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      color: "#0179FF",
    },
    iconButton2: {
      padding: 6, // Set padding to 0 to remove the default padding
    },
  })
);

interface GridDemoProps {
  row: number;
  col: number;
  rowDistance: number;
  setRowDistance: React.Dispatch<React.SetStateAction<number>>;
  colDistance: number;
  setColDistance: React.Dispatch<React.SetStateAction<number>>;
  setRow: React.Dispatch<React.SetStateAction<number>>;
  setCol: React.Dispatch<React.SetStateAction<number>>;
  selectedSquare: { col: number; row: number } | null;
  squaresWithImages: Array<Array<string>>;
  setSelectedSquare: React.Dispatch<React.SetStateAction<{
    col: number;
    row: number;
  } | null>>;
}
interface RowData {
  row: number;
  [key: string]: number; // This allows any number of properties with string keys and number values
}
interface SquareData {
  row: number;
  col: number;
  duplexType1: number;
  duplexType2: number;
  duplexType3: number;
}

const GridDemo: React.FC<GridDemoProps> = ({
  row,
  col,
  squaresWithImages,
  setSelectedSquare,
  selectedSquare
}) => {
  const classes = useStyles();
  const [squaresData, setSquaresData] = useState<SquareData[]>([]);

  const handleSquareClick = (colIndex: number, rowIndex: number) => {
    setSelectedSquare({ col: colIndex, row: rowIndex });
  };

  const handleDuplexTypeChange = (typeIndex: number, value: number) => {
    if (!selectedSquare) return;
    const newData = [...squaresData];
    const existingIndex = newData.findIndex(data => data.row === selectedSquare.row && data.col === selectedSquare.col);
    if (existingIndex > -1) {
      const square = newData[existingIndex];
      switch (typeIndex) {
        case 0:
          square.duplexType1 = value;
          break;
        case 1:
          square.duplexType2 = value;
          break;
        case 2:
          square.duplexType3 = value;
          break;
        default:
          break;
      }
    } else {
      const newSquareData = {
        row: selectedSquare.row,
        col: selectedSquare.col,
        duplexType1: typeIndex === 0 ? value : 0,
        duplexType2: typeIndex === 1 ? value : 0,
        duplexType3: typeIndex === 2 ? value : 0,
      };
      newData.push(newSquareData);
    }
    setSquaresData(newData);
  };
  const getDuplexTypeValue = (typeIndex: number) => {
    if (!selectedSquare) return 0;
    const squareData = squaresData.find(data => data.row === selectedSquare.row && data.col === selectedSquare.col);
    
    if (!squareData) return 0;
  
    switch (typeIndex) {
      case 0:
        return squareData.duplexType1;
      case 1:
        return squareData.duplexType2;
      case 2:
        return squareData.duplexType3;
      default:
        return 0;
    }
  };

  const renderSquares = () => {
    return Array.from({ length: row }, (_, rowIndex) => (
      <div key={rowIndex} className={classes.flexContainer}>
        {Array.from({ length: col }, (_, colIndex) => {
          const imageSrc = squaresWithImages[rowIndex]?.[colIndex];
          const isSelected = selectedSquare && selectedSquare.col === colIndex && selectedSquare.row === rowIndex;
  
          // Retrieve the number of boxes to render for each type only if it's the selected square
          const duplexType1Count = isSelected ? getDuplexTypeValue(0) : 0;
          const duplexType2Count = isSelected ? getDuplexTypeValue(1) : 0;
          const duplexType3Count = isSelected ? getDuplexTypeValue(2) : 0;
  
          const boxWidths = [15, 20, 25]; // Fixed widths for each type of box
  
          return (
            <div
              key={`${rowIndex}-${colIndex}`}
              onClick={() => handleSquareClick(colIndex, rowIndex)}
              style={{ padding: `4px 4px` }}
            >
              <Paper
                className={`${classes.square} ${isSelected ? classes.selectedSquare : ''}`}
                style={{
                  backgroundImage: imageSrc ? `url(${imageSrc})` : '',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {/* Consistently render nested sub-boxes for each box type */}
                {[...Array(duplexType1Count)].map((_, idx) => (
                  <div key={`type1-${idx}`} style={{  width: `${boxWidths[0]}px`, height: '100%', backgroundColor: 'red', marginRight: idx !== duplexType1Count - 1 ? '2px' : '0', margin:"2px 2px" }}>
                    <div style={{ width: `${boxWidths[0]}px`, height: '50%', backgroundColor: 'lightcoral' }}></div>
                    <div style={{ width: `${boxWidths[0]}px`, height: '50%', backgroundColor: 'darkred' }}></div>
                  </div>
                ))}
                {[...Array(duplexType2Count)].map((_, idx) => (
                  <div key={`type2-${idx}`} style={{ width: `${boxWidths[1]}px`, height: '20px', backgroundColor: 'green', marginRight: idx !== duplexType2Count - 1 ? '2px' : '0' }}>
                    <div style={{ width: `${boxWidths[1]}px`, height: '50%', backgroundColor: 'lightgreen' }}></div>
                    <div style={{ width: `${boxWidths[1]}px`, height: '50%', backgroundColor: 'darkgreen' }}></div>
                  </div>
                ))}
                {[...Array(duplexType3Count)].map((_, idx) => (
                  <div key={`type3-${idx}`} style={{ width: `${boxWidths[2]}px`, height: '20px', backgroundColor: 'blue', marginRight: idx !== duplexType3Count - 1 ? '2px' : '0' }}>
                    <div style={{ width: `${boxWidths[2]}px`, height: '50%', backgroundColor: 'skyblue' }}></div>
                    <div style={{ width: `${boxWidths[2]}px`, height: '50%', backgroundColor: 'navy' }}></div>
                  </div>
                ))}
              </Paper>
            </div>
          );
        })}
      </div>
    ));
  };
  
  
  
  
  
  

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems:"center", flexDirection: "column", backgroundColor: "#F5F9FF" }}>
      <div style={{ backgroundColor: "#F5F9FF", height: "100vh", display: "flex", width: "fit-content", padding: "0 10px", overflow: "auto", flexDirection: "column", justifyContent: "center", alignItems: "start" }}>
        <div style={{ alignSelf: "center", fontSize: "32px", color: "#9CA0AB", fontWeight: 800, marginBottom: "20px" }}>{col} x {row} Packs</div>
        {selectedSquare && (
          <div style={{ display: "flex", alignSelf: "center", gap: "10px" }}>
            <Input icon={undefined} text={"Duplex Type 1"} onChange={(value) => handleDuplexTypeChange(0, value)} value={getDuplexTypeValue(0)} />
            <Input icon={undefined} text={"Duplex Type 2"} onChange={(value) => handleDuplexTypeChange(1, value)} value={getDuplexTypeValue(1)} />
            <Input icon={undefined} text={"Duplex Type 3"} onChange={(value) => handleDuplexTypeChange(2, value)} value={getDuplexTypeValue(2)} />
          </div>
        )}
        <div style={{ display: "flex" }}>
          <div>{renderSquares()}</div>
        </div>
      </div>
    </div>
  );
};

export default GridDemo;
