import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

interface Chain {
  title: string;
  description: string;
  id: number;
}

interface Channel {
  channelId: number;
  channelName: string;
  chains: Chain[];
}

interface ChainOption extends Chain {
  channelName: string;
}

interface DropdownProps {
  channels: Channel[];
  initialValue?: Chain;
  onKeyAccountSelect: (chain: Chain) => void;
}

const DropdownComponent: React.FC<DropdownProps> = ({
  channels,
  initialValue,
  onKeyAccountSelect,
}) => {
  // Flatten the data into a single array of key accounts with channel names
  const options: ChainOption[] = React.useMemo(
    () =>
      channels.flatMap((channel) =>
        channel.chains.map((chain) => ({
          ...chain,
          channelName: channel.channelName,
        }))
      ),
    [channels]
  );

  // State to manage the selected key account
  const [value, setValue] = React.useState<ChainOption | null>(null);

  // Set initial value if provided
  React.useEffect(() => {
    if (initialValue) {
      const initialOption = options.find(
        (option) => option.id === initialValue.id
      );
      if (initialOption) {
        setValue(initialOption);
      }
    }
  }, [initialValue, options]);

  const handleChange = (event: any, newValue: ChainOption | null) => {
    setValue(newValue);
    if (newValue) {
      onKeyAccountSelect(newValue);
    }
  };

  return (
    <Autocomplete
      options={options}
      groupBy={(option) => option.channelName}
      getOptionLabel={(option) => option.title}
      value={value}
      onChange={handleChange}
      sx={{
        width: '360px',
        '&::placeholder': {
          textOverflow: 'ellipsis !important',
          color: 'blue'
        },
        '& .MuiAutocomplete-option': {

          color: '#383A49',
          fontFamily: 'Mulish',
          fontSize: '16px !important',
          fontStyle: 'normal',
          fontWeight: '400 !important',
          lineHeight: 'normal',
          // Adjust the height of each option
          minHeight: '47px',
          '&[data-focus="true"]': {
            backgroundColor: 'transparent',
            outline: 'none',
          }, // Set your desired option height here
          '&:hover': {
            backgroundColor: 'transparent',
          },
          // Remove default selected background
          '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
          },
          '&.Mui-focused': {
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
        },
        '& .MuiAutocomplete-groupLabel': {
          color: 'var(--Zen-Text, #383A49)',
          fontFamily: 'Mulish',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
          background: '#FFF',
        },
      }}
      componentsProps={{
        paper: {
          sx: {
            borderRadius: '6px',
            background: '#FFF',
            borderColor:'red',

         //   boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.07)',
            width: '450px',
            // Adjust the transform to shift the options box to the left
            transform: 'translateX(-90px)',
            // Adjust the height of the dropdown list
            maxHeight: '400px', // Set your desired max height here
            overflowY: 'auto', // Enable scrolling if content exceeds maxHeight
            '& .MuiAutocomplete-groupUl': {
              color: '#383A49',
              fontSize: '16px !important',
              fontStyle: 'normal',
              fontWeight: '400 !important',
            },
            '& .MuiListSubheader-root': {
              color: '#383A49',
              fontSize: '12px !important',
              fontStyle: 'normal',
              fontWeight: '700 !important',
            },
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="Select key account here"
          variant="outlined"
          sx={{
            background:'white',
            borderRadius: '8px',

            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              // Adjust the height of the input field
              minHeight: '47px', // Set your desired input height here

              '& fieldset': {
                borderColor: '#ECF3FB',

              },
              '&:hover fieldset': {
                borderColor: '#0179FF',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#0179FF',
                fontSize:'16px',
                fontWeight:'500',
                fontStyle:'italic',
                color:'red !important',
                // Remove default focus border color
                borderWidth: '1px',
              },
              
            },
            '& .MuiInputLabel-root': {
             color:'#383A49',
             fontSize:'16px',
             fontWeight:'500',
             fontStyle:'italic',
             top:'2px',
         
            },
            '&.Mui-focused .MuiInputLabel-root': {
           
              color:'red !important',
           
            },
            '& .MuiOutlinedInput-input': {
  '&:focus': {
    outline: 'none',
  },
  color: '#383A49', // Replace with your desired color
  fontFamily: 'Mulish',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
},
          }}
        />
      )}
    />
  );
};

export default DropdownComponent;
