import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

type MeasurementInputProps = {
  value: string;
  unit: string;
  onValueChange: (value: string) => void;
  onUnitChange: (unit: string) => void;
  width?: number;
  label:string;
  type?:string

};

const MeasurementInput: React.FC<MeasurementInputProps> = ({
  value,
  unit,
  onValueChange,
  onUnitChange,
  width=138,
  label,
  type="number"
}) => {
  return (
    <div style={{ alignSelf:"flex-start"}}>
  <Typography style={{fontSize:"12px",fontWeight:"600",color:"#9CA0AB", marginLeft:"10px"}}> {label}</Typography>

<Box sx={{ display: 'flex', alignItems: 'center', gap: 0 ,width,height:"36px", justifySelf:"flex-start"}}>
    <TextField
        type={type}
        value={value}
        onChange={(e) => onValueChange(e.target.value)}
        sx={{ '& .MuiOutlinedInput-root': {
          '& fieldset': {
              border:0, // Change newColor to your desired color
          },
          '&:hover fieldset': {
              borderColor: 'newColorHover', // Change newColorHover to your desired color for hover state
          },
          '&.Mui-focused fieldset': {
              borderColor: 'newColorFocused', // Change newColorFocused to your desired color for focused state
          },
      },}}
        InputProps={{
          endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
          style: { height: '36px' ,
            border: "0px solid #D9E7F8 !important",
            borderRadius: "56px",
            fontSize: "16px",
            fontWeight: 600,
            color:"#383A49",
            backgroundColor: "white",
            width: width,
            


        
        }, 
        }}
      />
    
    </Box>
    </div>
   
  );
};

export default MeasurementInput;
