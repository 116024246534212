import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../components/Step15 copy/sideBar/SideBar';
import DemoComponent from '../components/Step15 copy/Content/Content';
import { getShelfConfigById, updatePmiPositions, updateShelfConfig } from '../services/shelfConfigurationService';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface ShelfConfig {
  col_position: number;
  row_position: number;
  rowDistance: number;
  colDistance: number;
  widths: number[];
  pmiPositions: any[];
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Step5: React.FC = () => {
  const { id } = useParams<{ id?: string }>(); // Read the ID from the URL, may be undefined
  const navigate = useNavigate();

  const [pmiPositions, setPmiPositions] = useState<any[][][]>([]);
  const [isCleared, setIsCleared] = useState(false);
  const [col, setCol] = useState(2);
  const [row, setRow] = useState(2);
  const [rowDistance, setRowDistance] = useState(2);
  const [colDistance, setColDistance] = useState(2);
  const [widths, setWidths] = useState<number[]>([]);
  const [height, setHeight] = useState<number>(10);
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  const [hiddencells,setHiddencells] = useState([])

  useEffect(() => {
    const fetchShelfConfig = async () => {
      if (id) {
        try {
          const response = await getShelfConfigById(id);
          setCol(response.col_position);
          setRow(response.row_position);
          setRowDistance(response.rowDistance);
          setColDistance(response.colDistance);
          setWidths(response.widths.map((width: any) => width * 5)); // Multiply by 5
          setPmiPositions(response.pmiPositions || []);
          setHeight(response.height);
          setHiddencells(response.hiddencells)

        } catch (error) {
          console.error('Error fetching shelf configuration', error);
          setSnackbarMessage("Error fetching shelf configuration");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      }
    };

    fetchShelfConfig();
  }, [id]);

  const handleSave = async () => {
    if (id) {
      const shelfConfig = {
        pmiPositions,
        col_position: col,
        row_position: row,
        rowDistance: rowDistance,
        colDistance: colDistance,
        widths: widths.map(width => width / 5),
        height
      };

      setLoadingNext(true);
      try {
        await updatePmiPositions(id, pmiPositions);
        setSnackbarMessage("Shelf configuration updated successfully!");
        setSnackbarSeverity("success");
        navigate(`/step6/${id}`); // Navigate to the next step with the same ID
      } catch (error) {
        console.error('Error updating shelf configuration', error);
        setSnackbarMessage("Error updating shelf configuration");
        setSnackbarSeverity("error");
      } finally {
        setLoadingNext(false);
        setSnackbarOpen(true);
      }
    }
  };

  const handleBack = async () => {
    if (id) {
      const shelfConfig = {
        pmiPositions,
        col_position: col,
        row_position: row,
        rowDistance: rowDistance,
        colDistance: colDistance,
        widths: widths.map(width => width / 5),
        height
      };

      setLoadingBack(true);
      try {
        await updatePmiPositions(id, pmiPositions);
        setSnackbarMessage("Shelf configuration updated successfully!");
        setSnackbarSeverity("success");
        navigate(`/step3/${id}`); // Navigate back to the previous step with the same ID
      } catch (error) {
        console.error('Error updating shelf configuration', error);
        setSnackbarMessage("Error updating shelf configuration");
        setSnackbarSeverity("error");
      } finally {
        setLoadingBack(false);
        setSnackbarOpen(true);
      }
    }
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          <Sidebar
            id={id}
            hiddencells={hiddencells}

            pmiPositions={pmiPositions}
            clearClick={() => { setPmiPositions([]); setIsCleared(!isCleared); }}
            onSave={handleSave}
            onBack={handleBack}
            loadingNext={loadingNext}
            loadingBack={loadingBack}
          />
        </Grid>
        <Grid item xs={9}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#e0e0e0",
            }}
          >
            <DemoComponent
              onSelectedBoxesChange={setPmiPositions}
              selectedBoxes2={isCleared}
              col={col}
              row={row}
              rowDistance={rowDistance}
              colDistance={colDistance}
              widths={widths}
              selectedBoxes={pmiPositions}
              hiddencells={hiddencells}

            />
          </div>
        </Grid>
      </Grid>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Step5;
