import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Snackbar, Alert } from '@mui/material';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd';
import TowLines from '../../../assets/icons/TowLines.svg';
import {
  getOrderedBrands,
  reorderBrands,
} from '../../../services/configurationService';

interface ICar {
  id: number;
  text: string;
  imageSrc: string;
  minFacings: number;
}

interface CCOrderProps {
  height: number;
  boxPerRow: number;
  onChangeHappen: (value?:string) => void
}

const CCOrder: React.FC<CCOrderProps> = ({ height, boxPerRow,onChangeHappen }) => {
  const [items, setItems] = useState<ICar[]>([
    {
      imageSrc: 'Brand1.png',
      text: 'Marlboro',
      minFacings: 2,
      id: 4,
    },
    {
      imageSrc: 'Brand3.png',
      text: 'L&M',
      minFacings: 2,
      id: 3,
    },
    {
      imageSrc: 'Brand2.png',
      id: 2,
      text: 'PETRA',
      minFacings: 1,
    },
    {
      imageSrc: 'Brand4.png',
      text: 'RGD',
      minFacings: 1,
      id: 1,
    },
  ]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [loadingItem, setLoadingItem] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrderedBrands = async () => {
      setLoading(true);
      try {
        const data = await getOrderedBrands();
        console.log('data',data)
        const fetchedItems = data.map((id: number, index: number) => {
          const existingItem = items.find((item) => item.id === id);
          return (
            existingItem || {
              imageSrc: `Brand${index + 1}.png`,
              text: `Brand ${index + 1}`,
              minFacings: 1,
              id,
            }
          );
        });
        setItems(fetchedItems);
      } catch (error) {
        setError('Error fetching ordered brands');
        console.error('Error fetching ordered brands:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrderedBrands();
  }, []);

  const handleReorder = async (brandIds: number[]) => {
    try {
      await reorderBrands(brandIds);
      setSuccessMessage('Order updated successfully');
      onChangeHappen('CC')
    } catch (error) {
      setError('Error updating brand order');
      console.error('Error updating brand order:', error);
    } finally {
      setLoadingItem(null);
    }
  };

  const onChange = (
    sourceId: string,
    sourceIndex: number,
    targetIndex: number
  ) => {
    const nextState = swap(items, sourceIndex, targetIndex);
    setItems(nextState);
    const brandIds = nextState.map((item) => item.id);
    setLoadingItem(targetIndex.toString());
    handleReorder(brandIds);
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage(null);
    setError(null);
  };

  return (
    <Box className="App">
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id="items"
            boxesPerRow={boxPerRow}
            rowHeight={height}
            style={{ height: height * Math.ceil(items.length / boxPerRow) }}
          >
            {items.map((item: any, index: number) => (
              <GridItem key={item.id}>
                <div style={{ height: '100%', margin: '0.5px' }}>
                  <div
                    style={{
                      background: '#E1E3E6',
                      marginBottom: '2px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '12px',
                      fontWeight: '700',
                    }}
                  >
                    {index + 1}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      height: '79%',
                      borderRadius: '4px',
                      boxSizing: 'border-box',
                      border: '2px solid #E1E3E6',
                      padding: '2px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          marginBottom: '12px',
                        }}
                      >
                        <img
                          src={item.imageSrc}
                          width={36}
                          height={32}
                          draggable="false"
                          alt=""
                        ></img>
                      </div>
                      <div
                        style={{
                          fontSize: '10px',
                          fontWeight: '400',
                          color: '#383A49',
                          userSelect: 'none',
                        }}
                      >
                        {item.text}
                      </div>
                    </div>
                    <div
                      style={{
                        background: '#E4E7EC',
                        borderRadius: '2px',
                        height: '14px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img src={TowLines} alt="" />
                    </div>
                  </div>
                </div>
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
      )}
    </Box>
  );
};

export default CCOrder;
