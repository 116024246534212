import React, { useState } from "react";

import Sidebar from "../components/SKUList/sideBar/SideBar";
import Grid from "@mui/material/Grid";
import ContentComponent from "../components/SKUList/Content/Content";

const HomePage: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Grid container sx={{overflow:"hidden !important"}}>
        <Grid item xs={2.5}>
          <Sidebar setIsOpen={setIsOpen} ></Sidebar>
        </Grid>
        <Grid item xs={9.5} sx={{overflow:"hidden"}} >
          <div
              style={{
                width: "100%",
                maxHeight: '100vh', 
                height: '100vh',// Set a max-height to enable scrolling
                overflowY: 'auto', // Add vertical overflow control
                backgroundColor: "#e0e0e0",
              }}
          >
            <ContentComponent
            setIsOpen={setIsOpen}
            isOpen={isOpen}
              title="Welcome to PMI Shelf Composition"
              subtitle="Lorem ipsum description text holder"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HomePage;
