import makeStyles from '@mui/styles/makeStyles';


export const useStyles = makeStyles((theme) => ({
    container: {
      width: "100%",
      height: "100% !important",
      backgroundColor: "white !important",
      boxShadow: theme.shadows[2],
      display: "flex !important",
      flexDirection: "column",
      alignItems: "center !important",
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
    },
    logo: {
      maxWidth: "100%",
      maxHeight: "44px",
      marginBottom: theme.spacing(2),
      marginTop: "40px",
    },
    buttonContainer:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        marginBottom: "10px",
    },
   
    iconButton: {
      width: 34,
      height: 34,
      marginRight: theme.spacing(1),
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      color: "#0179FF",
    },
    svgIcon: {
      " & rect" : {
        fill:"#0179FF !important"

      },
      "& path": {
        fill:"white !important"
      }
    }
  }),{ name: 'SideBarStyles' });