import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box  >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface TabItem {
  title: string;
  content: React.ReactNode;
}

interface TabsComponentProps {
  tabs: TabItem[];
}

const TabsComponent: React.FC<TabsComponentProps> = ({ tabs }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value}   variant="fullWidth"  onChange={handleChange}  aria-label="basic tabs example">
          {tabs.map((tab, index) => (
            <Tab    label={tab.title} id={`simple-tab-${index}`} aria-controls={`simple-tabpanel-${index}`} key={index} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel value={value} index={index} key={index} >
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabsComponent;
