import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  Typography,
  CircularProgress
} from "@mui/material";
import Input from "../Input/Input";

interface BrandModalProps {
  open: boolean;
  handleClose: () => void;
  label: string;
  addService: (name: string) => Promise<{ data: { id: number; name: string } }>;
  onOptionAdded: (option: { label: string; value: string }) => void;
}

const BrandModal: React.FC<BrandModalProps> = ({ open, handleClose, label, addService, onOptionAdded }) => {
  const [itemName, setItemName] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setItemName('');
  }, [open]);

  const handleAddItem = async () => {
    setLoading(true);
    try {
      const response = await addService(itemName);
      setSnackbarMessage(`${label} added successfully`);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      onOptionAdded({ label: itemName, value: response?.data.id.toString() });
      handleClose();
    } catch (error) {
      setSnackbarMessage(`Error adding ${label}`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "15px !important",
            paddingBottom: "0",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h4">Add New {label}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography
              variant="h6"
              sx={{ marginBottom: "12px", color: "#9CA0AB" }}
            >
              To add a new {label}, please enter the name of the {label.toLowerCase()} you want to add.
            </Typography>
          </DialogContentText>
          <Input
            label={label}
            onChange={(value) => setItemName(value)}
            placeholder={`Enter ${label.toLowerCase()} name`}
            value={itemName}
            marginB={0}
          />
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex", width: "100%", gap: "8px" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleClose}
              sx={{
                marginBottom: "10px !important",
                background: "rgba(217, 231, 248, 0.50)",
                justifyContent: "center",
                color: "#383A49",
                flexBasis: "33%",
                "&:hover": {
                  color: "black",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleAddItem}
              sx={{
                marginBottom: "10px !important",
                backgroundColor: "#0179FF",
                color: "white",
                flexGrow: 1,
                justifyContent: "center",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#0179FF !important",
                  opacity: 0.8,
                },
              }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : `Add ${label}`}
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          variant="filled"
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BrandModal;
