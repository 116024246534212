import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error('API URL not defined in environment variables');
}

interface ShelfConfig {
  col_position?: number;
  row_position?: number;
  rowDistance?: number;
  colDistance?: number;
  height?: number;
  rrp?: any;
  marketingMaterials?: any;
  ledScreens?: any;
  facings?: any;
  widths?: any;
  pmiPositions?: any;
  question1?: any;
  question2?: any;
  isIqos?:string;
  duplex?:any;
  hiddencells?: boolean[][];
  key_account_id?:number;
  validFrom?:any;
  validTo?:any;
}

export const createShelfConfig = async (shelfConfig: ShelfConfig) => {
  try {
    const response = await axios.post(`${API_URL}/shelf-configs`, shelfConfig);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const updateShelfConfig = async (id: string, shelfConfig: ShelfConfig) => {
  try {
    const response = await axios.put(`${API_URL}/shelf-configs/${id}`, shelfConfig);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};


export const deleteShelfConfig = async (id: string) => {
  try {
    const response = await axios.delete(`${API_URL}/shelf-configs/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getAllShelfConfigs = async () => {
  try {
    const response = await axios.get(`${API_URL}/shelf-configs`);
    return response.data;
  } catch (error: any) {
    console.log('response', error);
    throw error.response.data;
  }
};

export const getShelfConfigById = async (id: string) => {
    try {
      const response = await axios.get(`${API_URL}/shelf-configs/${id}`);
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  };

  export const updatePmiPositions = async (id: string, pmiPositions: any[]) => {
    try {
      const response = await axios.put(`${API_URL}/shelf-configs/${id}/pmi-positions`, { pmiPositions });
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  };

  export const updateShelfConfigName = async (id: string, name: string) => {
    try {
      const response = await axios.put(`${API_URL}/shelf-configs/${id}/name`, { name });
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  };
  
  export const updateMarketingMaterials = async (id: string, marketingMaterialIds: string[]) => {
    try {
      const response = await axios.put(`${API_URL}/shelf-configs/${id}/marketing-materials`, { marketingMaterialIds });
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  };

  export const updateLedScreens = async (id: string, ledScreenIds: string[]) => {
    try {
      const response = await axios.put(`${API_URL}/shelf-configs/${id}/led-screens`, { ledScreenIds });
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  };

  export const updateFacings = async (id: string, facings: string[]) => {
    try {
      const response = await axios.put(`${API_URL}/shelf-configs/${id}/facings`, { facings });
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  };

  export const getShelfConfigBySearch = async (searchTerms: string) => {
    try {
      const response = await axios.get(`${API_URL}/shelf-configs/search`, {
        params: {
          searchTerms
        }
      });
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  };


  export const updateIsAutoConfiguration = async (id: string, isAutoConfiguration: boolean) => {
    try {
      const response = await axios.patch(`${API_URL}/shelf-configs/${id}/is-auto-configuration`, { isAutoConfiguration });
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  };