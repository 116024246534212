import { ReactComponent as Row } from "../../../../assets/icons/Row.svg";

import React from "react";
import { Box, Button, IconButton, CircularProgress } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Logo from "../../../../assets/images/Logo1.png";
import { ReactComponent as Back } from "../../../../assets/icons/Back.svg";
import { useStyles } from "./SideBarStyles";
import Input from "../Input/Input";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";

interface SidebarProps {
  onRowChange: (value: number) => void;
  row: number;
  onNext: () => void;
  loading: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ onRowChange, row, onNext, loading }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const index = searchParams.get('index');
  return (
    <Box className={classes.container}>
      <img src={Logo} alt="Logo" className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
            STEP 1/7
          </Typography>
          <Typography
            variant="h4"
            sx={{
              marginBottom: "12px",
              fontSize: {
                // // xs: '0.75rem',  // font size on extra-small screens
                // sm: '18px', // font size on small screens
                // md: '20px !important',     // font size on medium screens
                // lg: '24px', // font size on large screens
              },
            }}
          >
            New RRP Configuration
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "50px", maxWidth: '200px' }}>
            Select the number of rows for the RRP area
          </Typography>

          <Input
            onChange={onRowChange}
            value={row}
            icon={<Row />}
            max={12}
            min={1}
            text={"Rows"}
          ></Input>
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <IconButton
            onClick={() => {
              if (index) {
                navigate("/step17-new/"+index+'?index=1');
              } else {
                navigate("/sku-priority");
              }
            }}
            size="large">
            <Box component="span" className={classes.iconButton}>
              <Back />
            </Box>
          </IconButton>

          <Button variant="contained" color="info" onClick={onNext} sx={{ marginTop: "0px" }}>
            {loading ? <CircularProgress size={24} /> : "Next"}
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default Sidebar;
