import React from "react";

import Sidebar from "../components/sideBar/SideBar";
import Grid from "@mui/material/Grid";
import ContentComponent from "../components/SKUPriority copy/Content/Content";

const HomePage: React.FC = () => {
  return (
    <div>
      <Grid container>
        <Grid item xs={2.5}>
          <Sidebar></Sidebar>
        </Grid>
        <Grid item xs={9.5}>
          <div
             style={{
              width: "100%",
              maxHeight: '100vh', // Set a max-height to enable scrolling
              overflowY: 'auto', // Add vertical overflow control
              backgroundColor: "#e0e0e0",
            }}
          >
            <ContentComponent
              title="Welcome to PMI Shelf Composition"
              subtitle="Lorem ipsum description text holder"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HomePage;
