import makeStyles from "@mui/styles/makeStyles";
export const useStyles = makeStyles(
  (theme) => ({
    container: {
      overflow: "auto",
      width: "520px",
      height: "100% !important",
      backgroundColor: "white !important",
      boxShadow: theme.shadows[2],
      display: "flex !important",
      flexDirection: "column",
      alignItems: "center !important",
      padding: "0 24px",

      transition: "all 0.3s ease",
      position: "fixed", // or 'absolute', depending on your layout
      top: 0,
      right: 0,
      zIndex: 900, // Align to the right
    },
    logo: {
      maxWidth: "100%",
      maxHeight: "44px",
      marginBottom: theme.spacing(2),
      marginTop: "40px",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      marginBottom: "10px",
      width: "100%",
    },

    iconButton: {
      width: 32,
      height: 32,
      //marginRight: theme.spacing(1),
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgba(217, 231, 248, 0.50)",
      color: "#0179FF",
    },

    sidebarOpen: {
      right: 0, // Stick to the right edge when open
    },
    sidebarClosed: {
      right: "-650px", // Adjust as needed based on the width of your sidebar
    },
    toggleButton: {
      top: "0", // Adjust as needed
      right: `calc(100% - 270px)`, // Position it outside the sidebar
      transform: "translateY(0%)",
      zIndex: 801, // Above the sidebar to ensure visibility
      // Center it vertically
      // Add more styling for the button here
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: "520px", // This should match the sidebar's width to cover the rest of the screen
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black
      zIndex: 800, // Below the sidebar but above everything else
      transition: "all 0.3s ease", // Optional: Add transition for smooth appearance
    },
  }),
  { name: "SideBarStyles" }
);
