import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import StoreIcon from "../../assets/icons/KeyAccount.svg";
import BackIcon from "../../assets/icons/Back.svg";
import { Switch, SwitchProps, styled } from "@mui/material";
import Visibility from "../../assets/icons/Visb2.svg";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  createPosUnivers,
  getAllKeyAccounts,
  getPosUniversById,
  getShelfConfigsWithStoreCount,
  updatePosUnivers,
  assignShelfConfigToPos,
  getAllChannels,
  getAllPosUniverses,
} from "../../services/posuniversService"; // Import the assignShelfConfigToPos service
import ButtonCheckbox from "../POSUniverse/ButtonCheckBox/ButtonCheckBox";
import Input from "../POSUniverse/Input/Input";
import Dropdown from "../POSUniverse/Dropdown/Dropdown";
import { useStyles } from "./PosEditStyles";
import Modal from "./ModalComponent/Modal";
import ModalDropdown from "./ModalComponentDropDown/Modal";

import { getShelfConfigById } from "../../services/shelfConfigurationService"; // Import the service to get shelf config by ID
import { useNavigate } from "react-router-dom";
import ShelfPreview from "../Step19 copy 2/ShelfPreview";

interface SidebarProps {
  id?: number | null; // Optional ID prop for editing
}

interface OptionType {
  label: string;
  value: string;
}

const SideBarLeft: React.FC<SidebarProps> = ({ id }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<number | null>(null);
  const [checked, setChecked] = useState(false);
  const [selectedSize, setSelectedSize] = useState<number | null>(null);

  const [keyAccountOptions, setKeyAccountOptions] = useState<OptionType[]>([]);
  const [selectedKeyAccount, setSelectedKeyAccount] =
    useState<OptionType | null>(null);
    
    const [size, setSize] = useState("");
    const [channelId, setChannelId] = useState<number | undefined>();
 
    const [selectedChannel, setSelectedChannel] = useState<OptionType | null>(
      null
    );
    const [posName, setPosName] = useState("");
  const [posId, setPosId] = useState("");
  const [region, setRegion] = useState("");
  const [address, setAddress] = useState("");
  const [visibility, setVisibility] = useState(true);
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility
  const [dropdownModalOpen, setDropdownModalOpen] = useState(false); // State to manage modal visibility

  const [configurations, setConfigurations] = useState<any[]>([]); // State to hold configurations
  const [keyAccountID, setKeyAccountId] = useState<number>(); // State to hold configurations

  const [initialSelectedValues, setInitialSelectedValues] = useState<number[]>(
    []
  ); // State to hold initial selected values
  const [channelsOptions, setChannelsOptions] = useState<OptionType[]>( );
  const [assignedPlanogram, setAssignedPlanograms] = useState<any>(null); // State to hold the assigned planogram
  const [displayedPlanogram, setDisplayedPlanogram] = useState<any>(null); // State to hold the assigned planogram

  const [snackbarOpen, setSnackbarOpen] = useState(false); // State to manage snackbar visibility
  const [snackbarContent, setSnackbarContent] = useState(""); // State to hold snackbar content
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  ); // State to hold snackbar severity
  const [loading, setLoading] = useState(false); // State to manage loader
  const navigate = useNavigate();
  const planogramList = [
    {
      id: 1,
      name: "Planogram A",
      last_updated_at: "2024-11-01T10:00:00Z",
    },
    {
      id: 2,
      name: "Planogram B",
      last_updated_at: "2024-10-25T14:30:00Z",
    },
    {
      id: 3,
      name: "Planogram C",
      last_updated_at: "2024-10-20T08:15:00Z",
    },
    {
      id: 4,
      name: "Draft Planogram 4",
      last_updated_at: "2024-09-15T12:45:00Z",
    },
  ];
  
  useEffect(() => {
    const fetchKeyAccounts = async () => {
      try {
        setLoading(true);
        const keyAccounts = await getAllKeyAccounts();
        setKeyAccountOptions(
          keyAccounts.filter((value:any)=>value?.name != 'Individual Store').map((ka: any) => ({
            value: ka.id.toString(),
            label: ka?.name,
          }))
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching key accounts", error);
      }
    };
    const fetchChannels = async () => {
      try {
        setLoading(true);
        const channels = await getAllChannels();
        setChannelsOptions(
          channels.map((ka: any) => ({
            value: ka.id.toString(),
            label: ka?.name,
          }))
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching key accounts", error);
      }
    };

    const fetchPosUnivers = async (id: number) => {
      try {
        setLoading(true);
        const posUnivers = await getPosUniversById(id);
        console.log("posUnivers", posUnivers);
        setPosName(posUnivers?.name);
        setSelected(posUnivers.type === "key account" ? 2 : 1);
        setPosId(posUnivers.pos_id.toString());
        setRegion(posUnivers.region);
        setAddress(posUnivers.address);
        setChecked(posUnivers.iqos_partner);
        setVisibility(posUnivers.visibility);
        setSize(posUnivers.size);
        setChannelId(posUnivers.channel_id);
        setKeyAccountId(posUnivers.key_account_id);
        const channels = await getAllChannels()
        let channelSelected = channels.find(
          (c:any) => c.id === posUnivers.channel_id
        );
        if (channelSelected)
          setSelectedChannel({
            label: channelSelected?.name,
            value: channelSelected.id.toString(),
          });
        if (posUnivers.key_account_id) {
          const keyAccountsList = await getAllKeyAccounts();
          const keyAccount = keyAccountsList.find(
            (ka: any) => ka.id === posUnivers.key_account_id
          );
          setSelectedKeyAccount({
            value: keyAccount.id.toString(),
            label: keyAccount?.name,
          } as any);
        }
        if (posUnivers.shelf_configurations_ids) {
          const assignedPlanogramsData = await Promise.all(
         posUnivers.shelf_configurations_ids.map((id: number) =>
              getShelfConfigById(id.toString())
            )
          );
          
          setAssignedPlanograms(assignedPlanogramsData);
           
          const assingIds =      assignedPlanogramsData.map((val:any)=>{ return val.id})// Store the fetched planograms
          setInitialSelectedValues(
            assingIds as any
          ); // Use shelf_configurations_ids for initial values
          setDisplayedPlanogram(assignedPlanogramsData[0])
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching POS Univers", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchShelfConfigs = async () => {
      try {
        setLoading(true);
        const shelfConfigs = await getShelfConfigsWithStoreCount();
        console.log('shelfConfigs',shelfConfigs)
          console.log('shelfConfigs2',shelfConfigs.filter((value:any)=>value.key_account_id ==keyAccountID))
        setConfigurations(shelfConfigs.filter((value:any)=>value.key_account_id ==keyAccountID));
        console.log("shelfConfigs", shelfConfigs);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching shelf configurations", error);
      }
    };

    fetchKeyAccounts();
    fetchChannels()

    if (id) {
      fetchPosUnivers(id);
    }

    fetchShelfConfigs();
  }, [id,keyAccountID]);

  const handleAddKeyAccount = (newKeyAccount: OptionType) => {
    setKeyAccountOptions((prevOptions) => [...prevOptions, newKeyAccount]);
    setSelectedKeyAccount(newKeyAccount);
  };

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = async () => {
    if (!posName || !posId || !region || !address || !size ||
      !selectedChannel) {
      setSnackbarContent("Please fill in all required fields.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    const channels = await getAllChannels()
    const keyaccounts = await getAllKeyAccounts()
    const posUniversData = {
      name: posName,
      type: selected === 2 ? "key account" : "individual",
      posId: posId,
      region: region,
      address: address,
      iqosPartner: checked,
      visibility: visibility,
      size: size,
      channelId: channelId,
      ...(channels.some((x: any) => x?.name === selectedChannel?.label)
        ? {}
        : { newChannelName: selectedChannel?.label }),
      ...(keyaccounts.some((x: any) => x?.name === selectedKeyAccount?.label)
        ? {}
        : { newKeyAccount: selectedKeyAccount?.label }),
      keyAccountId:
        selected === 2 && selectedKeyAccount
          ? parseInt(selectedKeyAccount.value)
          : null,
    };

    try {
      setLoading(true);
      if (id) {
        await updatePosUnivers(id, posUniversData as any);
        setSnackbarContent("POS Univers updated successfully");
        setSnackbarSeverity("success");
      } else {
        await createPosUnivers(posUniversData as any);
        setSnackbarContent("POS Univers created successfully");
        setSnackbarSeverity("success");
      }
      setLoading(false);
      setSnackbarOpen(true);
    } catch (error) {
      setLoading(false);
      setSnackbarContent(`Error ${id ? "updating" : "creating"} POS Univers`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error(`Error ${id ? "updating" : "creating"} POS Univers`, error);
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleValueSelected = async (selectedConfiguration: any) => {
    console.log("Selected values:", selectedConfiguration);
    if ( id) {
      const selectedIds = selectedConfiguration.map((c:any) => c.id); // Extract IDs

      try {
        setLoading(true);
        const channels = await getAllChannels()
    const keyaccounts = await getAllKeyAccounts()
        const posUniversData = {
          name: posName,
          type: selected === 2 ? "key account" : "individual",
          posId: posId,
          region: region,
          address: address,
          iqosPartner: checked,
          visibility: visibility,
          size: size,
          shelfConfigurationsIds:selectedIds,
          channelId: channelId,
          ...(channels.some((x: any) => x?.name === selectedChannel?.label)
            ? {}
            : { newChannelName: selectedChannel?.label }),
          ...(keyaccounts.some((x: any) => x?.name === selectedKeyAccount?.label)
            ? {}
            : { newKeyAccount: selectedKeyAccount?.label }),
          keyAccountId:
            selected === 2 && selectedKeyAccount
              ? parseInt(selectedKeyAccount.value)
              : null,
        };
     //   await assignShelfConfigToPos(id, selectedConfiguration.id);
        await updatePosUnivers(id, posUniversData as any)
        setAssignedPlanograms(selectedConfiguration); // Update the assigned planogram
        setInitialSelectedValues(selectedIds); // Update initial selected values
        setSnackbarContent("Shelf configuration assigned successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setLoading(false);
        console.log("Shelf configuration assigned successfully");
      } catch (error) {
        setLoading(false);
        setSnackbarContent("Error assigning shelf configuration");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        console.error("Error assigning shelf configuration", error);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const parentRef = useRef<HTMLDivElement | null>(null);


  return (
    <Box className={classes.container}>
      <Box className={classes.sidebarContent}>
        <div className={classes.buttonContainer}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <IconButton
              onClick={() => navigate("/pos")}
              style={{ marginRight: "8px" }}
            >
              <img src={BackIcon} alt="Back" />
            </IconButton>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "0px",
                }}
              >
                <Typography variant="h4" sx={{ fontSize: "20px !important" }}>
                  {id ? "Edit POS" : "Add New POS"}
                </Typography>
              </div>
              <Typography
                variant="h6"
                sx={{
                  maxWidth: "244px",
                }}
              >
                {id ? "Edit the store details" : "Add a new store"}
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              gap: "10px",
              marginBottom: "16px",
              borderBottom: "0.5px solid rgba(156, 160, 171, 0.30)",
              paddingBottom: "-12px",
            }}
          >
            <img
              src={StoreIcon}
              width={50}
              height={50}
              style={{ marginTop: "4px" }}
              alt="Store Icon"
            />
            <Input
              label=""
              onChange={(e) => setPosName(e)}
              placeholder="Independent store name example"
              value={posName}
            />
          </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
         { channelsOptions &&   <Dropdown
                label="Channel"
                onSelect={(option: any) => {
                  setSelectedChannel(option);
                  setChannelId(parseInt(option.value));
                }}
                selectedOption={selectedChannel}
                onAdd={(opt: OptionType) => {
                  setSelectedChannel(opt);
                }}
                options={channelsOptions}
              />}
            </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
            <ButtonCheckbox
              option1Text={"Individual Store"}
              option2Text={"Key Accounts"}
              label={"Type"}
              onChange={(value) => setSelected(value)}
              selectedValue={selected}
            />
          </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
              <ButtonCheckbox
                option1Text={"Small"}
                option2Text={"Medium"}
                option3Text="Big"
                label="POS Size"
                selectedValue={
                  size?.length
                    ? size === "small"
                      ? 1
                      : size === "medium"
                      ? 2
                      : 3
                    : undefined
                }
                onChange={(value) => {
                  setSelectedSize(value);
                  setSize(
                    value === 1 ? "small" : value === 2 ? "medium" : "big"
                  );
                }}
              ></ButtonCheckbox>
            </div>
          {selected === 2 && (
            <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
              <Dropdown
                label="Key Account Chain"
                options={keyAccountOptions}
                onAddKeyAccount={handleAddKeyAccount}
                selectedOption={selectedKeyAccount}
                onSelect={setSelectedKeyAccount}
              />
            </div>
          )}
          <div style={{ display: "flex", gap: "10px", marginBottom: "0px" }}>
            <Input
              label="POS ID"
              onChange={(e) => setPosId(e)}
              placeholder="Enter POS ID"
              value={posId}
            />
          </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "0px" }}>
            <Input
              label="Region"
              onChange={(e) => setRegion(e)}
              placeholder="Type region here..."
              value={region}
            />
          </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "0px" }}>
            <Input
              label="Address"
              onChange={(e) => setAddress(e)}
              placeholder="Enter address"
              value={address}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "0px",
              flexDirection: "column",
            }}
          >
            <Typography variant={"h2"} style={{ marginBottom: "0px" }}>
              Partnership
            </Typography>
            <FormControlLabel
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#383A49",
                },
              }}
              control={
                <Checkbox
                  checked={checked}
                  sx={{ borderRadius: "3px", color: "#D9E7F8", padding: "8px" }}
                  onChange={handleChange}
                  name="checkedExample"
                  color="primary"
                />
              }
              label="IQOS Partner"
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "14px",
              marginBottom: "12px",
              padding: "16px",
              borderRadius: "10px",
              background: "#F5F9FF",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "14px",
                marginBottom: "1px",
              }}
            >
              <img
                src={Visibility}
                width={24}
                height={24}
                alt="Visibility Icon"
              />
              <div>
                <div
                  style={{
                    fontSize: "10px",
                    fontWeight: 400,
                    color: "#383A49",
                    opacity: 0.5,
                  }}
                >
                  {" "}
                  Visibility{" "}
                </div>
                <div style={{ fontSize: "14px" }}>
                  {" "}
                  {visibility ? "On" : "Off"}
                </div>
              </div>
            </div>
            <IOSSwitch
              sx={{ m: 1 }}
              checked={visibility}
              onChange={() => setVisibility(!visibility)}
            />
          </div>
          <div style={{ marginTop: "8px", display: "flex" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("/pos");
              }}
              fullWidth
              sx={{
                maxHeight: "56px",
                fontSize: "16px",
                fontWeight: "600",
                padding: "2px",
                marginTop: "0",
                background: "#ECF3FB",
                marginRight: "7px",
                color: "#383A49",
                "&:hover": {
                  color: "#383A49",
                },
              }}
            >
              <img src={BackIcon} alt="Back" style={{ marginRight: "8px" }} />
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                maxHeight: "56px",
                fontSize: "16px",
                fontWeight: "600",
                padding: "2px",
                marginTop: "0",
                background: "#0179FF",
                color: "white",
                "&:hover": {
                  color: "black",
                  background: "#0179FF",
                },
              }}
              onClick={handleSubmit}
            >
              {id ? "Update" : "Add"}
            </Button>
          </div>
        </div>
      </Box>
      <Box className={classes.content}>
        {loading ? (
          <Box className={classes.loaderContainer}>
            <CircularProgress />
          </Box>
        ) : assignedPlanogram?.length > 0 ? (
          <Box className={classes.assignedContent}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  color: "#383A49",
                  textAlign: "start",
                  alignSelf: "flex-start",
                  marginBottom: "20px",
                  textTransform: "uppercase",
                  width: "100%", // Make the text take the full width of the container
                }}
              >
                Active Planogram for this store
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  maxHeight: "36px",
                  fontSize: "14px",
                  fontWeight: "600",
                  padding: "10px 40px",
                  background: "#0179FF",
                  color: "white",
                  "&:hover": {
                    color: "black",
                    background: "#0179FF",
                  },
                }}
                onClick={handleOpenModal}
              >
                Reassign
              </Button>
           
            </div>

            <div
              style={{
                width: "100%",
                background: "white",
                padding: "14px",
                borderRadius: "10px",
                marginTop: "24px",
              }}
              ref={parentRef}

            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  color: "#383A49",
                  textAlign: "start",

                  alignSelf: "flex-start",
                  marginBottom: "10px",
                  width: "100%",
                }}
              >
                Active Planogram :{" "}
                {displayedPlanogram && displayedPlanogram?.name
                  ? displayedPlanogram?.name
                  : "Draft " + displayedPlanogram.id}
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    color: "#0179FF",
                    textAlign: "start",

                    marginBottom: "10px",
                  }}
                >
                  Last updated:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    textAlign: "start",

                    color: "#0179FF",
                    marginBottom: "10px",
                  }}
                >
                  {new Date(
                    displayedPlanogram.last_updated_at
                  ).toLocaleDateString()}
                </Typography>
                <IconButton
          onClick={() => setDropdownModalOpen(true)}
          sx={{
            padding: "8px",
            background: "#F5F9FF",
            borderRadius: "50%",
            marginTop:'-30px',
            alignSelf:'center',
            "&:hover": {
              background: "#e1e5ea",
            },
          }}
        >
 <ExpandMoreIcon />
         </IconButton>
              </div>
              <ModalDropdown
  open={dropdownModalOpen}
  handleClose={() => setDropdownModalOpen(false)}
  planogramList={assignedPlanogram}
  onValueSelected={(selected) => {
    console.log("Selected Planogram:", selected);
    setDisplayedPlanogram(selected)
  }}
  anchorEl={parentRef.current}

/>
            </div>

            <div
              style={{ transform: "scale(0.7)", transformOrigin: "center top" }}
            >
             {!dropdownModalOpen &&  <ShelfPreview id={displayedPlanogram.id.toString()} />}
            </div>
          </Box>
        ) : (
          <>
            <Typography
              sx={{
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                color: "#383A49",
                alignSelf: "flex-start",
                marginBottom: "20px",
                width: "100%", // Make the text take the full width of the container
                paddingLeft: "20px", // Add padding to the left to ensure it's not touching the edge
              }}
            >
              Active Planogram for this store
            </Typography>
            <div className={classes.contentTextContainer}>
              <Typography
                className={classes.mainText}
                sx={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  color: "#383A49",
                }}
              >
                No Planogram Assigned
              </Typography>
              <Typography
                className={classes.subText}
                sx={{
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  color: "#9CA0AB",
                  marginTop: "10px",
                  maxWidth: "177px",
                }}
              >
                Look for planograms that you would like to assign.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  maxHeight: "36px",
                  fontSize: "14px",
                  fontWeight: "600",
                  padding: "10px",
                  marginTop: "20px",
                  background: "#0179FF",
                  color: "white",
                  "&:hover": {
                    color: "black",
                    background: "#0179FF",
                  },
                }}
                onClick={handleOpenModal} // Open the modal on button click
              >
                Assign Planogram
              </Button>
            </div>
          </>
        )}
      </Box>
      <Modal
        open={modalOpen}
        handleClose={handleCloseModal}
        label="Planogram"
        onValueSelected={(value)=>{
          console.log('the value',value)
          
          handleValueSelected(value)}}
        configurations={configurations}
        initialSelectedValues={initialSelectedValues}
      />
   
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          variant="filled"
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarContent}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SideBarLeft;

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
