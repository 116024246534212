// AboutPage.tsx
import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import Sidebar from '../components/Step131/sideBar/SideBar';
import DemoComponent from '../components/Step131/Content/Content';


const Step3: React.FC = () => {
  const [boxCount, setBoxCount] = useState(10); // Example: set to 5 or any other number

  return (
    <div  style={{
      
      overflow: "hidden !important",

    }}>
      <Grid container style={{
      
      overflow: "hidden !important",

    }} >
        <Grid item xs={2.5} >
          <Sidebar  onChangeValue={(val)=>{setBoxCount(val)}} boxCount={boxCount}></Sidebar>
        </Grid>
        <Grid item xs={9.5} >
          <div
            style={{
              width: "100%",
              backgroundColor: "#e0e0e0",
              overflow: "hidden",

            }}
          >
           <DemoComponent boxCount={boxCount}></DemoComponent>
          </div>
        </Grid>
      </Grid>
    </div>
    
  );
};

export default Step3;
