import React, { useState } from "react";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomNumberInput from "../InputV2/InputV2";
import BoxList from "../BoxList/BoxList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customBox: {
      borderRadius: "5.134px",
      background: "#B5D8FF",
      width: "186.103px",
      height: "390.994px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      
    },
    secondBox: {
      width: "186.103px",
      height: "82.142px",
      flexShrink: 0,
      borderRadius: "3.85px",
      background: "#98C9FF",
      // marginTop: "10px",
    },
    lineContainer: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: "20px", // Adjust the margin as needed
    },
    line: {
      borderRight: "2px solid #000", // Change the color as needed
      height: "100%", // Height set dynamically to match the combined height
      position: "relative",
    },
    arrowIcon: {
      color: "#000", // Change the color as needed
      fontSize: "24px", // Adjust the size as needed
      position: "absolute",
      top: 0,
      left: -11,
      transform: "translateY(-50%) rotate(180deg)",
    },
    arrowIconDown: {
      color: "#000", // Change the color as needed
      fontSize: "24px", // Adjust the size as needed
      position: "absolute",
      bottom: 0,
      left: -12,
      transform: "translateY(-50%) rotate(360deg)",
    },
    subBox: {
      width: "492px",
      height: "107.5px",
      background: " #73B5FF",
      borderRadius: "8px",
      padding:"0 10px",
      display: "flex",
      justifyContent:"center",
      alignItems: "center",
      //gap:"2px"
    },
    subBoxSecond: {
      width: "492px",
      height: "107.5px",
      background: " #73B5FF",
      opacity:0.4,
      borderRadius: "8px",
      padding:"0 10px",
      display: "flex",
      justifyContent:"center",
      alignItems: "center",
      //gap:"2px"
    },
    subBoxEmpy: {
      width: "176.143px",
      height: "36.5px",
      background: " transparent",
      borderRadius: "4px",
    },
    control: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      " & > div ": {
        alignSelf: "center !important",
        marginBottom: "5px",
      },
    },
    whiteBox: {
      backgroundColor: "#FFFFFF",
      borderRadius: "4px",
      // Height and margin as per your design
      height: "80.5px",
      margin: "2px 2px",
    },
  })
);

interface GridDemoProps {
  boxCount:number
}

const GridDemo: React.FC<GridDemoProps> = ({boxCount}) => {
  const classes = useStyles();

  const renderBoxes = () => {
    return Array.from({ length: boxCount }, (_, index) => (
      <div
        key={index}
        className={classes.whiteBox}
        style={{ width: `calc(96% / ${boxCount})` }}
      />
    ));
  };
  const renderBoxesSecond = () => {
    return Array.from({ length: boxCount }, (_, index) => (
      <div
        key={index}
        className={classes.whiteBox}
        style={{ width: `calc(96% / ${boxCount})` }}
      />
    ));
  };
  const renderThreeBoxes = () => {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center', // This will ensure the middle box is always centered
        overflow: 'hidden', // This will clip the left and right boxes if they overflow
        width: '100%', 
        gap:"16px"
        // The container takes the full width of its parent
      }}>
         <div style={{ display: "flex",flexDirection:"column" }}>
          <div className={classes.subBoxSecond}>
            {renderBoxesSecond()}
          </div>

        </div>
        <div style={{ display: "flex",flexDirection:"column",marginTop:"16px" }}>
          <div className={classes.subBoxSecond}>
            {renderBoxesSecond()}
          </div>

        </div>
        <div style={{ display: "flex",flexDirection:"column" }}>
          <div className={classes.subBoxSecond}>
            {renderBoxesSecond()}
          </div>

        </div>
      </div>
    );
  };

  return (
 <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F5F9FF",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          backgroundColor: "#F5F9FF",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
          alignItems: "center",
          marginTop:"3%",
          marginBottom:"-3%",
          marginRight: "10px",
          overflow: "hidden",

          gap:"10px"
        }}
      >
        <div style={{ display: "flex",flexDirection:"column" }}>
          <div className={classes.subBox}>
            {renderBoxes()}
          </div>
        </div>
        {renderThreeBoxes()} 
        {renderThreeBoxes()} 
        {renderThreeBoxes()} 


      </div>

    </div>
    

   
  );
};

export default GridDemo;

