import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Sidebar from '../components/Step18 copy/sideBar/SideBar';
import DemoComponent from '../components/Step18 copy/Content/Content';
import SideBarRight from '../components/Step18 copy/sideBarRight/SideBarRight';
import SideBarRightYellow from '../components/Step18 copy/sideBarRightYellow/SideBarRight';
import { getFromJson, saveToJson } from '../components/helpers/localData';
import { getShelfConfigById, updateLedScreens, updateMarketingMaterials, updateShelfConfig } from '../services/shelfConfigurationService';
import { getMarketingMaterialById } from '../services/marketingMaterialsService';
import { getLedScreenById } from '../services/ledScreensService';
import { getRRPConfigurationById } from '../services/rrpConfigurationService';
import { useParams } from 'react-router-dom';
import { getRRPNewConfigById } from '../services/rrpNewConfigurationService';

type Square = {
  width: number;
  height: number;
  x: number;
  y: number;
  title?: string;
  positions?: any;
  id?: number;
  isAdded?: number;
};

type MarketingMaterial = {
  id?: number;
  title?: string;
  width: number;
  height: number;
  x: number;
  y: number;
  positions?: any;
};

const Step3: React.FC = () => {
  const [selectedPanel, setSelectedPanel] = useState(4);
  const [squares, setSquares] = useState<Square[]>(getFromJson<Square[]>("LedScreens") || []);
  const [squaresYellow, setSquaresYellow] = useState<Square[]>( []);
  const [squaresBlue, setSquaresBlue] = useState<Square[]>( []);
  const [pinkSquares, setPinkSquares] = useState<Square[]>(getFromJson<Square[]>("LedScreens") || squares);
  const [yellowSquares, setYellowSquares] = useState<Square[]>( squaresYellow);
  const [blueSquares, setBlueSquares] = useState<Square[]>( squaresBlue);
  const [loadingYellow, setLoadingYellow] = useState(true);
  const [loadingPink, setLoadingPink] = useState(true);
  const [col, setCol] = useState(2);
  const [row, setRow] = useState(2);
  const [rowDistance, setRowDistance] = useState(2);
  const [colDistance, setColDistance] = useState(2);
  const [widths, setWidths] = useState<number[]>([]);
  const [question, setQuestion] = useState<any[]>([]);
  const [question2, setQuestion2] = useState<any[]>([]);
  const [shelfDuplex, setShelfDuplex] = useState<boolean[][][]>([]);
  const [hiddencells,setHiddencells] = useState([])

  const [pmiPositions, setPmiPositions] = useState<any[][][]>([]);
  const { id } = useParams<{ id: string }>();
  const fetchShelfConfig = async (id: string) => {
    try {
      const shelfConfig = await getShelfConfigById(id);
      const marketingMaterialIds = shelfConfig.marketingMaterials || [];
      
      const materialMap = new Map<number, MarketingMaterial & { isAdded: number, instances: { x: number, y: number }[] }>();

      // Store materials with their respective x and y values
      for (const item of marketingMaterialIds) {
        const material = await getMarketingMaterialById(item.id);
        if (materialMap.has(material.id)) {
          const existingMaterial = materialMap.get(material.id)!;
          existingMaterial.isAdded! += 1;
          existingMaterial.instances.push({ x: item.x, y: item.y });
        } else {
          materialMap.set(material.id, { ...material, isAdded: 1, instances: [{ x: item.x, y: item.y }] });
        }
      }


      // Expand materials with their respective x and y values
      const expandedMaterials = Array.from(materialMap.values()).flatMap(material => 
        material.instances.map(instance => ({
          ...material,
          x: instance.x,
          y: instance.y,
          isAdded: 1 // Set isAdded to 1 for each instance in the expanded list
        }))
      );
      const rrpConfigurations = await Promise.all(
        (shelfConfig.rrp || []).map(async (rrp: { id: number, x: number, y: number }) => {
          const fullRRP = await getRRPNewConfigById(rrp.id.toString());
          return {
            ...fullRRP,
            x: rrp.x,
            y: rrp.y,
          };
        })
      );
      const newSquares = rrpConfigurations.map((value) => ({
        id: value.id,
        title: value?.name,
        width: value.widths[0] *5 || 250 ,
        height: (value.row_position /2) * 51.5+ (value.row_position /2 - 1) * (colDistance *5),
        x: value.x || 0,
        y: value.y || 0,
      }));
      setCol(shelfConfig.col_position);
      setRow(shelfConfig.row_position);
      setRowDistance(shelfConfig.rowDistance);
      setColDistance(shelfConfig.colDistance);
      setWidths(shelfConfig.widths.map((width: any) => width * 5));
      setPmiPositions(shelfConfig.pmiPositions || []);
      setBlueSquares(newSquares)
      setYellowSquares(expandedMaterials);
      setSquaresYellow(expandedMaterials);
      setQuestion(shelfConfig.question1)
      setQuestion2(shelfConfig.question2)
      setHiddencells(shelfConfig.hiddencells)
      initializeShelfDuplex(shelfConfig.duplex || [],shelfConfig.widths.map((width: any) => width * 5),shelfConfig.row_position)

    } catch (error) {
      console.error('Failed to fetch shelf configuration and marketing materials:', error);
    }
  };

  const fetchShelfConfigLed = async (id: string) => {
    try {
      const shelfConfig = await getShelfConfigById(id);
      const ledScreenIds = shelfConfig.ledScreens;
  
      const ledScreenMap = new Map<number, MarketingMaterial & { isAdded: number, instances: { x: number, y: number }[] }>();
  
      // Store LED screens with their respective x and y values
      for (const item of ledScreenIds) {
        const ledScreen = await getLedScreenById(item.id);
        if (ledScreenMap.has(ledScreen.id)) {
          const existingLedScreen = ledScreenMap.get(ledScreen.id)!;
          existingLedScreen.isAdded! += 1;
          existingLedScreen.instances.push({ x: item.x, y: item.y });
        } else {
          ledScreenMap.set(ledScreen.id, { ...ledScreen, isAdded: 1, instances: [{ x: item.x, y: item.y }] });
        }
      }
  
      // Expand LED screens with their respective x and y values
      const expandedLedScreens = Array.from(ledScreenMap.values()).flatMap(ledScreen => 
        ledScreen.instances.map(instance => ({
          ...ledScreen,
          x: instance.x,
          y: instance.y,
          isAdded: 1 // Set isAdded to 1 for each instance in the expanded list
        }))
      );
  
      setPinkSquares(expandedLedScreens);
    } catch (error) {
      console.error('Failed to fetch shelf configuration and LED screens:', error);
    }
  };
  useEffect(() => {

  
    id &&  fetchShelfConfig(id); // Replace with the actual shelf config ID
    id &&  fetchShelfConfigLed(id); // Replace with the actual shelf config ID

  }, [id]);





   const handleAddMaterial = async (material: MarketingMaterial) => {
    try {
      const existingMaterial = yellowSquares.find(m => m.id === material.id);
      let newYellowSquares;
      newYellowSquares = [...yellowSquares, { ...material, isAdded: 1, x: 0, y: 0 }];

  
      const marketingMaterialIds = newYellowSquares.map((m, index) => ({
        id: m.id?.toString() || '',
        x: m.x,
        y: m.y
      }));
  
      id &&   await updateMarketingMaterials((id), marketingMaterialIds as  any); 
      setYellowSquares(newYellowSquares);
      console.log('material tp add', material)

  // Replace with actual shelf config ID
    } catch (error) {
      console.error('Failed to update marketing materials:', error);
    }
  };

  useEffect(() => {
    const updatePositions = async () => {
      try {
        setLoadingYellow(true)
        const marketingMaterialIds = yellowSquares.map((m, index) => ({
          id: m.id?.toString() || '',
          x: m.x,
          y: m.y
        }));
        await updateMarketingMaterials((id || '15'), marketingMaterialIds as any); 
        setLoadingYellow(false)

      } catch (error) {
        console.error('Failed to update marketing materials:', error);
        setLoadingYellow(false)

      }
    };

    updatePositions();
  }, [yellowSquares,setYellowSquares]);
  useEffect(() => {
    const updatePositions = async () => {
      try {
        setLoadingPink(true);
        const ledScreenIds = pinkSquares.map((m, index) => ({
          id: m.id?.toString() || '',
          x: m.x,
          y: m.y
        }));
        id &&  await updateLedScreens((id ), ledScreenIds as any); 
        setLoadingPink(false);
  
      } catch (error) {
        console.error('Failed to update LED screens:', error);
        setLoadingPink(false);
      }
    };
  
    updatePositions();
  }, [pinkSquares, setPinkSquares,id]);
  const handleAddLedScreen = async (ledScreen: MarketingMaterial) => {
    try {
      const existingLedScreen = pinkSquares.find(m => m.id === ledScreen.id);
      let newPinkSquares;
      newPinkSquares = [...pinkSquares, { ...ledScreen, isAdded: 1, x: 0, y: 0 }];
  
      const ledScreenIds = newPinkSquares.map((m, index) => ({
        id: m.id?.toString() || '',
        x: m.x,
        y: m.y
      }));
  
    id &&   await updateLedScreens((id ), ledScreenIds as any); 
      setPinkSquares(newPinkSquares);
      console.log('LED screen to add', ledScreen);
  
      // Replace with actual shelf config ID
    } catch (error) {
      console.error('Failed to update LED screens:', error);
    }
  };

  const initializeShelfDuplex = (shelfDuplex:boolean[][][],columnWidths:number[],rowNumber:number) => {
    const savedBoxes = shelfDuplex;
    console.log('savedBoxes.length',savedBoxes)
    if (savedBoxes.length === 0 || !savedBoxes  ) {
      setShelfDuplex(
      columnWidths.map(columnWidth => {
        const boxesInColumn = Math.floor(columnWidth / 30.5);
        return new Array(rowNumber)
          .fill(null)
          .map(() => new Array(boxesInColumn).fill(false));
      }));
    }else {
      setShelfDuplex(savedBoxes)

    }
  };

  useEffect( ()=>{

    const updatePositions = async () => {
      try {
        setLoadingYellow(true)
        await updateShelfConfig(id!, { duplex: shelfDuplex });

        setLoadingYellow(false)

      } catch (error) {
        console.error('Failed to update duplex :', error);
        setLoadingYellow(false)

      }
    };

    updatePositions();

  },[shelfDuplex])
  console.log('shelfDuplex testy 3',shelfDuplex)
  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          <Sidebar onChange={(value) => { setSelectedPanel(value); }} squares={squares} question={question} question2={question2}></Sidebar>
        </Grid>
        <Grid item xs={9}>
          <div style={{ width: "100%", backgroundColor: "#e0e0e0" }}>
         {    <DemoComponent 
              selectionMode={selectedPanel} 
              squares={squares} 
              pinkSquares={pinkSquares} 
              setPinkSquares={setPinkSquares} 
              yellowSquares={yellowSquares} 
              setYellowSquares={setYellowSquares} 
              squaresYellow={squaresYellow} 
              blueSquares={blueSquares} 
              setBlueSquares={setBlueSquares} 
              squaresBlue={squaresBlue}
              col={col}
              row={row}
              rowDistance={rowDistance}
              colDistance={colDistance}
              widths={widths}
              selectedBoxesPmi={pmiPositions}
              shelfDuplex={shelfDuplex}
              setShelfDuplex={setShelfDuplex}
              hiddencells={hiddencells}


            />}
            {selectedPanel === 3  && question2.includes("LED Screens") && (
              <SideBarRight  
                onChange={(value) => { setSelectedPanel(value); }} 
                squaresYellow={pinkSquares} 
                loading={loadingPink}
                setLoading={setLoadingPink}

                onAddShelf={(w, h, t) => setPinkSquares([...pinkSquares, { width: parseInt(w), height: parseInt(h), title: t, x: 90, y: 90 }])} 
                onAddMaterial={handleAddLedScreen} 
              />
            )}
            {selectedPanel === 4 && question2.includes("Marketing Materials" ) && (
              <SideBarRightYellow 
                onChange={(value) => { setSelectedPanel(value); }} 
                squaresYellow={yellowSquares} 
  loading={loadingYellow}
  setLoading={setLoadingYellow}

                onAddShelf={(w, h, t) => setYellowSquares([...yellowSquares, { width: parseInt(w), height: parseInt(h), title: t, x: 90, y: 90 }])} 
                onAddMaterial={handleAddMaterial}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Step3;
