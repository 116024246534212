import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useParams, useNavigate } from "react-router-dom";
import { useStyles } from "./ContentStyles";
import RatioPicker from "../RatioPicker/RatioPicker";
import CCTab from "../CCTab/CCTab";
import Content from "../../Step1-mini copy/Content/Content";
import TabsComponent from "../TabComponent copy/Tab";
import RRPTab from "../RRPTab/RRPTab";
import DropdownComponent from "../DropDown/DropDown";
import DropdownComponentMulti from "../DropDownMulti/DropDown";

import { getAllChannels, getAllKeyAccounts, getGroupedChains, getKeyAccountById, getPosUniversesWithNotNullKeyAccount, getShelfConfigsWithStoreCount } from "../../../services/posuniversService";
import Modal from "../ModalComponentUpdate/Modal";
import ModalRRP from "../ModalComponentUpdateRRP/Modal"

import { getAllRRPConfigurations } from "../../../services/rrpConfigurationService";
import { getAllRRPNewConfigs, updateRRPNewConfig } from "../../../services/rrpNewConfigurationService";
import { updateFacings } from "../../../services/shelfConfigurationService";
import { Alert, Snackbar } from "@mui/material";
// Adjust the import path accordingly

interface ContentComponentProps {
  title: string;
  subtitle: string;
}

const ContentComponent: React.FC<ContentComponentProps> = ({
  title,
  subtitle,
}) => {
  const classes = useStyles();
  const { tab } = useParams<{ tab: string }>();
  const defaultTab = tab ? parseInt(tab, 10) - 1 : 0; // Convert tab param to zero-based index
  const navigate = useNavigate();

  const [selectedKeyAccount, setSelectedKeyAccount] = useState<any>(null);
  const [keyAccounts, setKeyAccounts] = useState<any[]>([]);
  const [channels, setChannels] = useState<any[]>([]);
  const [accountsStores, setAccountsStores] = useState<any[]>([]);
  const [channelsData, setChannelsData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false); // Loading state in the parent

  const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility
  const [modalOpenRRP, setModalOpenRRP] = useState(false); // State to manage modal visibility

  const [configurations, setConfigurations] = useState<any[]>([]); // State to hold configurations
  const [configurationsRRP, setConfigurationsRRP] = useState<any[]>([]); // State to hold configurations
  const [selectedConfiguration, SetSelectedConfiguration] = useState<any[]>([]); // State to hold configurations
  const [selectedConfigurationRRP, SetSelectedConfigurationRRP] = useState<any[]>([]); // State to hold configurations
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message
  const fetchShelfConfigs = async () => {
    try {
      const shelfConfigs = await getShelfConfigsWithStoreCount();
    
      setConfigurations(shelfConfigs.filter((value:any)=>value.key_account_id ==selectedKeyAccount.id));
      setConfigurations(
        shelfConfigs.filter((value: any) =>
          value.key_account_id === selectedKeyAccount.id &&
          value.facings && // Check if facings exist
          value.facings !== null && // Explicit null check
          value.facings[0] !== null &&
          value.facings.length > 0 // Check if the first element in facings is not null
        )
      );
      console.log('the configs',shelfConfigs.filter((value: any) =>
        value.key_account_id === selectedKeyAccount.id &&
        value.facings && // Check if facings exist
        value.facings !== null && // Explicit null check
        value.facings[0] !== null &&
        value.facings.length > 0 
         // Check if the first element in facings is not null
      ))
    } catch (error) {
      console.error("Error fetching shelf configurations", error);
    }
  };

  const fetchShelfConfigsRRP = async () => {
    try {
      const shelfConfigs = await getAllRRPNewConfigs();
      console.log('rrp configs',shelfConfigs)
      setConfigurationsRRP(shelfConfigs.filter((value:any)=>value.key_account_id ==selectedKeyAccount.id && hasImages(value.squaresData)));
      console.log("shelfConfigs", shelfConfigs);
    } catch (error) {
      console.error("Error fetching shelf configurations", error);
    }
  };

  useEffect(() => {
   


    

   
    fetchShelfConfigsRRP();
    fetchShelfConfigs();
  }, [selectedKeyAccount]);

  function removeImagesFromData(dataArray:any) {
    return dataArray.map((item:any) => {
      if (item.duplexTypes && Array.isArray(item.duplexTypes)) {
        const updatedDuplexTypes = item.duplexTypes.map((dt:any) => {
          const { images, ...rest } = dt; // remove images property
          return {
            ...rest,
            // If there are nested duplexTypes, recursively handle them as well
          };
        });
        return { ...item, duplexTypes: updatedDuplexTypes };
      }
      return item;
    });
  }
  function hasImagesInDuplexTypes(duplexTypes:any) {
    for (const dt of duplexTypes) {
      // If 'images' property exists on the current duplex type object, return true
      if ('images' in dt) {
        return true;
      }
      // If there are nested duplexTypes, recursively check them
      if (dt.duplexTypes && dt.duplexTypes.length > 0) {
        if (hasImagesInDuplexTypes(dt.duplexTypes)) {
          return true;
        }
      }
    }
    return false;
  }
  
  // Main function to check the entire data structure
  function hasImages(dataArray:any) {
    for (const item of dataArray) {
      if (item.duplexTypes && hasImagesInDuplexTypes(item.duplexTypes)) {
        return true;
      }
    }
    return false;
  }
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleCloseModalRRP = () => {
    setModalOpenRRP(false);
  };


  const handleValueSelected = async (selectedConfiguration: any) => {
    console.log("Selected values:", selectedConfiguration);

  };
  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const keyAccountsData = await getAllKeyAccounts();
        setKeyAccounts(keyAccountsData);

        const channelsData = await getAllChannels();
        setChannels(channelsData);

      

        const itemsChaine = keyAccountsData.map((account: any) => ({
          title: account.name,
          description: "Key account description",
          id: account.id,
        }));

        console.log("itemsChaine", itemsChaine);

        const groupedChains = await getGroupedChains()

       
        const Individual = keyAccountsData.find((value:any)=>value.name ==='Individual Store')
     
        setChannelsData([...groupedChains,{
          "channelId": 5,
          "channelName": "Individual Store",
          "chains": [
              {
                  "title": "Individual Store",
                  "description": "Key account description",
                  "id": Individual.id
              },
             
          ]
      }]);

        // Set the first channel's first key account as default selected
        if (groupedChains.length > 0 && groupedChains[1].chains.length > 0) {
          setSelectedKeyAccount(groupedChains[1].chains[0]);
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleKeyAccountSelect = (selectedChain: any) => {
    console.log("Selected Key Account:", selectedChain);
    setSelectedKeyAccount(selectedChain); // Update the selected key account
  };

  const onChangeHappen = (value?:string) => {
    if(value==='CC') {
      setModalOpen(true);
      console.log('selectedConfiguration',selectedConfiguration)
      // await updateFacings(id, []);


    }else {
setModalOpenRRP(true)

    }

  };
  const handleSnackbarClose = () => setSnackbarOpen(false); // Close Snackbar

  const tabs = [
    { title: "CC", content: <CCTab keyAccount={selectedKeyAccount}  onChangeHappen={onChangeHappen}/> },
    { title: "RRP", content: <RRPTab keyAccount={selectedKeyAccount}  onChangeHappen={onChangeHappen} /> },
    { title: "RRP CONFIGURATION", content: <Content title="" subtitle="" /> },
  ];

  // Wait for data to load before rendering the component
  if (isLoading) {
    return <div>Loading...</div>; // You can replace this with a spinner or skeleton loader
  }
 
  const updateAllFacings = async (data:any) => {
      if(data.length  > 0 ){
        try {
          // Loop through each item and call updateFacings
          setLoading(true);
          for (const item of data) {
              await updateFacings(item.id, []);
  
              console.log(`Facings updated for ID: ${item.id}`);
          }
          setModalOpen(false)

          setSnackbarMessage("All Configurations are Updated"); // Set success message
          setSnackbarOpen(true); // Show Snackbar
          setLoading(false);
          await fetchShelfConfigs();
          console.log("All facings updated successfully.");
      } catch (error) {
        setLoading(false); 
        setSnackbarMessage("Error updating facings!"); // Set error message
        setSnackbarOpen(true); 
          console.error("Error updating facings:", error);
      }
      }else {
        setModalOpen(false)

        setSnackbarMessage("No Configurations Selected"); // Set success message
        setSnackbarOpen(true); 
      }
   
};

const updateAllFacingsRRP = async (data:any) => {
console.log('rrp data',data)
  if(data.length  > 0 ){
    try {
      // Loop through each item and call updateFacings
      setLoading(true);
      for (const item of data) {
         const squaresData = item.squaresData
         const newData = removeImagesFromData(squaresData);
         console.log('Data After ermove auto allocation',newData)
         const config = {
          col_position: item.col,
          row_position: item.row,
          squaresData: newData,
          widths: item.widths,
          name:item.name,
        };
    
          await updateRRPNewConfig(item.id, config);
      }
      setModalOpen(false)
      setSnackbarMessage("All Configurations are Updated"); // Set success message
      setSnackbarOpen(true); // Show Snackbar
      setLoading(false); 
      await fetchShelfConfigsRRP();

      console.log("All facings updated successfully.");
  } catch (error) {
    setLoading(false); 
    setSnackbarMessage("Error updating facings!"); // Set error message
    setSnackbarOpen(true); 
      console.error("Error updating facings:", error);
  }
  }else {
    setModalOpen(false)

    setSnackbarMessage("No Configurations Selected"); // Set success message
    setSnackbarOpen(true); 
  }

};
  return (
    <div className={classes.root}>
      <div
        style={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Modal
        open={modalOpen}
        handleClose={handleCloseModal}
        label="Planogram"
        loading={loading}
        onValueSelected={(value)=>{
          console.log('the value',value)
          
          updateAllFacings(value)}}
        configurations={configurations}
        initialSelectedValues={[]}
      />
      <ModalRRP
        open={modalOpenRRP}
        handleClose={handleCloseModalRRP}
        loading={loading}

        label="Planogram"
        onValueSelected={(value)=>{
          updateAllFacingsRRP(value)
         }}
        configurations={configurationsRRP}
        initialSelectedValues={[]}
      />
        <div>
          <Typography variant="h4" gutterBottom>
            SKU Priority List
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ marginBottom: "32px" }}
            color="textSecondary"
          >
            Manage Your Automatic SKU Allocation Here
          </Typography>
        </div>

          <DropdownComponent
          channels={channelsData}
          onKeyAccountSelect={handleKeyAccountSelect}
         initialValue={selectedKeyAccount} // Pass the selected key account
        />
      </div>

      {/* <RatioPicker /> */}
      <div style={{ marginTop: "20px", width: "100%" }}>
        <TabsComponent tabs={tabs}   defaultTab={defaultTab} />
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={ snackbarMessage.includes("Nothing") ? 'info' :      snackbarMessage.includes("Error") ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContentComponent;
