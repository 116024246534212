import React, { useState } from "react";
import { Card, CardContent, IconButton } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Typography from "@mui/material/Typography";

import { useStyles } from "./CreatShelfCardStyles";
import { useNavigate } from "react-router-dom";
import BrandModal from "../ModalComponent/Modal";
interface CreateProps {
  groupedChains:any[]
  indId:number | undefined
}
const CreateShelfCard: React.FC<CreateProps> = ({groupedChains,indId}) => {
  const classes = useStyles();
const navigate = useNavigate()

const [openModal, setOpenModal] = useState(false);

const handleOpenModal = () => {
  setOpenModal(true);
};

const handleCloseModal = () => {
  setOpenModal(false);
};
  return (
    <Card className={classes.card}>
            <BrandModal  groupedChains={groupedChains} indId={indId} open={openModal} handleClose={handleCloseModal} label="Shelf"  />

      <CardContent className={classes.carContent}>
        <IconButton color="primary" className={classes.icon} onClick={handleOpenModal}>
          <AddIcon />
        </IconButton>
        <Typography variant="subtitle2" className={classes.textStyle}>
          Create New Shelf Composition
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CreateShelfCard;
