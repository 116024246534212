import React from "react";
import StoreIcon from "../../../assets/icons/KeyAccount.svg";
import Back from "../../../assets/icons/Back2.svg";
import Settings from "../../../assets/icons/settings.svg";
import MeasurementInput from "../InputV2/InputV2";

import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

// Define the types for the planogram and the component props
type Planogram = {
  id: number;
  name: string;
};

type ChainCardProps = {
  chainName: string;
  numberOfStores: number;
  planograms: Planogram[];
};

// The ChainCard component
const ChainCard: React.FC<ChainCardProps> = ({
  chainName,
  numberOfStores,
  planograms,
}) => {
  // Inline styles
  const cardStyle: React.CSSProperties = {
    borderRadius: "10px",
    padding: "16px",
    marginBottom: "12px",
    backgroundColor: "white",
    marginTop:"24px"
  };

  const titleStyle: React.CSSProperties = {
    fontSize: "24px",
    color: "#333",
  };

  const subtitleStyle: React.CSSProperties = {
    fontSize: "18px",
    color: "#666",
  };

  const planogramListStyle: React.CSSProperties = {
    listStyleType: "none",
    paddingLeft: "0",
  };
const navigate = useNavigate()
  const planogramItemStyle: React.CSSProperties = {
    padding: "10px ",
    background: "#F5F9FF",
    borderRadius: "6px",
    fontSize: "12px",
    fontWeight: "500",
    color: "#0179FF",
  };
  const rowStyle = {
    marginBottom: "5px",
  };
  return (
    <div style={cardStyle}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "14px",
            marginBottom: "12px",
          }}
        >
          <img src={Back} width={50} height={50} onClick={()=>navigate('/pos')}></img>

          <img src={StoreIcon} width={50} height={50}></img>
          <div>
            <div style={rowStyle}> {chainName}</div>
            <div style={rowStyle}> {numberOfStores}</div>
          </div>
        </div>
        <div>
          {/* <Button
            color="primary"
            variant="contained"
            onClick={() => {}}
            sx={{
              marginBottom: "10px !important",
              "background-color": "#0179FF",
              color: "white",
              width: "260px",
              justifyContent: "start",
              "&:hover": {
                color: "black", // Replace with your desired color
              },
            }}
            startIcon={
              <Box component="span">
                <img src={Settings} width={50} height={50}></img>
              </Box>
            }
          >
            Add New Store
          </Button> */}
        </div>
      </div>
      <div>
        <div style={{fontSize:"12px",fontWeight:"600",color:"#9CA0AB",textTransform:"uppercase",marginBottom:"8px",borderTop:"0.5px solid #E1E3E6", paddingTop:"12px",marginTop:"12px"}}>
        Planograms Included:

        </div>
        <div style={{ display: "flex", gap: "14px" }}>
          {planograms.slice(0, 10).map((planogram) => (
            <div key={planogram.id} style={planogramItemStyle}>
              {planogram.name}
            </div>
          ))}
          <div  style={{...planogramItemStyle, display:planograms.length-10 > 0 ? `block`:"none"}}>
             
              {planograms.length-5 > 0 ? `+${planograms.length-10}`:""}
            </div>
        </div>
        {/* <MeasurementInput  width={280} unit="" value="" onValueChange={()=>{}} onUnitChange={()=>{}} label={""} ></MeasurementInput> */}

      </div>
    </div>
  );
};

export default ChainCard;
