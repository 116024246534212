import React, { useState, useEffect } from 'react';
import SKUCard from '../SKUCard/SKUCard';
import CircularProgress from '@mui/material/CircularProgress'; // Add this import for the loader

interface SKUCardItem {
  title: string;
  imageUrl: string;
  id: number; // Add ID property
}

interface SKUCardListProps {
  items: SKUCardItem[];
  onEditClick: (id: number) => void; // Pass SKU ID to edit click handler
  loading: boolean; // Add loading prop
}

const SKUCardList: React.FC<SKUCardListProps> = ({ items, onEditClick, loading }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getGridTemplateColumns = () => {
    if (windowWidth >= 1200) return 'repeat(4, 1fr)';
    if (windowWidth >= 900) return 'repeat(3, 1fr)';
    if (windowWidth >= 600) return 'repeat(2, 1fr)';
    return '1fr';
  };

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: getGridTemplateColumns(),
    gridGap: '16px',
    padding: '16px 0',
    width: '100%' // Corrected 'with' to 'width'
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop:'20px' }}>
          <CircularProgress />
        </div>
      ) : (
        <div style={gridStyle}>
          {items.map((item, index) => (
            <SKUCard key={index} title={item.title} imageUrl={item.imageUrl} id={item.id} onEditClick={onEditClick} />
          ))}
        </div>
      )}
    </div>
  );
};

export default SKUCardList;
