import React, { ReactNode, ChangeEvent } from "react";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { InputAdornment, Box } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface InputProps {
  icon: ReactNode;
  text: string;
  onChange: (value: number) => void;
  value: number; // Add the value prop
  defaultValue?: number;
  type?: number;
  min?: number;
  max?: number;
  background?: string;
}

const Input: React.FC<InputProps> = ({ icon, text, onChange, value, defaultValue = 0, type = 1, min = 0, max = 9999, background = "white" }) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10) || 0;
    onChange(newValue);
  };

  const handleIncrement = () => {
    if (value < (max || 9999)) {
      onChange(value + 1);
    }
  };

  const handleDecrement = () => {
    if (value > (min || 0)) {
      onChange(value - 1);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        gap: "20px",
        marginTop: type === 2 ? "32px" : "0"
      }}
    >
      <div style={{ display: "flex", alignItems: "center", }}>
        <IconButton size="large">{icon}</IconButton>
        <Typography variant="h6" sx={{ fontSize: "12px", fontWeight: "400" }}>{text}</Typography>
      </div>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <IconButton
          onClick={handleDecrement}
          sx={{
            backgroundColor: 'white',
            borderRadius: '50%',
            width: '24px',
            height: '24px',
            border: '1px solid #D9E7F8',
            '&:hover': { backgroundColor: '#f0f0f0' }
          }}
          size="large">
          <RemoveIcon sx={{ fontSize: '16px' }} />
        </IconButton>

        <TextField
          size="small"
          type="number"
          value={value} // Set the value prop
          onChange={handleInputChange}
          sx={{
            textAlign: "center !important", // Center the text
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid #D9E7F8 !important',
              },
              '&:hover fieldset': {
                borderColor: 'green', // Add custom color for hover state if needed
              },
              '&.Mui-focused fieldset': {
                borderColor: 'blue', // Add custom color for focus state if needed
              },
              '& input[type="number"]': {
                '-moz-appearance': 'textfield',
                textAlign: "center !important", // Center the text
                '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
              },
            },
          }}
          InputProps={{
            inputProps: { min: min, max: max },
            style: {
              width: "62px",
              height: "32px",
              border: "0px solid #D9E7F8 !important",
              backgroundColor: background, // Override border here
              borderRadius: "5px",
              fontSize: "14px",
              fontWeight: 600,
              color: "#383A49"
            },
          }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
        />

        <IconButton
          onClick={handleIncrement}
          sx={{
            backgroundColor: 'white',
            borderRadius: '50%',
            width: '24px',
            height: '24px',
            border: '1px solid #D9E7F8',
            '&:hover': { backgroundColor: '#f0f0f0' }
          }}
          size="large">
          <AddIcon sx={{ fontSize: '16px' }} />
        </IconButton>
      </Box>
    </div>
  );
};

export default Input;
