import React, { ReactNode, ChangeEvent } from "react";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

interface InputProps {
  icon: ReactNode;
  text: string;
  onChange: (value: number) => void;
  value: number; // Add the value prop
  defaultValue?: number;
}

const Input: React.FC<InputProps> = ({ icon, text, onChange, value, defaultValue = 0 }) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10) || 0;
    onChange(newValue);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
        minWidth: "280px"
       
      }}
    >
      <div style={{ display: "flex", alignItems: "center" ,gap:"2px", }}>
        {icon}
        <Typography variant="h6">{text}</Typography>
      </div>

      <TextField
        size="small"
        type="number"
        value={value} // Set the value prop
        onChange={handleInputChange}
        InputProps={{
          inputProps: { min: 0, max: 99 },
          style: {
            width: "80px",
            height: "47px",
            border: "0px  !important",
            borderRadius: "10px",
            fontSize: "14px",
            fontWeight: 500,
            color:"#383A49",
            backgroundColor:"#F5F9FF"


          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
      />
    </div>
  );
};

export default Input;
