import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import { getFromJson, saveToJson } from "../../helpers/localData";
import { LeakRemoveTwoTone } from '@mui/icons-material';
// import { decreaseOrder, decreaseOrderChester, increaseOrder, increaseOrderChester, minchester, minimumFacing, packOrder, packOrderChester } from './data';
import { fetchSkusOrderedChesterfield, fetchSkusOrderedMarlboro, getAllSkus, getSkusOrdered, getSkusOrderedByBrand, getSkusOrderedByDecreaseOrder, getSkusOrderedByDecreaseOrderChesterfield, getSkusOrderedByDecreaseOrderMarlboro, getSkusOrderedByIncreaseOrder, getSkusOrderedByIncreaseOrderChesterfield, getSkusOrderedByIncreaseOrderMarlboro, getSkusWithMinFacingsChesterfield, getSkusWithMinFacingsMarlboro } from '../../../services/skuService';
import { getConfiguration, getOrderedBrands } from '../../../services/configurationService';
import Viewer from '../../RrpPreview/Viewer';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { read, utils, WorkBook, write, writeFile } from 'xlsx-js-style';
import ExcelJS from 'exceljs';
import { useParams } from 'react-router-dom';
import { getSkusWithPriority } from '../../../services/skuPriorityService';
import useStyles from './utils/GridDemoStyles'; // Import styles
import { handleExportToPDF ,handleExportToPNG,insertBase64ImageIntoExcel,exportToExcel} from './utils/exportFunctions';
import { updatePmiPositions2,updatePmiPositions,combineArrays,createFacingArray,initializeSelectedBoxesPMI,handleMouseUp,processSelectedBoxesOverlay,combineBooleanArrays,replaceWithEmptyString,initializeSelectedBoxes,orderByCustomOrder} from './utils/gridFunctions';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { getShelfConfigById, updateFacings } from '../../../services/shelfConfigurationService';

type Array3D = boolean[][][];

// Define your brand mapping
const brandMapping: Record<number, string> = {
  1: 'RGD',
  4: 'Marlboro',
  2: 'Petra',
  3: 'L&M',
};


type Square = {
  title?:string;
  width: number;
  height: number;
  x:number;
  y:number;
};

interface GridDemoProps {
  selectionMode?: number;
    squares:Square[]
    squaresYellow:Square[]
    squaresBlue:Square[]
    shelfDuplex: any[][][];
    setShelfDuplex: React.Dispatch<React.SetStateAction<any[][][]>>;
    pinkSquares: {
      width: number;
      height: number;
      x: number;
      y: number;
      title?: string | undefined;
      positions?: any;
  }[]
  setPinkSquares:React.Dispatch<React.SetStateAction<{
    title?: string | undefined;
    width: number;
    height: number;
    x: number;
    y: number;
}[]>>
yellowSquares:{
  width: number;
  height: number;
  x: number;
  y: number;
  title?: string | undefined;
  positions?: any;
}[]
setYellowSquares:React.Dispatch<React.SetStateAction<{
  title?: string | undefined;
  width: number;
  height: number;
  x: number;
  y: number;
}[]>>
blueSquares:{
  width: number;
  height: number;
  x: number;
  y: number;
  title?: string | undefined;
  positions?: any;
}[]
setBlueSquares:React.Dispatch<React.SetStateAction<{
  title?: string | undefined;
  width: number;
  height: number;
  x: number;
  y: number;
}[]>>
selectedSquare:{ col: number; row: number,z:number } | null;
squaresWithImages:Array<Array<Array<string>>>;
squaresWithNames:Array<Array<Array<string>>>;
squaresWithEan:Array<Array<Array<string>>>;
squaresWithWithds: Array<Array<Array<string>>>;
squaresWithHeights:Array<Array<Array<string>>>;
setSelectedSquare: React.Dispatch<React.SetStateAction<{
  col: number;
  row: number;
  z: number;

} | null>>;
col: number;
row: number;
rowDistance: number;
colDistance: number;
widths: number[];
selectedBoxesPmi: boolean[][][];
setIsLoading: React.Dispatch<React.SetStateAction<any >>
isAutoConfiguration:boolean;  // New prop for reset function
hiddencells: never[];
keyAccountId: number | null
}

export interface ChildRef {
  triggerChildFunction: () => void;
  triggerSecondChildFunction: () => void;

}
const GridDemo = forwardRef<ChildRef, GridDemoProps>((props: GridDemoProps, ref) => {

const  {
  selectionMode = 1 ,squares,squaresYellow,pinkSquares,setPinkSquares,yellowSquares,setYellowSquares,blueSquares,squaresWithImages,
  setSelectedSquare,
  selectedSquare,
  col,
  row,
  rowDistance,
  colDistance,
  widths,
  selectedBoxesPmi,
  setIsLoading,
  shelfDuplex,
  setShelfDuplex,
  squaresWithNames,
  isAutoConfiguration,
  squaresWithEan,
  hiddencells,
  keyAccountId,
  squaresWithWithds,
  squaresWithHeights
} = props
const classes = useStyles();
const [exportArray, setExportArray] = useState<any[]>(
  []
);
const [exportArrayEan, setExportArrayEan] = useState<any[]>(
  []
);

const { id } = useParams<{ id: string }>();

const exportToPDF = () => {
id &&  handleExportToPDF(containerRef, setIsLoading, id);
};
const exportToPNG = () => {
  return  handleExportToPNG(containerRef, setIsLoading);
};

useImperativeHandle(ref, () => ({
triggerChildFunction: exportToPDF,
triggerSecondChildFunction:async ()=>{
    const blob =   await exportToExcel(images,imagesLaying,columnWidths,row,col,shelfDuplex,RRPArea,marketingMaterialArea,ledScreenArea,'grid_data.xlsx',naming,
      ean,
      exportArray,
      exportArrayEan)
    const pngDataUrl = await exportToPNG();
    pngDataUrl && id &&    await insertBase64ImageIntoExcel(blob, pngDataUrl,id,col,row);
}
}));
  
  const handleSquareClick = (colIndex: number, rowIndex: number,boxIndex:number) => {
    setSelectedSquare({ col: colIndex, row: rowIndex, z:boxIndex});
  };

  const [facedArray, setFacedArray] = useState<string[]>([]);
  const [facedArrayNaming, setFacedArrayNaming] = useState<string[]>([]);
  const [facedArrayEan, setFacedArrayEan] = useState<string[]>([]);
  const [facedArrayLaying, setFacedArrayLaying] = useState<string[]>([]);
  const [facedArrayIds, setFacedArrayIds] = useState<string[]>([]);
  const [facedArrayIdsOriginal, setFacedArrayIdsOriginal] = useState<string[]>([]);
  const [facedArrayWidths, setFacedArrayWidths] = useState<string[]>([]);
  const [facedArrayHeights, setFacedArrayHeights] = useState<string[]>([]);

  const columnWidths = widths
  const [selectedBoxes, setSelectedBoxes] = useState(() => initializeSelectedBoxes(col,row,columnWidths));

  useEffect(() => {
    saveToJson('selectedBoxesDuplex', selectedBoxes);
  }, [selectedBoxes]);
  
  const [selectedBoxesPMI, setSelectedBoxesPMI] = useState(() => initializeSelectedBoxesPMI(selectedBoxesPmi,col,row,columnWidths));
  const [selectionBox, setSelectionBox] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [isSelecting, setIsSelecting] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [mergedBoxes, setMergedBoxes] = useState<
    Array<{ x: number; y: number; width: number; height: number }>
  >([]);



  const GetAllOverLayBoxs = () => {

    let pink = pinkSquares.map(({width,height,x,y})=>{
         return handleMouseUp((width*5),(height*5),x,y,columnWidths,selectedBoxesPMIOverLay,rowDistance,colDistance)
      })
     
      let yellow =  yellowSquares.map(({width,height,x,y})=>{
       return handleMouseUp((width*5),(height*5),x,y,columnWidths,selectedBoxesPMIOverLay,rowDistance,colDistance)
      })
      let blue =  blueSquares.map(({width,height,x,y})=>{
        return  handleMouseUp(width,height,x,y,columnWidths,selectedBoxesPMIOverLay,rowDistance,colDistance)
       })

      if(pink.length > 0 ||yellow.length > 0  || blue.length > 0  ){
        setSelectedBoxesPMIOverLay (combineArrays([...pink,...yellow,...blue]).map((value,index1)=> {
          return value.map((value2,index2)=>{
            return value2.map((value3,index3)=>{
              if( !selectedBoxesPMI[index1][index2][index3] ){
                return true
              } else {
                return value3
              }
            })
          })
        }))
        setRRPArea(blue)
        setMarketingMaterialArea(yellow)
        setLedScreenArea(pink)
      }else {
     // setSelectedBoxesPMIOverLay([...selectedBoxesPmi])

      }
  }
 
const [RRPArea, setRRPArea] = useState<any[][][]>([])
const [marketingMaterialArea, setMarketingMaterialArea] = useState<any[][][]>([])
const [ledScreenArea, setLedScreenArea] = useState<any[][][]>([])
const [selectedBoxesPMIOverLay, setSelectedBoxesPMIOverLay] = useState<any[][][]>(columnWidths.map(columnWidth => {
const boxesInColumn = Math.floor(columnWidth / 30.5);
    return new Array(row )
      .fill(null)
      .map(() => new Array(boxesInColumn).fill(false));
  }));


  useEffect(()=>{
    GetAllOverLayBoxs()
    updatePmiPositions(shelfDuplex || [],selectedBoxesPmi|| [])

  },[])
  const boxWidth = 30.5; // Fixed width of each box
  
  const [boxOrder, setBoxOrder] = useState(() => {
    let uniqueId = 0;
    const TheBaseArray =   combineBooleanArrays( updatePmiPositions2(shelfDuplex || [],selectedBoxesPMIOverLay,selectedBoxesPMIOverLay) ,     updatePmiPositions(shelfDuplex || [],selectedBoxesPMI || []))
    return TheBaseArray.map((col, colIndex) =>
      col.map((row, rowIndex) =>
        row.flatMap((isSelected, boxIndex) => {
          if (isSelected) return []; // Skip unselected boxes
  
          // Check if the current box is a duplex pusher
          const isDuplexPusher = shelfDuplex[colIndex]?.[rowIndex]?.[boxIndex];
  
          if (isDuplexPusher) {
            // If it's a duplex pusher, assign two IDs
            return [(uniqueId++).toString()];
          } else {
            // If not, assign one ID
            return [(uniqueId++).toString()];
          }
        })
      )
    );
  });

  const getOriginalOrder = () => {
    let uniqueId = 0;
    const TheBaseArray =   combineBooleanArrays( updatePmiPositions2(shelfDuplex || [],selectedBoxesPMIOverLay,selectedBoxesPMIOverLay) ,     updatePmiPositions(shelfDuplex || [],selectedBoxesPMI || []))
    console.log('TheBaseArray',TheBaseArray)

    return TheBaseArray.map((col, colIndex) =>
      col.map((row, rowIndex) =>
        row.flatMap((isSelected, boxIndex) => {
          if (isSelected) return []; // Skip unselected boxes
  
          // Check if the current box is a duplex pusher
          const isDuplexPusher = shelfDuplex[colIndex]?.[rowIndex]?.[boxIndex];
  
          if (isDuplexPusher) {
            // If it's a duplex pusher, assign two IDs
            return [(uniqueId++).toString()];
          } else {
            // If not, assign one ID
            return [(uniqueId++).toString()];
          }
        })
      )
    );
  }

  useEffect(()=>{
    let uniqueId = 0;
    const TheBaseArray =   combineBooleanArrays( updatePmiPositions2(shelfDuplex || [],selectedBoxesPMIOverLay,selectedBoxesPMIOverLay) ,     updatePmiPositions(shelfDuplex || [],selectedBoxesPMI || []))
    

    setBoxOrder(TheBaseArray.map((col, colIndex) =>
      col.map((row, rowIndex) =>
        row.flatMap((isSelected, boxIndex) => {
          if (isSelected) return []; // Skip unselected boxes
  
          // Check if the current box is a duplex pusher
          const isDuplexPusher = shelfDuplex[colIndex]?.[rowIndex]?.[boxIndex];
  
          if (isDuplexPusher) {
            // If it's a duplex pusher, assign two IDs
            return [(uniqueId++).toString()];
          } else {
            // If not, assign one ID
            return [(uniqueId++).toString()];
          }
        })
      )
    )) ;

  },[shelfDuplex,selectedBoxesPMIOverLay,selectedBoxesPMI])
  function reconstructBoxOrderFromItems(flatItems:any, capacities:any, numCols:any) {
    const newBoxOrder :any= [];
    let index = 0;
  
    for (let i = 0; i < capacities.length; i++) {
      const capacity = capacities[i];
      const items = flatItems.slice(index, index + capacity);
      index += capacity;
  
      // Calculate `colIndex` and `rowIndex` based on `i` and `numCols`
      const rowIndex = Math.floor(i / numCols);
      const colIndex = i % numCols;
  
      if (!newBoxOrder[colIndex]) newBoxOrder[colIndex] = [];
      newBoxOrder[colIndex][rowIndex] = items;
    }
  
    return newBoxOrder;
  }
  const handleDuplexPusherDrag = (result:any) => {
    const { source, destination, draggableId } = result;
    const sourceColIndex = parseInt(source.droppableId.split('-')[1], 10);
    const sourceRowIndex = parseInt(source.droppableId.split('-')[2], 10);
    const sourceItemIndex = source.index;
 
  
    const destinationColIndex = parseInt(destination.droppableId.split('-')[1], 10);
    const destinationRowIndex = parseInt(destination.droppableId.split('-')[2], 10);
    const destinationItemIndex = destination.index;
  
    // Flatten `boxOrder` with capacities
    const { flatItems, capacities, numCols } = flattenBoxOrderWithCapacities(boxOrder);
    
    // Build item to index map
    const itemToIndexMap:any = {};
    flatItems.forEach((item, index) => {
      itemToIndexMap[item] = index;
    });
  
    // Get the item values
    const sourceItem = boxOrder[sourceColIndex][sourceRowIndex][sourceItemIndex];
    const destinationItem = boxOrder[destinationColIndex][destinationRowIndex][destinationItemIndex];
  
    // Get global indices
    const sourceGlobalIndex = itemToIndexMap[sourceItem as any];
    const destinationGlobalIndex = itemToIndexMap[destinationItem as any];
  
    // Perform the move operation
    console.log('facedArrayIds.length',facedArrayIds)
    console.log('flatItems.length',flatItems)
    const fixedIndices = facedArrayIds
    .map((value, index) => (value.toString() === '9999'  ? index : -1))
    .filter(index => index !== -1)
   
    const newFlatItems = moveItem(flatItems, sourceGlobalIndex, destinationGlobalIndex,fixedIndices as any);
     

    const facedArrayIdsNew = moveItem([...facedArrayIds], sourceGlobalIndex, destinationGlobalIndex,fixedIndices);

    console.log('facedArrayIdsNew',facedArrayIdsNew)
    console.log('facedArrayIdsNew2',facedArrayIdsOriginal)

    // Reconstruct the `boxOrder`
    const newBoxOrder = reconstructBoxOrderFromItems(newFlatItems, capacities, numCols);
    setFacedArrayIds(facedArrayIdsNew)
    // Update the state
    setBoxOrder(newBoxOrder);
  
    // Update images or any other related state
    

   
    updateImagesWithNewOrder(newBoxOrder, images, imagesWithIndexs());
    updateImagesWithNewOrderLaying(newBoxOrder, imagesLaying, imagesWithIndexs());

};

  const onDragEnd = (result:any) => {
    if (!result.destination) return;
  
    const { source, destination,draggableId } = result;
    const isDuplexPusherPart = draggableId.startsWith('duplex-top-') || draggableId.startsWith('duplex-bottom-');

    if (isDuplexPusherPart) {
        // Handle duplex pusher parts separately
        handleDuplexPusherDrag(result);
        return;
    }
    // Parse indices
    const sourceColIndex = parseInt(source.droppableId.split('-')[1], 10);
    const sourceRowIndex = parseInt(source.droppableId.split('-')[2], 10);
    const sourceItemIndex = source.index;
  
    const destinationColIndex = parseInt(destination.droppableId.split('-')[1], 10);
    const destinationRowIndex = parseInt(destination.droppableId.split('-')[2], 10);
    const destinationItemIndex = destination.index;
    
    // Flatten `boxOrder` with capacities
    const { flatItems, capacities, numCols } = flattenBoxOrderWithCapacities(boxOrder);
    
    // Build item to index map
    const itemToIndexMap:any = {};
    flatItems.forEach((item, index) => {
      itemToIndexMap[item] = index;
    });
  
    // Get the item values
    const sourceItem = boxOrder[sourceColIndex][sourceRowIndex][sourceItemIndex];
    const destinationItem = boxOrder[destinationColIndex][destinationRowIndex][destinationItemIndex];
  
    // Get global indices
    const sourceGlobalIndex = itemToIndexMap[sourceItem as any];
    const destinationGlobalIndex = itemToIndexMap[destinationItem as any];
  
    // Perform the move operation
    const fixedIndices = facedArrayIds
    .map((value, index) => (value.toString() === '9999'  ? index : -1))
    .filter(index => index !== -1)
    const newFlatItems = moveItem(flatItems, sourceGlobalIndex, destinationGlobalIndex,fixedIndices as any);
    const facedArrayIdsNew = moveItem([...facedArrayIds], sourceGlobalIndex, destinationGlobalIndex,fixedIndices);
    console.log('facedArrayIdsNew',flatItems)
    console.log('facedArrayIdsNew2',facedArrayIds)


    // Reconstruct the `boxOrder`
    
    const newBoxOrder = reconstructBoxOrderFromItems(newFlatItems, capacities, numCols);
    setFacedArrayIds(facedArrayIdsNew)
    // Update the state
    

    

    setBoxOrder(newBoxOrder);
    // Update images or any other related state
    updateImagesWithNewOrder(newBoxOrder, images, imagesWithIndexs());
    updateHeightWithNewOrder(newBoxOrder, packheights, imagesWithIndexs());
    updateWidthWithNewOrder(newBoxOrder, packWidths, imagesWithIndexs());

    updateImagesWithNewOrderLaying(newBoxOrder, imagesLaying, imagesWithIndexs());

  };
  
  
  function flattenBoxOrderWithCapacities(boxOrder:any) {
    const flatItems = [];
    const capacities = [];
    const numRows = boxOrder[0].length; // assuming all columns have the same number of rows
    const numCols = boxOrder.length;    // number of columns
  
    for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
      for (let colIndex = 0; colIndex < numCols; colIndex++) {
        const items = boxOrder[colIndex][rowIndex];
        flatItems.push(...items);
        capacities.push(items.length);
      }
    }
  
    return { flatItems, capacities, numCols };
  }
  
  
  
  
  
  
  
  
  
  


const imagesWithIndexs = () => {
  let uniqueId = 0;
  const TheBaseArray =   combineBooleanArrays( updatePmiPositions2(shelfDuplex || [],selectedBoxesPMIOverLay,selectedBoxesPMIOverLay) ,     updatePmiPositions(shelfDuplex || [],selectedBoxesPMI || []))
  return TheBaseArray.map((col, colIndex) =>
    col.map((row, rowIndex) =>
      row.flatMap((isSelected, boxIndex) => {
        if (isSelected) return []; // Skip unselected boxes

        // Check if the current box is a duplex pusher
        const isDuplexPusher = shelfDuplex[colIndex]?.[rowIndex]?.[boxIndex];

        if (isDuplexPusher) {
          // If it's a duplex pusher, assign two IDs
          return [(uniqueId++).toString()];
        } else {
          // If not, assign one ID
          return [(uniqueId++).toString()];
        }
      })
    )
  );
};



  
  const updateImagesWithNewOrder = (newBoxOrder:any, images:any,imagesWithIndexs:any) => {
    const imagesWithIndex = imagesWithIndexs
   
    // Flattened structure of images for easy access by id
    let idxCount = -1
    const flatImages = images.flat(2).map((img:any, idx:any) => { 
        if(img != '') {
          idxCount++
          return   { id: idxCount, src: img }

        }else {
          return false
        }
      
  
  }).filter((item:any) => item !== false);
   
    // Map through newBoxOrder and update imagesWithIndex wherever there is an index mismatch
    const updatedImagesWithIndex = newBoxOrder.map((col:any, colIdx:any) =>
        col.map((row:any, rowIdx:any) =>
            row.map((boxId:any, boxIdx:any) => {
                const expectedImage = flatImages.find((img:any,index:any) => img.id.toString() === boxId);
                const currentImage = imagesWithIndex[colIdx]?.[rowIdx]?.[boxIdx];
                const currentImageSrc = images[colIdx]?.[rowIdx]?.[boxIdx];
                  
                  
                  
                  
                // If the current index in imagesWithIndex doesn't match boxOrder, update it
                if (currentImage && currentImage !== expectedImage?.id) {
                    return expectedImage ? expectedImage.src : ''; // Update to new image src if mismatched
                }

                return currentImageSrc ? currentImageSrc : ''; // Keep current if matches
            })
        )
    );
    // Finally, set the images with the updated order
  

    setUpdatedImages([...updatedImagesWithIndex]);
};
const updateHeightWithNewOrder = (newBoxOrder:any, heights:any,heightsWithIndexs:any) => {
  const imagesWithIndex = heightsWithIndexs
  console.log('height1',newBoxOrder)
  console.log('height2',heights)
  console.log('height3',heightsWithIndexs)

  // Flattened structure of images for easy access by id
  let idxCount = -1
  const flatImages = heights.flat(2).map((img:any, idx:any) => { 
      if(img != '') {
        idxCount++
        return   { id: idxCount, src: img }

      }else {
        return false
      }
    

}).filter((item:any) => item !== false);
 
  // Map through newBoxOrder and update imagesWithIndex wherever there is an index mismatch
  const updatedImagesWithIndex = newBoxOrder.map((col:any, colIdx:any) =>
      col.map((row:any, rowIdx:any) =>
          row.map((boxId:any, boxIdx:any) => {
              const expectedImage = flatImages.find((img:any,index:any) => img.id.toString() === boxId);
              const currentImage = imagesWithIndex[colIdx]?.[rowIdx]?.[boxIdx];
              const currentImageSrc = heights[colIdx]?.[rowIdx]?.[boxIdx];
                
                
                
                
              // If the current index in imagesWithIndex doesn't match boxOrder, update it
              if (currentImage && currentImage !== expectedImage?.id) {
                  return expectedImage ? expectedImage.src : ''; // Update to new image src if mismatched
              }

              return currentImageSrc ? currentImageSrc : ''; // Keep current if matches
          })
      )
  );
  // Finally, set the images with the updated order
  console.log('updatedImagesWithIndex',updatedImagesWithIndex)

  setUpdatedHeights([...updatedImagesWithIndex]);
};
const updateWidthWithNewOrder = (newBoxOrder:any, heights:any,heightsWithIndexs:any) => {
  const imagesWithIndex = heightsWithIndexs
  console.log('height1',newBoxOrder)
  console.log('height2',heights)
  console.log('height3',heightsWithIndexs)

  // Flattened structure of images for easy access by id
  let idxCount = -1
  const flatImages = heights.flat(2).map((img:any, idx:any) => { 
      if(img != '') {
        idxCount++
        return   { id: idxCount, src: img }

      }else {
        return false
      }
    

}).filter((item:any) => item !== false);
 
  // Map through newBoxOrder and update imagesWithIndex wherever there is an index mismatch
  const updatedImagesWithIndex = newBoxOrder.map((col:any, colIdx:any) =>
      col.map((row:any, rowIdx:any) =>
          row.map((boxId:any, boxIdx:any) => {
              const expectedImage = flatImages.find((img:any,index:any) => img.id.toString() === boxId);
              const currentImage = imagesWithIndex[colIdx]?.[rowIdx]?.[boxIdx];
              const currentImageSrc = heights[colIdx]?.[rowIdx]?.[boxIdx];
                
                
                
                
              // If the current index in imagesWithIndex doesn't match boxOrder, update it
              if (currentImage && currentImage !== expectedImage?.id) {
                  return expectedImage ? expectedImage.src : ''; // Update to new image src if mismatched
              }

              return currentImageSrc ? currentImageSrc : ''; // Keep current if matches
          })
      )
  );
  // Finally, set the images with the updated order
  console.log('updatedImagesWithIndex',updatedImagesWithIndex)

  setUpdatedWidths([...updatedImagesWithIndex]);
};
const updateImagesWithNewOrderLaying = (newBoxOrder:any, images:any,imagesWithIndexs:any) => {
  const imagesWithIndex = imagesWithIndexs
 
  // Flattened structure of images for easy access by id
  let idxCount = -1
  const flatImages = imagesLaying.flat(2).map((img:any, idx:any) => { 
      if(img != '') {
        idxCount++
        return   { id: idxCount, src: img }

      }else {
        return false
      }
    

}).filter((item:any) => item !== false);
 
  // Map through newBoxOrder and update imagesWithIndex wherever there is an index mismatch
  const updatedImagesWithIndex = newBoxOrder.map((col:any, colIdx:any) =>
      col.map((row:any, rowIdx:any) =>
          row.map((boxId:any, boxIdx:any) => {
            const expectedImage = flatImages.find((img:any,index:any) => img.id.toString() === boxId);
            const currentImage = imagesWithIndex[colIdx]?.[rowIdx]?.[boxIdx];
            const currentImageSrc = imagesLaying[colIdx]?.[rowIdx]?.[boxIdx];
        

              // If the current index in imagesWithIndex doesn't match boxOrder, update it
              if (currentImage && currentImage !== (expectedImage as any)?.id) {
                  return expectedImage ? expectedImage.src : ''; // Update to new image src if mismatched
              }

              return currentImageSrc ? currentImageSrc : ''; // Keep current if matches
          })
      )
  );

  console.log('squaresWithImages',squaresWithImages)
  console.log('updatedImagesWithIndex',updatedImagesWithIndex)
  console.log('facedArray',images)
  setUpdatedImagesLaying([...updatedImagesWithIndex]);

};

const previousBoxOrderRef = useRef(boxOrder);




const reconstructOriginalArray = (orderedArray:any, TheBaseArray:any) => {
  let uniqueId = 0; // Start from the first unique ID
  return TheBaseArray.map((col:any, colIndex:any) =>
    col.map((row:any, rowIndex:any) =>
    {  
      let idx = -1
      return row.map((isSelected:any, boxIndex:any) => {
        if (isSelected) {
          // Return an empty array for previously skipped (selected) boxes
          return '';
        } else {
          idx++;
          // Return the unique ID in its original position
          return orderedArray[colIndex][rowIndex][idx];
        
        }
      })}
    )
  );
};


console.log('facedArrayIds',facedArrayIds)
const getMatchingElements = (array1:any, array2:any) => {
  return array1.map((col:any, colIndex:any) =>
    col.map((row:any, rowIndex:any) =>
      row.map((value:any, boxIndex:any) => {
        // Check if the elements at the same position in both arrays are equal
        if (value != array2[colIndex]?.[rowIndex]?.[boxIndex]) {
          return parseInt(array2[colIndex]?.[rowIndex]?.[boxIndex]); // Return the matching value
        } else {
          return ""; // Return empty string for non-matching values
        }
      })
    )
  );
};
const getOriginalChanged = (array1:any, array2:any) => {
  return array1.map((col:any, colIndex:any) =>
    col.map((row:any, rowIndex:any) =>
      row.map((value:any, boxIndex:any) => {
        // Check if the elements at the same position in both arrays are equal
        if (value != '' && value != null ) {
          return value; // Return the matching value
        } else {
          return array2[colIndex]?.[rowIndex]?.[boxIndex]; // Return empty string for non-matching values
        }
      })
    )
  );
};




function countElementsBefore(structure:any, targetIndices:any) {
  let count = 0;
  let found = false;

  // Loop through the main structure (outer array)
  structure.some((col:any, colIndex:any) => {
      if (colIndex < targetIndices[0]) {
          // Sum up the elements in all previous columns, ignoring `true` values
          count += col.flat().filter((elem:any) => elem !== true).length;
      } else if (colIndex === targetIndices[0]) {
          // Loop through rows in the target column
          col.some((row:any, rowIndex:any) => {
              if (rowIndex < targetIndices[1]) {
                  // Add all elements in rows before the target row, ignoring `true` values
                  count += row.filter((elem:any) => elem !== true).length;
              } else if (rowIndex === targetIndices[1]) {
                  // Count elements within the target row up to the target index, ignoring `true` values
                  count += row.slice(0, targetIndices[2]).filter((elem:any) => elem !== true).length;
                  found = true;
                  return true; // Stop inner loop
              }
              return false; // Continue inner loop
          });
      }
      return found; // Stop outer loop if target is found
  });

  return count;
}
function countTrueBeforeFalse(array:any) {
  let count = 0;
  let foundFalseAfterTrue = false;


  for (let i = 0; i < array.length; i++) {
      if (array[i] === true) {
          // Count the first continuous block of `true` values
          if (!foundFalseAfterTrue) {
             
              count++;
          }
      } else {
          // Set flag once we encounter the first `false` after a `true`
          foundFalseAfterTrue = true;
      }
  }

  return count;
}
function countTrueBeforeFalseReversed(array:any,custom:number,index:number) {
  let count = 0;
  let foundFalseAfterTrue = false;
  if(array===null) {
    return 0
  }
  for (let i = 0; i < array.length; i++) {
      if (array[i] === false ) {
          // Count the first continuous block of `true` values
          if (!foundFalseAfterTrue) {
                if(countTrueBeforeFalse(selectedBoxesPMIOverLay[custom][index]) ==0 ) {
                  count++;

                }
          }
      } else {
          // Set flag once we encounter the first `false` after a `true`
          foundFalseAfterTrue = true;
      }
  }

  return count;
}
function countTrueBeforeIndex(array:any, index:any) {
  let count = 0;
    if( array===null) {
      return 0
    }
  for (let i = 0; i < index; i++) {
      if (array[i] === true) {
          count++;
      }
  }
  
  return count;
}
  const RenderBoxes = (customBoxIndex: number, setIndex: number, totalWidth: number) => {
    const dynamicBoxCount = boxOrder[customBoxIndex]?.[setIndex]?.length || 0;
    const currentBoxOrder = boxOrder[customBoxIndex]?.[setIndex] || [];
    const imagesForCurrentRow =  updatedImages.flat(Infinity).length > 0 ? updatedImages[customBoxIndex]?.[setIndex] || [] : images[customBoxIndex]?.[setIndex] || [];

    const duplexPushers = shelfDuplex || [];
    const boxWidth = 30.5;
      let boxIdIndex=0
     let nextAvailableIndex = 0;

      const items = useMemo(() => {
        let nextAvailableIndex = 0;
        return selectedBoxesPmi[customBoxIndex][setIndex].map((isSelected, index) => {
          const isDuplexPusher = duplexPushers[customBoxIndex]?.[setIndex]?.[index]
            && !selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex]?.[index];
          if (isDuplexPusher) {
            nextAvailableIndex += 2;
          } else {
            nextAvailableIndex += 1;
          }
    
          return {
          
            nextAvailableIndex,
            isDuplexPusher,
          
          };
        });
      }, [selectedBoxesPmi, customBoxIndex, setIndex, selectedBoxesPMIOverLay, duplexPushers])
    return (
        <Droppable droppableId={`droppable-${customBoxIndex}-${setIndex}`} direction="horizontal">
            {(provided,snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps} style={{ display: 'flex', }}>
                    {selectedBoxesPmi[customBoxIndex][setIndex].map((isSelected, index) => {
                        const {
                      
                          nextAvailableIndex,
         
                        } = items[index];

                        const currentIndex = nextAvailableIndex -2;
                        const nextIndex = currentIndex + 1;
                        const isDuplexPusher = duplexPushers[customBoxIndex]?.[setIndex]?.[index] 
                        && !selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex]?.[index];
                        boxIdIndex=(  !selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex]?.[index])  ? boxIdIndex : boxIdIndex + 1
                       const theUpdatedIndex =(index )-countTrueBeforeFalse(selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex]) +countTrueBeforeIndex(shelfDuplex[customBoxIndex]?.[setIndex] || null,index) -countTrueBeforeFalseReversed(selectedBoxesPMI[customBoxIndex]?.[setIndex],customBoxIndex,setIndex)
                       const imageHeight=  updatedHeights.flat(Infinity).length > 0  ? updatedHeights[customBoxIndex]?.[setIndex]?.[theUpdatedIndex] :  packheights[customBoxIndex]?.[setIndex]?.[index]
                       const imageWidth= updatedHeights.flat(Infinity).length > 0  ?updatedWidths[customBoxIndex]?.[setIndex]?.[theUpdatedIndex] :  packWidths[customBoxIndex]?.[setIndex]?.[index]

                       const theUpdatedIndex2 =(nextAvailableIndex-2 )-countTrueBeforeFalse(selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex])  -countTrueBeforeFalseReversed(selectedBoxesPMI[customBoxIndex]?.[setIndex],customBoxIndex,setIndex)
                       const theUpdatedIndex3 =(nextIndex)-countTrueBeforeFalse(selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex])  -countTrueBeforeFalseReversed(selectedBoxesPMI[customBoxIndex]?.[setIndex],customBoxIndex,setIndex)

                        const boxId = currentBoxOrder[index-countTrueBeforeFalse(selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex]) +countTrueBeforeIndex(shelfDuplex[customBoxIndex]?.[setIndex] || null,index) -countTrueBeforeFalseReversed(selectedBoxesPMI[customBoxIndex]?.[setIndex],customBoxIndex,setIndex) ] 
                        const boxIdNext = currentBoxOrder[index+1] 
                        const boxIdDuplex= currentBoxOrder[index-countTrueBeforeFalse(selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex])+countTrueBeforeIndex(shelfDuplex[customBoxIndex]?.[setIndex]||null,index) -countTrueBeforeFalseReversed(selectedBoxesPMI[customBoxIndex]?.[setIndex],customBoxIndex,setIndex) ]
                        const boxIdDuplexNext= currentBoxOrder[index-countTrueBeforeFalse(selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex])+countTrueBeforeIndex(shelfDuplex[customBoxIndex]?.[setIndex]||null,index)+1 -countTrueBeforeFalseReversed(selectedBoxesPMI[customBoxIndex]?.[setIndex],customBoxIndex,setIndex) ]

                 
                        const imageSrcLaying = updatedImagesLaying.flat(Infinity).length > 0  ? (updatedImagesLaying as any)[customBoxIndex]?.[setIndex]?.[theUpdatedIndex2]?.toString()?.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29') :  imagesLaying[customBoxIndex]?.[setIndex]?.[currentIndex]?.toString()?.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29');
                        const nextImageSrcLaying = updatedImagesLaying.flat(Infinity).length > 0  ?  (updatedImagesLaying as any)[customBoxIndex]?.[setIndex]?.[theUpdatedIndex3]?.toString()?.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29') :  imagesLaying[customBoxIndex]?.[setIndex]?.[nextIndex]?.toString()?.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29');

                        const imageSrcTop =  imagesForCurrentRow[ updatedImagesLaying.flat(Infinity).length > 0  ?theUpdatedIndex :currentIndex+1]
                            ?.toString()?.replace(/\s/g, '%20')
                            .replace(/\(/g, '%28')
                            .replace(/\)/g, '%29');
                        const imageSrcBottom = imagesForCurrentRow[ updatedImagesLaying.flat(Infinity).length > 0  ?theUpdatedIndex :nextIndex]
                            ?.toString()?.replace(/\s/g, '%20')
                            .replace(/\(/g, '%28')
                            .replace(/\)/g, '%29');

                        const isSelectedSquare =
                            selectedSquare &&
                            selectedSquare.col === customBoxIndex &&
                            selectedSquare.row === setIndex &&
                            selectedSquare.z === index;
                        const DuplexIndexTop= (currentIndex) -countTrueBeforeFalse(selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex]) -countTrueBeforeFalseReversed(selectedBoxesPMI[customBoxIndex]?.[setIndex],customBoxIndex,setIndex)
                        const DuplexIndexBottom= (nextIndex)-countTrueBeforeFalse(selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex]) -countTrueBeforeFalseReversed(selectedBoxesPMI[customBoxIndex]?.[setIndex],customBoxIndex,setIndex)
                                              
                                              //  if (isDuplexPusher) {
                                              //   nextAvailableIndex += 2; // Skip next index as it's used in duplex pusher
                                              // } else {
                                              //   nextAvailableIndex += 1;
                                              // }
                                              const globalIndex = customBoxIndex * setIndex + index;
                                                           
                        if (isDuplexPusher) {
                          return (
                              <div
                                  key={`duplex-pusher-${boxId}`}
                                  className={`${classes.whiteBox} ${selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex]?.[index] ? classes.OverlayBox : ''}`}
                                  style={{
                                 
                                      width: `${boxWidth}px`,
                                      display: 'flex',
                                      flexDirection: 'column',
                                      fontSize:'10px'
                                  }}
                              >
                                  {/* Top part of duplex pusher as a Draggable */}
                                  <Draggable
                                      key={`duplex-top-${boxIdDuplex}`}
                                      draggableId={`duplex-top-${boxIdDuplex}-${customBoxIndex}-${setIndex}-${index}`}
                                      index={DuplexIndexTop} // Unique index for the top part
                                      
                                  >
                                      {(provided, snapshot) => {
                                        
                                        return   <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            //  onClick={() => setSelectedSquare({ col: customBoxIndex, row: setIndex, z: index * 2 })}
                                              className={`${classes.whiteBox} ${isSelectedSquare ? classes.selectedBox : ''}`}
                                              style={{
                                                  width: '100%',
                                                  height: '45%',
                                                  zIndex:1,
                                                  backgroundImage: imageSrcLaying ? `url(${imageSrcLaying})` : '',
                                                  backgroundPosition: 'center',
                                                  backgroundRepeat: 'no-repeat',
                                                  backgroundColor: '#9747FF',

                                                  backgroundSize: 'contain',
                                                  border: isSelectedSquare ? '2px solid #0179FF' : 'none',
                                                  opacity: snapshot.isDragging ? 0.8 : 1,
                                                  ...provided.draggableProps.style,
                                              }}
                                          > 
                                          
                                          {/* {parseInt(boxIdDuplex)} /{DuplexIndexTop} */}
                                          
                                          </div>
                                      }}
                                  </Draggable>
                                  {/* Bottom part of duplex pusher as a Draggable */}
                                  <Draggable
                                      key={`duplex-bottom-${parseInt(boxIdDuplex)+shelfDuplex[customBoxIndex]?.[setIndex].filter(val=>val!=false).length}`}
                                      draggableId={`duplex-bottom-${boxIdDuplexNext}-${customBoxIndex}-${setIndex}-${index}`}
                                      index={DuplexIndexBottom} // Unique index for the bottom part
                                  >
                                      {(provided, snapshot) => (
                                          <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            //  onClick={() => setSelectedSquare({ col: customBoxIndex, row: setIndex, z: index * 2 + 1 })}
                                              className={`${classes.whiteBox} ${isSelectedSquare ? classes.selectedBox : ''}`}
                                              style={{
                                                  width: '100%',
                                                  height: '50%',
                                                  zIndex:1,
                                                  backgroundImage: nextImageSrcLaying ? `url(${nextImageSrcLaying})` : '',
                                                  backgroundPosition: 'center',
                                                  backgroundRepeat: 'no-repeat',
                                                  backgroundColor: '#9747FF',
                                                  backgroundSize: 'contain',
                                                  border: isSelectedSquare ? '2px solid #0179FF' : 'none',
                                                  opacity: snapshot.isDragging ? 0.8 : 1,
                                                  ...provided.draggableProps.style,
                                              }}
                                          > 
                                          {/* {parseInt(boxIdDuplexNext)  }/{DuplexIndexBottom} */}
    </div>
                                      )}
                                  </Draggable>
                              </div>
                          );
                      }else  if (isSelected && !selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex]?.[index] ) {
                            return (
                                <Draggable key={`box-${boxId}`} draggableId={`box-${boxId}`} index={index-countTrueBeforeFalse(selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex])+countTrueBeforeIndex(shelfDuplex[customBoxIndex]?.[setIndex]||null,index) -countTrueBeforeFalseReversed(selectedBoxesPMI[customBoxIndex]?.[setIndex],customBoxIndex,setIndex)} >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={`${classes.whiteBox} ${classes.selectedBox} ${
                                                selectedBoxes[customBoxIndex]?.[setIndex]?.[index] ? classes.whiteBoxSelected : ''
                                            } ${
                                                selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex]?.[index] ? classes.OverlayBox : ''
                                            }`}
                                        //    onClick={() => setSelectedSquare({ col: customBoxIndex, row: setIndex, z: index })}
                                            style={{
                                                width: `${boxWidth}px`,
                                                zIndex:1,
                                               backgroundImage: imageSrcTop ? `url(${imageSrcTop})` : '',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: isNaN(parseFloat(imageWidth)) || isNaN(parseFloat(imageHeight))
                                                ? 'contain'
                                                : `${parseFloat(imageWidth) * 4}px ${parseFloat(imageHeight) * 3.6}px`,
                                               backgroundColor: '#9747FF',
                                                border: isSelectedSquare ? '2px solid #0179FF' : 'none',
                                                opacity: snapshot.isDragging ? 0.8 : 1,
                                                ...provided.draggableProps.style,
                                            }}
                                        >
                                            {/* {boxId} /
                                            {index-countTrueBeforeFalse(selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex])+countTrueBeforeIndex(shelfDuplex[customBoxIndex]?.[setIndex],index) }/
                                            {} */}
                                        </div>
                                    )}
                                </Draggable>
                            );
                        }else {
                          return (
                            <div
                                key={`static-box-${index}`}
                                className={`${classes.whiteBox} ${
                                    selectedBoxes[customBoxIndex]?.[setIndex]?.[index] ? classes.whiteBoxSelected : ''
                                } ${
                                    selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex]?.[index] ? classes.OverlayBox : ''
                                }`}
                                style={{
                                    width: `${boxWidth}px`,
                                    zIndex:-1,
                                   // backgroundImage: imageSrcTop ? `url(${imageSrcTop})` : '',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    backgroundColor: isSelectedSquare ? 'lightgray' : 'white',
                                    border: '1px solid #ddd', // Gray border to differentiate non-draggable items
                                }}
                            >
                                {boxId}
                            </div>
                        );
                        }

                        // Render as a non-draggable static box for `false` items
                        
                    })}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

  
  


const combinedArray =    combineBooleanArrays( updatePmiPositions2(shelfDuplex || [],selectedBoxesPMIOverLay,selectedBoxesPMIOverLay) ,     updatePmiPositions(shelfDuplex || [],selectedBoxesPMI || []))

const flatArray = combinedArray.flat(Infinity);
const [falseCount,setFalseCount] = useState(flatArray.filter((item:any) => item === false).length)
useEffect(()=>{
const combinedArray =    combineBooleanArrays( updatePmiPositions2(shelfDuplex || [],selectedBoxesPMIOverLay,selectedBoxesPMIOverLay) ,     updatePmiPositions(shelfDuplex || [],selectedBoxesPMI || []))
  const falseCountGet = flatArray.filter((item:any) => item === false).length;
  setFalseCount(falseCountGet)
},[shelfDuplex,selectedBoxesPMIOverLay,selectedBoxesPMI])
const [images,setImages]  = useState <Array<Array<Array<string>>>>([])
const [naming,setNaming]  = useState <Array<Array<Array<string>>>>([])
const [packWidths,setPackWidths]  = useState <Array<Array<Array<string>>>>([])
const [packheights,setPackHeights]  = useState <Array<Array<Array<string>>>>([])
const [ean,setEan]  = useState <Array<Array<Array<string>>>>([])
const [imagesLaying,setImagesLaying]  = useState <Array<Array<Array<string>>>>([])
const [isAuto,setIsAuto] = useState(false)
const [updatedImages, setUpdatedImages] = useState([[[]]]);
const [updatedHeights, setUpdatedHeights] = useState([[[]]]);
const [updatedWidths, setUpdatedWidths] = useState([[[]]]);

const [updatedImagesLaying, setUpdatedImagesLaying] = useState([[[]]]);

useEffect(()=>{
if(updatedImages.flat(Infinity).length > 0) {
  const { flatItems, capacities, numCols } = flattenBoxOrderWithCapacities(getOriginalOrder());
  const newBoxOrderAfterChange = reconstructBoxOrderFromItems(facedArrayIds, capacities, numCols);
  const BoxOrderOriginalids = reconstructBoxOrderFromItems(facedArrayIdsOriginal, capacities, numCols);


  const TheBaseArray =   combineBooleanArrays( updatePmiPositions2(shelfDuplex || [],selectedBoxesPMIOverLay,selectedBoxesPMIOverLay) ,     updatePmiPositions(shelfDuplex || [],selectedBoxesPMI || []))


  const UpdateFacingsFetch = async () =>{
    const shelfConfig =id && await  getShelfConfigById(id);

    const matching =getMatchingElements(reconstructOriginalArray(BoxOrderOriginalids,TheBaseArray),reconstructOriginalArray(newBoxOrderAfterChange,TheBaseArray))
    console.log('reconstructOriginalArray(BoxOrderOriginalids)',reconstructOriginalArray(BoxOrderOriginalids,TheBaseArray))  
    console.log('reconstructOriginalArray(newBoxOrderAfterChange)',reconstructOriginalArray(newBoxOrderAfterChange,TheBaseArray))  

    console.log('matching',matching)  
    if(  shelfConfig &&
        shelfConfig.facings &&
        shelfConfig.facings != null &&
        shelfConfig.facings[0] != null) {


          id && await updateFacings(id,  matching)


        }else {
          id && await updateFacings(id,  matching)
        }
        // setUpdatedImages([])
        // setUpdatedImagesLaying([])
  }
  UpdateFacingsFetch()
}
 


},[updatedImages,facedArrayIds])


useEffect(()=>{
  setIsAuto(isAutoConfiguration)
},[isAutoConfiguration])

useEffect(()=>{
  processSelectedBoxesOverlay(
    selectedBoxesPMIOverLay,
    combinedArray,
    squaresWithImages,
    facedArray,
    facedArrayLaying,
    facedArrayNaming,
    facedArrayEan,
    squaresWithNames,
    isAuto,
    setImages,
    setImagesLaying,
    setNaming,
    setEan,
  setPackHeights,
setPackWidths,
squaresWithWithds,
squaresWithHeights,
facedArrayWidths,
facedArrayHeights
)
},[squaresWithImages,facedArray,isAuto,updatedImages])

useEffect(() => {
  // Fetch all the data on component mount
  const fetchData = async () => {
    if(keyAccountId) {
      const [ skusList,orderedBrands] = await Promise.all([
        getAllSkus(),
        getOrderedBrands()  
      ]);

        const MinFacingsKeyAccount = await getSkusWithPriority(keyAccountId, 'CC');
        const marlboroInc = MinFacingsKeyAccount
        .sort((a: any, b: any) => a.increase_order - b.increase_order);
      
      const skuPromises = orderedBrands.map((id) => {
        const brand = brandMapping[id];
        return getSkusOrderedByBrand(brand);
      });
      const results = await Promise.all(skuPromises);
      const combined = results.flat();
  
      const packOrder = combined
      .map((item: any) => item.id.toString())
      .filter((id: string) => {
        const minFacingItem = MinFacingsKeyAccount.find(
          (facingItem: any) => facingItem.id.toString() === id
        );
        return minFacingItem ? minFacingItem.min_facings > 0 : true;
      }); 
    
      const increaseOrder = marlboroInc
  .map((item: any) => item.id.toString())
  .filter((id: string) => {
    const minFacingItem = MinFacingsKeyAccount.find(
      (facingItem: any) => facingItem.id.toString() === id
    );
    return minFacingItem ? minFacingItem.min_facings > 0 : true;
  });

// Filter decreaseOrder based on min_facings and reverse it
const decreaseOrder = marlboroInc
  .map((item: any) => item.id.toString())
  .filter((id: string) => {
    const minFacingItem = MinFacingsKeyAccount.find(
      (facingItem: any) => facingItem.id.toString() === id
    );
    return minFacingItem ? minFacingItem.min_facings > 0 : true;
  })
  .reverse();
      const minimumFacing = MinFacingsKeyAccount.map((item: any) => {
        return { minFacing: item.min_facings, title: item.id.toString() };
      });
      const facedArrayMalboroNOrder =   createFacingArray( Math.ceil(falseCount * (100 * 0.01)), minimumFacing, packOrder, increaseOrder,decreaseOrder) ;
      const facedArrayMalboro =   orderByCustomOrder(facedArrayMalboroNOrder, packOrder) ;
     // Create a mapping of IDs to pack_image
     type PackItem = {
      id: string;
      pack_image: string;
    };
     const idToPackImageMap: { [key: string]: string } = {};
     (skusList as PackItem[]).forEach((item: PackItem) => {
       idToPackImageMap[item.id.toString()] = item.pack_image;
     });
     const idToPackImageMapNaming: { [key: string]: string } = {};
     (skusList as any[]).forEach((item: any) => {
      idToPackImageMapNaming[item.id.toString()] = (item as any).brandName + ' ' + (item as any).variantName;
     });
     const idToPackImageMapWidths: { [key: string]: string } = {};
     (skusList as any[]).forEach((item: any) => {
      idToPackImageMapWidths[item.id.toString()] = (item as any).width ;
     });
     const idToPackImageMapHeights: { [key: string]: string } = {};
     (skusList as any[]).forEach((item: any) => {
      idToPackImageMapHeights[item.id.toString()] = (item as any).height ;
     });
     const idToPackImageMapEAN: { [key: string]: string } = {};
     (skusList as any[]).forEach((item: any) => {
      idToPackImageMapEAN[item.id.toString()] = (item as any).ean_code ;
     });
     const idToPackImageMapLaying: { [key: string]: string } = {};
     (skusList as PackItem[]).forEach((item: PackItem) => {
      idToPackImageMapLaying[item.id.toString()] = (item as any).pack_image_laying;
     });
     const idToPackImageMapids: { [key: string]: string } = {};
     (skusList as PackItem[]).forEach((item: PackItem) => {
      idToPackImageMapids[item.id.toString()] = (item as any).id;
     });
      // Map the IDs to their corresponding pack_image
    const facedArrayMalboroWithImages = facedArrayMalboro.map(id => (idToPackImageMap[id]?.toString() || ''));
    const facedArrayMalboroWithNaming = facedArrayMalboro.map(id => (idToPackImageMapNaming[id]?.toString() || ''));
    const facedArrayMalboroWithean = facedArrayMalboro.map(id => (idToPackImageMapEAN[id]?.toString() || ''));
    const facedArrayMalboroWithids = facedArrayMalboro.map(id => (idToPackImageMapids[id]?.toString() || ''));
    const facedArrayMalboroWithWidths = facedArrayMalboro.map(id => (idToPackImageMapWidths[id]?.toString() || ''));
    const facedArrayMalboroWithHeights = facedArrayMalboro.map(id => (idToPackImageMapHeights[id]?.toString() || ''));
    const facedArrayMalboroWithImagesLaying = facedArrayMalboro.map(id => (idToPackImageMapLaying[id]?.toString() || ''));
    setFacedArray([...facedArrayMalboroWithImages]);
    setFacedArrayNaming([...facedArrayMalboroWithNaming]);
    setFacedArrayEan([...facedArrayMalboroWithean]);
    setFacedArrayWidths([...facedArrayMalboroWithWidths]);
    setFacedArrayHeights([...facedArrayMalboroWithHeights]);
    setFacedArrayLaying([...facedArrayMalboroWithImagesLaying]);

    const shelfConfig =id && await  getShelfConfigById(id);
     if(shelfConfig.facings && shelfConfig.facings.length > 0 ) {
      const TheBaseArray =   combineBooleanArrays( updatePmiPositions2(shelfDuplex || [],selectedBoxesPMIOverLay,selectedBoxesPMIOverLay) ,     updatePmiPositions(shelfDuplex || [],selectedBoxesPMI || []))
      const flatItemspre = TheBaseArray.map((col, colIndex) =>
        col.map((row, rowIndex) =>
          row.flatMap((isSelected, boxIndex) => {
            if (isSelected) return []; // Skip unselected boxes
    
            // Check if the current box is a duplex pusher
            const isDuplexPusher = shelfDuplex[colIndex]?.[rowIndex]?.[boxIndex];
    
            if (isDuplexPusher) {
              // If it's a duplex pusher, assign two IDs
              return shelfConfig.facings[colIndex][rowIndex][boxIndex];
            } else {
              // If not, assign one ID
              return shelfConfig.facings[colIndex][rowIndex][boxIndex];
            }
          })
        )
      );
      const { flatItems, capacities, numCols } = flattenBoxOrderWithCapacities(flatItemspre)
      
  
     const updatedFacings = facedArrayMalboroWithids.flat(Infinity).map((value:any,index:any)=>{
        if(flatItems[index]=='') {
          return value
        }else {
          return flatItems.flat(Infinity)[index]
        }
  
      })
      
      setFacedArrayIds([...updatedFacings]);
     }else {
      setFacedArrayIds([...facedArrayMalboroWithids]);

     }
   
    setFacedArrayIdsOriginal([...facedArrayMalboroWithids]);
    


    }
   
  };
   fetchData();
}, [falseCount]);

function flattenBoxOrder(boxOrder:[][][]) {
  const numRows = boxOrder[0].length;
  const flatArray = [];
  for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
    for (let colIndex = 0; colIndex < boxOrder.length; colIndex++) {
      flatArray.push(boxOrder[colIndex][rowIndex]);
    }
  }
  return flatArray;
}

// function moveItem(array:any, fromIndex:any, toIndex:any) {
//   const newArray = array.slice();
//   const [movedItem] = newArray.splice(fromIndex, 1);
//   newArray.splice(toIndex, 0, movedItem);
//   return newArray;
// }
function moveItem(array: any[], fromIndex: number, toIndex: number, fixedIndices: number[] = []) {
  // Create a set for faster lookups of fixed indices
  const fixedSet = new Set(fixedIndices);

  // Step 1: Extract movable items (exclude fixed indices)
  const movableArray = array.filter((_, index) => !fixedSet.has(index));

  // Step 2: Find adjusted fromIndex and toIndex in the movable array
  const movableFromIndex = array.slice(0, fromIndex + 1).filter((_, index) => !fixedSet.has(index)).length - 1;
  const movableToIndex = array.slice(0, toIndex + 1).filter((_, index) => !fixedSet.has(index)).length - 1;

  // Step 3: Move the item in the movable array
  const [movedItem] = movableArray.splice(movableFromIndex, 1);
  movableArray.splice(movableToIndex, 0, movedItem);

  // Step 4: Rebuild the original array with fixed items in their original positions
  const resultArray = [];
  let movableIndex = 0;

  for (let i = 0; i < array.length; i++) {
    if (fixedSet.has(i)) {
      // Place fixed items back at their original positions
      resultArray.push(array[i]);
    } else {
      // Place the next movable item
      resultArray.push(movableArray[movableIndex]);
      movableIndex++;
    }
  }

  return resultArray;
}








function reconstructBoxOrder(flatArray:any, numCols:any, numRows:any) {
  const newBoxOrder:any = [];
  let flatIndex = 0;

  for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
    for (let colIndex = 0; colIndex < numCols; colIndex++) {
      if (!newBoxOrder[colIndex]) newBoxOrder[colIndex] = [];
      newBoxOrder[colIndex][rowIndex] = flatArray[flatIndex];
      flatIndex++;
    }
  }
  return newBoxOrder;
}

console.log('boxorder',boxOrder)

return (
  <DragDropContext onDragEnd={onDragEnd}>
    <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#F5F9FF' }}>
      <div style={{ height: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          ref={containerRef}
          style={{
            display: 'flex',
            gap: rowDistance * 5 || 2,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            cursor: 'default',
          }}
        >
          {selectedBoxesPmi.map((col, customBoxIndex) => (
            <div
              key={customBoxIndex}
              style={{ display: "flex", flexDirection: 'column', gap: rowDistance * 5 || 2 }}
            >
              <div
                key={customBoxIndex}
                className={classes.customBox}
                style={{ gap: colDistance * 5 || 2, width: `${widths[customBoxIndex]+ 50 }px` }}
              >
                {col.map((row, setIndex) => (
                  <div
                    key={`${customBoxIndex}-${setIndex}`}
                    className={classes.subBox}
                    style={{
                      width: `${widths[customBoxIndex]}px`,
                      visibility:hiddencells[setIndex]&& hiddencells[setIndex][customBoxIndex] ? 'unset':'hidden'  
                                      }}
                  >
                    {RenderBoxes(customBoxIndex, setIndex, widths[customBoxIndex])}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </DragDropContext>
);

});

export default GridDemo;
