import React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';

type SKUCardProps = {
  imageUrl: string; // The URL of the image to display on the card
  title: string; // The title text to display on the card
  id: number; // Add SKU ID property
  onEditClick: (id: number) => void; // Pass SKU ID to edit click handler
};

const SKUCard: React.FC<SKUCardProps> = ({ imageUrl, title, id, onEditClick }) => {
  const cardStyle: React.CSSProperties = {
    width: '100%',
    height: '206px',
    background: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign:"left"
  };

  const imageStyle: React.CSSProperties = {
    width: '90%',
    height: '133px',
    borderRadius: '8px 8px 0px 0px',
    background: '#F5F9FF',
    justifyContent: 'center',
    display: 'flex',
    alignItems: "center"
  };

  const titleStyle: React.CSSProperties = {
    marginTop: '10px',
    fontWeight: 'bold'
  };

  const linkStyle: React.CSSProperties = {
    marginTop: '5px',
    fontSize: '12px',
    color: '#0179FF', // typical link color
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight:"400"
  };

  return (
    <div style={cardStyle}>
        <div style={imageStyle} >
        <img src={imageUrl} alt={title} style={{maxWidth:"90px",maxHeight:'120px'}} />

        </div>
        <div style={{alignSelf:"start",padding:"0 10px"}}>
        <Typography variant="subtitle2" color="text.primary" sx={{
          textTransform:'capitalize'
        }}>
            {title}
          </Typography>
      <a style={linkStyle} onClick={() => onEditClick(id)}>
        Edit Product
      </a>
        </div>
      
    </div>
  );
};

export default SKUCard;
