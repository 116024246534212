import React, { useState } from 'react';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";

const ColorCircle = styled('span')<{ color: string }>(({ color }) => ({
  display: 'inline-block',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  backgroundColor: color,
  //marginRight: '8px',
}));

const CheckIcon = styled('svg')({
  width: '14px',
  height: '11px',
  marginLeft: '8px',
});

const colors = ['#FF5733', '#3498DB', '#2ECC71', '#9B59B6']; // Add more colors as needed

const Dropdown: React.FC = () => {
  const [selectedColor, setSelectedColor] = useState<string>(colors[0]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedColor(event.target.value as string);
  };

  return (
    <div style={{display:"flex",justifyContent:"space-between",marginTop:"20px",alignItems:"center"}}>
      
      <Typography variant="h5">RRP Color</Typography>
      <Select
      value={selectedColor}
      onChange={handleChange as any}
      sx={{ width:"78px",borderRadius:"14px",backgroundColor:"#F5F9FF",
      height:"47px","& > div": {alignItems:"center",display:"flex",justifyContent:"center"},"fieldset": {border:"2px solid #0179FF", "&:active":{
        border:"2px solid red"
      }}}}
      MenuProps={{
        sx: {
          '& .MuiPaper-root': {
            borderRadius: '20px',
            width:"78px",
            marginTop:"8px"
             // Adjust border radius here
          },
        },
      }}
      renderValue={(selected) => <ColorCircle color={selected as string} />}
    >
      {colors.map((color, index) => (
        <MenuItem key={index} value={color}>
          <ColorCircle color={color} />
          {selectedColor === color && (
            <CheckIcon xmlns="http://www.w3.org/2000/svg">
              <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 5.5L4.5 9L12.5 1" stroke="#0179FF" stroke-width="2" stroke-linecap="round"/>
</svg>

            </CheckIcon>
          )}
        </MenuItem>
      ))}
    </Select>
    </div>
   
  );
};

export default Dropdown;
