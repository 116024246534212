import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import NoPartner from "../../../assets/icons/Nopartner.svg";
import Partner from "../../../assets/icons/Partner.svg";
import Iqos from "../../../assets/icons/Iqos.svg";
import makeStyles from "@mui/styles/makeStyles";
type ButtonCheckboxProps = {
  option1Text: string;
  option2Text: string;
  onChange: (value: boolean) => void;
  isPartner: boolean;
};

const useStyles = makeStyles({
  element: {
    display: "flex",
    gap: "2px",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const IqosPartnerCheckBox: React.FC<ButtonCheckboxProps> = ({
  option1Text,
  option2Text,
  onChange,
  isPartner,
}) => {
  const classes = useStyles();

  const handleToggle = () => {
    const newStatus = !isPartner;
    onChange(newStatus);
  };

  return (
    <div style={{ width: "100%" }}>
      {!isPartner ? (
        <div className={classes.element} onClick={handleToggle}>
          <img src={Partner} alt="Partner Icon" />
          <img src={Iqos} alt="Iqos Icon" />
          <Typography variant="h2" sx={{ color: "#0179FF" }}>
            PARTNER
          </Typography>
        </div>
      ) : (
        <div className={classes.element} onClick={handleToggle}>
          <img src={NoPartner} alt="No Partner Icon" />
          <Typography variant="h2">Not IQOS Partner</Typography>
        </div>
      )}
    </div>
  );
};

export default IqosPartnerCheckBox;
