import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from "../components/Step13 copy/sideBar/SideBar";
import DemoComponent from "../components/Step13 copy/Content/Content";
import { getShelfConfigById, updateShelfConfig } from '../services/shelfConfigurationService';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface shelfConfig {
  col_position: number;
  row_position: number;
  rowDistance: number;
  colDistance: number;
  widths: number[];
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Step3: React.FC = () => {
  const { id } = useParams<{ id?: string }>(); // Read the ID from the URL, may be undefined
  const navigate = useNavigate();
  const [height, setHeight] = useState<number>(10);
  const [shelfConfig, setShelfConfig] = useState<shelfConfig>();
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

  useEffect(() => {
    const fetchShelfConfig = async () => {
      if (id) {
        try {
          const response = await getShelfConfigById(id);
          setHeight(response.height || 10);
          setShelfConfig(response)
        } catch (error) {
          console.error('Error fetching shelf configuration', error);
          setSnackbarMessage("Error fetching shelf configuration");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      }
    };

    fetchShelfConfig();
  }, [id]);

  const handleSave = async () => {
    if (id && shelfConfig) {
      setLoadingNext(true);
      try {
        await updateShelfConfig(id, {
          height: height,
          col_position: shelfConfig.col_position,
          row_position: shelfConfig.row_position,
          rowDistance: shelfConfig.rowDistance,
          colDistance: shelfConfig.colDistance,
          widths: shelfConfig.widths,
        });
        setSnackbarMessage("Shelf configuration updated successfully!");
        setSnackbarSeverity("success");
        navigate(`/step5/${id}`); // Navigate to the next step with the same ID
      } catch (error) {
        console.error('Error updating shelf configuration', error);
        setSnackbarMessage("Error updating shelf configuration");
        setSnackbarSeverity("error");
      } finally {
        setLoadingNext(false);
        setSnackbarOpen(true);
      }
    }
  };

  const handleBack = async () => {
    if (id && shelfConfig) {
      setLoadingBack(true);
      try {
        await updateShelfConfig(id, {
          height: height,
          col_position: shelfConfig.col_position,
          row_position: shelfConfig.row_position,
          rowDistance: shelfConfig.rowDistance,
          colDistance: shelfConfig.colDistance,
          widths: shelfConfig.widths,
        });
        setSnackbarMessage("Shelf configuration updated successfully!");
        setSnackbarSeverity("success");
        navigate(`/step11/${id}`);
      } catch (error) {
        console.error('Error updating shelf configuration', error);
        setSnackbarMessage("Error updating shelf configuration");
        setSnackbarSeverity("error");
      } finally {
        setLoadingBack(false);
        setSnackbarOpen(true);
      }
    }
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          <Sidebar
            id={id}
            height={height}
            onHeightChange={setHeight}
            onSave={handleSave}
            onBack={handleBack}
            loadingNext={loadingNext}
            loadingBack={loadingBack}
          />
        </Grid>
        <Grid item xs={9}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#e0e0e0",
            }}
          >
            {shelfConfig && (
              <DemoComponent
                height={height}
                onHeightChange={setHeight}
              />
            )}
          </div>
        </Grid>
      </Grid>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Step3;
