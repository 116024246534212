import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { ReactComponent as Open } from "../../../assets/icons/Open.svg";
import { ReactComponent as Close } from "../../../assets/icons/Close.svg";
import StoreIcon from "../../../assets/icons/KeyAccount.svg";
import { Switch, SwitchProps, styled } from "@mui/material";
import Visibility from "../../../assets/icons/Visb2.svg";
import { useStyles } from "./SideBarStyles";
import Typography from "@mui/material/Typography";
import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import ButtonCheckbox from "../ButtonCheckBox/ButtonCheckBox";
import {
  createPosUnivers,
  getPosUniversById,
  updatePosUnivers,
  getAllKeyAccounts,
} from "../../../services/posuniversService";

interface SidebarProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id?: number | null; // Optional ID prop for editing
  channels: { id: number; name: string }[]; // List of channels
  keyaccounts: { id: number; name: string }[]; // List of key accounts
  onActionComplete?: () => void; // Callback to trigger data refresh in parent component
}

interface OptionType {
  label: string;
  value: string;
}

const SideBarRight: React.FC<SidebarProps> = ({
  isOpen,
  setIsOpen,
  id,
  onActionComplete,
  keyaccounts,
  channels,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<number | null>(null);
  const [checked, setChecked] = useState(false);
  const [selectedSize, setSelectedSize] = useState<number | null>(null);
  const [channelsOptions, setChannelsOptions] = useState<OptionType[]>(
    channels.map((x: { name: string; id: number }) => ({
      label: x.name,
      value: x.id.toString(),
    }))
  );

  const [keyAccountOptions, setKeyAccountOptions] = useState<OptionType[]>([]);
  const [selectedKeyAccount, setSelectedKeyAccount] =
    useState<OptionType | null>(null);
  const [selectedChannel, setSelectedChannel] = useState<OptionType | null>(
    null
  );
  const [posName, setPosName] = useState("");
  const [posId, setPosId] = useState("");
  const [region, setRegion] = useState("");
  const [address, setAddress] = useState("");
  const [visibility, setVisibility] = useState(true);
  const [size, setSize] = useState("");
  const [channelId, setChannelId] = useState<number | undefined>();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  useEffect(() => {
    setChannelsOptions(
      channels.map((x: { name: string; id: number }) => ({
        label: x.name,
        value: x.id.toString(),
      }))
    );
    const fetchKeyAccounts = async () => {
      try {
        const keyAccounts = await getAllKeyAccounts();
        setKeyAccountOptions(
          keyAccounts.filter((value:any)=>value.name != 'Individual Store').map((ka: any) => ({
            value: ka.id.toString(),
            label: ka.name,
          }))
        );
      } catch (error) {
        console.error("Error fetching key accounts", error);
      }
    };

    const fetchPosUnivers = async (id: number) => {
      try {
        const posUnivers = await getPosUniversById(id);
        setPosName(posUnivers.name);
        setSelected(posUnivers.type === "key account" ? 2 : 1);
        setPosId(posUnivers.pos_id.toString());
        setRegion(posUnivers.region);
        setAddress(posUnivers.address);
        setChecked(posUnivers.iqos_partner);
        setVisibility(posUnivers.visibility);
        setSelectedSize(posUnivers.size);
        setSize(posUnivers.size);
        setChannelId(posUnivers.channel_id);

        let channelSelected = channels.find(
          (c) => c.id === posUnivers.channel_id
        );
        if (channelSelected)
          setSelectedChannel({
            label: channelSelected.name,
            value: channelSelected.id.toString(),
          });
        if (posUnivers.key_account_id) {
          const keyAccountsList = await getAllKeyAccounts();
          const keyAccount = keyAccountsList.find(
            (ka: any) => ka.id === posUnivers.key_account_id
          );
          setSelectedKeyAccount({
            value: keyAccount.id.toString(),
            label: keyAccount.name,
          } as any);
        }
      } catch (error) {
        console.error("Error fetching POS Univers", error);
      }
    };

    fetchKeyAccounts();

    if (id) {
      fetchPosUnivers(id);
    } else {
      setPosName("");
      setSelected(null);
      setPosId("");
      setRegion("");
      setAddress("");
      setChecked(false);
      setVisibility(true);
      setSize("");
      setChannelId(undefined);
      setSelectedChannel(null);
      setSelectedKeyAccount(null);
    }
  }, [id, isOpen, channels]);

  const handleAddKeyAccount = (newKeyAccount: OptionType) => {
    setKeyAccountOptions((prevOptions) => [...prevOptions, newKeyAccount]);
    setSelectedKeyAccount(newKeyAccount);
  };
  const handleAddChannel = (newChannel: OptionType) => {
    setChannelsOptions((prevOptions) => [...prevOptions, newChannel]);
    setChannelId(parseInt(newChannel.value));
    setSelectedChannel(newChannel);
  };

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = async () => {
    if (
      !posName ||
      !posId ||
      !region ||
      !address ||
      (selected === 2 && !selectedKeyAccount) ||
      !size ||
      !selectedChannel
    ) {
      setSnackbarMessage("Please fill in all required fields.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    const posUniversData = {
      name: posName,
      type: selected === 2 ? "key account" : "individual",
      posId: posId,
      region: region,
      address: address,
      iqosPartner: checked,
      visibility: visibility,
      size: size,
      channelId: channelId,
      ...(channels.some((x: any) => x?.name === selectedChannel?.label)
        ? {}
        : { newChannelName: selectedChannel?.label }),
      ...(keyaccounts.some((x: any) => x?.name === selectedKeyAccount?.label)
        ? {}
        : { newKeyAccount: selectedKeyAccount?.label }),
      keyAccountId:
        selected === 2 && selectedKeyAccount
          ? parseInt(selectedKeyAccount.value)
          : null,
    };

    try {
      setLoading(true);
      if (id) {
        await updatePosUnivers(id, posUniversData as any);
        setSnackbarMessage("POS Univers updated successfully.");
      } else {
        await createPosUnivers(posUniversData as any);
        setSnackbarMessage("POS Univers created successfully.");
      }
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      toggleSidebar();
      if (onActionComplete) onActionComplete(); // Trigger the callback to refresh data

      // Reset form fields to initial values
      setPosName("");
      setSelected(null);
      setPosId("");
      setRegion("");
      setAddress("");
      setChecked(false);
      setVisibility(true);
      setSize("");
      setChannelId(undefined);
      setSelectedChannel(null);

      setSelectedKeyAccount(null);
    } catch (error) {
      setSnackbarMessage(`Error ${id ? "updating" : "creating"} POS Univers`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div key={id}>
      {isOpen && (
        <div className={classes.overlay} onClick={toggleSidebar}></div>
      )}
      <Box
        className={`${classes.container} ${
          isOpen ? classes.sidebarOpen : classes.sidebarClosed
        }`}
      >
        <IconButton className={classes.toggleButton} onClick={toggleSidebar}>
          <Box component="span" className={classes.iconButton}>
            {isOpen ? <Close /> : <Open />}
          </Box>
        </IconButton>
        <div
          className={classes.buttonContainer}
          style={{ justifyContent: "flex-start" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h4"
              sx={{ marginBottom: "12px", fontSize: "20px !important" }}
            >
              {id ? "Edit POS" : "Add New POS"}
            </Typography>
            <Typography
              variant="h6"
              sx={{ marginBottom: "50px", maxWidth: "244px" }}
            >
              {id ? "Edit the store details" : "Add a new store"}
            </Typography>
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginBottom: "16px",
                borderBottom: "0.5px solid rgba(156, 160, 171, 0.30)",
                paddingBottom: "-12px",
              }}
            >
              <img
                src={StoreIcon}
                width={50}
                height={50}
                style={{ marginTop: "4px" }}
                alt="Store Icon"
              />
              <Input
                label=""
                onChange={(e) => setPosName(e)}
                placeholder="Independent store name example"
                value={posName}
              />
            </div>
            <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
              <Dropdown
                label="Channel"
                onSelect={(option: any) => {
                  setSelectedChannel(option);
                  setChannelId(parseInt(option.value));
                }}
                selectedOption={selectedChannel}
                onAdd={(opt: OptionType) => {
                  setSelectedChannel(opt);
                }}
                options={channelsOptions}
              />
            </div>
            <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
              <ButtonCheckbox
                label={"Type"}
                option1Text={"Individual Store"}
                option2Text={"Key Accounts"}
                onChange={(value) => setSelected(value)}
                selectedValue={selected}
              />
            </div>
            <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
              <ButtonCheckbox
                option1Text={"Small"}
                option2Text={"Medium"}
                option3Text="Big"
                label="POS Size"
                selectedValue={
                  size?.length
                    ? size === "small"
                      ? 1
                      : size === "medium"
                      ? 2
                      : 3
                    : undefined
                }
                onChange={(value) => {
                  setSelectedSize(value);
                  setSize(
                    value === 1 ? "small" : value === 2 ? "medium" : "big"
                  );
                }}
              ></ButtonCheckbox>
            </div>
            {selected === 2 && (
              <div
                style={{ display: "flex", gap: "10px", marginBottom: "20px" }}
              >
                <Dropdown
                  label="Key Account Chain"
                  options={keyAccountOptions}
                  selectedOption={selectedKeyAccount}
                  onSelect={setSelectedKeyAccount}
                  onAdd={(opt: OptionType) => {
                    setSelectedKeyAccount(opt);
                  }}
                />
              </div>
            )}
            <div style={{ display: "flex", gap: "10px", marginBottom: "0px" }}>
              <Input
                label="POS ID"
                onChange={(e) => setPosId(e)}
                placeholder="Enter POS ID"
                value={posId}
              />
            </div>
            <div style={{ display: "flex", gap: "10px", marginBottom: "0px" }}>
              <Input
                label="Region"
                onChange={(e) => setRegion(e)}
                placeholder="Type region here..."
                value={region}
              />
            </div>
            <div style={{ display: "flex", gap: "10px", marginBottom: "0px" }}>
              <Input
                label="Address"
                onChange={(e) => setAddress(e)}
                placeholder="Enter address"
                value={address}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "0px",
                flexDirection: "column",
              }}
            >
              <Typography variant={"h2"} style={{ marginBottom: "0px" }}>
                Partnership
              </Typography>
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#383A49",
                  },
                }}
                control={
                  <Checkbox
                    checked={checked}
                    sx={{
                      borderRadius: "3px",
                      color: "#D9E7F8",
                      padding: "8px",
                    }}
                    onChange={handleChange}
                    name="checkedExample"
                    color="primary"
                  />
                }
                label="IQOS Partner"
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "14px",
                marginBottom: "12px",
                padding: "16px",
                borderRadius: "10px",
                background: "#F5F9FF",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "14px",
                  marginBottom: "1px",
                }}
              >
                <img
                  src={Visibility}
                  width={24}
                  height={24}
                  alt="Visibility Icon"
                />
                <div>
                  <div
                    style={{
                      fontSize: "10px",
                      fontWeight: 400,
                      color: "#383A49",
                      opacity: 0.5,
                    }}
                  >
                    {" "}
                    Visibility{" "}
                  </div>
                  <div style={{ fontSize: "14px" }}>
                    {" "}
                    {visibility ? "On" : "Off"}
                  </div>
                </div>
              </div>
              <IOSSwitch
                sx={{ m: 1 }}
                checked={visibility}
                onChange={() => setVisibility(!visibility)}
              />
            </div>
            <div style={{ marginTop: "8px", display: "flex", marginBottom:'10px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={toggleSidebar}
                fullWidth
                sx={{
                  maxHeight: "56px",
                  fontSize: "16px",
                  fontWeight: "600",
                  padding: "2px",
                  marginTop: "0",
                  background: "#ECF3FB",
                  marginRight: "7px",
                  color: "#383A49",
                  "&:hover": {
                    color: "#383A49",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  maxHeight: "56px",
                  fontSize: "16px",
                  fontWeight: "600",
                  padding: "2px",
                  marginTop: "0",
                  background: "#0179FF",
                  color: "white",
                  "&:hover": {
                    color: "black",
                    background: "#0179FF",
                  },
                }}
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : id ? (
                  "Update"
                ) : (
                  "Add"
                )}
              </Button>
            </div>
          </div>
        </div>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SideBarRight;

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
