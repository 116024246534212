import React, { ReactNode, ChangeEvent } from "react";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { InputAdornment } from "@mui/material";

interface InputProps {
  icon: ReactNode;
  text: string;
  onChange: (value: number) => void;
  value: number; // Add the value prop
  defaultValue?: number;
  type?:number;
  min?:number;
  max?:number;
  background?: string;
}

const Input: React.FC<InputProps> = ({ icon, text, onChange, value, defaultValue = 0,type=1,min=0,max=9999,background="#F5F9FF" }) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10) || 0;
    onChange(newValue);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
        gap: "20px",
        marginTop: type ===2 ? "32px" : "0"
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton size="large">{icon}</IconButton>
        <Typography variant="h6">{text}</Typography>
      </div>

      {type===1 && <TextField
        size="small"
        type="number"
        value={value} // Set the value prop
        onChange={handleInputChange}
        sx={{
          textAlign: "center !important", // Center the text

          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '0px solid #D9E7F8 !important',

            },
            '&:hover fieldset': {
              borderColor: 'green', // Add custom color for hover state if needed
            },
            '&.Mui-focused fieldset': {
              borderColor: 'blue', // Add custom color for focus state if needed
            },
            '& input[type="number"]': {
              '-moz-appearance': 'textfield',
              textAlign: "center !important", // Center the text

              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,

              },
            },
          },
        }}
        InputProps={{
          inputProps: { min: min, max: max },
          style: {
            width: "80px",
            height: "47px",
            //border: "1px solid #D9E7F8",
            border: "0px solid #D9E7F8 !important",
            backgroundColor: background, // Override border here

            borderRadius: "10px",
            fontSize: "14px",
            fontWeight: 600,
            color:"#383A49"


          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
      />}
      {type===2 && <TextField
        size="small"
        type="number"
        value={value} // Set the value prop
        onChange={handleInputChange}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: "3px solid rgba(1, 121, 255, 0.20)"


            },
            '&:hover fieldset': {
              border: "3px solid rgba(1, 121, 255, 0.20)"
            },
            '&.Mui-focused fieldset': {
              border: "3px solid rgba(1, 121, 255, 0.20)"
            },
            '& input[type="number"]': {
              '-moz-appearance': 'textfield',
              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
                
              },
            },
          },
        }}
        InputProps={{
          inputProps: { min: 0, max: 99 },
          endAdornment:  <InputAdornment position="end" sx={{ fontSize: '14px', '& .MuiTypography-root': { fontSize: '14px !important' } }}>
          cm
        </InputAdornment>,

          style: {
            width: "80px",
            height: "47px",
            
            //border: "1px solid #D9E7F8",
            border: "0px solid #D9E7F8 !important",
            backgroundColor:"white", // Override border here

            borderRadius: "10px",
            fontSize: "14px",
            fontWeight: 600,
            color:"#383A49"


          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
      />}
    </div>
  );
};

export default Input;
