import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Logo from "../../../../assets/images/Logo1.png";
import { ReactComponent as Back } from "../../../../assets/icons/Back.svg";
import { useStyles } from "./SideBarStyles";
import { useNavigate, useParams } from "react-router-dom";
import SearchInput from "../SearchInput/SearchInput";
import HNBProductList from "../HNBProductList/HNBProductList";
import { getSkusByBrandName } from "../../../../services/skuService"; // Adjust the path as necessary
import BrandModal from "../ModalComponent/Modal";

interface SidebarProps {
  setSelectedImage: (image: string) => void;
  selectedSquare: string | null;
  onContinue: (name:string) => void; // New prop for handling continue action
  name: string;
}
const data = [
  { text: "VEEV ONE Standing DP", id: 0, color: '#5DD671', value: 0, width: 28.5 },
  { text: "VEEV NOW Laying  DP", id: 1, color: '#FF9F47', value: 0, width: 19.5},
  { text: "VEEV ONE Standing & Laying DP", id: 2, color: '#FFE455', value: 0, width: 28.5 },
  { text: " CC Laying 2 DP", id: 3, color: 'blue', value: 0, width: 27.5 },
  { text: "VEEV Now Standing DP", id: 4, color: '#FF9F47', value: 0, width: 19.5 },
  { text: "HNB DP", id: 5, color: 'blue', value: 0, width: 35 },
  { text: "VEEV ONE Laying DP", id: 6, color: '#F95252', value: 0, width: 28.5 },
  { text: "HEETS", id: 7, color: '#0179FF', value: 0, width:   38.75  },
  { text: "TEREA", id: 8, color: '#F95252', value: 0, width:   38.75 },
  { text: "Fiit", id: 9, color: '#FFE455', value: 0, width:  38.75  },
  { text: "Delia", id: 10, color: '#A5ABAA', value: 0, width:   38.75  },
  { text: "VEEV ONE", id: 11, color: '#9747FF', value: 0, width: 28.5},
  { text: "VEEV NOW", id: 12, color: '#FF9F47', value: 0, width: 19.5 },
  { text: "LEVIA", id: 13, color: '#5DD671', value: 0, width: 36.5 },
];

const Sidebar: React.FC<SidebarProps> = ({ setSelectedImage, selectedSquare, onContinue,name }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [HNB, setHNB] = useState<{ title: string; imgSrc: string }[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMaterials, setFilteredMaterials] = useState<{ title: string; imgSrc: string }[]>([]);

  const handleProductSelect = (image: string) => {
    setSelectedImage(image);
  };

  useEffect(() => {
    const fetchSkus = async (brandName: string) => {
      try {
        const skus = await getSkusByBrandName(brandName);
        const formattedHNB = skus.map((sku: any) => ({
          title: `${sku.brandName} ${sku.variantName}`,
          imgSrc: sku.pack_image,
          id:sku.id
        }));
        setHNB(formattedHNB);
        setFilteredMaterials(formattedHNB);
      } catch (error) {
        console.error('Error fetching SKUs:', error);
      }
    };
    const fetchSkusHNB = async (brandName: string) => {
      try {
        const skusfiit = await getSkusByBrandName('FIIT');
        const skusheets = await getSkusByBrandName('HEETS');
        const skustrea = await getSkusByBrandName('TEREA');
        const skusdelia = await getSkusByBrandName('Delia');

        const formattedfiit = skusfiit.map((sku: any) => ({
          title: `${sku.brandName} ${sku.variantName}`,
          imgSrc: sku.pack_image,
          id:sku.id
        }));
        const formattedheets = skusheets.map((sku: any) => ({
          title: `${sku.brandName} ${sku.variantName}`,
          imgSrc: sku.pack_image,
          id:sku.id
        }));
        const formattedtrea= skustrea.map((sku: any) => ({
          title: `${sku.brandName} ${sku.variantName}`,
          imgSrc: sku.pack_image,
          id:sku.id
        }));
        const formatteddelia= skusdelia.map((sku: any) => ({
          title: `${sku.brandName} ${sku.variantName}`,
          imgSrc: sku.pack_image,
          id:sku.id
        }));

        setHNB([...formattedheets,...formattedfiit,...formattedtrea,...formatteddelia]);
        setFilteredMaterials([...formattedheets,...formattedfiit,...formattedtrea,...formatteddelia]);
      } catch (error) {
        console.error('Error fetching SKUs:', error);
      }
    };

    if (selectedSquare?.split("-")[2] === "0" || selectedSquare?.split("-")[2] === "2" || selectedSquare?.split("-")[2] === "6") {
      fetchSkus('VEEV ONE');
    } else if (selectedSquare?.split("-")[2] === "4" || selectedSquare?.split("-")[2] === "1") {
      fetchSkus('VEEV NOW');
    } else if (selectedSquare?.split("-")[2] === "9" || selectedSquare?.split("-")[2] === "5") {
      fetchSkusHNB('FIIT');
    } else if (selectedSquare?.split("-")[2] === "7") {
      fetchSkusHNB('HEETS');
    } else if (selectedSquare?.split("-")[2] === "8") {
      fetchSkusHNB('TEREA');
    } else if (selectedSquare?.split("-")[2] === "10") {
      fetchSkusHNB('Delia');
    } else if (selectedSquare?.split("-")[2] === "13") {
      fetchSkus('LEVIA');
    } else {
      setHNB([]);
      setFilteredMaterials([]);
    }
    

  }, [selectedSquare,searchTerm]);

  const { id, index } = useParams<{ id: string,index?:string }>();

  const handleModalSubmit = async (name: string) => {
    try {
//      id && await updateShelfConfigName(id, name);
      onContinue(name)
      setOpenModal(false);
    } catch (error) {
      console.error("Failed to update shelf configuration name:", error);
      // Optionally, handle the error, e.g., show a notification to the user
    }
  };

  useEffect(() => {
    console.log('HNB',HNB)
    

    const filtered = filteredMaterials
  .map((item) => {
    const title = item.title.toLowerCase();
    const search = searchTerm.toLowerCase();

    // Determine match score
    let score = 0;
    if (title === search) {
      // Exact match
      score = 3;
    } else if (title.startsWith(search)) {
      // Starts with search term
      score = 2;
    } else if (title.includes(search)) {
      // Contains search term somewhere
      score = 1;
    }

    return { ...item, score };
  })
  .filter((item) => item.score > 0) // Only include items with some match
  .sort((a, b) => b.score - a.score);
    setFilteredMaterials(filtered);
    
  }, [searchTerm, HNB]);
  const handleSearchChange = (value: string) => {
      
    setSearchTerm(value);
  };
  return (
    <Box className={classes.container}>
      <img src={Logo} alt="Logo" className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
            STEP 7/7
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "12px" }}>
            New RRP Configuration
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "0px", maxWidth: '250px' }}>
             Press on an SKU if you would like to change it
          </Typography>
          <SearchInput value={searchTerm} onValueChange={handleSearchChange} unit={""} onUnitChange={() => { }} label={"PRODUCT LIST"} />
          <Typography variant="h6" sx={{ marginBottom: "10px", maxWidth: "250px" }}>
            {selectedSquare?.split("-")[2] !== undefined && `The selected pusher is ${data.find(item => item.id === parseInt(selectedSquare?.split("-")[2]))?.text}`}
          </Typography>
          <HNBProductList
            materials={filteredMaterials}
            setSelectedProductCardImage={handleProductSelect}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => {
            
              
            if(index ) {
              navigate('/rrp/step4New/' + (id || '')+'/'+(index || ''))

            }else {
              navigate('/rrp/step4New/' + (id || ''))

            }
         }} size="large">
            <Box component="span" className={classes.iconButton2}>
              <Back />
            </Box>
          </IconButton>
          <Button variant="contained" color="info" onClick={()=>setOpenModal(true)} sx={{ marginTop: "0px" }}>
            Continue
          </Button>
        </div>
      </div>
      <BrandModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleSubmit={handleModalSubmit}
        label="RRP Configuration"
        usedName={name==null ? '' : name}
      />
    </Box>
  );
};

export default Sidebar;
