import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    height: "100vh",
    background: "#F5F9FF",
    boxShadow: "none",
    overflowY: 'auto',
    width: "100%",
  },
  contentContainer: {
    display: "flex",
    justifyContent: 'space-between',
    padding: "30px 40px 0 40px",
    alignSelf: "flex-start",
    width: "100%",
    boxSizing:'border-box'
  },
  centerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    flex: 1,
    width: "100%",
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      height: "auto",
      padding: "20px",
    },
    contentContainer: {
      padding: "20px",
    },
  },
}));
