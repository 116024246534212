import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    card: {
      background: "#F5F9FF",
      width: "100%",
      border: "1px dashed #0179FF",
      borderRadius: 10,
      padding: "16px 0 0px 0",
      display: "flex",
      flexDirection: "column",
      height:'240px',
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "none",
      margin: "10px", // Add margin to separate the cards
      [theme.breakpoints.down('sm')]: {
        width: "100%", // Make the card take full width on small screens
        margin: "10px 0", // Adjust margin for small screens
      },
    },
   
    icon: {
      fontSize: 48,
      marginBottom: theme.spacing(1),
      backgroundColor: "white",
      color: "#0179FF",
    },
    carContent:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    textStyle: {
        maxWidth: "150px", 
        textAlign: "center" 
    }
  }));
  