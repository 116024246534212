import React, { useEffect, useState } from "react";
import FillOrder from "../FillOrder/FillOrder";
import { CircularProgress, Box, Typography } from "@mui/material";

interface FillPolicyProps {
  increase: {
    pack_image: string;
    id: number;
    brandName: string;
    variantName: string;
    min_facings:number;
  }[];
  decrease: {
    pack_image: string;
    id: number;
    brandName: string;
    variantName: string;
    min_facings:number;

  }[];
  brandName: string;
  loadingIncrease: boolean;
  loadingDecrease: boolean;
  errorIncrease: string | null;
  errorDecrease: string | null;
  category?: string;
  setMarlboroIncrease: (items: any[]) => void; // Add prop to update the marlboroIncrease state
  keyAccountId?:any;
  onChangeHappen:(value?:string)=> void
}

const FillPolicy: React.FC<FillPolicyProps> = ({
  increase,
  decrease,
  brandName,
  loadingIncrease,
  loadingDecrease,
  errorIncrease,
  errorDecrease,
  setMarlboroIncrease,
  category,
  keyAccountId,
  onChangeHappen
}) => {

  const [increaseItems, setIncreaseItems] = useState(
    increase.map((item) => ({
      id: item.id,
      imageSrc: item.pack_image,
      text: `${item.brandName} ${item.variantName}`,
      minFacings:item.min_facings
    }))
  );
  useEffect(()=>{
    setIncreaseItems( increase.map((item) => ({
      id: item.id,
      imageSrc: item.pack_image,
      text: `${item.brandName} ${item.variantName}`,
      minFacings:item.min_facings
    })))
  },[increase])

  const [decreaseItems, setDecreaseItems] = useState(
    decrease.map((item) => ({
      id: item.id,
      imageSrc: item.pack_image,
      text: `${item.brandName} ${item.variantName}`,
      minFacings:item.min_facings

    }))
  );
  useEffect(() => {
    setDecreaseItems(
      decrease.map((item) => ({
        id: item.id,
        imageSrc: item.pack_image,
        text: `${item.brandName} ${item.variantName}`,
        minFacings:item.min_facings

      }))
    );
    console.log(
      "test",
      decrease.map((item) => ({
        id: item.id,
        imageSrc: item.pack_image,
        text: `${item.brandName} ${item.variantName}`,
      }))
    );
  }, [increase]);
  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1, textAlign: "left" }}>
        <div
          style={{
            padding: "20px",
            paddingTop: "0px",
            borderRight: "2px solid #000",
            marginTop: "22px",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: "900",
              color: "#63D259",
              marginBottom: "18px",
            }}
          >
            + Increased <span style={{ color: "#383A49" }}>Space </span>
            <span style={{ color: "#9CA0AB", fontWeight: "700" }}>
              on the Shelf Priority List
            </span>
          </div>
          {loadingIncrease ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                height: "100px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : errorIncrease ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                color: "red",
              }}
            >
              <Typography>{errorIncrease}</Typography>
            </Box>
          ) : (
            <FillOrder
              category={category}
              onChangeHappen={onChangeHappen}
              height={58}
              boxPerRow={1}
              itemsList={increaseItems}
              setMarlboroIncrease={setMarlboroIncrease}
              orderType="increase_order"
              brandName={brandName}
              keyAccountId={keyAccountId}
            />
          )}
        </div>
      </div>
      <div style={{ flex: 1, textAlign: "left" }}>
        <div
          style={{
            padding: "20px",
            paddingTop: "0px",
            marginTop: "22px",
            opacity: 0.5,
            pointerEvents: "none",
            cursor: "not-allowed",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: "900",
              color: "#F95252",
              marginBottom: "18px",
            }}
          >
            - Decreased <span style={{ color: "#383A49" }}>Space </span>
            <span style={{ color: "#9CA0AB", fontWeight: "700" }}>
              on the Shelf Priority List
            </span>
          </div>
          {loadingDecrease ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : errorDecrease ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                color: "red",
              }}
            >
              <Typography>{errorDecrease}</Typography>
            </Box>
          ) : (
            <FillOrder
              category={category}
              height={58}
              onChangeHappen={onChangeHappen}

              boxPerRow={1}
              itemsList={[...decreaseItems].reverse()}
              setMarlboroIncrease={setMarlboroIncrease}
              orderType="increase_order"
              brandName={brandName}
              keyAccountId={keyAccountId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FillPolicy;
