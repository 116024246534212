import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error('API URL not defined in environment variables');
}

/**
 * Fetch SKUs with priority data for a specific key account.
 * @param keyAccountId - The ID of the key account.
 * @param category - (Optional) The category to filter SKUs by (e.g., 'CC' or 'RRP').
 * @returns A list of SKUs with priority data.
 */
export const getSkusWithPriority = async (keyAccountId: number, category?: string) => {
  try {
    const response = await axios.get(`${API_URL}/sku-priority/${keyAccountId}`, {
      params: { category },
    });
    return response.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};

/**
 * Update priority data for a specific SKU and key account.
 * @param keyAccountId - The ID of the key account.
 * @param skuId - The ID of the SKU.
 * @param priorityData - An object containing the priority attributes to update.
 * @returns A success message upon successful update.
 */
export const updateSkuPriority = async (
  keyAccountId: number,
  skuId: number,
  priorityData: {
    minFacings?: number;
    ccOrder?: number;
    increaseOrder?: number;
    decreaseOrder?: number;
  }
) => {
  try {
    const response = await axios.put(
      `${API_URL}/sku-priority/${keyAccountId}/sku/${skuId}`,
      priorityData
    );
    return response.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};

/**
 * Reorder SKUs in priority for a specific key account.
 * @param keyAccountId - The ID of the key account.
 * @param skuIds - An array of SKU IDs in the desired order.
 * @param orderType - The type of order to update ('cc_order', 'increase_order', 'decrease_order').
 * @returns A success message upon successful reordering.
 */
export const reorderSkusInPriority = async (
  keyAccountId: number,
  skuIds: number[],
  orderType: 'cc_order' | 'increase_order' | 'decrease_order'
) => {
  try {
    const response = await axios.post(`${API_URL}/sku-priority/${keyAccountId}/reorder`, {
      skuIds,
      orderType,
    });
    return response.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};


/**
 * Fetch key accounts with min_facings > 0 for a specific SKU.
 * @param skuId - The ID of the SKU.
 * @returns A list of key accounts with min_facings > 0 for the SKU.
 */
export const getKeyAccountsWithMinFacingsForSku = async (skuId: number) => {
  try {
    const response = await axios.get(
      `${API_URL}/sku-priority/sku/${skuId}/key-accounts-with-min-facings`
    );
    console.log('response',response)
    return response.data.keyAccountIds;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};