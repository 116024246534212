import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ReactComponent as Upload } from "../../../assets/icons/Upload.svg";

const ImageUpload: React.FC = () => {
    const [selectedImage, setSelectedImage] = useState<File | null>(null);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedImage(event.target.files[0]);
        }
    };

    return (
        <div>
            <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleImageChange}
            />
            <label htmlFor="raised-button-file" style={{textAlign:"center" }}>
                <Button variant="contained" component="span" sx={{
                    width: "210px",
                    borderRadius:"10px",
                    marginTop:"0",
                    marginBottom:"4px"
                }} startIcon={<Upload />}>
                    Upload
                </Button>
                <Typography variant="h5" sx={{
                    fontSize:"12px",
                    color:"#9CA0AB",
                    maxWidth:"220px"
                }}>     { "Available formats PNG, JPG, JPEG click to browse (3MB max)"}</Typography>

          
            </label>
            {selectedImage && <p>{selectedImage.name}</p>}
        </div>
    );
};

export default ImageUpload;
