import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import { utils, WorkBook, write, writeFile } from 'xlsx-js-style';
import ExcelJS from 'exceljs';

export const handleExportToPDF = async ( containerRef: React.RefObject<HTMLDivElement>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    id: string) => {
    setIsLoading(true); // Set loading state to true
  
    const input = containerRef.current;
    if (input) {
      const scale = 4;
      const width = input.scrollWidth;
      const height = input.scrollHeight;
  
      const fetchImageFromServer = async (url:any) => {
  
        const response = await fetch(`https://shelf-creation-mk.stage.pmi.zenown.com/shelf-configs/fetch-image?url=${encodeURIComponent(url)}`);
        const data = await response.json();
        return data.data;
      };
  
      const embedBackgroundImagesAsBase64 = async (node:any) => {
        // Handle div background images
        const divs = Array.from(node.getElementsByTagName('div'));
        const divPromises = divs.map(async (div:any) => {
          const style = window.getComputedStyle(div);
          const backgroundImage = style.getPropertyValue('background-image');
          const urlMatch = backgroundImage.match(/url\("(.*)"\)/);
          if (urlMatch && urlMatch[1]) {
            const originalUrl = urlMatch[1];
            const dataUrl = await fetchImageFromServer(originalUrl);
            div.style.backgroundImage = `url(${dataUrl})`;
          }
        });
      
        // Handle img elements
        const imgs = Array.from(node.getElementsByTagName('img'));
        const imgPromises = imgs.map(async (img:any) => {
          const originalUrl = img.src;
          const dataUrl = await fetchImageFromServer(originalUrl);
          img.src = dataUrl;
        });
      
        await Promise.all([...divPromises, ...imgPromises]);
      };
  
      await embedBackgroundImagesAsBase64(input);
      const style = {
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
        width: `${width}px`,
        height: `${height}px`,
        position: 'absolute',
        left: '0',
        top: '0'
      };
      domtoimage.toPng(input, { width: width * scale, height: height * scale, style })
      .then((dataUrl) => {
        // Create a PDF document
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const img = new Image();
        img.src = dataUrl;
        
        img.onload = () => {
          const imgProps = pdf.getImageProperties(img);
          const imgRatio = imgProps.height / imgProps.width;
          const pdfImgHeight = pdfWidth * imgRatio;
  
          // If the image fits within one page, add it directly
          if (pdfImgHeight <= pdfHeight) {
            const yOffset = (pdfHeight - pdfImgHeight) / 2; // Center vertically
            pdf.addImage(dataUrl, 'PNG', 0, yOffset, pdfWidth, pdfImgHeight);
          } else {
            // If the image is taller than one page, split it across pages
            let position = 0;
            while (position < imgProps.height) {
              const canvasPage = document.createElement('canvas');
              canvasPage.width = imgProps.width;
              const pageHeight = Math.min(imgProps.height - position, imgProps.width * pdfHeight / pdfWidth);
              canvasPage.height = pageHeight;
  
              const ctx = canvasPage.getContext('2d');
              if (ctx) {
                ctx.drawImage(img, 0, position, imgProps.width, pageHeight, 0, 0, imgProps.width, pageHeight);
              }
  
              const imgPageData = canvasPage.toDataURL('image/png');
              const yOffset = (pdfHeight - (pdfWidth * pageHeight / imgProps.width)) / 2; // Center vertically
              pdf.addImage(imgPageData, 'PNG', 0, yOffset, pdfWidth, pdfWidth * pageHeight / imgProps.width);
  
              position += pageHeight;
              if (position < imgProps.height) {
                pdf.addPage();
              }
            }
          }
  
          pdf.save(`Shelf-export-${id}.pdf`);
          setIsLoading(false); // Set loading state to false after completion
  
        };
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
        setIsLoading(false); // Set loading state to false after completion
  
      });
    }
  };


export const handleExportToPNG = async (containerRef: any, setIsLoading: React.Dispatch<React.SetStateAction<any>>) => {
    setIsLoading(true); // Set loading state to true
  
    const input = containerRef.current;
    if (input) {
      const scale = 4;
      const width = input.scrollWidth;
      const height = input.scrollHeight;
  
      const fetchImageFromServer = async (url:any) => {
        const response = await fetch(`https://shelf-creation-mk.stage.pmi.zenown.com/shelf-configs/fetch-image?url=${encodeURIComponent(url)}`);
        const data = await response.json();
        return data.data;
      };
  
      const embedBackgroundImagesAsBase64 = async (node:any) => {
        const divs = Array.from(node.getElementsByTagName('div'));
        const divPromises = divs.map(async (div:any) => {
          const style = window.getComputedStyle(div);
          const backgroundImage = style.getPropertyValue('background-image');
          const urlMatch = backgroundImage.match(/url\("(.*)"\)/);
          if (urlMatch && urlMatch[1]) {
            const originalUrl = urlMatch[1];
            const dataUrl = await fetchImageFromServer(originalUrl);
            div.style.backgroundImage = `url(${dataUrl})`;
          }
        });
      
        const imgs = Array.from(node.getElementsByTagName('img'));
        const imgPromises = imgs.map(async (img:any) => {
          const originalUrl = img.src;
          const dataUrl = await fetchImageFromServer(originalUrl);
          img.src = dataUrl;
        });
      
        await Promise.all([...divPromises, ...imgPromises]);
      };
  
      await embedBackgroundImagesAsBase64(input);
  
      const style = {
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
        width: `${width}px`,
        height: `${height}px`,
        position: 'absolute',
        left: '0',
        top: '0'
      };
  
      // Generate the PNG image
      const dataUrl = await domtoimage.toPng(input, { width: width * scale, height: height * scale, style });
  
      setIsLoading(false); // Set loading state to false after completion
  
      return dataUrl; // Return the PNG data URL for later use
    }
  };


export const insertBase64ImageIntoExcel = async (blob: Blob, imageUrl: string, id: string, col: number, row:number) => {
    const buffer = await blob.arrayBuffer();
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(buffer);
    // Get the first worksheet
    const sheet = workbook.getWorksheet(1);
    if (sheet && sheet.properties) {
      sheet.properties.defaultRowHeight = 15; // Set default row height to 15 points
    } else {
      console.error("Sheet or sheet properties are undefined.");
    }
    sheet?.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      row.height = 17; // Set a small height for the row
      row.eachCell((cell) => {
        cell.alignment = { wrapText: false }; // Prevent text wrapping
      });
    });
    // Fetch the image as a base64 string
    const base64Image = imageUrl;
  
    
  
    // Ensure the base64 string is in the correct format
    if (!base64Image.startsWith('data:image')) {
      throw new Error('Invalid image data');
    }
  
    // Add the image to the workbook
    const imageId = workbook.addImage({
      base64: base64Image.split(',')[1], // Strip off the "data:image/png;base64," part
      extension: 'png',
    });
    let totalWidth = 0;
    sheet?.columns.forEach(column => {
      totalWidth += column.width ? column.width * 7.5 : 8.43 * 7.5; // Approximate width in pixels, assuming default font size
    });
  
    // Calculate the total height of the content
    let totalHeight = 0;
    sheet?.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      totalHeight += row.height ? row.height : 15; // Approximate height in pixels, assuming default font size
    });
  
    // Insert the image at row 20, column 1
    sheet?.addImage(imageId, {
      tl: { col: 0, row: row +3 }, // Top-left position (row 20, column 1, zero-indexed)
      ext: { width: totalWidth, height: 14*15 *col }, // Adjust the size of the image as needed
    }); 
  
  
    // Export the updated workbook as a Blob
    const updatedBuffer = await workbook.xlsx.writeBuffer();
    const updatedBlob = new Blob([updatedBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
    // Create a download link to download the updated Excel file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(updatedBlob);
    link.download =  `shelf-export-sheet-${id}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  export const exportToExcel =async  (
    images: string[][][],
    imagesLaying: string[][][],
    columnWidths: number[],
    row: number,
    col: number,
    shelfDuplex: any[][][],
    colorConditionArrays: boolean[][][][], // Multi-dimensional boolean arrays for various conditions
    highlightConditionArrays: boolean[][][][], // Multi-dimensional boolean array for Marketing Material
    ledScreenConditionArrays: boolean[][][][], // Multi-dimensional boolean array for LED Screens
    fileName = 'grid_data.xlsx',
    naming:any,
    ean:any,
    exportArray:any,
    exportArrayEan:any
  ) => {
    const ws = utils.aoa_to_sheet([]);
    let rrpRowDetection = -1;
    // Step 1: Generate the worksheet content
    for (let customBoxIndex = 0; customBoxIndex < col; customBoxIndex++) {
      const dynamicBoxCount = Math.floor(columnWidths[customBoxIndex] / 30.5);
  
      for (let setIndex = 0; setIndex < row; setIndex++) {
        let mergeStartColumnMarketing = null;
        let mergeEndColumnMarketing = null;
        let mergeStartColumnLED = null;
        let mergeEndColumnLED = null;
  
        for (let boxIndex = 0; boxIndex < dynamicBoxCount; boxIndex++) {
          const actualRow = setIndex;
          const actualColumn = customBoxIndex * dynamicBoxCount + boxIndex;
  
          const imageSrc = naming[customBoxIndex]?.[actualRow]?.[boxIndex] || '';
          const eancode = ean[customBoxIndex]?.[actualRow]?.[boxIndex] || '';
          const imageSrcLaying = naming[customBoxIndex]?.[actualRow]?.[boxIndex] || '';
  
          const isDuplexPusher = shelfDuplex[customBoxIndex]?.[actualRow]?.[boxIndex];
          const shouldColorRed = colorConditionArrays.some(array => array[customBoxIndex]?.[actualRow]?.[boxIndex]);
  
          // Generate the cell address in Excel format
          const cellAddress = utils.encode_cell({
            c: actualColumn,
            r: actualRow,
          });
  
          // Determine cell value
          let cellValue = imageSrc +'\n' + eancode;
          if (isDuplexPusher && imageSrcLaying) {
            cellValue = `${imageSrc} // ${imageSrcLaying} \n  ${eancode}`;
          }
  
          ws[cellAddress] = { v: cellValue };
  
          // Track RRP row for exportArray adjustments
          if (shouldColorRed) {
            if (rrpRowDetection === -1) {
              rrpRowDetection = setIndex;
            }
            for (let i = 0; i < exportArray.length; i++) {
              const exportData = exportArray[i]?.[setIndex - rrpRowDetection]?.[boxIndex];
              if (exportData) {
                cellValue = `${exportData.top} // ${exportData.bottom}`;
              }
            }
            let cellValueean =''
            for (let i = 0; i < exportArray.length; i++) {
              const exportDataEan = exportArrayEan[i]?.[setIndex - rrpRowDetection]?.[boxIndex];
              if (exportDataEan) {
                cellValueean = `${exportDataEan.top} // ${exportDataEan.bottom}`;
              }
            }
            ws[cellAddress] = { v: cellValue +'\n' + cellValueean};
          }
  
          // Check highlightConditionArrays for Marketing Material
          let shouldHighlightYellow = false;
          for (let i = 0; i < highlightConditionArrays.length; i++) {
            if (highlightConditionArrays[i][customBoxIndex]?.[actualRow]?.[boxIndex]) {
              shouldHighlightYellow = true;
              break;
            }
          }
  
          // Check ledScreenConditionArrays for LED Screens
          let shouldHighlightRed = false;
          for (let i = 0; i < ledScreenConditionArrays.length; i++) {
            if (ledScreenConditionArrays[i][customBoxIndex]?.[actualRow]?.[boxIndex]) {
              shouldHighlightRed = true;
              break;
            }
          }
  
          // Handle merging cells for Marketing Material
          if (shouldHighlightYellow) {
            if (mergeStartColumnMarketing === null) {
              mergeStartColumnMarketing = actualColumn;
            }
            mergeEndColumnMarketing = actualColumn;
  
            // If it's the last cell in the row or the next cell doesn't satisfy the condition, merge and apply style
            if (boxIndex === dynamicBoxCount - 1 || !highlightConditionArrays.some(
                array => array[customBoxIndex]?.[actualRow]?.[boxIndex + 1])) {
  
              ws['!merges'] = ws['!merges'] || [];
              ws['!merges'].push({
                s: { c: mergeStartColumnMarketing, r: actualRow },
                e: { c: mergeEndColumnMarketing, r: actualRow },
              });
  
              const mergedCellAddress = utils.encode_cell({ c: mergeStartColumnMarketing, r: actualRow });
              ws[mergedCellAddress] = {
                v: "Marketing Material",
                s: {
                  fill: {
                    patternType: 'solid',
                    fgColor: { rgb: 'FFFF00' }, // Yellow background
                  },
                  alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
                  font: { name: 'Arial', bold: true, sz: 10 }, // Bold and Arial
                },
              };
  
              // Reset for next potential merge
              mergeStartColumnMarketing = null;
              mergeEndColumnMarketing = null;
            }
          } else {
            // If the current cell doesn't satisfy the condition, reset merging variables
            mergeStartColumnMarketing = null;
            mergeEndColumnMarketing = null;
          }
  
          // Handle merging cells for LED Screens
          if (shouldHighlightRed) {
            if (mergeStartColumnLED === null) {
              mergeStartColumnLED = actualColumn;
            }
            mergeEndColumnLED = actualColumn;
  
            // If it's the last cell in the row or the next cell doesn't satisfy the condition, merge and apply style
            if (boxIndex === dynamicBoxCount - 1 || !ledScreenConditionArrays.some(
                array => array[customBoxIndex]?.[actualRow]?.[boxIndex + 1])) {
  
              ws['!merges'] = ws['!merges'] || [];
              ws['!merges'].push({
                s: { c: mergeStartColumnLED, r: actualRow },
                e: { c: mergeEndColumnLED, r: actualRow },
              });
  
              const mergedCellAddress = utils.encode_cell({ c: mergeStartColumnLED, r: actualRow });
              ws[mergedCellAddress] = {
                v: "LED Screens",
                s: {
                  fill: {
                    patternType: 'solid',
                    fgColor: { rgb: 'FFCCCC' }, // Light red background
                  },
                  alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
                  font: { name: 'Arial', bold: true, sz: 10 }, // Bold and Arial
                },
              };
  
              // Reset for next potential merge
              mergeStartColumnLED = null;
              mergeEndColumnLED = null;
            }
          } else {
            // If the current cell doesn't satisfy the condition, reset merging variables
            mergeStartColumnLED = null;
            mergeEndColumnLED = null;
          }
        }
      }
    }
  
    // Step 2: Handle merging for colorConditionArrays separately
    for (let i = 0; i < colorConditionArrays.length; i++) {
      for (let setIndex = 0; setIndex < row; setIndex++) {
        let mergeStartColumn = null;
        let mergeEndColumn = null;
        let mergedValue = ''; // Variable to accumulate merged cell value
        let valueName = ''; // Variable to accumulate merged cell value
        let valueEan = ''; // Variable to accumulate merged cell value
  
  
        for (let customBoxIndex = 0; customBoxIndex < col; customBoxIndex++) {
          const dynamicBoxCount = Math.floor(columnWidths[customBoxIndex] / 30.5);
          for (let boxIndex = 0; boxIndex < dynamicBoxCount; boxIndex++) {
            const shouldColorRed = colorConditionArrays[i]?.[customBoxIndex]?.[setIndex]?.[boxIndex];
  
            if (shouldColorRed) {
              const actualColumn = customBoxIndex * dynamicBoxCount + boxIndex;
              if (mergeStartColumn === null) {
                mergeStartColumn = actualColumn;
              }
              mergeEndColumn = actualColumn;
  
              // Add to the merged value if colorConditionArray is met
              const exportData = exportArray[i]?.[setIndex - rrpRowDetection]?.[boxIndex];
              const exportDataean = exportArrayEan[i]?.[setIndex - rrpRowDetection]?.[boxIndex];
  
              if (exportData) {
                if(exportData.bottom ===''){
                 valueName +=`(${exportData.top} ) | `
                valueEan += `(${exportDataean.top} ) | `
                }else if(exportData.top ==='') {
       valueName +=`(${exportData.bottom}) | `
                valueEan += `( ${exportDataean.bottom}) | `
                }else {
   valueName +=`(${exportData.top} // ${exportData.bottom}) | `
                valueEan += `(${exportDataean.top} // ${exportDataean.bottom}) | `
                }
               
  
                mergedValue = `${valueName} \n  ${valueEan}`;
  
              }
            }
          }
        }
  
        if (mergeStartColumn !== null && mergeEndColumn !== null && mergeStartColumn !== mergeEndColumn) {
          ws['!merges'] = ws['!merges'] || [];
          ws['!merges'].push({
            s: { c: mergeStartColumn, r: setIndex },
            e: { c: mergeEndColumn, r: setIndex },
          });
  
          // Set the merged cell value
          const mergedCellAddress = utils.encode_cell({ c: mergeStartColumn, r: setIndex });
  
          if (mergedValue.trim()) {
            ws[mergedCellAddress] = {
              v: mergedValue.trim(),
              s: {
                fill: {
                  patternType: 'solid',
                  fgColor: { rgb: 'ADD8E6' }, // Light blue background
                },
                alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
                font: { name: 'Arial', bold: false, sz: 10 }, // Bold and Arial
              },
            };
          }
        }
      }
    }
  
    // Define the range of the worksheet
    const range = {
      s: { c: 0, r: 0 },
      e: { c: col * Math.floor(columnWidths[0] / 30.5) - 1, r: row - 1 },
    };
    ws['!ref'] = utils.encode_range(range);
  
    // Create a workbook and add the worksheet
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Grid Data');
  
     // Write the Excel file to a blob
     const buffer = write(wb, { type: 'array' });
     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
     return blob;
    // writeFile(wb, fileName);
  
    // Export the Excel file
  };
