import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      customBox: {
        borderRadius: '5.134px',
        background: 'transparent',
        width: '186.103px',
        //height: '390.994px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '2px',
        position: 'relative',
      },
      gridContainer : {
        display: 'grid',
        gridAutoFlow: 'dense',
        gridTemplateColumns: '120px 180px 150px', /* Use your column widths */
        gridAutoRows: 'auto'
      },
      
      gridItem :{
        width: '30px', /* Box width */
        height: '30px'/* Box height */
      },
      line: {
        borderRight: '2px solid #000',
        height: '100%',
        position: 'relative',
      },
      subBox: {
        width: '176.143px',
        height: '51.5px',
        background: '#EFEFEF',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      },
      secondBox: {
        width: '177.103px',
        height: '82.142px',
        flexShrink: 0,
        borderRadius: '3.85px',
        background: '#EFEFEF',
      },
      selectedBox: {
        backgroundColor: '#ADD5FF !important',
      },
      selectionBox: {
        position: 'absolute',
        border: '2px solid #0179FF',
        zIndex: 1000,
        pointerEvents: 'none',
        cursor: 'pointer',
        backgroundColor: 'rgba(1, 121, 255, 0.50)',
        '&:active': {
          cursor: 'pointer',
        },
      },
      whiteBox: {
        backgroundColor: '#FFFFFF',
        borderRadius: '4px',
        height: '41.5px',
        margin: '2px 2px',
        userSelect: 'none',
        //width: 'calc(75% / 10)',
        position: 'relative',
      },
      pinkSquare: {
        position: 'absolute',
        backgroundColor: '#F95252',
        cursor: 'grab',
        zIndex: 500,
        '&:active': {
          cursor: 'grabbing',
        },
      },
      whiteBoxSelected: {
        backgroundColor: 'transparent !important',
  
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          borderRadius: '4px',
          right: 0,
          height: 'calc(50% - 1.5px)',
          backgroundColor: '#63D259',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: '4px',
          height: 'calc(50% - 1.5px)',
          backgroundColor: '#63D259',
        },
        
      },
      OverlayBox: {
        backgroundColor: 'red !important',
        opacity:0.5
  
       
        
      },
      closeIcon: {
        position: 'absolute',
        width: "16px !important",
        height:"16px !important",
        top: 0,
        right: 0,
        cursor: 'pointer',
        zIndex: 600, // Ensure it's above the squares
        color: 'black', // Adjust color as needed
        '&:hover': {
          opacity: 0.4, // Change color on hover
        },
      },
      yellowSquare: {
        position: 'absolute',
        backgroundColor: '#FFD700', // Yellow color
        cursor: 'grab',
        zIndex: 400, // Ensure it's below the pink square if they overlap
        '&:active': {
          cursor: 'grabbing',
        },
      },
      blueSquare: {
        position: 'absolute',
        backgroundColor: '#0179FF', // Yellow color
        cursor: 'grab',
        zIndex: 400, // Ensure it's below the pink square if they overlap
        '&:active': {
          cursor: 'grabbing',
        },
      },
      mergedBox: {
        position: 'absolute',
        backgroundColor: '#FF6C01',
        zIndex: 900,
      },
    })
  );

export default useStyles;
