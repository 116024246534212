import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customBox: {
      borderRadius: "5.134px",
      background: "#B5D8FF",
      width: "186.103px",
      height: "320px",
    },
    secondBox: {
      width: "186.103px",
      height: "60.142px",
      flexShrink: 0,
      borderRadius: "3.85px",
      background: "#98C9FF",
      // marginTop: "10px",
    },
    customBoxCute: {
      borderRadius: "5.134px",
      background: "#B5D8FF",
      width: "140.103px",
      height: "320px",
    },
    secondBoxCute: {
      width: "140.103px",
      height: "60.142px",
      flexShrink: 0,
      borderRadius: "3.85px",
      background: "#98C9FF",
      // marginTop: "10px",
    },
  })
);

const Component: React.FC = () => {
  const classes = useStyles();

  return (
    <div style={{ display: "flex", gap: "5px" , justifySelf:"flex-end" }}>
     
      <div
        style={{
          backgroundColor: "#F5F9FF",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <div className={classes.customBox}></div>
          <div className={classes.secondBox}></div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#F5F9FF",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <div className={classes.customBox}></div>
          <div className={classes.secondBox}></div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#F5F9FF",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <div className={classes.customBoxCute}></div>
          <div className={classes.secondBoxCute}></div>
        </div>
      </div>
    </div>
  );
};

export default Component;
