import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error('API URL not defined in environment variables');
}

export const createLedScreen = async (title: string, width: number, height: number) => {
  try {
    const response = await axios.post(`${API_URL}/led-screens`, { title, width, height });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getAllLedScreens = async () => {
  try {
    const response = await axios.get(`${API_URL}/led-screens`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getLedScreenById = async (id: number) => {
  try {
    const response = await axios.get(`${API_URL}/led-screens/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const updateLedScreen = async (id: number, title: string, width: number, height: number) => {
  try {
    const response = await axios.put(`${API_URL}/led-screens/${id}`, { title, width, height });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const deleteLedScreen = async (id: number) => {
  try {
    const response = await axios.delete(`${API_URL}/led-screens/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
