import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error("API URL not defined in environment variables");
}
export interface Sku {
  name: string;
  description: string;
  category: string;
  subcategory: string;
  consumablesDevice: string;
  packBrandForm: string;
  productDescription: string;
  eanCode: string;
  width: number;
  height: number;
  depth: number;
  brandId: number;
  variantId: number;
  packTypeId: number;
  packImageLaying?: string; // New field

  sizeId: number;
  packImage: string;
  minFacings?: number;
  ccOrder?: number;
  increaseOrder?: number;
  decreaseOrder?: number;
  keyAccountsWithMinFacings?:any
}

export const createSkuWithImage = async (
  sku: Sku,
  file: File,
  fileLaying?: File | null
) => {
  const formData = new FormData();
  formData.append("packImage", file);
  if (fileLaying) {
    formData.append("packImageLaying", fileLaying); // New field
  }
  for (const key in sku) {
    formData.append(key, (sku as any)[key]);
  }

  try {
    const response = await axios.post(`${API_URL}/skus`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const updateSkuWithImage = async (
  id: number,
  sku: Sku,
  file?: File,
  fileLaying?: File
) => {
  const formData = new FormData();
  if (file) {
    formData.append("packImage", file);
  }
  if (fileLaying) {
    formData.append("packImageLaying", fileLaying); // New field
  }
  for (const key in sku) {
    formData.append(key, (sku as any)[key]);
  }

  try {
    const response = await axios.put(`${API_URL}/skus/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const deleteSku = async (id: number) => {
  try {
    const response = await axios.delete(`${API_URL}/skus/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getAllSkus = async () => {
  try {
    const response = await axios.get(`${API_URL}/skus`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getSkuById = async (id: number) => {
  try {
    const response = await axios.get(`${API_URL}/skus/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getSkusByCategory = async (category: string) => {
  try {
    const response = await axios.get(`${API_URL}/skus/category/${category}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const searchSkusByBrandVariantAndCategory = async (
  category: string,
  search: string
) => {
  try {
    const response = await axios.get(`${API_URL}/skus/search`, {
      params: { search, category },
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getSkusOrdered = async () => {
  try {
    const response = await axios.get(`${API_URL}/skus/skus-ordered`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getSkusOrderedByIncreaseOrder = async (category?: string) => {
  try {
    const response = await axios.get(
      `${API_URL}/skus/skus-ordered-increase` +
        (category ? `?category=${category}` : "")
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getSkusOrderedByDecreaseOrder = async () => {
  try {
    const response = await axios.get(`${API_URL}/skus/skus-ordered-decrease`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const reorderSkus = async (skuIds: number[]) => {
  try {
    const response = await axios.patch(`${API_URL}/skus/reorder`, { skuIds });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const reorderIncreaseOrder = async (skuIds: number[]) => {
  try {
    const response = await axios.patch(
      `${API_URL}/skus/increase-order/reorder`,
      { skuIds }
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
export const reorderDecreaseOrder = async (skuIds: number[]) => {
  try {
    const response = await axios.patch(`${API_URL}/skus/reorder-decrease`, {
      skuIds,
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const updateSkuMinFacings = async (id: number, minFacings: number) => {
  try {
    const response = await axios.patch(`${API_URL}/skus/${id}/min-facings`, {
      minFacings,
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getSkusWithMinFacings = async (category?: string) => {
  try {
    const response = await axios.get(
      `${API_URL}/skus/skus-with-min-facings` +
        (category ? `?category=${category}` : "")
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
export const reorderSkusBrand = async (
  skuIds: number[],
  orderType: "increase_order" | "decrease_order",
  brandName: string
) => {
  try {
    const response = await axios.patch(
      `${API_URL}/skus/${brandName.toLowerCase()}/reorder-${orderType}`,
      { skuIds }
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
export const reorderIncreaseOrderMarlboro = async (skuIds: number[]) => {
  try {
    const response = await axios.patch(
      `${API_URL}/skus/marlboro/reorder-increase`,
      { skuIds }
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const reorderDecreaseOrderMarlboro = async (skuIds: number[]) => {
  try {
    const response = await axios.patch(
      `${API_URL}/skus/marlboro/reorder-decrease`,
      { skuIds }
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const reorderIncreaseOrderChesterfield = async (skuIds: number[]) => {
  try {
    const response = await axios.patch(
      `${API_URL}/skus/chesterfield/reorder-increase`,
      { skuIds }
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const reorderDecreaseOrderChesterfield = async (skuIds: number[]) => {
  try {
    const response = await axios.patch(
      `${API_URL}/skus/chesterfield/reorder-decrease`,
      { skuIds }
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getSkusOrderedByIncreaseOrderMarlboro = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/skus/marlboro/ordered-increase`
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getSkusOrderedByDecreaseOrderMarlboro = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/skus/marlboro/ordered-decrease`
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getSkusOrderedByIncreaseOrderChesterfield = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/skus/chesterfield/ordered-increase`
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getSkusOrderedByDecreaseOrderChesterfield = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/skus/chesterfield/ordered-decrease`
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const fetchSkusOrderedMarlboro = async () => {
  try {
    const response = await axios.get(`${API_URL}/skus/skus-ordered-marlboro`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const fetchSkusOrderedChesterfield = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/skus/skus-ordered-chesterfield`
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getSkusWithMinFacingsMarlboro = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/skus/skus-with-min-facings-marlboro`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Marlboro SKUs:", error);
    throw error;
  }
};

export const getSkusWithMinFacingsChesterfield = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/skus/skus-with-min-facings-chesterfield`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Chesterfield SKUs:", error);
    throw error;
  }
};

export const reorderSkusByBrand = async (skuIds: number[], brandId: number) => {
  try {
    const response = await axios.patch(`${API_URL}/skus/reorder-by-brand`, {
      skuIds,
      brandId,
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getSkusOrderedByBrand = async (
  brandName: string,
  category?: string
) => {
  try {
    const response = await axios.get(
      `${API_URL}/skus/ordered-by-brand` +
        (category ? `?category=${category}` : ""),
      {
        params: { brand: brandName },
      }
    );
    console.log('the response data',response.data)
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getSkusByBrandName = async (brandName: string) => {
  try {
    const response = await axios.get(`${API_URL}/skus/brand/${brandName}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};


export const fetchSkusByBrandAndIncreaseOrder = async (brand: string, category?: string) => {
  try {
    const response = await axios.get(`${API_URL}/skus/ordered-increase`, {
      params: { brand, category },
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

// Fetch SKUs ordered by decrease_order for a specific brand
export const fetchSkusByBrandAndDecreaseOrder = async (brand: string, category?: string) => {
  try {
    const response = await axios.get(`${API_URL}/skus/ordered-decrease`, {
      params: { brand, category },
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};