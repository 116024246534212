import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Sidebar from '../components/Step16 copy/sideBar/SideBar';
import DemoComponent from '../components/Step16 copy/Content/Content';
import { useParams, useNavigate } from 'react-router-dom';
import { getShelfConfigById, updateShelfConfig } from '../services/shelfConfigurationService';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Step6: React.FC = () => {
  const { id } = useParams<{ id?: string }>(); // Read the ID from the URL, may be undefined
  const navigate = useNavigate();

  const [question1, setQuestion1] = useState<string[]>([]);
  const [question2, setQuestion2] = useState<string[]>([]);
  const [partner, setPartner] = useState<string>('');

  const [col, setCol] = useState(2);
  const [row, setRow] = useState(2);
  const [rowDistance, setRowDistance] = useState(2);
  const [colDistance, setColDistance] = useState(2);
  const [widths, setWidths] = useState<number[]>([]);
  const [height, setHeight] = useState<number>(10);
  const [pmiPositions, setPmiPositions] = useState<any[][][]>([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const [loadingNext, setLoadingNext] = useState(false); // Add button loading state
  const [loadingBack, setLoadingBack] = useState(false); // Add button loading state
  const [error, setError] = useState<string | null>(null); // Add error state for snackbar
  const [hiddencells,setHiddencells] = useState([])

  useEffect(() => {
    const fetchShelfConfig = async () => {
      if (id) {
        setLoading(true);
        try {
          const response = await getShelfConfigById(id);
          setQuestion1(response.question1 || []);
          setQuestion2(response.question2 || []);
          setCol(response.col_position);
          setRow(response.row_position);
          setRowDistance(response.rowDistance);
          setColDistance(response.colDistance);
          setWidths(response.widths.map((width: any) => width * 5)); 
          setHeight(response.height);
          setPmiPositions(response.pmiPositions || []);
          setPartner(response.isIqos || 'no');
          setHiddencells(response.hiddencells)

        } catch (error) {
          setError('Error fetching shelf configuration');
          console.error('Error fetching shelf configuration', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchShelfConfig();
  }, [id]);

  const handleSave = async () => {
    if (id) {
      setLoadingNext(true);
      try {
        await updateShelfConfig(id, { question1, question2, isIqos: partner });
        if(question1.length === 0 && question2.length === 0 ){
        navigate(`/step9/${id}`);

        }else if (question1.length > 0 ){
          navigate(`/step17-new/${id}`) 

        }else {
          navigate(`/step8/${id}`)
        }

      } catch (error) {
        setError('Error updating shelf configuration');
        console.error('Error updating shelf configuration', error);
      } finally {
        setLoadingNext(false);
      }
    }
  };

  const handleBack = async () => {
    if (id) {
      setLoadingBack(true);
      try {
        await updateShelfConfig(id, { question1, question2, isIqos: partner });
        navigate(`/step5/${id}`);
      } catch (error) {
        setError('Error updating shelf configuration');
        console.error('Error updating shelf configuration', error);
      } finally {
        setLoadingBack(false);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setError(null);
  };

  return (
    <div>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      )}
      { (
        <Grid container>
          <Grid item xs={3}>
            <Sidebar
              question1={question1}
              setQuestion1={setQuestion1}
              question2={question2}
              setQuestion2={setQuestion2}
              onSave={handleSave}
              onBack={handleBack}
              partner={partner}
              setPartner={setPartner}
              loadingNext={loadingNext} // Pass button loading state
              loadingBack={loadingBack} // Pass button loading state
            />
          </Grid>
          <Grid item xs={9}>
            <div
              style={{
                width: "100%",
                backgroundColor: "#e0e0e0",
              }}
            >
              <DemoComponent 
                onSelectedBoxesChange={setPmiPositions} 
                selectedBoxes2={false} 
                col={col}
                row={row}
                rowDistance={rowDistance}
                colDistance={colDistance}
                widths={widths}
                selectedBoxes={pmiPositions}
                hiddencells={hiddencells}

              />
            </div>
          </Grid>
        </Grid>
      )}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Step6;
