import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error('API URL not defined in environment variables');
}

interface RRPNewConfig {
  col_position?: number;
  row_position?: number;
  marketingMaterials?: any;
  ledScreens?: any;
  squaresData?: any;
  items?: any;
  ccSpace?: number;
  key_account_id?:number

}

export const createRRPNewConfig = async (config: RRPNewConfig) => {
  try {
    const response = await axios.post(`${API_URL}/rrp-new-configurations`, config);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const updateRRPNewConfig = async (id: string, config: RRPNewConfig) => {
  try {
    const response = await axios.put(`${API_URL}/rrp-new-configurations/${id}`, config);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const deleteRRPNewConfig = async (id: string) => {
  try {
    const response = await axios.delete(`${API_URL}/rrp-new-configurations/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getAllRRPNewConfigs = async () => {
  try {
    const response = await axios.get(`${API_URL}/rrp-new-configurations`);
    return response.data;
  } catch (error: any) {
    console.log('response', error);
    throw error.response.data;
  }
};

export const getRRPNewConfigById = async (id: string) => {
  try {
    const response = await axios.get(`${API_URL}/rrp-new-configurations/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const updateMarketingMaterialsRRP = async (id: string, marketingMaterialIds: any) => {
  try {
    const response = await axios.put(`${API_URL}/rrp-new-configurations/${id}/marketing-materials`, { marketingMaterialIds });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const updateLedScreensRRP = async (id: string, ledScreenIds: any) => {
  try {
    const response = await axios.put(`${API_URL}/rrp-new-configurations/${id}/led-screens`, { ledScreenIds });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};