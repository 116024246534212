import React from "react";
import {  Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomNumberInput from "../InputV2/InputV2";
import BoxList from "../BoxList/BoxList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customBox: {
      borderRadius: "5.134px",
      background: "#B5D8FF",

      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      padding: "4px",
      gap: "2px",
    },
    secondBox: {
      width: "186.103px",
      height: "82.142px",
      flexShrink: 0,
      borderRadius: "3.85px",
      background: "#98C9FF",
      // marginTop: "10px",
    },
    lineContainer: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: "20px", // Adjust the margin as needed
    },
    line: {
      borderRight: "2px solid #000", // Change the color as needed
      height: "100%", // Height set dynamically to match the combined height
      position: "relative",
    },
    arrowIcon: {
      color: "#000", // Change the color as needed
      fontSize: "24px", // Adjust the size as needed
      position: "absolute",
      top: 0,
      left: -11,
      transform: "translateY(-50%) rotate(180deg)",
    },
    arrowIconDown: {
      color: "#000", // Change the color as needed
      fontSize: "24px", // Adjust the size as needed
      position: "absolute",
      bottom: 0,
      left: -12,
      transform: "translateY(-50%) rotate(360deg)",
    },
    subBox: {
      width: "176.143px",
      height: "36.5px",
      background: " #73B5FF",
      borderRadius: "4px",
    },

    subBoxDisplay: {
      width: "176.143px",
      height: "36.5px",
      background: " #73B5FF",
      borderRadius: "4px",
      margin: "4px 0px 0px 6px",
    },

    subBoxEmpy: {
      width: "176.143px",
      height: "36.5px",
      background: " transparent",
      borderRadius: "4px",
    },
    control: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      " & > div ": {
        alignSelf: "center !important",
        marginBottom: "5px",
      },
    },
  })
);

interface GridDemoProps {
  height: number;
  onHeightChange: (value: number) => void;
}

const GridDemo: React.FC<GridDemoProps> = ({
  height,
  onHeightChange,
}) => {
  const classes = useStyles();

  // Calculate the combined height of the two boxes
  const combinedHeight = 473; // Adjust the margin as needed
  const svgHeight = height *5  - 4; // You can change this value as needed

  // SVG code using the variable
  
  const VerSvgCode = `
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="${svgHeight}" viewBox="0 0 10 ${svgHeight}"  fill="none">
            <path d="M5 ${svgHeight - 1}L5.00009 1M5 ${svgHeight - 1}L9 ${
    svgHeight - 5
  }M5 ${svgHeight - 1}L1 ${
    svgHeight - 5
  }M5.00009 1L1.00011 4.99997M5.00009 1L9.00008 4.99997" stroke="#0179FF"/>
        </svg>
    `;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        overflowX :"auto",

        backgroundColor: "#F5F9FF",
      }}
    >
      <div style={{ flexGrow: 0.8 }}></div>
      <div
        style={{
          backgroundColor: "#F5F9FF",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          overflow :"auto",
          width: "fit-content",

          alignItems: "flex-end",
          marginRight: "50px",
          marginTop: "-48px",
        }}
      >
        <div className={classes.control}>
          
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CustomNumberInput
            value={height}
            unit="cm"
            width={91}
            onValueChange={(value) => {
              onHeightChange(value);
            }}
            min={2}
            max={60}
            onUnitChange={() => {}}
          />
          <div
            style={{ marginTop: "10px" }}
            dangerouslySetInnerHTML={{ __html: VerSvgCode }}
          />

          <div
            className={classes.subBoxDisplay}
            style={{ width:220, height: height * 5 }}
          ></div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#F5F9FF",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#F5F9FF",

            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <div className={classes.customBox}>
              {Array.from({ length: 10 }).map((_, index) => (
                <div
                  key={index}
                  className={index === 5 ? classes.subBoxEmpy : classes.subBox}
                  style={{ width:220, height:height * 5 }}
                ></div>
              ))}
            </div>
            <div
              className={classes.secondBox}
              style={{ width: 220 + 8 }}
            ></div>
          </div>
        </div>
      </div>

      <div style={{ flexGrow: 1 }}></div>

      <div
        style={{
          backgroundColor: "#F5F9FF",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // Add additional styling as needed for BoxList
        }}
      >
        <BoxList></BoxList>
      </div>
    </div>
  );
};

export default GridDemo;