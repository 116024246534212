import React, { useEffect, useState } from "react";
import { Typography, Snackbar, Alert, CircularProgress } from "@mui/material";
import DiscreteSliderSteps from "../DiscreteSliderSteps/DiscreteSliderSteps";
import MinFacings from "../MinFacings/MinFacings";
import RRPOrder from "../RRPOrder/RRPOrder";
import FillPolicy from "../FillPolicyRRP/FillPolicy";
import {
  getSkusWithMinFacings,
  updateSkuMinFacings,
  getSkusOrderedByIncreaseOrder,
  fetchSkusByBrandAndIncreaseOrder,
} from "../../../services/skuService";
import {
  getConfiguration,
  updateConfiguration,
  Configuration,
} from "../../../services/configurationService";
import BrandOrder from "../BrandOrder/BrandOrder";
import TabsComponent from "../TabComponent/Tab";
import { getSkusWithPriority, updateSkuPriority } from "../../../services/skuPriorityService";

interface RRPTabProps {
  keyAccount: any;
  onChangeHappen: (value?:string) => void

}

const cardStyle: React.CSSProperties = {
  boxShadow: "0px 2px 2px #eee",
  backgroundColor: "white",
  padding: "22px 32px",
};
type ItemProps = {
  id: number;
  pack_image: string;
  variantName: string;
  brandName: string;
  min_facings: number;
};

const RRPTab: React.FC<RRPTabProps> = ({keyAccount,onChangeHappen}) => {
  const [valueIqos, setValueIqos] = useState<number>(50);
  const [config, setConfig] = useState<Configuration | null>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);
  const [loadingItemId, setLoadingItemId] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const [marlboroIncrease, setMarlboroIncrease] = useState<{}>({});
  const [marlboroDecrease, setMarlboroDecrease] = useState<{}>({});

  const [loadingMarlboroIncrease, setLoadingMarlboroIncrease] =
    useState<boolean>(false);
  const [loadingMarlboroDecrease, setLoadingMarlboroDecrease] =
    useState<boolean>(false);

  const [errorMarlboroIncrease, setErrorMarlboroIncrease] = useState<
    string | null
  >(null);
  const [errorMarlboroDecrease, setErrorMarlboroDecrease] = useState<
    string | null
  >(null);
  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    const fetchConfiguration = async () => {
      try {
        const configData = await getConfiguration();
        setValue(configData.Marlboro);
        setConfig(configData);
        setValueIqos(configData.cc_iqos);
      } catch (error: any) {
        setError("Error fetching configuration");
        console.error("Error fetching configuration:", error);
      }
    };
    fetchConfiguration();
  }, []);
  const updateMinFacings = (heetsItems: ItemProps[], data: ItemProps[]) => {
    return heetsItems.map(heet => {
      const matchingData = data.find(item => item.id === heet.id);
      return matchingData ? { ...heet, min_facings: matchingData.min_facings } : heet;
    });
  };
  const handleMinFacingsChange = async (id: number, minFacings: number,KeyAccount:number) => {
    setLoadingItemId(id);
    try {
      await updateSkuPriority(KeyAccount, id, { minFacings });
      const data = await getSkusWithPriority(keyAccount.id, 'RRP');

      setItems((prevItems) =>
        prevItems.map((item) =>
          item.id === id ? { ...item, min_facings: minFacings } : item
        )
      );
      
      const dataInc = data.sort((a: any, b: any) => a.increase_order - b.increase_order);

       // Initialize brand arrays
       let heetsIncrease: ItemProps[] = [];
       let tereaIncrease: ItemProps[] = [];
       let fiitIncrease: ItemProps[] = [];
       let deliaIncrease: ItemProps[] = [];
       let veevOneIncrease: ItemProps[] = [];
       let veevNowIncrease: ItemProps[] = [];
       let leviaIncrease: ItemProps[] = [];
     
       // Split dataInc based on brandName and assign to respective variables
       dataInc.forEach((item: ItemProps) => {
         switch (item.brandName.toLowerCase()) {
           case 'heets':
             heetsIncrease.push(item);
             break;
           case 'terea':
             tereaIncrease.push(item);
             break;
           case 'fiit':
             fiitIncrease.push(item);
             break;
           case 'delia':
             deliaIncrease.push(item);
             break;
           case 'veev one':
             veevOneIncrease.push(item);
             break;
           case 'veev now':
             veevNowIncrease.push(item);
             break;
           case 'levia':
             leviaIncrease.push(item);
             break;
           default:
             break;
         }
       });
     
       // Set the updated state for MarlboroIncrease
       setMarlboroIncrease({
         HEETS: heetsIncrease,
         FIIT: fiitIncrease,
         DELIA: deliaIncrease,
         TEREA: tereaIncrease,
         VEEV_ONE: veevOneIncrease,
         VEEV_NOW: veevNowIncrease,
         LEVIA: leviaIncrease,
       });

      setSuccess("SKU min facings updated successfully");
      onChangeHappen('RRP')
    } catch (error) {
      setError("Error updating min facings");
      console.error("Error updating min facings:", error);
    } finally {
      setLoadingItemId(null);
    }
  };
  useEffect(() => {
    const fetchSkus = async () => {
      try {
        // Initialize state variables for each brand
     
        // Fetch SKUs for HNB brand
        setLoadingMarlboroIncrease(true);

        const data = await getSkusWithPriority(keyAccount.id, 'RRP');
        const dataInc = data.sort((a: any, b: any) => a.increase_order - b.increase_order);

        setItems(data);
       
      
        setLoadingMarlboroIncrease(false);

      
        // Initialize brand arrays
        let heetsIncrease: ItemProps[] = [];
        let tereaIncrease: ItemProps[] = [];
        let fiitIncrease: ItemProps[] = [];
        let deliaIncrease: ItemProps[] = [];
        let veevOneIncrease: ItemProps[] = [];
        let veevNowIncrease: ItemProps[] = [];
        let leviaIncrease: ItemProps[] = [];
      
        // Split dataInc based on brandName and assign to respective variables
        dataInc.forEach((item: ItemProps) => {
          switch (item.brandName.toLowerCase()) {
            case 'heets':
              heetsIncrease.push(item);
              break;
            case 'terea':
              tereaIncrease.push(item);
              break;
            case 'fiit':
              fiitIncrease.push(item);
              break;
            case 'delia':
              deliaIncrease.push(item);
              break;
            case 'veev one':
              veevOneIncrease.push(item);
              break;
            case 'veev now':
              veevNowIncrease.push(item);
              break;
            case 'levia':
              leviaIncrease.push(item);
              break;
            default:
              break;
          }
        });
      
        // Set the updated state for MarlboroIncrease
        setMarlboroIncrease({
          HEETS: heetsIncrease,
          FIIT: fiitIncrease,
          DELIA: deliaIncrease,
          TEREA: tereaIncrease,
          VEEV_ONE: veevOneIncrease,
          VEEV_NOW: veevNowIncrease,
          LEVIA: leviaIncrease,
        });
     

  
  
      
  
      
  
      
        // Reset loading state for each brand
        setLoadingMarlboroIncrease(false);
        setLoadingMarlboroDecrease(false);
      } catch (error) {
        setError("Error fetching SKUs");
        console.error("Error fetching SKUs:", error);
      }
    };
    fetchSkus();
  }, [keyAccount]);


 

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setError(null);
    setSuccess(null);
    setErrorMarlboroIncrease(null);
    setErrorMarlboroDecrease(null);
  };

  console.log("key account rrp", keyAccount);
  const tabs = [
    { title: 'HEETS', content: (marlboroIncrease as any)?.HEETS?.length > 0 && <FillPolicy
      increase={(marlboroIncrease as any).HEETS}
      decrease={(marlboroIncrease as any).HEETS}
      brandName="HEETS"
      loadingIncrease={loadingMarlboroIncrease}
      loadingDecrease={loadingMarlboroDecrease}
      errorIncrease={errorMarlboroIncrease}
      errorDecrease={errorMarlboroDecrease}
      setMarlboroIncrease={setMarlboroIncrease} 
      keyAccountId={keyAccount.id}
      onChangeHappen={onChangeHappen}
    /> },
    { title: 'FIIT', content: (marlboroIncrease as any)?.FIIT?.length > 0 && <FillPolicy
      increase={(marlboroIncrease as any).FIIT}
      decrease={(marlboroIncrease as any).FIIT}
      brandName="FIIT"
      loadingIncrease={loadingMarlboroIncrease}
      loadingDecrease={loadingMarlboroDecrease}
      errorIncrease={errorMarlboroIncrease}
      errorDecrease={errorMarlboroDecrease}
      setMarlboroIncrease={setMarlboroIncrease} 
      keyAccountId={keyAccount.id}
      onChangeHappen={onChangeHappen}

    /> },
    { title: 'TEREA', content: (marlboroIncrease as any)?.TEREA?.length > 0 && <FillPolicy
      increase={(marlboroIncrease as any).TEREA}
      decrease={(marlboroIncrease as any).TEREA}
      brandName="TEREA"
      loadingIncrease={loadingMarlboroIncrease}
      loadingDecrease={loadingMarlboroDecrease}
      errorIncrease={errorMarlboroIncrease}
      errorDecrease={errorMarlboroDecrease}
      setMarlboroIncrease={setMarlboroIncrease} 
      keyAccountId={keyAccount.id}
      onChangeHappen={onChangeHappen}

    /> },
    { title: 'DELIA', content: (marlboroIncrease as any)?.DELIA?.length > 0 && <FillPolicy
      increase={(marlboroIncrease as any).DELIA}
      decrease={(marlboroIncrease as any).DELIA}
      brandName="DELIA"
      loadingIncrease={loadingMarlboroIncrease}
      loadingDecrease={loadingMarlboroDecrease}
      errorIncrease={errorMarlboroIncrease}
      errorDecrease={errorMarlboroDecrease}
      setMarlboroIncrease={setMarlboroIncrease} 
      keyAccountId={keyAccount.id}
      onChangeHappen={onChangeHappen}

    /> },
    { title: 'VEEV NOW', content: (marlboroIncrease as any)?.VEEV_NOW?.length > 0 && <FillPolicy
      increase={(marlboroIncrease as any).VEEV_NOW}
      decrease={(marlboroIncrease as any).VEEV_NOW}
      brandName="Marlboro"
      loadingIncrease={loadingMarlboroIncrease}
      loadingDecrease={loadingMarlboroDecrease}
      errorIncrease={errorMarlboroIncrease}
      errorDecrease={errorMarlboroDecrease}
      setMarlboroIncrease={setMarlboroIncrease}
      keyAccountId={keyAccount.id}
      onChangeHappen={onChangeHappen}
 
    /> },
    { title: 'VEEV ONE', content:  (marlboroIncrease as any)?.VEEV_ONE?.length > 0 && <FillPolicy
      increase={(marlboroIncrease as any).VEEV_ONE}
      decrease={(marlboroIncrease as any).VEEV_ONE}
      brandName="Marlboro"
      loadingIncrease={loadingMarlboroIncrease}
      loadingDecrease={loadingMarlboroDecrease}
      errorIncrease={errorMarlboroIncrease}
      errorDecrease={errorMarlboroDecrease}
      setMarlboroIncrease={setMarlboroIncrease}
      keyAccountId={keyAccount.id}
      onChangeHappen={onChangeHappen}
      // Pass the setter as a prop
    /> },
    { title: 'LEVIA', content: (marlboroIncrease as any)?.LEVIA?.length > 0 && <FillPolicy
      increase={(marlboroIncrease as any).LEVIA}
      decrease={(marlboroIncrease as any).LEVIA}
      brandName="LEVIA"
      loadingIncrease={loadingMarlboroIncrease}
      loadingDecrease={loadingMarlboroDecrease}
      errorIncrease={errorMarlboroIncrease}
      errorDecrease={errorMarlboroDecrease}
      setMarlboroIncrease={setMarlboroIncrease} 
      keyAccountId={keyAccount.id}
      onChangeHappen={onChangeHappen}

    /> },
  ];
  return (
    <div style={{ marginTop: "14px" }}>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!success}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {success}
        </Alert>
      </Snackbar>
      <div
        style={{
          ...cardStyle,
          borderTopRightRadius: "15px",
          borderTopLeftRadius: "15px",
          borderBottom: "1px solid #E1E3E6",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            color: "#383A49",
            marginBottom: "6px",
          }}
          color="text.primary"
        >
          RRP MINIMUM Number of Facings: <strong style={{color:'#5779FF', textTransform:'capitalize'}}>{keyAccount.title}</strong> 
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 500,
            color: "#383A49",
            opacity: 0.5,
            marginBottom: "12px",
          }}
          color="text.primary"
        >
          Type MINIMUM Number Of Facings for Each Pack
        </Typography>
       { keyAccount &&  <MinFacings
          items={items}
          onMinFacingsChange={handleMinFacingsChange}
          keyAccount={keyAccount}

          loadingItemId={loadingItemId}
        />}
        <div
          style={{
            border: "1px solid #E1E3E6",
            marginBottom: "24px",
            marginTop: "26px",
          }}
        ></div>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            color: "#383A49",
            marginBottom: "6px",
          }}
          color="text.primary"
        >
          RRP Order: <strong style={{color:'#5779FF', textTransform:'capitalize'}}>{keyAccount.title}</strong> 
        </Typography>
        <RRPOrder height={150} boxPerRow={10}   minFacings={items}  onChangeHappen={onChangeHappen}  />
        <div
          style={{
            border: "1px solid #E1E3E6",
            marginBottom: "24px",
            marginTop: "20px",
          }}
        ></div>
        {/* {marlboroIncrease.length > 0 && (
          <FillPolicy
            category="RRP"
            increase={marlboroIncrease}
            decrease={marlboroIncrease}
            brandName="Marlboro"
            loadingIncrease={loadingMarlboroIncrease}
            loadingDecrease={loadingMarlboroDecrease}
            errorIncrease={errorMarlboroIncrease}
            errorDecrease={errorMarlboroDecrease}
            setMarlboroIncrease={setMarlboroIncrease} // Pass the setter as a prop
          />
        )} */}
{                    <TabsComponent tabs={tabs} />
}
      </div>
    </div>
  );
};

export default RRPTab;
