import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import Box from '@mui/material/Box';

interface MultiCheckboxProps {
  label?: string;
  options: string[];
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
}

const MultiCheckboxComponent: React.FC<MultiCheckboxProps> = ({ label, options, selectedOptions, setSelectedOptions }) => {
  const handleOnChange = (option: string) => {
    const updatedSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter(item => item !== option)
      : [...selectedOptions, option];
    setSelectedOptions(updatedSelectedOptions);
  };

  return (
    <Box sx={{ marginTop: label ? "20px" : 0 }}>
      {label && <Typography variant="h6" gutterBottom>
        {label}
      </Typography>}
      <FormGroup>
        {options.map((option, index) => (
          <FormControlLabel
            key={option}
            sx={{
              '.MuiFormControlLabel-label': {
                fontSize: '14px',
                fontWeight: "300",
                color: "#383A49"
              },
            }}
            control={
              <Checkbox
                checked={selectedOptions.includes(option)}
                onChange={() => handleOnChange(option)}
                name={option}
                sx={{
                  borderRadius: "3px",
                  color: "#D9E7F8",
                }}
                color="primary"
              />
            }
            label={option}
          />
        ))}
      </FormGroup>
    </Box>
  );
};

export default MultiCheckboxComponent;
