import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  TextField,
  Chip,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Chain {
  title: string;
  description: string;
  id: number;
}

interface Channel {
  channelId: number;
  channelName: string;
  chains: Chain[];
}

interface ChainOption extends Chain {
  channelName: string;
}

interface DropdownProps {
  channels: Channel[];
  initialValue?: [];
  onKeyAccountSelect: (chains: Chain[]) => void;
}

const DropdownComponent: React.FC<DropdownProps> = ({
  channels,
  initialValue,
  onKeyAccountSelect,
}) => {
  // Flatten the data into a single array of key accounts with channel names
  console.log('initialValue test',initialValue)
  const options: ChainOption[] = React.useMemo(
    () =>
      channels.flatMap((channel) =>
        channel.chains.map((chain) => ({
          ...chain,
          channelName: channel.channelName,
        }))
      ),
    [channels]
  );

  // State to manage the selected key accounts
  const [value, setValue] = React.useState<ChainOption[]>([]);

  // Set initial value if provided
  React.useEffect(() => {
    if (initialValue) {
      const initialOptions = options.filter((option) =>
        initialValue.some((init) => init === option.id)
      );
      console.log()
      setValue(initialOptions);
    }
  }, [initialValue, options]);

  const handleChange = (event: any, newValue: ChainOption[]) => {
    setValue(newValue);
    onKeyAccountSelect(newValue);
  };
  const inputRef = useRef<HTMLDivElement>(null);
  const [inputWidth, setInputWidth] = useState(0);

  // Update input width whenever the component mounts or resizes
  useEffect(() => {
    const updateWidth = () => {
      if (inputRef.current) {
        setInputWidth(inputRef.current.offsetWidth);
      }
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  const averageChipWidth = 122; // Average width of a chip in pixels (adjust as needed)
  const chipMargin = 4;

  return (
    <Autocomplete
     id='reactmulti-select-dropdown'
      multiple
      fullWidth
      options={options}
      groupBy={(option) => option.channelName}
      getOptionLabel={(option) => option.title}
      value={value}
      onChange={handleChange}
      limitTags={2}
      renderTags={(value, getTagProps) => {
        const availableWidth = inputWidth - 100; // Subtract some padding (adjust as needed)
        let totalWidth = 0;
        let displayedItems: ChainOption[] = [];

        for (let i = 0; i < value.length; i++) {
          totalWidth += averageChipWidth + chipMargin;
          if (totalWidth < availableWidth) {
            displayedItems.push(value[i]);
          } else {
            break;
          }
        }

        const moreItems = value.length - displayedItems.length;

        return (
          <>
            {displayedItems.map((option, index) => (
              <Chip
                label={option.title}
                {...getTagProps({ index })}
                sx={{
                  backgroundColor: 'black' ,
                  borderRadius:'20px',
                  height:'24px',
                  color:  'white' ,
                  '& .MuiChip-deleteIcon': {
                    color: 'white',
                    width:'14px',
                    '&:hover':{
                      color:'white',
                      opacity:0.5
                    }
                    
                  },
                }}
              />
            ))}
            {moreItems > 0 && (
              <Chip
                label={`+${moreItems} more`}
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                }}
              />
            )}
          </>
        );
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <div
            {...props as any}
            style={{
              margin: '4px',
              background:'transparent',
              padding:'6px 0'
            }}
          >
            <Chip
              label={option.title}
              onDelete={
                selected
                  ? (event) => {
                      event.stopPropagation();
                      const newValue = value.filter((v) => v.id !== option.id);
                      setValue(newValue);
                      onKeyAccountSelect(newValue);
                    }
                  : undefined
              }
              deleteIcon={selected ? <CloseIcon /> : undefined}
              sx={{
                backgroundColor: selected ? 'black' : '#E3E3E3',
                borderRadius:'20px',
                height:'24px',
                color: selected ? 'white' : 'black',
                '& .MuiChip-deleteIcon': {
                  color: 'white',
                  width:'14px',
                  '&:hover':{
                    color:'white',
                    opacity:0.5
                  }
                  
                },
              }}
            />
          </div>
        );
      }}
      renderGroup={(params) => (
        <div key={params.key}>
          <div className="MuiAutocomplete-groupLabel">{params.group}</div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {params.children}
          </div>
        </div>
      )}
      sx={{
        
        '&::placeholder': {
          textOverflow: 'ellipsis !important',
          color: 'blue',
        },
        '& .MuiAutocomplete-groupLabel': {
          color: '#383A49',
          fontFamily: 'Mulish',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
          background: '#FFF',
        },
        '& .MuiAutocomplete-popupIndicator ': {
  marginRight:'-11px'
},
      }}
      componentsProps={{
        paper: {
          sx: {
            borderRadius: '6px',
            background: '#FFF',
            padding:'16px',
            boxShadow:' 0px 2px 10px 0px rgba(0, 0, 0, 0.20)',
            overflowY: 'auto',
            '& .MuiAutocomplete-groupUl': {
              color: '#383A49',
              fontSize: '16px !important',
              fontStyle: 'normal',
              fontWeight: '400 !important',
            },
            '& .MuiAutocomplete-groupLabel': {
              color: '#888992',
              fontFamily: 'Mulish',
              textTransform:'uppercase',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: 'normal',
              background: '#FFF',
            },
            '& .MuiListSubheader-root': {
              color: '#383A49',
              fontSize: '12px !important',
              fontStyle: 'normal',
              fontWeight: '700 !important',
            },
          },
        },
      }}
      renderInput={(params) => (
        <TextField
    
        ref={inputRef}
        
          {...params}
          InputLabelProps={{
            shrink: false, // Prevent the label from shrinking on focus
          }}
          size="small"
          label="Select key account here"
          variant="outlined"
          sx={{
            background: 'white',
            borderRadius: '8px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              minHeight: '50px',
              '& fieldset': {
              //  borderColor: 'rgb(147,194,255)',

                borderRadius: "10px",
                
              },
              '&:hover fieldset': {
               // borderColor: '#0179FF',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'rgb(38,132,255)',
                color:'#888992 !important'

              },
            },
            '& .MuiInputLabel-root': {
              color: '#888992 !important',
              fontSize: '16px',
              fontWeight: '400',
              fontStyle: 'italic',
              top: '4px',
              zIndex:'0',
              //transform: 'translate(0, 0) scale(1)',
              display: value.length > 0 ?'none':'block', // Keep the label in place
              '& .MuiInputLabel-shrink': {
                 transform: 'translate(0, -6px) scale(2)', 
                // color:'white'// Shrink when there is content
              },
            },
            '&.Mui-focused .MuiInputLabel-root': {
              color: 'red !important',
            },
            '& .MuiOutlinedInput-input': {
              paddingTop: '22px',
              '&:focus': {
                outline: 'none',
              },
              color: '#383A49',
              fontFamily: 'Mulish',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
            },
          }}
          // InputProps={{
          //   ...params.InputProps,
          //   ref: inputRef, // Attach ref to the input container
          // }}
        />

      )}
    />
  );
};

export default DropdownComponent;
