import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Sidebar from '../components/Step12-mini/sideBar/SideBar';
import DemoComponent from '../components/Step12-mini/Content/Content';
import { getFromJson, saveToJson } from '../components/helpers/localData';
// Import the utility functions

const AboutPage: React.FC = () => {
  // Attempt to retrieve initial values from localStorage, falling back to default values if not found
  const initialCol = getFromJson<number>('col-mini') || 2;
  const initialRow = getFromJson<number>('row-mini') || 2;
  const initialRowDistance = getFromJson<number>('rowDistance') || 2;
  const initialColDistance = getFromJson<number>('colDistance') || 2;

  // State initialization with potentially retrieved values
  const [col, setCol] = useState(initialCol);
  const [row, setRow] = useState(initialRow);
  const [rowDistance, setRowDistance] = useState(initialRowDistance);
  const [colDistance, setColDistance] = useState(initialColDistance);

  // Save state to localStorage whenever it changes
  useEffect(() => {
    saveToJson('col-mini', col);
  }, [col]);

  useEffect(() => {
    saveToJson('row-mini', row);
  }, [row]);

  useEffect(() => {
    saveToJson('rowDistance', rowDistance);
  }, [rowDistance]);

  useEffect(() => {
    saveToJson('colDistance', colDistance);
  }, [colDistance]);
  const [squaresWithImages, setSquaresWithImages] = useState<Array<Array<string>>>([]);

  // New state for the currently selected square
  const [selectedSquare, setSelectedSquare] = useState<{ col: number; row: number } | null>(null);
  useEffect(()=>{
   console.log(selectedSquare)
  },[selectedSquare])

  // New state for the selected product card image URL
  const [selectedProductCardImage, setSelectedProductCardImage] = useState<string>('');
  useEffect(()=>{
    console.log(selectedProductCardImage)
   },[selectedProductCardImage])
  // Initialize squaresWithImages based on col and row state
  useEffect(() => {
    setSquaresWithImages(new Array(row).fill(new Array(col).fill('')));
  }, [col, row]);
  useEffect(() => {
    // Ensure both selectedSquare and selectedProductCardImage have values before proceeding
    if (selectedSquare && selectedProductCardImage) {
      // Update the squaresWithImages state with the new image URL at the selected square's position
      setSquaresWithImages(currentSquares => {
        // Clone the current state to avoid direct mutation
        const updatedSquares = currentSquares.map(row => [...row]);

        if (updatedSquares[selectedSquare.row] && updatedSquares[selectedSquare.row][selectedSquare.col] !== undefined) {
          updatedSquares[selectedSquare.row][selectedSquare.col] = selectedProductCardImage;
        }

        return updatedSquares;
      });

      // Optionally reset the selected states if needed
      setSelectedSquare(null);
      setSelectedProductCardImage('');
    }
  }, [selectedSquare, selectedProductCardImage, row, col]);
  return (
    <div>
      <Grid container>
        <Grid item xs={2.8}>
          <Sidebar 
            col={col} row={row} 
            colDistance={colDistance} onColDistanceChange={setColDistance} 
            rowDistance={rowDistance} onRowDistanceChange={setRowDistance}  
            onColChange={setCol} onRowChange={setRow} 
            selectedProductCardImage={selectedProductCardImage} 
            setSelectedProductCardImage={setSelectedProductCardImage}/>
       
        </Grid>
        <Grid item xs={9.2}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#e0e0e0",
            }}
          >
           <DemoComponent 
             col={col} row={row} 
             setCol={setCol} setRow={setRow} 
             rowDistance={rowDistance} setRowDistance={setRowDistance}  
             colDistance={colDistance} setColDistance={setColDistance} 
             squaresWithImages={squaresWithImages}
             selectedSquare={selectedSquare}
             setSelectedSquare={setSelectedSquare}
             />
     
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AboutPage;
