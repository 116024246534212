import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error('API URL not defined in environment variables');
}

export const createMarketingMaterial = async (title: string, width: number, height: number, image?: File) => {
  try {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('width', width.toString());
    formData.append('height', height.toString());
    if (image) {
      formData.append('image', image);
    }

    const response = await axios.post(`${API_URL}/marketing-materials`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getAllMarketingMaterials = async () => {
  try {
    const response = await axios.get(`${API_URL}/marketing-materials`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getMarketingMaterialById = async (id: number) => {
  try {
    const response = await axios.get(`${API_URL}/marketing-materials/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const updateMarketingMaterial = async (id: number, title: string, width: number, height: number, image?: File) => {
  try {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('width', width.toString());
    formData.append('height', height.toString());
    if (image) {
      formData.append('image', image);
    }

    const response = await axios.put(`${API_URL}/marketing-materials/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const deleteMarketingMaterial = async (id: number) => {
  try {
    const response = await axios.delete(`${API_URL}/marketing-materials/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};


export const searchMarketingMaterialsByName = async (name: string) => {
  try {
    const response = await axios.get(`${API_URL}/marketing-materials/search`, {
      params: { name }
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const searchLedScreensByName = async (name: string) => {
  try {
    const response = await axios.get(`${API_URL}/led-screens/search`, {
      params: { name }
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};