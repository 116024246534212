import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import NoShelfCard from "../noShelfCard/NoshelfCard";
import { useStyles } from "./ContentStyles";
import CreateShelfCard from "../CreateShelfCard/CreatShelfCard";
import ExcistShelfCard from "../ExcistSheflCard/ExcistShelfCard";
import { Grid, CircularProgress, Snackbar, Alert } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { getAllRRPConfigurations } from "../../../services/rrpConfigurationService";
import { getAllRRPNewConfigs } from "../../../services/rrpNewConfigurationService";
import { getAllKeyAccounts, getGroupedChains } from "../../../services/posuniversService";

interface ContentComponentProps {
  title: string;
  subtitle: string;
}

const ContentComponent: React.FC<ContentComponentProps> = ({
  title,
  subtitle,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [configurations, setConfigurations] = useState<any[]>([]);
  const [isList, setIsList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const removeConfiguration = (id: number) => {
    setConfigurations((prevConfigs) => prevConfigs.filter((config) => config.id !== id));
    setIsList(configurations.length > 0);
  };
  const [groupedChains, setGroupedChains] = useState<any[]>([]);
  const [indId,setIndId]=useState<number| undefined>()

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const fetchedConfigurations = await getAllRRPNewConfigs();
        setConfigurations(fetchedConfigurations);
        setIsList(fetchedConfigurations.length > 0);
        const fetchedGroupedChains = await getGroupedChains();
        setGroupedChains(fetchedGroupedChains);
        const keyAccountsData = await getAllKeyAccounts();
        // const accountsStoresData =
        //   await getPosUniversesWithNotNullKeyAccount();
        // setAccountsStoresData(accountsStoresData); // Store accountsStoresData
       
        const Individual = keyAccountsData.find((value:any)=>value.name ==='Individual Store')
        setIndId(Individual.id)
      } catch (error) {
        console.error('Error fetching configurations:', error);
        setError('Failed to fetch configurations. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchConfigurations();
  }, []);

  const handleCloseSnackbar = () => {
    setError(null);
  };
 

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {subtitle}
        </Typography>
      </div>
      
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {isList ? (
            <Grid container spacing={2} style={{ padding: "40px", paddingBottom:'0', paddingTop:'0' }}>
              {configurations.map((config) => (
                <Grid key={config.id} item xs={12} sm={6} md={4} lg={3}>
                  <ExcistShelfCard
                    name={config.name}
                    col={config.col}
                    row={config.row_position}
                    createdAt={config.created_at}
                    id={config.id}
                    groupedChains={groupedChains}
                    indId={indId}
                    removeConfiguration={removeConfiguration} // Pass the remove function
                    setError={setError}
                  />
                </Grid>
              ))}
              <Grid key={"create-new"} item xs={12} sm={6} md={4} lg={3}>
                <CreateShelfCard groupedChains={groupedChains}                     indId={indId}
 />
              </Grid>
            </Grid>
          ) : (
            <div className={classes.centerContainer}>
              <NoShelfCard
                title="Not created any shelves yet"
                buttonText="+ Create New Shelf"
                onClick={() => { navigate("/rrp/step1") }}
              />
            </div>
          )}
        </>
      )}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContentComponent;
