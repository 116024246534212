import React from "react";
import {
  Paper,
  IconButton,
  Theme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { Remove as RemoveIcon } from "@mui/icons-material";
import Plus from "../../../assets/icons/Plus.svg";
import Minus from "../../../assets/icons/Minus.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      margin: "-5px", // Adjust this value as needed
      width: "auto",
      "& > .MuiGrid-item": {
        padding: "5px", // Adjust this value as needed
      },
    },
    flexContainer: {
      display: "flex",
      margin: `2px`,
    },
    flexItem: {
      padding: `2px 3px`,
    },
    square: {
      width: 180,
      height: 30,
      backgroundColor: "#B5D8FF",
      boxShadow: "none",
    },
    controlBar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(2),
      backgroundColor: "#D9E7F8",
      width: "100%",
      borderRadius: "3px",
    },
    verticalControlBar: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "0 34px",
      backgroundColor: "#D9E7F8",

      borderRadius: "3px",
      alignItems: "center",
      minWidth: "82px",
      marginLeft: theme.spacing(2),
      height: "100%", // Make the vertical bar take the height of the squares
    },
    iconButton: {
      width: 28,
      height: 28,
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      color: "#0179FF",
    },
    iconButton2: {
      padding: 6, // Set padding to 0 to remove the default padding
    },
  })
);

interface GridDemoProps {
  row: number;
  col: number;
  rowDistance: number;
  setRowDistance: React.Dispatch<React.SetStateAction<number>>;
  colDistance: number;
  setColDistance: React.Dispatch<React.SetStateAction<number>>;
  setRow: React.Dispatch<React.SetStateAction<number>>;
  setCol: React.Dispatch<React.SetStateAction<number>>;
  hiddencells: boolean[][];
  setHiddencells: React.Dispatch<React.SetStateAction<boolean[][]>>;

}

const GridDemo: React.FC<GridDemoProps> = ({
  row,
  col,
  setRow,
  setCol,
  rowDistance,
  colDistance,
  hiddencells,
  setHiddencells,
}) => {
  const classes = useStyles();

  const handleAddRow = () => {
    setRow(row + 1);

let value = row + 1
    if(value > 12) {
      setRow(12)
    }else if(value < 1) {
      setRow(1)

    }else {
      setRow(value) 
    }
  };

  const handleRemoveRow = () => {
    

    let value = row - 1
    if(value > 12) {
      setRow(12)
    }else if(value < 1) {
      setRow(1)

    }else {
      setRow(value) 
    }

  };

  const handleAddColumn = () => {
    
    if((col + 1) > 4) {
      setCol(4)
    }else if((col + 1) < 1) {
      setCol(1)

    }else {
      setCol(col + 1)
    }
  };

  const handleRemoveColumn = () => {
    if (col > 1) {
      setCol(col - 1);
    }

    if((col - 1) > 4) {
      setCol(4)
    }else if((col - 1) < 1) {
      setCol(1)

    }else {
      setCol(col - 1)
    }
  };

  const renderSquares = () => {
    const rowsArray = [];
    for (let i = 0; i < row; i++) {
      const rowSquares = [];
      for (let j = 0; j < col; j++) {
        rowSquares.push(
          <div
            key={`${i}-${j}`}
            style={{ padding: `${colDistance *5 /2}px ${rowDistance *5/2 }px`  }}
          >
            <Paper
              className={classes.square}
              sx={{
                backgroundColor: hiddencells[i] && hiddencells[i][j] ? '#B5D8FF !important' : 'rgba(181, 216, 255, 0.3) !important',
              }}
            >
              <IconButton onClick={() => handleIconClick(i, j)}>
                {hiddencells[i] && hiddencells[i][j] ? (
                  <img src={Minus} alt="Minus Icon" />
                ) : (
                  <img src={Plus} alt="Plus Icon" />
                )}
              </IconButton>
            </Paper>
          </div>
        );
      }
      rowsArray.push(
        <div key={i} className={classes.flexContainer}>
          {rowSquares}
        </div>
      );
    }
    return rowsArray;
  };

  React.useEffect(() => {
    setHiddencells((prevGrid) => {
      const newGrid = [];
      for (let i = 0; i < row; i++) {
        const newRow = [];
        for (let j = 0; j < col; j++) {
          if (prevGrid && prevGrid[i] && prevGrid[i][j] !== undefined) {
            newRow.push(prevGrid[i][j]);
          } else {
            newRow.push(true); // Initial value is true
          }
        }
        newGrid.push(newRow);
      }
      return newGrid;
    });
  }, [row, col]);
  const handleIconClick = (rowIndex: number, colIndex: number) => {
    setHiddencells((prevGrid) => {
      const newGrid = prevGrid.map((row) => [...row]); // Deep copy
      newGrid[rowIndex][colIndex] = !newGrid[rowIndex][colIndex];
      return newGrid;
    });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F5F9FF",
      }}
    >
      <div
        style={{
          backgroundColor: "#F5F9FF",
          height: "100vh",
          display: "flex",
          width: "fit-content",
          padding:"0 10px",
          overflow :"auto",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        <div
          className={classes.controlBar}
          style={{
            width: `calc(180px * ${col} + ${
              rowDistance *5 /2 * ((col - 1) * 2)
            }px  )`,
            marginLeft: `${rowDistance *5/2}px`,
          }}
        >
          <IconButton className={classes.iconButton2} onClick={handleAddRow}>
            <Box component="span" className={classes.iconButton}>
              <AddIcon />
            </Box>
          </IconButton>
          <Typography variant="h3">  Drawers  {row} </Typography>
          <IconButton onClick={handleRemoveRow} className={classes.iconButton2}>
            <Box component="span" className={classes.iconButton}>
              <RemoveIcon />
            </Box>
          </IconButton>
        </div>
        <div style={{ display: "flex" }}>
          <div>{renderSquares()}</div>
          <div
            className={classes.verticalControlBar}
            style={{
              height: row > 1 ? `calc(32px * ${row} + ${
                colDistance *5 /2  * ((row - 1) * 2)
              }px  )` : '60px',
              marginTop: `${colDistance * 5}px`,
            }}
          >
            <Typography variant="h3">Columns  {col} </Typography>
            <div>
              <IconButton
                className={classes.iconButton2}
                onClick={handleAddColumn}
              >
                <Box component="span" className={classes.iconButton}>
                  <AddIcon />
                </Box>
              </IconButton>

              <IconButton
                className={classes.iconButton2}
                onClick={handleRemoveColumn}
              >
                <Box component="span" className={classes.iconButton}>
                  <RemoveIcon />
                </Box>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridDemo;
