import React, { useEffect, useRef, useState } from "react";
import { Paper, Theme, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import FillOrder from "../FillOrder/FillOrder";
import { getFromJson, saveToJson } from "../../../helpers/localData";
import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon
import { yellow } from "@mui/material/colors";
import Tooltip from "@mui/material/Tooltip";
import { getRRPNewConfigById } from "../../../../services/rrpNewConfigurationService";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexContainer: {
      display: "flex",
    //  margin: `5px`,
    },
    square: {
      width: 400,
      height: 50,
      backgroundColor: "#B5D8FF",
      boxShadow: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundSize: "cover",
      backgroundPosition: "center",
      zindex: -1,
      position: "relative",

      "&:hover": {
        cursor: "pointer",
      },
    },
    smallDiv: {
      width: 28.5,
      height: 44,
      backgroundColor: "#ffffff",
      margin: "1.25px",
      borderRadius: "4px",
    },
    smallDivContainer: {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      zIndex: 0,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      justifyContent: "flex-start",
    },
    selectedSquare: {
      border: "2px solid #0179FF",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "20px",
      paddingTop: "20px",
    },
    button: {
      display: "flex",
      width: 140,
      height: 56,
      fontFamily: "Mulish",
      flexDirection: "column",
      alignItems: "center",
      margin: "0 2px",
      padding: "10px 20px",
      borderRadius: "0",
      border: "1px solid transparent",
      backgroundColor: "#fff",
      fontSize: "16px",
      fontWeight: 400,
      color: "#383A49",
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
      "&.selected": {
        color: "#0179FF  !important ",
        fontSize: "16px !important",
        fontWeight: 700,
      },
      "&.disabled": {
        opacity: 0.95,
        cursor: "not-allowed",
      },
    },
    buttonLeft: {
      borderTopLeftRadius: "30px",
      borderBottomLeftRadius: "30px",
    },
    buttonRight: {
      borderTopRightRadius: "30px",
      borderBottomRightRadius: "30px",
    },
    buttonText: {
      marginBottom: "5px",
    },
    buttonDot: {
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: "#0179FF",
      marginTop: "5px",
    },
    closeIcon: {
      position: "absolute",
      width: "16px !important",
      height: "16px !important",
      top: 0,
      right: 0,
      cursor: "pointer",
      zIndex: 600, // Ensure it's above the squares
      color: "black", // Adjust color as needed
      "&:hover": {
        opacity: 0.4, // Change color on hover
      },
    },
    yellowSquare: {
      position: "absolute",
      backgroundColor: "#FFD700", // Yellow color
      cursor: "grab",
      zIndex: 400, // Ensure it's below the pink square if they overlap
      "&:active": {
        cursor: "grabbing",
      },
    },
    pinkSquare: {
      position: "absolute",
      backgroundColor: "#F95252",
      cursor: "grab",
      zIndex: 500,
      "&:active": {
        cursor: "grabbing",
      },
    },
  })
);

type Square = {
  title?: string;
  width: number;
  height: number;
  x: number;
  y: number;
};
interface GridDemoProps {
  row: number;
  col: number;
  squares: Square[];
  squaresYellow: Square[];

  setRow: React.Dispatch<React.SetStateAction<number>>;
  setCol: React.Dispatch<React.SetStateAction<number>>;

  items: {
    text: string;
    id: number;
    color: string;
    value: number;
    width: number;
  }[];

  pinkSquares: {
    width: number;
    height: number;
    x: number;
    y: number;
    title?: string | undefined;
    positions?: any;
  }[];
  setPinkSquares: React.Dispatch<
    React.SetStateAction<
      {
        title?: string | undefined;
        width: number;
        height: number;
        x: number;
        y: number;
      }[]
    >
  >;
  yellowSquares: {
    width: number;
    height: number;
    x: number;
    y: number;
    title?: string | undefined;
    positions?: any;
  }[];
  setYellowSquares: React.Dispatch<
    React.SetStateAction<
      {
        title?: string | undefined;
        width: number;
        height: number;
        x: number;
        y: number;
      }[]
    >
  >;
}
interface SquareData {
  row: number;
  col: number;
  duplexTypes: { id: number; value: number ,color:string,text:string,width:number}[];
}
const GridDemo: React.FC<GridDemoProps> = ({
  row,
  col,
  items,
  pinkSquares,
  setPinkSquares,
  yellowSquares,
  setYellowSquares,
  squares,
  squaresYellow,
}) => {
  const classes = useStyles();
  const [columnWidths, setColumnWidths] = useState<number[]>([]);
  const [ccSpace, setCcSpace] = useState<number>(8);
  const [squaresData, setSquaresData] = useState<SquareData[]>(
    () => getFromJson<SquareData[]>("squaresData") || []
  );
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config =id && await getRRPNewConfigById(id);
        setColumnWidths(config.widths);
        setCcSpace(config.ccSpace);
        setSquaresData(config.squaresData);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };

    fetchConfig();
  }, [items]);

  const containerRef = useRef<HTMLDivElement>(null);

  const removePinkSquare = (index: number) => {
    setPinkSquares((prev) => prev.filter((_, i) => i !== index));
  };
  useEffect(() => {
    setPinkSquares((prevPinkSquares) => {
      return squares.map((square, index) => {
        console.log("existingPinkSquare", square);

        // Find the corresponding pink square in the current state
        const existingPinkSquare = prevPinkSquares[index];
        return {
          ...square,
          // Use the current positions if the pink square exists, otherwise use initial positions
          x: existingPinkSquare ? existingPinkSquare.x : 50,
          y: existingPinkSquare ? existingPinkSquare.y : 50,
        };
      });
    });
    saveToJson("RRPLedScreens", pinkSquares);
  }, [squares, setPinkSquares]);
  useEffect(() => {
    setYellowSquares((prevYellowSquares) => {
      return squaresYellow.map((square, index) => {
        // Find the corresponding yellow square in the current state
        const existingYellowSquare = prevYellowSquares[index];
        return {
          ...square,
          x: existingYellowSquare ? existingYellowSquare.x : 100,
          y: existingYellowSquare ? existingYellowSquare.y : 100,
        };
      });
    });
    saveToJson("RRPMarketingList", squaresYellow);
  }, [squaresYellow, setYellowSquares]);
  const handlePinkSquareMouseDown = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => {
    event.stopPropagation();
    event.preventDefault(); // Prevent default to avoid text selection, etc.

    const pinkSquare = pinkSquares[index];
    const containerBounds = containerRef.current?.getBoundingClientRect();
    if (!containerBounds) return;

    // Calculate the offset inside the pink square where the mouse was clicked.
    const offsetX = event.clientX - pinkSquare.x - containerBounds.left;
    const offsetY = event.clientY - pinkSquare.y - containerBounds.top;

    const handleMouseMove = (moveEvent: MouseEvent) => {
      if (!containerRef.current) return;

      const containerBounds = containerRef.current.getBoundingClientRect();

      // Adjust the new position based on the mouse movement and initial click offset.
      // Substract container's left/top to convert to relative container coordinates.
      let newX =
        moveEvent.clientX - offsetX - containerBounds.left + window.scrollX; // Adjust for horizontal scroll
      let newY =
        moveEvent.clientY - offsetY - containerBounds.top + window.scrollY; // Adjust for vertical scroll

      // Check boundaries: Ensure the pink square doesn't go outside the container.
      newX = Math.max(
        0,
        Math.min(newX, containerBounds.width - pinkSquare.width)
      );
      newY = Math.max(
        0,
        Math.min(newY, containerBounds.height - pinkSquare.height)
      );

      // Update the specific pink square's position.
      setPinkSquares((prev) =>
        prev.map((square, idx) =>
          idx === index
            ? {
                ...square,
                x: newX,
                y: newY,
              }
            : square
        )
      );
    };

    const handleMouseUp = () => {
      // Remove event listeners when the mouse is released.
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    // Add event listeners to the document for mouse move and mouse up.
    // This allows the drag to continue even if the mouse leaves the pink square or container.
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };
  const handleYellowSquareMouseDown = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => {
    event.stopPropagation();
    event.preventDefault(); // Prevent default to avoid text selection, etc.

    const yellowSquare = yellowSquares[index];
    const containerBounds = containerRef.current?.getBoundingClientRect();
    if (!containerBounds) return;

    // Calculate the offset inside the pink square where the mouse was clicked.
    const offsetX = event.clientX - yellowSquare.x - containerBounds.left;
    const offsetY = event.clientY - yellowSquare.y - containerBounds.top;

    const handleMouseMove = (moveEvent: MouseEvent) => {
      if (!containerRef.current) return;

      const containerBounds = containerRef.current.getBoundingClientRect();

      // Adjust the new position based on the mouse movement and initial click offset.
      // Substract container's left/top to convert to relative container coordinates.
      let newX =
        moveEvent.clientX - offsetX - containerBounds.left + window.scrollX; // Adjust for horizontal scroll
      let newY =
        moveEvent.clientY - offsetY - containerBounds.top + window.scrollY; // Adjust for vertical scroll

      // Check boundaries: Ensure the pink square doesn't go outside the container.
      newX = Math.max(
        0,
        Math.min(newX, containerBounds.width - yellowSquare.width)
      );
      newY = Math.max(
        0,
        Math.min(newY, containerBounds.height - yellowSquare.height)
      );
      // Update the specific pink square's position.
      setYellowSquares((prev) =>
        prev.map((square, idx) =>
          idx === index
            ? {
                ...square,
                x: newX,
                y: newY,
              }
            : square
        )
      );
      console.log("setYellowSquares", yellowSquare);
    };
    // Function to handle removing a pink square

    const handleMouseUp = () => {
      // Remove event listeners when the mouse is released.
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    // Add event listeners to the document for mouse move and mouse up.
    // This allows the drag to continue even if the mouse leaves the pink square or container.
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };
  const removeYellowSquare = (index: number) => {
    setYellowSquares((prev) => prev.filter((_, i) => i !== index));
    saveToJson("RRPMarketingList", yellowSquares);
  };
  const renderSmallDivs = (numDivs: number) => {
    const smallDivs = [];
    for (let i = 0; i < numDivs; i++) {
      smallDivs.push(
        <div
          key={i}
          className={classes.smallDiv}
          style={{ left: `${i * 32}px` }}
        />
      ); // Position each small div
    }
    return smallDivs;
  };

  const renderSquares = () => {
    return Array.from({ length: row }, (_, rowIndex) => (
      <div key={rowIndex} className={classes.flexContainer}>
        {Array.from({ length: col }, (_, colIndex) => {
          const imageSrc = ''
          const squareData = squaresData.find(
            (data) => data.row === rowIndex && data.col === colIndex
          );
          const isSelected =false

          return (
            <div
              key={`${rowIndex}-${colIndex}`}
              //onClick={() => handleSquareClick(colIndex, rowIndex)}
              style={{ padding:  rowIndex != 0 ? `4px 4px` : '0 4px'}}
            >
              <Paper
                className={`${classes.square} ${
                  isSelected ? classes.selectedSquare : ""
                }`}
                style={{
                  backgroundImage: imageSrc ? `url(${imageSrc})` : "",
                  width:
                    (columnWidths?.[0] || 50) * 5,
                  padding: "2px",
                  justifyContent: "start",
                  gap: "2px",
                }}
              >
                <div className={classes.smallDivContainer}>
                  {renderSmallDivs(ccSpace|| 8)}
                </div>
                {squareData &&
                  squareData.duplexTypes.map((item, index) => {
                    const duplex = squareData.duplexTypes.find(
                      (d) => d.id === item.id
                    );
                    const isNonMatching =
                      duplex?.id !== 11 && duplex?.id !== 0 && duplex?.id !== 2 && duplex?.id !== 6;
                    return duplex
                      ? [...Array(duplex.value)].map((_, boxIdx) => (
                          <Tooltip
                            key={`type-${index}-${boxIdx}`}
                            title={` ${item.text}`}
                            arrow
                            placement="top"
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, 10],
                                  },
                                },
                              ],
                            }}
                          >
                            <div
                              key={`type-${index}-${boxIdx}`}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "85%",
                                zIndex: 15,
                                //opacity: isNonMatching ? 0.3 : 1,
                              }}
                            >
                              { item.id === 0 || item.id === 11 ? (
                                <div
                                  style={{
                                    width: `${item.width}px`,
                                    height: "100%",
                                    backgroundColor:  items.find((item2)=>{return item.id === item2.id})?.color,
                                    borderRadius: "3px",
                                  }}
                                />
                              ) : item.id === 6? (
                                <div
                                  style={{
                                    width: `${item.width}px`,
                                    height: "100%",
                                    display:'flex',
                                    flexDirection:'column',
                                    alignItems:  'center',
                                    justifyContent: 'space-between',
                                    boxSizing:'border-box',
                                    padding:'2px',
                                     gap:'1px',
                                    borderRadius: "3px",
                                    border: "1px solid",
                                    borderColor:  items.find((item2)=>{return item.id === item2.id})?.color,
                                  }}
                                >
                                  <div  style={{
                                    width: `${item.width - 4}px `,
                                    height: "12%",
                                    backgroundColor: items.find((item2)=>{return item.id === item2.id})?.color,
                                    borderRadius: "2px",
                                  }} > 
                                  
                                    </div>
                                    
                                  <div  style={{
                                    width: `${item.width - 4}px `,
                                    height: "12%",
                                    backgroundColor:  items.find((item2)=>{return item.id === item2.id})?.color,
                                    borderRadius: "2px",
                                  }} > 
                                  
                                    </div>
                                    
                                  <div  style={{
                                    width: `${item.width - 4}px `,
                                    height: "12%",
                                    backgroundColor:items.find((item2)=>{return item.id === item2.id})?.color,
                                    borderRadius: "2px",
                                  }} > 
                                  
                                    </div>
                                    <div  style={{
                                    width: `${item.width - 4}px `,
                                    height: "12%",
                                    backgroundColor: items.find((item2)=>{return item.id === item2.id})?.color,
                                      borderRadius: "2px",
                                      marginBottom:'2px'

                                    }} > 
                                    </div>
                                    <div  style={{
                                    width: `${item.width - 4}px `,
                                    height: "12%",
                                    backgroundColor: items.find((item2)=>{return item.id === item2.id})?.color,
                                      borderRadius: "2px",
                                    }} > 
                                    </div>
                                    <div  style={{
                                    width: `${item.width - 4}px `,
                                    height: "12%",
                                    backgroundColor:  items.find((item2)=>{return item.id === item2.id})?.color,
                                      borderRadius: "2px",
                                    }} > 
                                    </div>
                                    <div  style={{
                                    width: `${item.width - 4}px `,
                                    height: "12%",
                                    backgroundColor:  items.find((item2)=>{return item.id === item2.id})?.color,
                                      borderRadius: "2px",
                                    }} > 
                                    </div>
                                    <div  style={{
                                    width: `${item.width - 4}px `,
                                    height: "12%",
                                    backgroundColor:  items.find((item2)=>{return item.id === item2.id})?.color,
                                      borderRadius: "2px",
                                    }} > 
                                    </div>


                                </div>
                              ) : item.id === 2? (
                                <div
                                  style={{
                                    width: `${item.width}px`,
                                    height: "100%",
                                    display:'flex',
                                    flexDirection:'column',
                                    alignItems:  'center',
                                    justifyContent: 'space-between',
                                    boxSizing:'border-box',
                                    padding:'2px',
                                     gap:'1px',
                                    borderRadius: "3px",
                                    border: "1px solid",
                                    borderColor:  items.find((item2)=>{return item.id === item2.id})?.color,
                                  }}
                                >
                                  <div  style={{
                                    width: `${item.width - 4}px `,
                                    height: "69%",
                                    backgroundColor:  items.find((item2)=>{return item.id === item2.id})?.color,
                                    borderRadius: "2px",
                                  }} > 
                                    </div>
                                    <div  style={{
                                    width: `${item.width - 4}px `,
                                    height: "29%",
                                    backgroundColor:  items.find((item2)=>{return item.id === item2.id})?.color,
                                      borderRadius: "2px",
                                    }} > 
                                    </div>
                                  


                                </div>
                              ): item.id === 4 || item.id === 12 ? (
                                <div
                                  style={{
                                    width: `${item.width}px`,
                                    height: "100%",
                                    backgroundColor: items.find((item2)=>{return item.id === item2.id})?.color,
                                    borderRadius: "3px",
                                  }}
                                />
                              ) : item.id === 1 ? (
                                <div
                                  style={{
                                    width: `${item.width}px`,
                                    height: "100%",
                                    display:'flex',
                                    flexDirection:'column',
                                    alignItems:  'center',
                                    justifyContent: 'space-between',
                                    boxSizing:'border-box',
                                    padding:'2px',
                                     gap:'1px',
                                    borderRadius: "3px",
                                    border: "1px solid",
                                    borderColor:  items.find((item2)=>{return item.id === item2.id})?.color,
                                  }}
                                >
                                  <div  style={{
                                    width: `${item.width - 4}px `,
                                    height: "24%",
                                    backgroundColor:  items.find((item2)=>{return item.id === item2.id})?.color,
                                    borderRadius: "2px",
                                  }} > 
                                    </div>
                                    <div  style={{
                                    width: `${item.width - 4}px `,
                                    height: "24%",
                                    backgroundColor:  items.find((item2)=>{return item.id === item2.id})?.color,
                                      borderRadius: "2px",
                                    }} > 
                                    </div>
                                    <div  style={{
                                    width: `${item.width - 4}px `,
                                    height: "24%",
                                    backgroundColor: items.find((item2)=>{return item.id === item2.id})?.color,
                                      borderRadius: "2px",
                                    }} > 
                                    </div>
                                    <div  style={{
                                    width: `${item.width - 4}px `,
                                    height: "24%",
                                    backgroundColor:  items.find((item2)=>{return item.id === item2.id})?.color,
                                      borderRadius: "2px",
                                    }} > 
                                    </div>


                                </div>
                              ):(
                                <>
                                  <div
                                    style={{
                                      width: `${item.width}px`,
                                      height: "50%",
                                      backgroundColor:  item.color,
                                      borderRadius: "3px",
                                      marginBottom: "2px",
                                    }}
                                  />
                                  <div
                                    style={{
                                      width: `${item.width}px`,
                                      height: "50%",
                                      backgroundColor:  item.color,
                                      borderRadius: "3px",
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </Tooltip>
                        ))
                      : null;
                  })}
              </Paper>
            </div>
          );
        })}
      </div>
    ));
  };
  const buttons = [
    {
      text: "HNB",
      className: `${classes.buttonLeft} disabled`,
      color: "",
      disabled: true,
    },
    {
      text: "Veev One",
      className: "disabled",
      color: "#9747FF",
      disabled: false,
    },
    { text: "Veev Now", className: "", color: "#FF9F47", disabled: true },
    {
      text: "Levia",
      className: `${classes.buttonRight} disabled`,
      color: "#5DD671",
      disabled: true,
    },
  ];
 
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#F5F9FF",
        height:'100vh',
      }}
    >
      <div className={classes.buttonContainer} >
        {buttons.map((button) => (
          <Button
            key={button.text}
            className={`${classes.button} ${button.className} ${
            ""
            }`}
          //  onClick={() => !button.disabled && setSelectedButton(button.text)}
            disabled={true}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className={classes.buttonText}>{button.text}</div>
              {button.text == "HNB" ? (
                <div style={{ display: "flex", gap: "2px" }}>
                  <div
                    className={classes.buttonDot}
                    style={{ backgroundColor: "#0179FF" }}
                  ></div>
                  <div
                    className={classes.buttonDot}
                    style={{ backgroundColor: "#F95252" }}
                  ></div>
                  <div
                    className={classes.buttonDot}
                    style={{ backgroundColor: "#FFE455" }}
                  ></div>
                  <div
                    className={classes.buttonDot}
                    style={{ backgroundColor: "#A5ABAA" }}
                  ></div>
                </div>
              ) : (
                <div
                  className={classes.buttonDot}
                  style={{ backgroundColor: button.color }}
                ></div>
              )}
            </div>
          </Button>
        ))}
      </div>
      <div
        style={{
          backgroundColor: "#F5F9FF",
          height: "100vh",
          display: "flex",
          width: (columnWidths?.[0] || 50) * 5,
          padding: "0 10px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",

        }}
      >
        <div
          style={{
            alignSelf: "center",
            fontSize: "32px",
            color: "#9CA0AB",
            fontWeight: 800,
            marginBottom: "20px",
          }}
        >
  
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            //flexDirection: 'column',
            gap: (getFromJson<number>("rowDistance") as number) * 5 || 2,
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
          ref={containerRef}
        >
          <div style={{ position: "relative" }}>
            {renderSquares()}

            {pinkSquares.map((square, index) => (
              <div
                key={index}
                className={classes.pinkSquare}
                style={{
                  width: square.width * 5,
                  height: square.height * 5,
                  left: square.x,
                  top: square.y,
                  position: "absolute",
                }}
                onMouseDown={(e) => handlePinkSquareMouseDown(e, index)}
              >
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={() => removePinkSquare(index)}
                />
              </div>
            ))}
            {yellowSquares.map((square, index) => (
              <div
                key={index}
                className={classes.yellowSquare}
                style={{
                  width: square.width * 5,
                  height: square.height * 5,
                  left: square.x,
                  top: square.y ,
                  position: "absolute",
                }}

                onMouseDown={(e) => handleYellowSquareMouseDown(e, index)}
              >
                  <img  src={(square as any).image} width={square.width * 5} height={square.height * 5} style={{objectFit:'contain'}}>

</img>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={() => removeYellowSquare(index)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridDemo;


function hexToHexWithOpacity(hex: any, opacity: any) {
  // Remove the hash at the start if it's there
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Blend with white (255, 255, 255)
  r = Math.round(r + (255 - r) * opacity);
  g = Math.round(g + (255 - g) * opacity);
  b = Math.round(b + (255 - b) * opacity);

  // Convert to hex and pad with zeroes if necessary
  const blendedHex = `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;

  return blendedHex;
}