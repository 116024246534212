import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Sidebar from '../../components/RRP/Step1/sideBar/SideBar';
import DemoComponent from '../../components/RRP/Step1/Content/Content';
import { getFromJson, saveToJson } from '../../components/helpers/localData';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from 'react-router-dom';
import { createRRPNewConfig, getRRPNewConfigById, updateRRPNewConfig } from '../../services/rrpNewConfigurationService';
import { useSearchParams } from 'react-router-dom';

const AboutPage: React.FC = () => {
  const { id } = useParams<{ id?: string }>(); // Get the ID from the URL
  const initialCol = getFromJson<number>('colRRP') || 1;
  const initialRow = getFromJson<number>('rowRRP') || 1;
  const [searchParams] = useSearchParams();
  const index = searchParams.get('index');

  const [col, setCol] = useState(initialCol);
  const [row, setRow] = useState(initialRow);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch existing config if ID is present
    if (id) {
      const fetchConfig = async () => {
        setLoading(true);
        try {
          const existingConfig = await getRRPNewConfigById(id);
          setCol(existingConfig.col_position);
          setRow(existingConfig.row_position);
        } catch (error) {
          console.error('Error fetching RRP configuration:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchConfig();
    }
  }, [id]);

  useEffect(() => {
    saveToJson('colRRP', col);
  }, [col]);

  useEffect(() => {
    saveToJson('rowRRP', row);
  }, [row]);

  const handleNext = async () => {
    setLoading(true);
    try {
      const data = {
        col_position: col,
        row_position: row,
        marketingMaterials: [],
        ledScreens: [],
        squaresData: [],
        items: [],
        ccSpace: 8
      };

      if (id) {
        await updateRRPNewConfig(id, {     col_position: col,
          row_position: row,});
        navigate(`/rrp/step2/${id}/${index || ''}`);
      } else {
        // Create new configuration
        const res = await createRRPNewConfig(data);
        navigate(`/rrp/step2/${res.data.id}/${index || ''}`);
      }
    } catch (error) {
      console.error('Error creating or updating RRP configuration:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          <Sidebar
            row={row}
            onRowChange={(value) => {
              if (value > 12) {
                setRow(12);
              } else if (value < 1) {
                setRow(1);
              } else {
                setRow(value);
              }
            }}
            onNext={handleNext}
            loading={loading}
          />
        </Grid>
        <Grid item xs={9}>
          <div style={{ width: "100%", backgroundColor: "#e0e0e0" }}>
            <DemoComponent col={1} row={row} setCol={setCol} setRow={setRow} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AboutPage;
