import React from "react";
import StoreCard from "../StoreCard/StoreCard";

interface Store {
  name: string;
  posId: string;
  address: string;
  region: string;
  visibility: boolean;
  id: number;
  iqosPartner: boolean;
  keyAccountId: number;
  type: "individual" | "key account";
  size: string;
  channelId: number;
}

interface StoreCardsListProps {
  stores: Store[];
  onEdit: (id: number) => void;
}

const StoreCardsList: React.FC<StoreCardsListProps> = ({ onEdit, stores }) => {
  const gridStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
    gap: "20px",
    padding: "20px 0",
  };

  return (
    <>
      <div style={gridStyle}>
        {stores.map((store, index) => (
          <StoreCard
            key={index}
            name={store.name}
            posId={store.posId}
            address={store.address}
            region={store.region}
            visibility={store.visibility}
            id={store.id}
            iqosPartner={store.iqosPartner}
            keyAccountId={store.keyAccountId}
            type={store.type}
            size={store.size}
            channelId={store.channelId}
            onEdit={onEdit}
          ></StoreCard>
        ))}
      </div>
    </>
  );
};

export default StoreCardsList;
