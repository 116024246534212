import React, { useRef, useState } from "react";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { getFromJson } from "../../helpers/localData";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customBox: {
      borderRadius: "5.134px",
      background: "transparent",
      width: "186.103px",
      //height: "390.994px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      gap:"2px",
      position: "relative",

    },
  
  
    line: {
      borderRight: "2px solid #000", // Change the color as needed
      height: "100%", // Height set dynamically to match the combined height
      position: "relative",
    },
    
    
    subBox: {
      width: "176.143px",
      height: "36.5px",
      background:" #EFEFEF",
      borderRadius: "4px",
      display: "flex",
      justifyContent:"center",
      alignItems: "center",
      position: "relative",
    },
    secondBox: {
      width: "177.103px",
      height: "82.142px",
      flexShrink: 0,
      borderRadius: "3.85px",
      background: "#EFEFEF",
      // marginTop: "10px",
    },
    selectedBox: {
      backgroundColor: "#0179FF !important",
    },
    selectionBox: {
      position: "absolute",
      border: "2px solid #0179FF",
      zIndex: 1000,
      pointerEvents: "none",
      cursor: "pointer",
      backgroundColor:"rgba(1, 121, 255, 0.50)",
      "&:active":{
        cursor: "pointer",
      }
    },
    
    whiteBox: {
      backgroundColor: "#FFFFFF",
      borderRadius: "4px",
      height: "28.5px",
      margin: "2px 2px",
      userSelect: "none",
      width: "10px", // Box width set to 3px
 
    },
  })
);

interface GridDemoProps {}

const GridDemo: React.FC = () => {
  const boxCount = 10;
  const setCount = 10; // Number of sets of boxes
 
  const [columnWidths, setColumnWidths] = useState<number[]>(() => {
    // Assuming the widths are stored under the key 'columnWidths'
    return getFromJson<number[]>('columnWidths') || [176.143]; // Default width if not found
  });
  
  console.log("columnWidths",columnWidths)
  const customBoxCount = columnWidths.length; // Number of customBox instances
  const initializeSelectedBoxes = () => {
    return columnWidths.map(columnWidth => {
      const boxesInColumn = Math.floor(columnWidth / 10); // Calculate the number of boxes per column dynamically
      return new Array(getFromJson<number>('row') || 2)
        .fill(null)
        .map(() => new Array(boxesInColumn).fill(false)); // Initialize each set with the correct number of boxes
    });
  };
  const [selectedBoxes, setSelectedBoxes] = useState(() => initializeSelectedBoxes());

  const [selectionBox, setSelectionBox] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [isSelecting, setIsSelecting] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const handleMouseDown = (event: React.MouseEvent) => {
    const containerBounds = containerRef.current?.getBoundingClientRect();
    if (!containerBounds) return;

    const x = event.clientX - containerBounds.left;
    const y = event.clientY - containerBounds.top;
    setStartPoint({ x, y });
    setSelectionBox({ x, y, width: 0, height: 0 });
    setIsSelecting(true);
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (!isSelecting || !containerRef.current) return;
    const containerBounds = containerRef.current.getBoundingClientRect();

    const x = Math.min(event.clientX - containerBounds.left, startPoint.x);
    const y = Math.min(event.clientY - containerBounds.top, startPoint.y);
    const width = Math.abs(event.clientX - containerBounds.left - startPoint.x);
    const height = Math.abs(event.clientY - containerBounds.top - startPoint.y);
    setSelectionBox({ x, y, width, height });
  };
  const handleMouseUp = () => {
    if (!isSelecting || !containerRef.current) return;
    setIsSelecting(false);
  
    // Constants
    const boxWidth = 15; // Fixed width of each box
    const boxHeight = 36.5; // Assuming a fixed height for simplicity
  
    const containerBounds = containerRef.current.getBoundingClientRect();
    const selectionStartX = selectionBox.x;
    const selectionEndX = selectionStartX + selectionBox.width;
    const selectionStartY = selectionBox.y;
    const selectionEndY = selectionStartY + selectionBox.height;
  
    // Calculate column offsets dynamically based on actual column widths
    let columnOffsets = columnWidths.reduce((acc:any, curr, idx) => {
      if (idx === 0) {
        acc.push(0);
      } else {
        acc.push(acc[idx - 1] + columnWidths[idx - 1]);
      }
      return acc;
    }, []);
   
  
    let updatedSelectedBoxes = selectedBoxes.map((customBox, customBoxIndex) => 
      customBox.map((set, setIndex) => 
        set.map((isSelected, boxIndex) => {
          const columnOffset = columnOffsets[customBoxIndex];
          const dynamicBoxCount = Math.floor(columnWidths[customBoxIndex] / boxWidth); 
         
          
          // Calculate boxes per column dynamically
          //if (boxIndex >= dynamicBoxCount) return false; // Ignore boxes that exceed the dynamic count
  
          const boxStartX = columnOffset + (boxIndex * (columnWidths[customBoxIndex]/dynamicBoxCount  )) +((getFromJson<number>('rowDistance') || 2)* customBoxIndex) ;
          const boxEndX = boxStartX + (columnWidths[customBoxIndex]/dynamicBoxCount  );
          const boxTopY = (setIndex) * (boxHeight+20) ; // Adjust if your layout differs
          const boxBottomY = boxTopY + (boxHeight  );
  
          // Check if the box intersects with the selection area
          const intersectsSelection = !(
            boxEndX < selectionStartX ||
            boxStartX > selectionEndX ||
            boxBottomY < selectionStartY ||
            boxTopY > selectionEndY
          );
  
          return isSelected || intersectsSelection;
        })
      )
    );
    setSelectedBoxes(updatedSelectedBoxes);
  };

  const renderBoxes = (customBoxIndex:number, setIndex:number, totalWidth:number) => {
    // Fixed box width
    const boxWidth = 15; // Fixed width of each box
  
    // Calculate the dynamic number of boxes that can fit in the totalWidth of the column
    const dynamicBoxCount = Math.floor(totalWidth / boxWidth);
  
    return Array.from({ length: dynamicBoxCount }, (_, boxIndex) => (
      <div
        key={`${customBoxIndex}-${setIndex}-${boxIndex}`}
        className={`${classes.whiteBox} ${selectedBoxes[customBoxIndex][setIndex][boxIndex] ? classes.selectedBox : ""}`}
        style={{ width: `${boxWidth}px` }} // Use fixed width
      />
    ));
  };
  

  return (
    <div
    style={{
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#F5F9FF",
      
    }}
  >
    <div
    style={{
      backgroundColor: "#F5F9FF",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap:"5px"}}
  >
    <div
    style={{
      backgroundColor: "#F5F9FF",

      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <div
      ref={containerRef}
      style={{ display: "flex", flexDirection: "row", position: 'relative',gap:(getFromJson<number>('rowDistance') || 2),cursor: isSelecting ? 'pointer' : 'default', }} // Added position relative here
      // onMouseDown={handleMouseDown}
      // onMouseMove={handleMouseMove}
      // onMouseUp={handleMouseUp}
    >
      {isSelecting && (
        <div
          className={classes.selectionBox}
          style={{
            left: selectionBox.x,
            top: selectionBox.y,
            width: selectionBox.width,
            height: selectionBox.height,
          }}
        />
      )}
      {Array.from({ length: customBoxCount }).map((_, customBoxIndex) => (
       <div style={{display:"flex",flexDirection:'column', justifyContent:"center",alignItems:"center",gap:(getFromJson<number>('rowDistance') || 2)}}>
       <div key={customBoxIndex} className={classes.customBox} style={{gap: (getFromJson<number>('colDistance') || 2),width: `${columnWidths[customBoxIndex ]}px` }}>
       
       {Array.from({ length: getFromJson<number>('row') || 2 }).map((_, setIndex) => (
            <div
              key={`${customBoxIndex}-${setIndex}`}
              className={classes.subBox}
              style={{ width: `${columnWidths[customBoxIndex ]}px` }} // Dynamically set width
            >
              {renderBoxes(customBoxIndex, setIndex,columnWidths[customBoxIndex ])}
            </div>
          ))}
        </div>
        
         {/* <div className={classes.secondBox}></div> */}

       </div> 
      ))}
    </div>
    </div>
    </div>
    </div>
  );
};

export default GridDemo;
