import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, IconButton, Typography, Snackbar, Alert } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { ReactComponent as Close } from "../../../../assets/icons/Close.svg";
import { ReactComponent as Open } from "../../../../assets/icons/Open.svg";
import { useStyles } from "./SideBarStyles";
import AddMaterialMarketing from "../AddMaterialMarketing/AddMaterialMarketing";
import MarketingMaterialList from "../MarketingList/MarketingMaterialList";
import SearchInput from "../SearchInput/SearchInput";
import { createMarketingMaterial, deleteMarketingMaterial, getAllMarketingMaterials, searchMarketingMaterialsByName, updateMarketingMaterial } from "../../../../services/marketingMaterialsService";
import { useParams } from "react-router-dom";
import { getRRPNewConfigById } from "../../../../services/rrpNewConfigurationService";
import { deleteLedScreen } from "../../../../services/ledScreensService";
import EditMaterialMarketing from "../EditMaterialMarketing/AddMaterialMarketing";

interface SidebarProps {
  onChange: (value: number) => void;
  onAddShelf: (width: string, height: string, title: string) => void;
  onAddMaterial: (material: MarketingMaterial) => void;
  squaresYellow: any;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface MarketingMaterial {
  id?: number;
  title: string;
  width: number;
  height: number;
  x: number;
  y: number;
  image?: string;
}

const SideBarRightYellow: React.FC<SidebarProps> = ({ onChange, onAddShelf, onAddMaterial, squaresYellow, loading, setLoading }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [materials, setMaterials] = useState<MarketingMaterial[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const { id } = useParams<{ id: string }>();
  const [isEditing, setIsEditing] = useState(false);
  const [materialsToEdit, setMaterialsToEdit] = useState<MarketingMaterial[]>([]);

  const fetchMaterials = async (searchQuery = '') => {
    try {
      setLoading(true);
      const shelfConfig = id && await getRRPNewConfigById((id)); // Replace with the actual shelf config ID
      const marketingMaterialIds = shelfConfig.marketingMaterials || [] ;
      const fetchedMaterials = searchQuery
        ? await searchMarketingMaterialsByName(searchQuery)
        : await getAllMarketingMaterials();

      const materialCountMap = new Map<string, number>();
      marketingMaterialIds.forEach((item: any) => {
        const count = materialCountMap.get(item.id.toString()) || 0;
        materialCountMap.set(item.id.toString(), count + 1);
      });

      const materialsWithIsAdded = fetchedMaterials.map((material: MarketingMaterial) => {
        const isAdded = materialCountMap.get(material.id?.toString() || '') || 0;
        return {
          ...material,
          isAdded
        };
      });

      setMaterials([...materialsWithIsAdded]);
      console.log('materialsWithIsAdded', materialsWithIsAdded);
    } catch (error) {
      console.error('Failed to fetch marketing materials:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMaterials();
  }, [squaresYellow,id]);

  const handleAddMaterial = async (title: string, width: number, height: number, image?: File) => {
    try {
      setLoading(true);
      const newMaterial = await createMarketingMaterial(title, width, height, image);
      setMaterials([ { ...newMaterial, x: 0, y: 0 }]);
      setIsAdding(false);
      await fetchMaterials();
      setSnackbarMessage('Marketing material added successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setLoading(false);
    } catch (error) {
      console.error('Failed to add marketing material:', error);
      setSnackbarMessage('Failed to add marketing material');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
    await fetchMaterials(query);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleRemoveClick = async (id: number) => {
    try {
      setLoading(true); // Optional: Show a loading indicator
      await deleteMarketingMaterial(id); // Call the delete function
      setSnackbarMessage('Marketing material deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      await fetchMaterials(); // Refresh the list after deletion
    } catch (error) {
      console.error('Failed to delete marketing material:', error);
      setSnackbarMessage('Failed to delete marketing material');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Stop the loading indicator
    }
  };
  const handleDeleteLED = async (id: number) => {
    try {
      setLoading(true); // Optional: Show a loading indicator
      await deleteLedScreen(id); // Call the delete function
      setSnackbarMessage('LED Screen deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      await fetchMaterials(); // Refresh the list to reflect the deletion
    } catch (error: any) {
      console.error(`Failed to delete LED Screen with ID ${id}:`, error);
      setSnackbarMessage(error.message || 'Failed to delete LED Screen');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Ensure loading is stopped regardless of success or failure
    }
  };
  const handleUpdateMaterial = async (id: number, title: string, width: number, height: number, image?: File) => {
    try {
      setLoading(true);
      await updateMarketingMaterial(id, title, width, height, image);
      await fetchMaterials(); // Refresh the list after updating
      setIsEditing(false);
      setSnackbarMessage('Marketing material updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to update marketing material:', error);
      setSnackbarMessage('Failed to update marketing material');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box className={`${classes.container} ${isOpen ? classes.sidebarOpen : classes.sidebarClosed}`}>
      <IconButton className={classes.toggleButton} onClick={toggleSidebar}>
        <Box component="span" className={classes.iconButton}>
          {isOpen ? <Close /> : <Open />}
        </Box>
      </IconButton>
      <div className={classes.buttonContainer} style={{ justifyContent: (isAdding || isEditing) ? "flex-start" : "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h4" sx={{ marginBottom: "12px", fontSize: "20px !important" }}>Marketing Materials</Typography>
          <Typography variant="h6" sx={{ marginBottom: "50px", maxWidth: "244px" }}>Choose your Marketing Material here</Typography>
          {!isAdding  && !isEditing && (
            <>
              <Button
                color="primary"
                variant="contained"
                sx={{ marginBottom: "10px !important", minWidth: "301px", justifyContent: "flex-start" }}
                onClick={() => setIsAdding(true)}
                startIcon={<Box component="span" className={classes.iconButton}><AddIcon /></Box>}
              >
                Add a material
              </Button>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <SearchInput
                  label={"TYPE OF LED SCREEN"}
                  type={"text"}
                  value={searchQuery}
                  unit={""}
                  onValueChange={handleSearch}
                  onUnitChange={() => {}}
                  width={280}
                  placeholder="Search for marketing Materials"
                />
                {loading && (
                  <Box display="flex" justifyContent="center" alignItems="center" height="100%" marginTop={'40px'}>
                    <CircularProgress size={20} />
                  </Box>
                )}
              </div>
            </>
          )}
        </div>
        {isAdding ? (
          <AddMaterialMarketing
            onCancel={() => setIsAdding(!isAdding)}
            addToShelf={(width, height, title, image) => image && handleAddMaterial(title, parseFloat(width), parseFloat(height), image)}
            type={2}
            color={"#FFE455"}
          />
        ) : isEditing ?
        <EditMaterialMarketing
          onCancel={() => setIsEditing(!isEditing)}
          onContinue={(obj)=>{ handleUpdateMaterial(obj.id,obj.title,parseInt(obj.width),parseInt(obj.height),obj.image ? obj.image : undefined)}}
          initialValues={{
            id: (materialsToEdit as any).id,
            width: (materialsToEdit as any).width,
            height:  (materialsToEdit as any).height,
            title: (materialsToEdit as any).title,
            imageUrl:(materialsToEdit as any).image
          }}
          type={2}
          color={"#FFE455"}
        /> :  (
          <MarketingMaterialList
            materials={materials as any}
            handleAddToShelf={(mat) => onAddMaterial(mat as any)}
            color={"#FFE455"}
            loading={loading}
            setIsEditing={setIsEditing}
            setMaterialsToEdit={setMaterialsToEdit}
            remove={(id,type)=>{
              if(type==='M') {
                handleRemoveClick(id)
              }else {
                handleDeleteLED(id)
              }
            }}
          />
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SideBarRightYellow;
