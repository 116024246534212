import React, { useState } from "react";
import { TextField, Grid, Paper, IconButton, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Typography from "@mui/material/Typography";

import { Box, Button } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { Remove as RemoveIcon } from "@mui/icons-material";
import { wrap } from "module";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      // marginTop: theme.spacing(2),
      margin: "-5px", // Adjust this value as needed
      width: "auto",
      "& > .MuiGrid-item": {
        padding: "5px", // Adjust this value as needed
      },
    },
    flexContainer: {
      display: "flex",
      margin: `2px`,
    },
    flexItem: {
      padding: `2px 3px`,
    },
    square: {
      width: 137,
      height: 54,
      backgroundColor: "#B5D8FF",
      boxShadow: "none",
    },
    controlBar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(2),
      backgroundColor: "#D9E7F8",
      width: "100%",
      borderRadius: "3px",

      // alignSelf: "start",
    },
    verticalControlBar: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "0 34px",
      backgroundColor: "#D9E7F8",

      borderRadius: "3px",
      alignItems: "center",
      minWidth: "82px",
      marginLeft: theme.spacing(2),
      height: "100%", // Make the vertical bar take the height of the squares
    },
    iconButton: {
      width: 28,
      height: 28,
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      color: "#0179FF",
    },
    iconButton2: {
      padding: 6, // Set padding to 0 to remove the default padding
    },
  })
);

interface GridDemoProps {
  row: number;
  col: number;
  rowDistance: number;
  setRowDistance: React.Dispatch<React.SetStateAction<number>>;
  colDistance: number;
  setColDistance: React.Dispatch<React.SetStateAction<number>>;
  setRow: React.Dispatch<React.SetStateAction<number>>;
  setCol: React.Dispatch<React.SetStateAction<number>>;
}

const GridDemo: React.FC<GridDemoProps> = ({
  row,
  col,
  setRow,
  setCol,
  rowDistance,
  colDistance,
}) => {
  const classes = useStyles();

  const handleAddRow = () => {
    setRow(row + 1);
  };

  const handleRemoveRow = () => {
    if (row > 1) {
      setRow(row - 1);
    }
  };

  const handleAddColumn = () => {
    setCol(col + 1);
  };

  const handleRemoveColumn = () => {
    if (col > 1) {
      setCol(col - 1);
    }
  };

  const renderSquares = () => {
    const rowsArray = [];
    for (let i = 0; i < row; i++) {
      const rowSquares = [];
      for (let j = 0; j < col; j++) {
        rowSquares.push(
          <div
            key={`${i}-${j}`}
            style={{ padding: `2px 2px`  }}
          >
            <Paper className={classes.square}></Paper>
          </div>
        );
      }
      rowsArray.push(
        <div key={i} className={classes.flexContainer}>
          {rowSquares}
        </div>
      );
    }
    return rowsArray;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F5F9FF",
      }}
    >
      <div
        style={{
          backgroundColor: "#F5F9FF",
          height: "100vh",
          display: "flex",
          width: "fit-content",
          padding:"0 10px",
          overflow :"auto",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
    
        <div style={{ display: "flex" }}>
          <div>{renderSquares()}</div>
          <div
            className={classes.verticalControlBar}
            style={{
              height: `calc(56px * ${row} + ${
                2 * ((row - 1) * 2)
              }px  )`,
              marginTop: `${2}px`,
            }}
          >
            <Typography variant="h3">Drawers {row}</Typography>
            <div>
              <IconButton className={classes.iconButton2} onClick={handleAddColumn} size="large">
                <Box component="span" className={classes.iconButton}>
                  <AddIcon />
                </Box>
              </IconButton>

              <IconButton className={classes.iconButton2} onClick={handleRemoveColumn} size="large">
                <Box component="span" className={classes.iconButton}>
                  <RemoveIcon />
                </Box>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridDemo;
