import React from "react";
import { Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface PlanogramModalProps {
  open: boolean;
  handleClose: () => void;
  planogramList: { id: number; name: string; last_updated_at: string }[];
  onValueSelected: (selected: { id: number; name: string; last_updated_at: string }) => void;
  anchorEl: HTMLElement | null; // Reference to the element for positioning
}

const PlanogramModal: React.FC<PlanogramModalProps> = ({
  open,
  handleClose,
  planogramList,
  onValueSelected,
  anchorEl,
}) => {
  const handleSelect = (planogram: { id: number; name: string; last_updated_at: string }) => {
    onValueSelected(planogram);
    handleClose();
  };

  // Calculate modal position and width based on the anchor element
  const modalStyles = anchorEl
    ? {
        top: anchorEl.getBoundingClientRect().top + window.scrollY -30 , // Slightly above the element
        left: anchorEl.getBoundingClientRect().left,
        width: `${anchorEl.offsetWidth}px`, // Match the width of the anchor element
      }
    : { top: 0, left: 0, width: "auto" };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "transparent", // Transparent modal background
          boxShadow: "none",
          padding: "0",
          overflow:'hidden',
          position: "absolute", // Allow absolute positioning
          top: `${modalStyles.top}px`,
          left: `${modalStyles.left-30}px`,
          width: modalStyles.width, 
           maxWidth:1000,// Set the width dynamically
          transform: "none", // Prevent default centering
        },
      }}
    >
      <DialogContent sx={{padding:0}}>
        {planogramList.length > 0 ? (
          planogramList.map((planogram,index) => (
            <div
              key={planogram.id}
              onClick={() => handleSelect(planogram)}
              style={{
                //width: "100%",
                background: "white",
                padding: "14px",
                borderRadius: "10px",
                marginTop: "4px",
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  color: "#383A49",
                  textAlign: "start",
                  alignSelf: "flex-start",
                  marginBottom: "10px",
                  width: "100%",
                }}
              >
                Active Planogram :{" "}
                {planogram.name ? planogram.name : "Draft " + planogram.id}
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    color: "#0179FF",
                    textAlign: "start",
                    marginBottom: "10px",
                  }}
                >
                  Last updated:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    textAlign: "start",
                    color: "#0179FF",
                    marginBottom: "10px",
                  }}
                >
                  {new Date(planogram.last_updated_at).toLocaleDateString()}
                </Typography>
            {     <IconButton
          onClick={() => {}}
          sx={{
            padding: "8px",
            visibility: index!=0 ?'hidden' : 'visible', 
            background: "#F5F9FF",
            borderRadius: "50%",
            marginTop:'-30px',
            alignSelf:'center',
            "&:hover": {
              background: "#e1e5ea",
            },
          }}
        >
 <ExpandMoreIcon />
         </IconButton>}
              </div>
          
            </div>
          ))
        ) : (
          <Typography variant="body1" sx={{ color: "#9CA0AB" }}>
            No planograms available.
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PlanogramModal;
