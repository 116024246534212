import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './authConfig';
import { MsalProvider } from '@azure/msal-react';
i18next.init({
  interpolation: { escapeValue: false }, // React already escapes values
  lng: "en", // default language
  resources: {
    en: {
      translation: require("./locales/en/translation.json"),
    },
    es: {
      translation: require("./locales/es/translation.json"),
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const msalInstance = new PublicClientApplication(msalConfig);
root.render(
    <I18nextProvider i18n={i18next}>
            <MsalProvider instance={msalInstance}>
      <App />
      </MsalProvider>
    </I18nextProvider>
);
