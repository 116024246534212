import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { ReactComponent as Upload } from "../../../../assets/icons/Upload.svg";

interface ImageUploadProps {
    onUpload: (file: File) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onUpload }) => {
    const [selectedImage, setSelectedImage] = useState<File | null>(null);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            setSelectedImage(file);
            onUpload(file);
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleImageChange}
            />
            <label htmlFor="raised-button-file">
                <Button
                    variant="contained"
                    component="span"
                    sx={{
                        width: "210px",
                        borderRadius: "10px",
                        marginTop: "0",
                        marginBottom: "4px"
                    }}
                    startIcon={<Upload />}
                >
                    Upload
                </Button>
            </label>
            <Typography
                variant="h5"
                sx={{
                    fontSize: "12px",
                    color: "#9CA0AB",
                    maxWidth: "220px",
                    marginTop: "4px"
                }}
            >
                Available formats PNG, JPG, JPEG click to browse (3MB max)
            </Typography>
            {selectedImage && (
                <Typography variant="body2" sx={{ marginTop: "4px" }}>
                    {selectedImage.name}
                </Typography>
            )}
        </div>
    );
};

export default ImageUpload;
